import { apiUrl2, unizHomeUrl } from '../../../settings'
import { Unizhome_Url } from '../../../api'

export const getUnizHomePropertyListUrl = () => {
  return Unizhome_Url + '/v2/get-property-list'
}
export const updateUnizhomePropertyUrl = () => {
  return Unizhome_Url + '/v2/update-property'
}
export const updatePropertyFaqUrl = () => {
  return unizHomeUrl + '/v2/update-faqs'
}
export const addUnizhomeFaqUrl = () => {
  return unizHomeUrl + '/v2/add-faq'
}
export const deleteUnizhomeFaqUrl = () => {
  return unizHomeUrl + '/v2/delete-faq'
}
export const addUnizhomePolicyUrl = () => {
  return unizHomeUrl + '/v2/add-policy'
}
export const updateRoomDescriptionUrl = () => {
  return unizHomeUrl + '/unizhome/update-room-description'
}
export const deleteUnizhomePolicyUrl = () => {
  return unizHomeUrl + '/v2/delete-policy'
}
export const addUnizhomePropertyUrl = () => {
  return unizHomeUrl + '/v2/add-property'
}
export const addUnizhomeRoomUrl = () => {
  return unizHomeUrl + '/v2/add-room'
}
export const editUnizhomeRoomUrl = () => {
  return Unizhome_Url + '/v2/edit-room'
}
export const editUnizhomeActiveChildRoomUrl = () => {
  return Unizhome_Url + '/v2/edit-room-active-children'
}
export const deleteUnizhomeRoomUrl = () => {
  return unizHomeUrl + '/v2/delete-room'
}
export const deleteUnizhomeActiveChildRoomUrl = () => {
  return unizHomeUrl + '/v2/delete-room-active-children'
}
export const updateUnizhomePolicyUrl = () => {
  return unizHomeUrl + '/v2/update-policy'
}

export const unizhomeSinglePropertyUrl = () => {
  return Unizhome_Url + '/v2/get-single-property'
}
// export const enquiryDocumentsUrl = () => {
//   return Unizhome_Url + '/unizhome/enquiry-document'
// }

export const enquiryDocumentsUrl = () => {
  return Unizhome_Url + '/unizhome/update-property-image'
}
export const addPropertyImageUrl = () => {
  return Unizhome_Url + '/unizhome/upload-property-image'
}
export const getSuggestPropertyUrl = () => {
  return unizHomeUrl + '/unizhome/get-suggested-properties'
}


export const updatePropertyFeatureUrl = () => {
  return unizHomeUrl + '/unizhome/update-features'
}


export const commonAmenitiesUrl = () => {
  return unizHomeUrl + '/v2/get-common-amenities'
}


export const getAmenitiesCategoryUrl = () => {
  return unizHomeUrl + '/v2/get-amenities-category'
}

export const commonRoomAmenitiesUrl = () => {
  return unizHomeUrl + '/v2/get-common-amenities'
}

export const updatePropertyActiveStatusUrl = () => {
  return unizHomeUrl + '/v2/update-property-status'
}
export const updateRoomActiveStatusUrl = () => {
  return unizHomeUrl + '/v2/update-room-status'
}

export const addUnizhomeSubRoomUrl = () => {
  return unizHomeUrl + '/v2/add-room-active-children'
}
export const getMediaByRoomIdUrl = () => {
  return unizHomeUrl + '/v2/property-media'
}
export const deleteRoomMediaImageUrl = () => {
  return unizHomeUrl + '/unizhome/delete-room-media-Image'
}

export const getAmenitiesUrl = () => {
  return unizHomeUrl + '/v2/get-amenities'
}

export const getMediaUrl = () => {
  return unizHomeUrl + '/v2/get-media'
}
export const getRoomsUrl = () => {
  return unizHomeUrl + '/v2/get-rooms'
}
export const getRoomUnitTypeUrl = () => {
  return unizHomeUrl + '/v2/rooms/unit-types'
}
export const addAmenitiesUrl = () => {
  return unizHomeUrl + '/v2/add-amenities'
}
export const deleteAmenitiesUrl = () => {
  return unizHomeUrl + '/v2/delete-amenities'
}
export const updateFeaturedImageUrl = () => {
  return unizHomeUrl + '/v2/update-featured-image'
}
export const updateRoomFeaturedImageUrl = () => {
  return unizHomeUrl + '/v2/update-room-featured-image'
}
