import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import { addXTravelTransferOtpUrl, addXTravelTransferUrl, getAllTransfer, getSingleUserApiUrl } from '../api'
import { listWithdrawalUrl } from '../../withdrawal/api/withdrawalApi'
import { getSingleUserUrl } from '../../users/api/user'

export const addXTravelTransferOtpFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addXTravelTransferOtpUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addXTravelTransferFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addXTravelTransferUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const listAllTransferFunctionFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAllTransfer(), config)
  dispatch(hidePageLoad())

  return data.data
}

export const getSingleUserFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(getSingleUserApiUrl(), config)
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
