import React from 'react'
import { Button, Col, Modal, Form, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addUnizhomeFaqFxn } from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const AddPropertyFaq = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, form: { getFieldDecorator }, propertyId, onSubmit } = props
  let inputTypes = {
    fields: [
      {
        key: 'question',
        label: 'Question',
        placeholder: 'Add Question',
        type: 'text',
        required: true
      },
      {
        key: 'answer',
        label: 'Answer',
        placeholder: 'Add Answer',
        type: 'editor',
        required: true,
        rows: 4
      }
    ]
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const resp = await dispatch(addUnizhomeFaqFxn({ propertyId, ...valData }))
        if (resp && resp.success) {
          notification.success({ message: resp.message })
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }


  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Add`}
        width={'60%'}
        footer={null}>
        <div className='form-box commissionBox'>
          <div className='card unizportal'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={24} key={key}>

                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={{
                            labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                            wrapperCol: { xs: { span: 24 } }
                          }}
                        />

                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary' htmlType='submit' className={'btn mt40'}>
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const AddPropertyFaqModal = Form.create()(AddPropertyFaq)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPropertyFaqModal)
