import React, {useRef, useState} from "react"
import { Button, Card, DatePicker, Drawer, Icon, notification, Popconfirm, Table,Tooltip } from 'antd'
import TableComp from "../../components/_utils/table";
import {useDispatch} from "react-redux";

import renderHTML from "react-render-html";
import { generateInvoiceCommissionFxn } from '../applications/actions/chooseStudent'
import { commissionInvoiceListByStudentFxn, commissionInvoiceListFxn, payCommission } from './actions'
import { useEffect } from 'react/index'
const { MonthPicker } = DatePicker;
// const SingleStructure = (props) => {
//   let {data} = props;
//   let [hideV, setHideV] = useState(true);
//
//   return (
//     <>
//       <div className={hideV ? 'structureDotsReplace' : ''} style={{width: "100%", textWrap: "wrap"}}>
//         {data ? renderHTML(data) : ''}
//       </div>
//       {data && data.length > 50 ? (
//         <div className={'alignRight'}>
//           {hideV ? (
//             <a className={'moreBtn'}
//                onClick={() => {
//                  setHideV(false)
//                }}>
//               Show More
//             </a>
//           ) : (
//             <a className={'moreBtn'}
//                onClick={() => {
//                  setHideV(true)
//                }}>
//               Show Less
//             </a>
//           )}
//         </div>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }


const CommissionInvoiceDrawer = (props) => {
  let tableRef = useRef()
  let dispatch = useDispatch()
  let {courseId, visible, onClose,  allApplication} = props;
  let [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dates, setDates] = useState(null);
  const [invoiceList, setInvoiceList] = useState([]);
  const apiRequest = () => {

    return new Promise(async (resolve) => {
      let params = {
        InvoiceDate: selectedDate,
      }
      let data = await dispatch(commissionInvoiceListByStudentFxn(params))
      console.log(data.data, "Ssssssssssssssssss")
      setInvoiceList(data.data.respData)
      resolve({data:data.data.respData})

    });
  };
  console.log(invoiceList, "aaaaaaaaaaaaaaae")
  let columns = [
    {
      title: "Student Name",
      key: "name",
      dataIndex: "name",
      render: (item, record) => {

        return (
          <>
            {item}
          </>
        )
      }
    },
    {
      title: "Student Id",
      key: "studentId",
      dataIndex: "studentId",
      render: (item, record) => {

        return (
          <>
            {item}
          </>
        )
      }
    },
    {
      title: "Program Name",
      key: "courseName",
      dataIndex: "courseName",
      render: (item, record) => {

        return (
          <>
            {record.applications.courseName}
          </>
        )
      }
    },
    {
      title: "Tuition Fee",
      key: "tuitionFee",
      dataIndex: "tuitionFee",
      render: (item, record) => {

        return (
          <>
            {record.applications.tuitionFee}
          </>
        )
      }
    },
    {
      title: "Commission",
      key: "commission",
      dataIndex: "commission",
      render: (item, record) => {

        return (
          <>
            {"40%"}
          </>
        )
      }
    },
    {
      title: "Total Amount",
      key: "tuitionFee",
      dataIndex: "tuitionFee",
      render: (item, record) => {
        const total = record.applications.tuitionFee * 0.4;
        return (
          <>
            {total.toFixed(2)}
          </>
        )
      }
    },
  ]
  const handleDateChange = (date,dateString) => {
    setSelectedDate(date);
    setDates(dateString);


  };

  const clearFxn = () => {
    setInvoiceList([])
    setSelectedDate(null)
  }
  let exportPdf = async () => {

    if (!selectedDate) {
      notification.error({
        message: 'Please select a date to generate invoice'
      })
      return;
    }
    if (invoiceList && invoiceList.length === 0) {

      notification.error({
        message: 'Invoice cannot generated for the selected month'
      })
      return;
    }

    try {

      let date = typeof dates === 'string' ? new Date(dates) : dates;

      let obj = {
        InvoiceDate: selectedDate,
        intake: {
          month: date.getMonth() + 1,
          year: date.getFullYear()
        },
      };

      const resp = await dispatch(generateInvoiceCommissionFxn(obj));
      setSelectedDate('')
    } catch (err) {
      console.log(err)
    }

  }

  return (
    <>
      <Drawer
        visible={visible} onClose={onClose}
        title={(
          <>
            Invoice

          </>
        )}
        width={"80%"}>
        <div className="form-box ml-3 mb-4">
          <div className="row ml-auto align-items-center">
            <div className='sort-box-table mark-btn mt-3'>
        <span className={'sort-box-table-right'}>
            <MonthPicker className="custom-month-picker"  value={selectedDate} onChange={handleDateChange} placeholder="Select month" />
        </span>
            </div>

              <Button  onClick={() =>{apiRequest()}} className={'roundBtn mt-3'}>Search</Button>

            <Button  onClick={() =>{clearFxn()}} className={'roundBtn mt-3'}>Clear</Button>

            <Button className={'roundBtn btn-sm mt-3 ml-auto mr-5'} onClick={() => exportPdf()}>
              Generate Invoice
            </Button>
          </div>
          <div className="card mt-3">
            {/*<TableComp*/}
            {/*  bordered={true}*/}
            {/*  apiRequest={apiRequest}*/}
            {/*  ref={tableRef}*/}
            {/*  columns={columns}*/}
            {/*  pagination={{*/}
            {/*    defaultPageSize: 20,*/}
            {/*    pageSizeOptions: ['20', '50', '100'],*/}
            {/*    position: "bottom"*/}
            {/*  }}/>*/}
            <Table dataSource={invoiceList} columns={columns} />
          </div>
        </div>
      </Drawer>


    </>
  )
}
export default CommissionInvoiceDrawer
