import React, { useEffect, useState } from 'react'
import { Select, Spin } from 'antd'
import { getAllExpenseCategoryFxn } from './action'
import debounce from 'lodash/debounce'
import _ from 'lodash'
import InputBox from '../../components/_utils/InputBox'

const { Option } = Select

class ExpenseCategoryTypeheadComponent extends React.Component {
  state = {
    arrList: [],
    value: [],
    fetching: false,
    name: '',
    expenseCategoryId: ''
  }

  constructor(props) {
    super(props)
  }

  _getExpenseCategoryId = () => {
    let { expenseCategoryId } = this.props
    if (expenseCategoryId) {
      this.setState({
        expenseCategoryId
      }, () => {
        this.fetchExpenseCategory()
      })
    }
  }

  componentDidMount() {
    this._getExpenseCategoryId()
  }

  fetchExpenseCategory = async (name) => {
    let { expenseCategoryId } = this.state
    let { expenseType } = this.props  // Extract the expenseType from props
    this.setState({ arrList: [], fetching: true })
    let obj = {
      results: 30,
      count: 30,
      select: ['name'],
      name,
      regExFilters: ['name']
    }
    if (expenseCategoryId && !name) {
      obj._id = expenseCategoryId
    }

    if (expenseType) {
      obj.type = expenseType  // Add the expenseType to the request
    }
    let { data } = await getAllExpenseCategoryFxn(obj)
    let dataNew = []
    if (data && data.data && data.data.length) {
      _.each(data.data, (user) => {
        dataNew.push({
          text: user.name,
          value: user._id
        })
      })
    }
    let newObj = {
      arrList: dataNew
    }
    if (expenseCategoryId && !name && dataNew && dataNew.length) {
      let currentAgent = dataNew[0]
      newObj.name = {
        key: currentAgent.value,
        label: currentAgent.text
      }
    }
    this.setState(newObj)

  }

  handleChange = value => {
    let { onSelect } = this.props
    this.setState({
      name: value,
      expenseCategoryId: value && value.key ? value.key : '',
      data: [],
      fetching: false
    })
    onSelect((value && value.key) || '')
  }

  render() {
    const { fetching, arrList, name, required = false } = this.state
    return (
      <InputBox title={'Select Expense Category'}>
        <Select
          labelInValue
          value={name || undefined}
          className={'mt10'}
          placeholder='Select Expense Category'
          allowClear={true}
          notFoundContent={fetching ? <Spin size='small' /> : null}
          filterOption={false}
          showSearch={true}
          onSearch={debounce(this.fetchExpenseCategory, 500)}
          onChange={this.handleChange}>
          {arrList.map(d => (
            <Option key={d.value} value={d.value}>{d.text}</Option>
          ))}
        </Select>
      </InputBox>

    )
  }
}

export default ExpenseCategoryTypeheadComponent
