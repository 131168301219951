import {
  Avatar,
  Button,
  Col,
  Form,
  Row,
  Select,
  Tooltip
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  countryIds,
  defaultLogoList,
  filterOption,
  Intakes,
  newFormatDisplayDate
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import {
  CheckUserRight
} from '../../dashboard/views/DashboardUserWise'
import { listAllUniversities } from '../../university/actions/university'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { draftApplicationListFxn } from './actions.'
import AddGicDraftDrawer from './addGicDrawer'

const { Option } = Select
const DraftApplicationList = () => {
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [agentId, setAgentId] = useState('')
  const [universityList, setUniversityList] = useState([])
  const [appUniversityId, setAppUniversityId] = useState('')
  const [totalApplication, setTotalApplication] = useState(0)
  const [status, setStatus] = useState('')
  const [courseId, setCourseId] = useState('')
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [intake, setIntake] = useState('')
  let [marketingUserId, setMarketingUserId] = useState('')


  const [visibleAddGicDrawer, setVisibleAddGicDrawer] = useState(false)

  const dispatch = useDispatch()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }

      if (!params.countryId) {
        params.countryId = ''
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      params.type = 'all'
      try {
        setFilters(params)
        let resp = await dispatch(
          draftApplicationListFxn({
            ...params,
            regExFilters: ['name']
          })
        )
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }
    })
  }


  useEffect(() => {
    loadUniversityNameList()
    setFieldByParams()

  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()

    if (resp.name) {
      setName(resp.name)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.courseId) {
      setCourseId(resp.courseId)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }

    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
  }

  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    params.universityCountry = countryIds.canada

    let { data } = await dispatch(listAllUniversities(params))
    setAppUniversityId('')
    if (data && data.length) {
      setUniversityList(data)
    }
  }


  const events = {
    reloadFxn: () => {
      tableAppRef.current.reload()
    },

    refreshApplication: () => {
      tableAppRef.current.reload()
    },


    enterName: value => {
      setName(value)
    },

    showAddGicDrawer: () => {
      setVisibleAddGicDrawer(true)
    },
    hideAddGicDrawer: () => {
      setVisibleAddGicDrawer(false)
      tableAppRef.current.reload()
    }
  }


  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let appUniversityId = searchParams.get('appUniversityId')
      let name = searchParams.get('name')
      let courseId = searchParams.get('courseId')
      let intake = searchParams.get('intake')
      let marketingUserId = searchParams.get('marketingUserId')
      let obj = {}
      if (agentId) {
        obj.agentId = agentId
      }
      if (status) {
        obj.status = status
      }
      if (courseId) {
        obj.courseId = courseId
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (name) {
        obj.name = name
      }
      if (intake) {
        obj.intake = intake
      }

      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}

    if (courseId) {
      obj.courseId = courseId
    }
    if (status) {
      obj.status = status
    }
    if (name) {
      obj.name = name
    }
    if (agentId) {
      obj.agentId = agentId
    }

    if (appUniversityId) {
      obj.appUniversityId = appUniversityId
    }

    if (intake) {
      obj.intake = intake
    }

    if (marketingUserId) {
      obj.marketingUserId = marketingUserId
    }
    dispatch(
      getUrlPushWrapper('openGicDraftList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let obj = {}
    setAppUniversityId('')
    setStatus('')
    setAgentId('')
    setName('')
    setMarketingUserId('')
    dispatch(
      getUrlPushWrapper('openGicDraftList', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  const columns = [
    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      searchTextName: 'applications.id',
      render: (item, record) => {
        return <div style={{ width: 80 }}>{item}</div>
      },
      width: 80
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 100,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'DOB',
      width: 100,
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      render: val => {
        return <div style={{ width: 90 }}>{newFormatDisplayDate(val)}</div>
      }
    },
    {
      title: 'University',
      key: 'applications.courseUniversity',
      dataIndex: 'applications.courseUniversity',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.logo && item.logo.url ? (
              <Avatar src={item.logo.url} size={20} />
            ) : (
              <Avatar src={defaultLogoList.defaultUniversityLogo} size={20} />
            )}
            {item.universityName ? `  ${item.universityName}` : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      width: 250,
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      render: item => {
        return <div>{item}</div>
      }
    },
    {
      title: 'Intake',
      width: 150,
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item && item.month && item.year
              ? `${item.month}, ${item.year}`
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Marketing',
      width: 150,
      dataIndex: 'marketingUser',
      key: 'marketingUser',
      render: (item, record) => {
        let { marketingUserId } = record.applications
        return (
          <React.Fragment>
            {marketingUserId && marketingUserId.name
              ? marketingUserId.name
              : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Action',
      width: 100,
      key: 'action',
      dataIndex: 'action',
      fixed: 'right',
      render: (val, record) => {
        return (
          <div className={'actionBtnGroup'}>
            <Tooltip title={'Complete Your Details & Get CAD $175'}>
              <button
                className='btn btn-default roundNew xs'
                onClick={() => {
                  events.showAddGicDrawer()
                  setStudentData(record)
                }}>
                Complete Your Details<br /> & Get CAD $175
              </button>
            </Tooltip>

          </div>
        )
      }
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='search-box-table round ml0'>
          <input
            className='form-control form-control'
            type='search'
            placeholder='Name...'
            value={name}
            onChange={e => {
              events.enterName(e.target.value)
            }}
          />
          <img src='/dist/img/search.png' alt='' />
        </div>
      </Col>

      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Search By University'
            onChange={item => setAppUniversityId(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'University'}
            value={appUniversityId || undefined}>
            {universityList && universityList.length
              ? universityList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>
                    {item.universityName}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <div className='sort-box-table custom-sort-box-new'>
          <Select
            name='Intake'
            onChange={item => setIntake(item)}
            filterOption={filterOption}
            className={'antSelect'}
            allowClear={true}
            showSearch={true}
            placeholder={'Intake'}
            value={intake || undefined}>
            {Intakes && Intakes.length
              ? Intakes.map((item, key) => {
                return (
                  <Option value={item} key={key}>
                    {item}
                  </Option>
                )
              })
              : null}
          </Select>
        </div>
      </Col>
      <CheckUserRight
        user={user}
        rightUserType={['admin', 'branchManager', 'branchUser']}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='sort-box-table custom-sort-box-new'>
            <AgentTypeheadComponent
              key={agentId}
              agentId={agentId}
              dispatch={dispatch}
              onSelect={item => {
                setAgentId(item)
              }}
            />
          </div>
        </Col>
      </CheckUserRight>
      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>Open GIC Draft List - (Total : {totalApplication})</h5>
                <div className='sort-box-table mark-btn'>

                </div>
              </div>
              <div className='card-body table-responsive'>
                {filterBlock}
                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      {visibleAddGicDrawer ? (
        <AddGicDraftDrawer
          visible={visibleAddGicDrawer}
          studentData={studentData}
          onClose={() => events.hideAddGicDrawer()}
          onSubmit={() => events.hideAddGicDrawer()}
        />
      ) : (
        ''
      )}
    </React.Fragment>
  )
}
const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(DraftApplicationList)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
