import {
  Button,
  Col,
  Row,
  notification, Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import {
  eventListExport
} from '../../event/action/event'
import { enquiryListFxn, unizHomeEnquiryFxn } from '../action'

const AgentEnquiryList = props => {
  const [filter, setFIlter] = useState({})
  const [total, setTotal] = useState(0)
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = params => {
    return new Promise(async resolve => {

      let resp = await dispatch(
        enquiryListFxn({
          ...params,
          enquiryBy: 'amber'
          // userType: "agent",

        })
      )
      setTotal(resp.total)
      resolve({ data: resp.data })
    })
  }

  // const apiRequest = params => {
  //     return new Promise(async resolve => {
  //         params.sortField = 'countryName'
  //         params.sortOrder = 'ascend'
  //         let resp = await dispatch(
  //             singleUserEnquiryFxn({
  //                 ...params,
  //                 userType: "agent",
  //                 regExFilters: [
  //                     'name',
  //                     'enrollmentBasic',
  //                     'enrollmentProcedure',
  //                     'fundSponsors'
  //                 ]
  //             })
  //         )
  //         setFIlter(params)
  //         setTotal(resp.total)
  //         resolve(resp)
  //     })
  // }

  const events = {
    viewCourse: record => {
      //   setState({
      //     ...state,
      //     viewCourseDrawer: true,
      //     studentObj: record
      //   })
    },
    viewCourseClose: () => {
      //   setState({
      //     ...state,
      //     viewCourseDrawer: false,
      //     studentObj: {}
      //   })
    },
    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    },
    searchData: async () => {
      //   let obj = {}
      //   if (name) {
      //     obj.name = name
      //   }
      //   if (countryId) {
      //     obj.countryId = countryId
      //   }
      //   if (status) {
      //     obj.status = status
      //   }
      //   if (branchUserId) {
      //     obj.branchUserId = branchUserId
      //   }
      //   if (intake) {
      //     obj.intake = intake
      //   }
      //   if (appUniversityId) {
      //     obj.appUniversityId = appUniversityId
      //   }
      //   if (agentId) {
      //     obj.agentId = agentId
      //   }
      //   if (studentShore) {
      //     obj.studentShore = studentShore
      //   }
      //   dispatch(
      //     getUrlPushWrapper('allStudent', {
      //       ...obj
      //     }))
      //   setTableSearch(true)
      //   setTimeout(() => {
      //     events.reloadTable()
      //   }, 200)
    }


  }
  const statusFilter = [
    { name: 'Verified', value: 'Verified' },
    { name: 'Unverified', value: 'Unverified' }
  ]

  const sendData = async record => {
    let resp = await dispatch(unizHomeEnquiryFxn({ eventId: record._id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const columns = [
    /*    {
          title: 'Property',
          dataIndex: 'propertyImage',
          key: 'propertyImage',
          searchTextName: 'propertyName',
          render: (val, record) => {
            return (
              <p>
                <img src={record.propertyImage} height='90px' width='100px' />
              </p>
            )
          }
        },*/
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
      searchTextName: 'propertyName',
      render: (val, record) => {
        return (
          <p>
            {record.propertyName}
          </p>
        )
      }
    },
    {
      title: 'Room Details',
      dataIndex: 'roomDetails',
      key: 'roomDetails',
      render: (item, record) => {

        return (
          <>
            Room : {record && record.roomType}
            <br />
            Start : {record && record.moveInDate}
            <br />
            End : {record && record.moveOutDate
          }
            <br />
            Price : {record && record.price}
            <br />
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      render: (val, record) => {
        return (
          <p>
            {record.title} {record.fullName}
          </p>
        )
      }
    },

    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
      searchTextName: 'nationality'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },

    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (val, record) => {
        return (
          <p>
            {record.dailCode} {record.phoneNumber}
          </p>
        )
      }
    },

    {
      title: 'University',
      dataIndex: 'university',
      key: 'university',
      // filters: statusFilter.map(x => ({value: x.value, text: x.name})),
      render: item => {
        return item
      }
    },
    // {
    //     title: 'Property',
    //     dataIndex: 'property',
    //     key: 'property',
    //     render: (item, record) => {
    //         return <>{item.address}</>
    //     }
    // },


    {
      title: 'Added By',
      dataIndex: 'userId',
      key: 'userId',
      render: (item, record) => {

        return (
          <>
            Name : {record.userId && record.userId.name}
            <br />
            Email : {record.userId && record.userId.email}
            <br />

          </>
        )
      }
    },

    //    {
    //         title: 'Approved',
    //         dataIndex: 'approved',
    //         key: 'approved',
    //         render: (item, record) => {

    //             return (
    //                 <>

    //                     {item ? "Approved" : <>

    //                         <Popconfirm title={'Are you sure, you want to approved ths lead?'} onConfirm={() => {
    //                             sendData(record)
    //                         }
    //                         }>
    //                             <Button size={'small'}>Approved Now</Button>
    //                         </Popconfirm>

    //                     </>}
    //                 </>
    //             )
    //         }
    //     },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 100,
      key: 'action',
      render: (item, record) => {
        return (
          <>

            <Tooltip title={'View Enquiry'}>
              <a className='btn'
                 href={`/uniz-home/view-enquiry?enquiryId=${record._id}`}
                 target={'_blank'}><img src='../../dist/img/view.svg' alt='' /></a>
            </Tooltip>

            {/*<Tooltip title="Edit">*/}
            {/*    <button*/}
            {/*        className={'btn'}*/}
            {/*        style={{ marginRight: 6 }}*/}
            {/*        onClick={() => events.showUpdateModal(record._id)}*/}
            {/*    >*/}
            {/*        <Icon type={'edit'}/>*/}
            {/*    </button>*/}
            {/*</Tooltip>*/}

          </>)
      }
    }
  ]


  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <Button
              onClick={() => events.goBack()}
              className={'roundGoBackBtn'}
              icon={'arrow-left'}>
              Back
            </Button>
            <h5>All Enquiries: {total || 0}</h5>

            <div
              className='sort-box-table mark-btn'
              style={{ marginLeft: '1000px' }}>

            </div>
          </div>
          <div className='card-body table-responsive'>

            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={DefaultTablePagination()}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default AgentEnquiryList
