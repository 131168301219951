import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Tooltip, Tabs
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes/routes'
import { listAllCommission } from '../actions'
import { displayDate } from '../../../components/_utils/appUtils'
import CommissionStructureInnerComponent from './commissionStructureInner'
import { push } from 'connected-react-router'

const CommissionsStructure = () => {
  let [user, setUser] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    setUser(user)
  }

  const openApp = (record) => {
    let studentId = record.studentId._id
    if (user && user.userType && user.userType == 'admin') {
      window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
    } else {
      dispatch(
        getUrlPushWrapper('application.singleApplication', {
          appId: record.applications_id,
          studentId: studentId
        }))
    }
  }


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center'>
            <h5>Commission Structure</h5>
            <div className='search-box-table'>

            </div>
            <div className='sort-box-table mark-btn'>

            </div>
          </div>
          <CommissionStructureInnerComponent />
        </div>
      </div>
    </div>
  )
}

export default CommissionsStructure
