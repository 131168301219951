import {
  Button,
  Col,
  Form,
  Row
} from 'antd'
import lodash from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes/routes'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { listAllTransferFunctionFxn } from '../actions'
import { displayDate, displayTime } from '../../../components/_utils/appUtils'

const XTravelTransactionList = () => {
  const [totalApplication, setTotalApplication] = useState(0)
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [agentId, setAgentId] = useState('')
  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const tableAppRef = useRef()

  let apiRequest = params => {
    return new Promise(async resolve => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      try {
        setFilters(params)
        let resp = await dispatch(listAllTransferFunctionFxn({
          ...params, regExFilters: ['name']
        }))
        setTotalApplication(resp.total)
        resolve(resp)
      } catch (e) {
      }
    })
  }


  useEffect(() => {
    setFieldByParams()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
  }

  const getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let agentId = searchParams.get('agentId')
      let obj = {}
      if (agentId) {
        obj.agentId = agentId
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let obj = {}
    if (agentId) {
      obj.agentId = agentId
    }
    dispatch(
      getUrlPushWrapper('x-travel-transfer-list', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {}
    setAgentId('')
    dispatch(
      getUrlPushWrapper('x-travel-transfer-list', {
        ...obj
      })
    )
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }

  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })


  const columns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Name',
      key: 'agentName',
      dataIndex: 'agentName',
      width: 150,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'Company Name',
      key: 'agentCompanyName',
      dataIndex: 'agentCompanyName',
      width: 150,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'Transaction Type',
      key: 'transactionType',
      dataIndex: 'transactionType',
      width: 150,
      render: (item, record) => {
        return (item)
      }
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 150,
      render: (item, record) => {
        return (
          <>
            {displayDate(item)}, {displayTime(item)}
          </>
        )
      }
    },
    {
      title: 'Amount',
      width: 150,
      dataIndex: 'amount',
      key: 'amount',
      render: (item, record) => {
        return (
          <>
            Rs. {item}
          </>
        )
      }
    }
  ]


  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, item => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const filterBlock = (
    <Row gutter={16}>
      <Col md={6} sm={6} xs={10} lg={6}>
        <div className='sort-box-table custom-sort-box-new'>
          <AgentTypeheadComponent
            key={agentId}
            agentId={agentId}
            dispatch={dispatch}
            onSelect={item => {
              setAgentId(item)
            }}
          />
        </div>
      </Col>
      <Col md={4} sm={4} xs={8} lg={4}>
        <Button onClick={() => searchFxn()} className={'roundBtn'}>
          Search
        </Button>
        <Button onClick={() => clearFxn()} className={'roundBtn'}>
          Clear
        </Button>
      </Col>
    </Row>
  )
  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div
                className='table-head d-flex align-items-center all-student-search'
                style={{ height: 60 }}>
                <Button
                  onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                  className={'roundGoBackBtn'}
                  icon={'arrow-left'}>
                  Back
                </Button>
                <h5>All XTravelWorld Transfers</h5>

                <div className='sort-box-table mark-btn'>
                  ( Total : {totalApplication} )
                </div>
              </div>
              <div className='card-body table-responsive'>

                {user.userType === 'admin' ? filterBlock : null}

                <TableComp
                  columns={loadColumns()}
                  rowKey={(item, index) => {
                    return index
                  }}
                  apiRequest={apiRequest}
                  pagination={{
                    position: 'top',
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    defaultPageSize: 10,
                    current: filters.page ? filters.page : 1
                  }}
                  ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(XTravelTransactionList)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
