import { apiUrl2 } from '../../settings'

export const addTrackerUrl = () => {
  return apiUrl2 + '/addTracker'
}
export const trackerListUrl = () => {
  return apiUrl2 + '/getTrackerList'
}

export const updateTrackerListUrl = () => {
  return apiUrl2 + '/updateTrackerList'
}