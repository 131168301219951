import React, { useState } from 'react'
import { Drawer, Button, notification } from 'antd'
import { useDispatch } from 'react-redux'
import InputBox from '../../components/_utils/InputBox'
import { addFormFieldsFxn } from './actions'

const AddNewFormFields = (props) => {
  const { visible, onClose, reloadTable } = props
  const [fieldName, setFieldName] = useState('')
  const dispatch = useDispatch()

  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, '')
  }

  const handleAddFormField = async () => {
    if (!fieldName) {
      return notification.warning({ message: 'Please enter a field name' })
    }

    const valData = {
      name: toCamelCase(fieldName),
      label: fieldName,
      type: 'string'
    }

    const response = await dispatch(addFormFieldsFxn(valData))
    if (response) {
      setFieldName('')
      onClose()
    }
  }


  return (
    <Drawer
      visible={visible}
      title='Add Form Fields'
      width='50%'
      onClose={onClose}
    >
      <div className='row card unizportal'>
        <div className='col-md-12 d-flex align-items-center'>
          <div className='col-md-6'>
            <InputBox title='Add Form Fields'>
              <input
                className='form-control'
                type='text'
                value={fieldName}
                placeholder='Enter Field Name'
                onChange={(e) => setFieldName(e.target.value)}
              />
            </InputBox>
          </div>
          <div className='col-md-4'>

          </div>
        </div>
        <Button
          onClick={handleAddFormField}
          type='primary'
          htmlType='submit'
          className='btn'
        >
          Add
        </Button>
      </div>
    </Drawer>
  )
}

export default AddNewFormFields
