import { apiUrl2 } from '../../../settings'

export const addXTravelTransferOtpUrl = () => {
  return apiUrl2 + '/x-travel/addTransferSendOtp'
}

export const addXTravelTransferUrl = () => {
  return apiUrl2 + '/x-travel/addTransfer'
}

export const getAllTransfer = () => {
  return apiUrl2 + '/x-travel/getAllTransfer'
}

export const getSingleUserApiUrl = () => {
  return apiUrl2 + '/getSingleUserApi'
}