import { Button, Input, Form, notification, Modal } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { enquiryDocumentFxn } from '../action'

const initialState = {
  imageDrawerVisible: false,
  type: '',
  caption: '',
  image: {},
  imageKey: moment()
}

const { TextArea } = Input

const EditPropertyImage = (props) => {
  const dispatch = useDispatch()
  let [state, setState] = useState(initialState)
  let { propertyId, visible, onClose, imageData, refreshData } = props
  const events = {
    setDefaultValues: () => {
      setState((prevState) => {
        return {
          ...prevState,
          path: imageData.path,
          type: imageData.type,
          caption: imageData.caption
        }
      })
    },
    chooseImage: (e) => {
      let { files } = e.target
      if (files && files.length) {
        setState({
          ...state,
          image: files[0]
        })
      }
    },
    handleSubmit: async () => {
      let { image, type, caption } = state
      if (!image || (image && !image.name)) {
        return notification.error({
          message: 'Please choose an image'
        })
      }
      if (!type || !caption) {
        return notification.error({
          message: 'Please provide all fields'
        })
      }
      let fd = new FormData()
      fd.append('type', type)
      fd.append('caption', caption)
      fd.append('image', image)
      fd.append('mediaType', imageData.mediaType)
      fd.append('imageId', imageData._id)
      fd.append('propertyId', propertyId)

      let data = await dispatch(enquiryDocumentFxn(fd))
      if (data && !data.error) {
        setState({
          ...state,
          type: '',
          caption: '',
          image: {},
          imageKey: moment()
        })
        onClose()
        refreshData()

      }
    }
  }
  useEffect(() => {
    events.setDefaultValues()
  }, [])

  return (
    <Modal
      width={700}
      title={`Update Property Image`}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form>
        <InputBox title='Type'>
          <Input
            value={state.type}
            placeholder='Enter the image type'
            className='form-control'
            onChange={(e) => setState({ ...state, type: e.target.value })}
          />
        </InputBox>

        <InputBox title='Caption'>
          <TextArea
            rows={4}
            placeholder='Enter caption for the image'
            value={state.caption}
            className='form-control'
            onChange={(e) => {
              setState({
                ...state,
                caption: e.target.value
              })
            }}
          />
        </InputBox>

        <InputBox title='Choose Image'>
          <Input
            type='file'
            key={state.imageKey}
            className='form-control'
            onChange={(e) => events.chooseImage(e)}
          />
        </InputBox>

        <Form.Item className='mt20'>
          <Button
            type='primary'
            onClick={events.handleSubmit}>
            SUBMIT
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}


export default EditPropertyImage
