import { Button, Input, Form, notification, Modal } from 'antd'
import { InputBox } from '../../../components/_utils/appUtils'
import React, { useState } from 'react'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { addPropertyImageFxn } from '../action'

const initialState = {
  imageDrawerVisible: false,
  type: '',
  caption: '',
  image: {},
  imageKey: moment(),
};

const { TextArea } = Input;

const AddPropertyImage = (props) => {
  const dispatch = useDispatch();
  let [state, setState] = useState(initialState);
  let { propertyId, visible,roomId, onClose, imageData,ImageId, refreshData, form : {setFieldsValue} } = props;
  console.log(imageData, "imageData")
  const events = {

    chooseImage: (e) => {
      let { files } = e.target;
      if (files && files.length) {
        setState({
          ...state,
          image: files[0],
        });
      }
    },
    handleSubmit: async () => {
      let { image, type, caption } = state;
      if (!image || (image && !image.name)) {
        return notification.error({
          message: 'Please choose an image',
        });
      }
      if (!type || !caption) {
        return notification.error({
          message: 'Please provide all fields',
        });
      }

      let obj = {
        propertyId,
        type,
        caption,
      };

      let fd = new FormData();

      fd.append('type', type);
      fd.append('caption', caption);
      fd.append('image', image);
      fd.append('propertyId', propertyId);
      fd.append('mediaType', "image");
      fd.append('category', 'property');


      let data = await dispatch(addPropertyImageFxn(fd));
      if (data && !data.error) {
        setState({
          ...state,
          type: '',
          caption: '',
          image: {},
          imageKey: moment(),
        });
        onClose();
        refreshData();

      }
    },
  };


  return (
    <Modal
      width={700}
      title={`Upload Property Image`}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form>
        <InputBox title='Type'>
          <Input
            value={state.type}
            placeholder='Enter the image type'
            className='form-control'
            onChange={(e) => setState({ ...state, type: e.target.value })}
          />
        </InputBox>

        <InputBox title='Caption'>
          <TextArea
            rows={4}
            placeholder='Enter caption for the image'
            value={state.caption}
            className='form-control'
            onChange={(e) => {
              setState({
                ...state,
                caption: e.target.value
              })
            }}
          />
        </InputBox>

        <InputBox title='Choose Image'>
          <Input
            type='file'
            key={state.imageKey}
            className='form-control'
            onChange={(e) => events.chooseImage(e)}
          />
        </InputBox>

        <Form.Item className='mt20'>
          <Button
            type='primary'
            onClick={events.handleSubmit}
          >
            SUBMIT
          </Button>
        </Form.Item>
      </Form>

    </Modal>
  );
};
const AddPropertyImageModal = Form.create()(AddPropertyImage);

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPropertyImageModal);
