import React from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Col, Row, Modal, notification } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addUnizhomeSubRoomFxn } from '../action'
import { DurationTypes } from '../utils/propertyUtils'

const AddPropertySubRoom = (props) => {
  let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId, onSubmit, roomId } = props
  const dispatch = useDispatch()
  const inputFields = {
    fields: [
      {
        key: 'pricing.duration',
        label: 'Duration Unit',
        placeholder: 'Duration Unit',
        type: 'select',
        required: true,
        options: DurationTypes,
        keyAccessor: (x) => `${x.value}`,
        valueAccessor: (x) => `${x.name}`,
        onChange: (value, option) => {
          setFieldsValue({
            'pricing.duration': value
          })
        }
      },
      {
        key: 'meta.lease_duration',
        label: 'Duration',
        placeholder: 'Duration',
        type: 'number',
        required: true
      },
      {
        key: 'meta.available_from_formatted',
        label: 'Move in',
        placeholder: 'Move in',
        type: 'date',
        required: true
      },
      {
        key: 'meta.available_to_formatted',
        label: 'Move out',
        placeholder: 'Move out',
        type: 'date',
        required: true
      },

      // { key: 'id', label: 'Room ID', required: true, placeholder: 'Enter ID', type: 'number' },
      // { key: 'name', label: 'Name', required: true, placeholder: 'Enter Name', type: 'text' },
      {
        key: 'weekly_price',
        label: 'Weekly Price',
        required: true,
        placeholder: 'Enter Weekly Price',
        type: 'number'
      },
      // { key: 'parent_id', label: 'Parent ID', required: true, placeholder: 'Enter Parent ID', type: 'number' },
      {
        key: 'pricing.deposit',
        label: 'Deposit',
        placeholder: 'Enter Deposit',
        type: 'number',
        required: true
      },



    ]
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        valData.propertyId = propertyId
        valData.roomId = roomId
        const resp = await dispatch(addUnizhomeSubRoomFxn(valData))
        if (resp && resp.success) {
          onSubmit()
        }
      } else {
        notification.warning({ message: 'Validation Error' })
      }
    })
  }



  return (
    <Modal
      visible={visible}
      title='Add Room Duration'
      onCancel={onClose}
      footer={null}
      width='50%'>
      <Form onSubmit={handleSubmit}>
        <Row gutter={24}>
          {inputFields.fields.map((field, idx) => (
            <Col key={idx} span={12}>
              <GetEachFormFields
                item={field}
                getFieldDecorator={getFieldDecorator}
                formItemLayout={{ labelCol: { xs: { span: 0 }, sm: { span: 0 } }, wrapperCol: { xs: { span: 24 } } }}
              />
            </Col>
          ))}

        </Row>
        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
          <Button style={{ marginLeft: '10px' }} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default Form.create()(AddPropertySubRoom)
