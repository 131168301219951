import React, { useState, useEffect, useRef } from 'react'
import {
  Card,
  Tooltip,
  Drawer,
  Icon
} from 'antd'
import _ from 'lodash'
import {useDispatch} from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes/routes'

import {DefaultTablePagination, longDisplayDate} from '../../../components/_utils/appUtils'

import Styles from './styles.less'
import { privateMessageReadyFxn } from '../../users/actions/user'
import { allNotification, notificationReadyFxn } from '../../users/actions/user'

const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const ListAllPrivateMessage = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const [state, setState] = useState(initialState)
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState('')
  const [itemNo, setItemNo] = useState(1)
  const [totalNotification, setTotalNotification] = useState(0)

  const tableRef = useRef()
  const dispatch = useDispatch()

  const apiRequest = async (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(allNotification({
        ...params,
        regExFilters: ['name']
      }))
      setTotalNotification(resp.total)
      resolve(resp)
    })
  }


  const events = {
    viewMessage: async (record) => {
      let resp = await privateMessageReadyFxn(record._id)
      setContent(record.content)
      setVisible(true)
    }
  }
  let subStringFxn = (item) => {
    let totalLength = item.length
    if (totalLength > 200) {
      item = item.substring(0, 200) + ' ...'
      return item
    } else {
      return item
    }
  }

  const changeReadState = async (clickedItem) => {
    const { _id, applicationId, studentId } = clickedItem
    let resp = await notificationReadyFxn(_id)
    tableRef.current.reload()
    if (user) {
      if (user.userType == 'admin' || user.userType == 'branchManager' || user.userType == 'branchUser') {
        let url = `/student/application?studentId=${studentId}&appId=${applicationId}`
        window.open(url, '_blank')
      } else {
        dispatch(
          getUrlPushWrapper('application.singleApplication', {
            studentId: studentId,
            appId: applicationId
          }))
      }
    }
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 70,
      key: 'sno',
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'App/Student ID',
      dataIndex: 'applicationNo',
      width: 150,
      render: (item, record) => {
        return (
          <div className={Styles.statusBox}>
            <div style={{ width: 20 }}>
              {record && record.status == 'unread' && <div className={Styles.unReadIcon}/>}
            </div>
            <span className={Styles.nameLabel}>{item}/{record.id}</span>
          </div>
        )
      }
    },
    {
      title: 'Title',
      dataIndex: 'title'
      /*  render:(item, record)=>{
          return (
            <div className={Styles.statusBox}>
              <span className={Styles.nameLabel}>{item}</span>
            </div>
          )
        }*/
    },
    {
      title: 'Message',
      dataIndex: 'message',
      width: 700,
      render: (item) => {
        return (<span className="badge">{item}</span>)
      }
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? longDisplayDate(item) : ''}
          </React.Fragment>
        )
      }
    },
    {
      key: 'actions',
      title: 'Action',
      width: 100,
      height: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Application'}>
              <button className={'btn'} onClick={() => changeReadState(record)}>
                <Icon type={'eye'}/>
              </button>
            </Tooltip>
          </React.Fragment>

        )
      }
    }
  ]

  const loadColumns = () => {
    let columnArr = []
    _.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }


  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Notifications {totalNotification || 0}</h5>
              <div className="search-box-table">
                <input className="form-control form-control" type="search" placeholder="Search Notifications"/>
                <img src="dist/img/search.png" alt=""/>
              </div>

              <div className="sort-box-table mark-btn">
                <button className="btn">Mark all as Read</button>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={loadColumns()}
                         apiRequest={apiRequest}
                         pagination={DefaultTablePagination()}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>


      {visible ?
        <Drawer
          visible={visible}
          title={'Private Message'}
          width={700}
          closable={true}
          maskClosable={true}
          onClose={() => {
            setVisible(false)
            tableRef.current.reload()
          }}>
          <Card>
            {content}
          </Card>
        </Drawer> : ''}

    </div>
  )
}

export default ListAllPrivateMessage
