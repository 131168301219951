import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Icon,
  Popconfirm,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { listAllSampleDocument, deleteSampleDocument } from '../actions/sampleDocument'
import EditCountry from './edit'
import { getUrlPushWrapper } from '../../../routes/routes'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import sampleDocumentReducers from '../reducers/sampleDocument'
import AddSampleDocument from './add'
import EditSampleDocuemnt from './edit'

const ListAllSampleDocument = (props) => {
  const { totalSampleDocument } = useSelector((state) => ({
    totalSampleDocument: state.sampleDocumentReducers.totalSampleDocument
  }))
  let { pageType, title } = props
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      resolve(dispatch(listAllSampleDocument({
        ...params,
        regExFilters: []
      })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const [id, setId] = useState(null)
  const [addSampleDocumentVisible, setAddSampleDocumentVisible] = useState(false)
  const [editSampleDocumentVisible, setEditSampleDocumentVisible] = useState(false)
  const confirmDelete = async (id) => {

  }
  const loadFilterCountry = async () => {
    let countryFilter = {
      results: 100
    }
    let resp = await dispatch(listAllSampleDocument(countryFilter))
  }

  const events = {
    hideDrawer: () => {
      setAddSampleDocumentVisible(false)
      tableRef.current.reload()
    },
    hideEditDrawer: () => {
      setEditSampleDocumentVisible(false)
      tableRef.current.reload()
    },
    deleteDocument: async (document) => {
      let resp = await dispatch(deleteSampleDocument(document._id))
      tableRef.current.reload()
    }
  }
  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 100,
      key: 'sno',
      fixed: true,
      render: (value, item, index) => (itemNo - 1) * 10 + index + 1
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 150,
      render: (text, record) => {
        return <div className={'rowFlex'}>
          {record.document && record.document.url ?
            <Tooltip title={record.document.name}>
              <a className={'btn'} target={'_blank'}
                 href={record.document.url}>
                <Icon type={'eye'}></Icon>
              </a> </Tooltip> : ''}

          {pageType == 'direct' ?
            <Tooltip title={'Delete Document'}>
              <Popconfirm title={'Are your sure, you want to delete document?'}
                          onConfirm={() => {
                            events.deleteDocument(record)
                          }}
                          okText="Yes" cancelText="No">
                <button className={'btn'}>
                  <Icon type={'delete'}></Icon>
                </button>
              </Popconfirm>
            </Tooltip> : ''}

        </div>
      }
    }
  ]

  return (
    <React.Fragment>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>{title}</h5>
              <div className="search-box-table">

              </div>

              <div className="sort-box-table mark-btn">
                {pageType == 'direct' ?
                  <button className="btn" onClick={() => setAddSampleDocumentVisible(true)}>Add Sample
                    Document</button> : ''}
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}

              />
            </div>
          </div>
        </div>
      </div>

      {addSampleDocumentVisible ? <AddSampleDocument visible={addSampleDocumentVisible} events={events}/> : ''}
      {editSampleDocumentVisible ?
        <EditSampleDocuemnt visible={editSampleDocumentVisible} events={events} id={id}/> : ''}
    </React.Fragment>
  )
}

export default ListAllSampleDocument
