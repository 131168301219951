import React, {useState, useEffect, useRef} from 'react'
import {
    Tabs, Drawer, Card, Tooltip, Icon
} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getUrlPushWrapper } from '../../../routes/routes'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import {listAllCommission} from '../actions'
import {displayDate} from '../../../components/_utils/appUtils'
import {RowTable} from '../../../components/_utils/RowTable'
import EditCommission from './editCommission'


const {TabPane} = Tabs

const AllCommissions = (props) => {
    let {agentId = ''} = props
    let [user, setUser] = useState({})
    let [allCommission, setAllCommission] = useState([])
    const [itemNo, setItemNo] = useState(1)
    const [selectedRow, setSelectedRow] = useState({})
    const [visibleStu, setVisibleStu] = useState(false)
    const [visibleUpdateCommission, setVisibleUpdateCommission] = useState(false)
    const [selectedCommission, setSelectedCommission] = useState({})

    const tableRef = useRef()
    const tableRef1 = useRef()
    const dispatch = useDispatch()
    const apiUkRequest = (params) => {
        return new Promise(async (resolve) => {
            params.country = 'uk'
            if (agentId) {
                params.agentId = agentId
            }
            let resp = await dispatch(listAllCommission({...params, regExFilters: []}))
            resolve(resp)
        })
    }

    const apiCanadaRequest = (params) => {
        return new Promise(async (resolve) => {
            params.country = 'canada'
            if (agentId) {
                params.agentId = agentId
            }
            /* params.sortField = 'cityName'
             params.sortOrder = 'ascend'*/
            let resp = await dispatch(listAllCommission({...params, regExFilters: []}))
            resolve(resp)
        })
    }

    useEffect(() => {
        loadUser()
    }, [])

    const loadUser = async () => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        setUser(user)
    }

    const openApp = (record) => {
        let studentId = record.studentId._id
        if (user && user.userType && user.userType == 'admin') {
            window.open(`/student/application?studentId=${studentId}&appId=${record.application_id}`, '_blank')
        } else {
            dispatch(
                getUrlPushWrapper('application.singleApplication', {
                    appId: record.applications_id,
                    studentId: studentId
                }))
        }
    }


    const callback = (key) => {
        //  console.log(key)
    }

    const events = {
        showStuDrawer: (row) => {
            if (row) {
                row.name = row.studentId.name
                row.id = row.studentId.studentId
                row.universityName = row.universityId.universityName
                row.courseName = row.courseId.courseName
            }
            setVisibleStu(true)
            setSelectedRow(row)
        },
        hideStuDrawer: (row) => {
            setVisibleStu(false)
            setSelectedRow({})
        }
    }

    const columns = [

        {
            title: 'S.No',
            dataIndex: 'sno',
            width: 50,
            key: 'sno',
            render: (value, item, index) => (itemNo - 1) * 10 + index + 1
        },
        /*    {
              title: 'Name',
              dataIndex: 'studentId',
              key: 'studentId',
              render: (item, record) => {
                return (
                  <a onClick={() => openApp(record)} className={'hyperLink'}>
                    {item && item.name ? item.name : ''} <br/>{item && item.studentId ? `( ID : ${item.studentId})` : ''}
                  </a>
                )
              }
            },
            {
              title: 'App ID',
              dataIndex: 'applicationId',
              width: 100,
              key: 'applicationId',
              render: (item, record) => {
                return <a className={'hyperLink'} onClick={() => openApp(record)}>{item}</a>
              }
            },
            {
              title: 'University',
              dataIndex: 'universityId',
              key: 'universityId',
              render: (item) => {
                return (
                  <div>
                    {item && item.universityName ? item.universityName : ''}
                  </div>
                )
              }
            },
            {
              title: 'Course',
              dataIndex: 'courseId',
              key: 'courseId',
              render: (item) => {
                return (
                  <div>
                    {item && item.courseName ? item.courseName : ''}
                  </div>
                )
              }
            },*/
        {
            title: 'Date',
            dataIndex: 'createdAt',
            width: 150,
            key: 'createdAt',
            render: (item) => {
                return item ? displayDate(item) : null
            }
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            key: 'semester'
        },
        {
            title: 'Payment Mode',
            dataIndex: 'paymentMode',
            key: 'paymentMode',
            render: (item, record) => {
                return (
                    <div>
                        {item}<br/>
                        {record.details ? <div>
                            Details : {record.details}
                        </div> : null}
                    </div>
                )
            }
        },
        {
            title: 'Credit',
            dataIndex: 'credit',
            key: 'credit',
            render: (item, record) => {
                return (
                    <div>
                        {record.type == 'Credit' ?
                            <label className={'label label-success-green sm'}>
                                {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{record.commission}
                            </label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Debit',
            dataIndex: 'debit',
            key: 'debit',
            render: (item, record) => {
                return (
                    <div>
                        {record.type == 'Debit' ?
                            <label className={'label label-danger sm'}>
                                {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{record.commission}
                            </label> : null}
                    </div>
                )
            }
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (item, record) => {
                return (
                    <label className={'label label-success sm'}>
                        {record && record.countryId && record.countryId.currency ? record.countryId.currency : ''}{item}
                    </label>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 100,
            key: 'action',
            render: (item, record) => {
                console.log('record record record record', record)
                return (
                    <>
                        {record && record.studentId ? <button className='btn' onClick={() => {
                                events.showStuDrawer(record)
                            }}><img src='/dist/img/view.svg' alt='' className={'eyeBtn'}/></button>
                            : null}

                        {record && record.type && record.type == 'Credit' ?
                            <Tooltip title='Edit'>
                                <button
                                    className={'btn'}
                                    style={{marginRight: 6}}
                                    onClick={() => {
                                        {
                                            setSelectedCommission(record)
                                            setVisibleUpdateCommission(true)
                                        }
                                    }}>
                                    <Icon type={'edit'}/>
                                </button>
                            </Tooltip> : null}

                    </>)
            }
        }
    ]
    const StudentInfoComponent = (
        <Card>
            <div className={'tableBox striped'}>
                <RowTable title={'Student Name'} value={selectedRow && selectedRow.name ? selectedRow.name : ''}/>
                <RowTable title={'Student ID'} value={selectedRow && selectedRow.id ? selectedRow.id : ''}/>
                <RowTable title={'App ID'} value={<a className={'hyperLink'}
                                                     onClick={() => openApp(selectedRow)}>{selectedRow && selectedRow.applicationId ? selectedRow.applicationId : ''}</a>}/>
                <RowTable title={'University'}
                          value={selectedRow && selectedRow.universityName ? selectedRow.universityName : ''}/>
                <RowTable title={'Course'} value={selectedRow && selectedRow.courseName ? selectedRow.courseName : ''}/>
            </div>
        </Card>
    )

    return (
        <div className='card-body table-responsive'>
            <Tabs defaultActiveKey='1' onChange={callback}>
                <TabPane tab='UK' key='1'>
                    <TableComp columns={columns}
                               apiRequest={apiUkRequest}
                               pagination={DefaultTablePagination()}
                               ref={tableRef}/>
                </TabPane>
                <TabPane tab='Canada' key='2'>
                    <TableComp columns={columns}
                               apiRequest={apiCanadaRequest}
                               pagination={DefaultTablePagination()}
                               ref={tableRef1}/>
                </TabPane>
            </Tabs>

            {visibleStu ?
                <Drawer
                    width={'40%'}
                    visible={visibleStu}
                    onClose={events.hideStuDrawer}
                    title={`${selectedRow.name} Info`}>
                    {StudentInfoComponent}
                </Drawer> : null}

            {visibleUpdateCommission ?
                <EditCommission
                    {...props}
                    width={'40%'}
                    commissionData={selectedCommission}
                    visible={visibleUpdateCommission}
                    onClose={() => {
                        setVisibleUpdateCommission(false)
                        tableRef.current.reload()
                        tableRef1.current.reload()
                    }}>
                </EditCommission> : null}

        </div>
    )
}

export default AllCommissions
