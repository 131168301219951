import React, { useRef, useState } from 'react'
import { Button, Drawer, Form, Icon, Input, notification, Popconfirm, Select, Tooltip } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination } from '../../components/_utils/appUtils'
import { addExpenseCategoryFxn, deleteExpenseCategoryFxn, getAllExpenseCategoryFxn } from './action'
import UpdateExpenseCategoryDrawer from './updateExpenseCategoryDrawer'


const AddExpenseCategoryDrawer = (props) => {
  let { visible, onClose, expenseType } = props
  const dispatch = useDispatch()
  const tableRef = useRef()
  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })
  const [expenseCategoryName, setExpenseCategoryName] = useState('')
  const [updateExpenseCategoryModal, setUpdateExpenseCategoryDrawer] = useState({
    visible: false,
    record: {}
  })
  const [type, setType] = useState('')

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      params.type = expenseType

      let { data } = await getAllExpenseCategoryFxn({ ...params })
      resolve(data)
    })
  }

  const addExpenseCategory = async () => {
    if (!expenseCategoryName) {
      notification.warning({ message: 'Please Enter Expense Category Name' })
      return
    }
    /*    if (!type) {
          notification.warning({ message: 'Please Enter Expense Category Type' })
          return
        }*/
    let valData = {}
    valData.name = expenseCategoryName
    valData.type = expenseType
    let addExpense = await dispatch(addExpenseCategoryFxn(valData))
    if (addExpense.success) {
      tableRef.current.reload()
      setExpenseCategoryName('')
    }
  }

  const deleteExpenseCategory = async (expenseCategoryId) => {
    let addExpense = await dispatch(deleteExpenseCategoryFxn({ expenseCategoryId: expenseCategoryId }))
    if (addExpense.success) {
      tableRef.current.reload()
    }
  }
  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Action',
      key: 'actions',
      width: 100,
      render: (item, record) => {
        return (
          <>
            <Tooltip title={'Edit'}>
              <button className={'btn'}
                      onClick={() => {
                        setUpdateExpenseCategoryDrawer({
                          visible: true,
                          record: record
                        })
                      }}
              >
                <Icon type={'edit'} />
              </button>
            </Tooltip>
            <Popconfirm
              title={'Are you sure, you want to delete expense category'}
              okText={'Yes'}
              cancelText={'No'}
              onConfirm={() => {
                deleteExpenseCategory(record._id)
              }}>
              <button className={'btn'}>
                <Icon type={'delete'} />
              </button>
            </Popconfirm>
          </>
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Drawer visible={visible}
              width={'35%'}
              closable={true}
              maskClosable={true}
              onClose={onClose}>

        <div className='row'>

          <div className='col-lg-12'>
            <div className='form-box mt-4'>
              <div className='d-flex align-items-center mb-3 heading-form'>
                <h5>Expense Category</h5>
              </div>
              <div className='card unizportal'>
                <div className={'row col-md-24'}>
                  <div className={'col-md-12'}>
                    <Input
                      placeholder='Enter Expense Category'
                      value={expenseCategoryName}
                      onChange={(e) => setExpenseCategoryName(e.target.value)}
                      className='form-control'
                    />
                  </div>
                  {/*   <div className={'col-md-6'}>
                    <Select
                      value={type}
                      onChange={setType}
                      style={{ width: '100%' }} // Adding some space between Input and Select
                      placeholder='Select Category Type'
                    >
                      <Select.Option disabled={true} selected={true} value=''>Select Type</Select.Option>
                      <Select.Option value='otherExpense'>Expense</Select.Option>
                      <Select.Option value='otherProfit'>Profit</Select.Option>
                    </Select>
                  </div>*/}
                </div>
                <div className={'row mt10'}>
                  <div className={'col-md-12 alignRight'}>
                    <Button
                      type='primary'
                      onClick={addExpenseCategory}
                      className={'btn btn-success roundNew md'}>
                      Save
                    </Button>
                    <Button
                      className={'btn btn-default roundNew md ml5'}
                      onClick={() => {
                        setExpenseCategoryName('')
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='form-box'>
              <div className='card unizportal'>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: 10
                    }}>
                    </div>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-md-12'}>
                    <TableComp columns={taskColumns}
                               apiRequest={apiRequest}
                               pagination={DefaultTablePagination()}
                               ref={tableRef}
                               rowKey='_id' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        {updateExpenseCategoryModal.visible &&
        <UpdateExpenseCategoryDrawer
          {...updateExpenseCategoryModal}
          onClose={() => {
            setUpdateExpenseCategoryDrawer({
              visible: false,
              record: {}
            })
            tableRef.current.reload()
          }}
        />
        }
      </Drawer>


    </React.Fragment>
  )
}

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(AddExpenseCategoryDrawer)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedComponent)
