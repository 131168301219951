import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Col, Drawer, Icon, notification,
  Popconfirm, Row, Select, Tag,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes/routes'
import { listAllCourse } from '../actions/university'
import { push } from 'connected-react-router'
import { listAllStates } from '../../state/actions/state'
import { listAllCities } from '../../city/actions/city'
import { Intakes } from '../../../components/_utils/appUtils'
import { deleteCourse } from '../../course/actions/courseAction'

const ListAllCourseDrawer = (props) => {
  let { campusUniversity, universityName, onClose, visible } = props
  let [allCities, setAllCities] = useState([])
  let [allState, setAllState] = useState([])
  let [campusName, setCampusName] = useState('')
  let [filters, setFilters] = useState({})
  let [tableSearch, setTableSearch] = useState(false)
  let [totalCourse, setTotalCourse] = useState('')
  // const { totalCampus } = useSelector((state) => ({
  //   totalCampus: state.campusReducers.totalCampus
  // }))


  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let { universityId } = props
      params.sortField = 'universityName'
      params.sortOrder = 'ascend'
      params.courseUniversity = universityId


      let page = params.page
      if (tableSearch) {
        params.page = 1
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      setFilters(params)
      // resolve(dispatch(listAllCourse({ ...params, page, regExFilters: ['campusAddress', 'campusName'] })))
      let { data } = await dispatch(listAllCourse({ ...params, page }))
      setTotalCourse(data.total)
      resolve(data)
      setTableSearch(false)
    })
  }

  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteCourse(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }

  const [itemNo, setItemNo] = useState(1)

  let events = {
    searchData: () => {
      tableRef.current.reload()
    }
  }

  useEffect(() => {
    loadAllState()
    loadAllCities()
  }, [])


  const loadAllState = async () => {
    let { data } = await dispatch(listAllStates({
      results: 1000,
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
    setAllState(data)
  }
  const loadAllCities = async () => {
    let { data } = await dispatch(listAllCities({
      results: 1000,
      sortField: 'cityName',
      sortOrder: 'ascend'
    }))
    setAllCities(data)
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return <a>
          {(itemNo - 1) * 10 + index + 1}
        </a>
      }
    },
    {
      title: 'Name',
      dataIndex: 'courseName',
      width: 150,
      key: 'courseName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'}
             onClick={() => {
               dispatch(
                 getPushPathWrapper('masters.editCourse', { id: record._id }))
             }}
          >
            {item}
          </a>
        )
      }
    },
    {
      title: 'Campus',
      dataIndex: 'campusName',
      width: 100,
      key: 'campusName',
      render: (value, record) => {
        return (
          <React.Fragment>

            {value && value.length ? <ul className={'countList'}>
              {
                value.map((item, key) => {
                  return (
                    <li key={key}>{item.campusName}</li>
                  )
                })
              }
            </ul> : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Duration',
      key: 'courseDuration',
      // width: 150,
      width: 100,
      dataIndex: 'courseDuration',
      searchTextName: 'courseDuration'
    },
    {
      title: 'Level',
      key: 'courseLevel',
      // width: 150,
      width: 100,
      dataIndex: 'courseLevel',
      searchTextName: 'courseLevel'
    },
    {
      title: 'Tuition Fee',
      dataIndex: 'tuitionFee',
      // width: 100,
      width: 100,
      key: 'tuitionFee',
      searchTextName: 'fundAcceptable'
    },
    {
      title: 'Intakes',
      dataIndex: 'intakes',
      width: 100,
      key: 'intakes',
      filterMultiple: false,
      filters: Intakes.map(x => ({ value: x, text: x })),
      render: (value, record) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Waiver',
      key: 'waiver',
      width: 100,
      dataIndex: 'waiver',
      render: (item) => {
        return (
          <React.Fragment>
            {item ? 'Yes' : 'No'}
          </React.Fragment>
        )
      }
    },

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 120,
      render: (text, record) => {
        return <div className={'pa-0'}>
          <Tooltip title='Edit'>
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                dispatch(
                  getPushPathWrapper('masters.editCourse', { id: record._id }))
              }}
            >
              <Icon type={'edit'} />
            </button>
          </Tooltip>

          <Tooltip title='Delete'>
            <Popconfirm title={'Are your sure, you want to delete course?'}
                        onConfirm={() => {
                          confirmDelete(record._id)
                        }}
                        okText='Yes' cancelText='No'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'} />
              </button>
            </Popconfirm>
          </Tooltip>
        </div>

      }
    }
  ]


  return (
    <Drawer visible={visible} onClose={onClose}
            width={'90%'}
    >
      <div className='row'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>{universityName} - Course: {totalCourse}</h5>
              <div className='search-box-table'>

              </div>
              <div className='sort-box-table mark-btn' style={{ marginRight: 20 }}>
                <a className='btn' onClick={() => dispatch(push('/masters/add-course'))}>
                  <img src={'../dist/icons/plus.png'} className={'plus'} />
                  Add Course</a>
              </div>
            </div>
            <div className='card-body table-responsive'>

              <Row gutter={16}>
                <Col span={6}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by course name...' value={campusName} onChange={(e) => {
                        setCampusName(e.target.value)
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                    </div>
                  </div>
                </Col>

                <Col span={6}>
                  <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                </Col>

              </Row>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         pagination={{
                           pageSizeOptions: ['50', '100', '200', '500'],
                           defaultPageSize: 50,
                           current: filters.page ? filters.page : 1
                         }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default ListAllCourseDrawer
