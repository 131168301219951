import {
  Button,
  Col,
  Icon, Popconfirm, Row,
  Tooltip
} from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import { getUnizHomePropertyListFxn, updatePropertyActiveStatusFxn } from '../action'
import { push } from 'connected-react-router'

let initFilter = {
  name: '',
  id: '',
  property_group: ''

}
const InventoryManagement = () => {
  const [total, setTotal] = useState(0)
  const [filterState, setFilterState] = useState(initFilter)
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = params => {
    return new Promise(async resolve => {
      let resp = events.getParamsForInventoryList()
      params = { ...params, ...resp }

      let respData = await dispatch(
        getUnizHomePropertyListFxn({
          ...params,
          select: ['inventory_featured_image_path', 'id', 'children_count', 'weekly_price', 'source', 'name', 'active', 'property_group'],
          regExFilters: ['name', 'property_group', 'id']
        })
      )
      setTotal(respData.total)
      resolve(respData)
    })
  }

  const events = {
    updateStatus: async (data) => {
      let resp = await dispatch(updatePropertyActiveStatusFxn({ propertyId: data._id }))
      if (resp && resp.success) {
        tableRef.current.reload()
      }
    },
    getParamsForInventoryList: () => {
      let { id, name, property_group } = filterState
      let obj = {}
      if (id) obj.id = id
      if (name) obj.name = name
      if (property_group) obj.property_group = property_group
      return obj
    },
    updateFilterState: (data) => {
      setFilterState({
        ...filterState,
        ...data
      })
    },
    searchFxn: async () => {
      reload()
    },
    clearFxn: async () => {
      events.updateFilterState({

        name: '',
        property_group: '',
        id: ''

      })
      setTimeout(() => {
        reload()
      }, 200)
    }
  }
  const reload = () => {
    if (tableRef && tableRef.current) {
      tableRef.current.reload()
    }
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (val, record, index) => {
        return index + 1
      }
    },
    /* {
       title: 'Property',
       dataIndex: 'inventory_featured_image_path',
       key: 'inventory_featured_image_path',
       searchTextName: 'propertyName',
       render: (val, record) => {
         return (
           <p>
             <img src={record.inventory_featured_image_path} height='90px' width='100px' />
           </p>
         )
       }
     },*/
    {
      title: 'Property Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name'
    },
    {
      title: 'Property Id',
      dataIndex: 'id',
      key: 'id',
      searchTextName: 'id'
    },
    {
      title: 'Property Management Group',
      dataIndex: 'property_group',
      key: 'property_group',
      searchTextName: 'property_group'
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      searchTextName: 'name',
      render: (item, record) => {
        return (
          <ColumnWidth width={120}>
            <Popconfirm title={'Are you sure, you want to update status?'} onConfirm={() => {
              events.updateStatus(record)
            }}>
              {item ? <label className={'label label-success-green'}>Published</label> :
                <label className={'label label-danger'}>Un-Published</label>}
            </Popconfirm>
          </ColumnWidth>
        )
      }
    },
    {
      title: 'Total Rooms',
      dataIndex: 'children_count',
      key: 'children_count',
      searchTextName: 'children_count'
    },
    {
      title: 'Action',
      key: '_id',
      dataIndex: '_id',
      width: 150,
      render: (val, record) => {
        return (
          <React.Fragment>
            <Tooltip title='Edit Property'>
              <a className={'btn btn-default roundNew sm'}
                 onClick={() => {
                   dispatch(push(`/edit-unizhome-property?propertyId=${record._id}`))
                 }}>
                Edit
              </a>
            </Tooltip>
          </React.Fragment>
        )
      }
    }

  ]
  const filterBlock = (
    <div className={'filter_box mt5'}>
      <Row gutter={16} className={'flexWrap'}>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Name'
              value={filterState.name}
              onChange={e => {
                events.updateFilterState({ name: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>


        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Id'
              value={filterState.id}
              onChange={e => {
                events.updateFilterState({ id: e.target.value })
              }}

            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Property Management Group'
              value={filterState.property_group}
              onChange={e => {
                events.updateFilterState({ property_group: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} sm={8} xs={12} lg={4}>
          <Button onClick={() => events.searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    </div>
  )
  return (
    <div className='row mt-4'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='table-head d-flex align-items-center all-student-search'>
            <h5>Inventory Management : ({total || 0})</h5>
            <div className='search-box-table round'>
            </div>
            <div className='sort-box-table mark-btn'>
              <a className={'btn btn-default roundNew'}
                 onClick={() => {
                   dispatch(push('/uniz-home/add-property'))
                 }}
              >Add Property</a>
            </div>

          </div>
          <div className='card-body table-responsive'>
            {filterBlock}
            <TableComp
              columns={columns}
              apiRequest={apiRequest}
              ref={tableRef}
              pagination={{
                position: 'top',
                pageSizeOptions: ['20', '50', '75', '100'],
                defaultPageSize: 20
              }}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default InventoryManagement
