import { Button, Col, Popconfirm, Row } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import {
  DefaultTablePagination, directUniversityList
} from '../../../components/_utils/appUtils'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import MobileEmailInfo from '../../../components/commonComponents/mobileEmailInfo'
import { getUrlPushWrapper } from '../../../routes/routes'
import {
  accessRequestListFxn,
  accessGrantedRequestFxn
} from '../actions/closeDeal'
import _ from 'lodash'
import UpdateRequestStatusComponent from '../drawer/updateRequestStatus'

class AllRequestedList extends Component {
  events = {
    showRequestDrawer: (data) => {
      this.setState({
        requestState: {
          visible: true,
          requestId: data._id
        }
      })
    },
    hideRequestDrawer: () => {
      this.setState({
        requestState: {
          visible: false,
          requestId: ''
        }
      })
    },
    updateAccessFxn: async (data) => {
      let { dispatch } = this.props
      let { success } = await dispatch(accessGrantedRequestFxn({ requestId: data._id }))
      if (success) {
        this.tableRef.current.reload()
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      filters: {},
      total: 0,
      requestState: {
        visible: false,
        requestId: ''
      }
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.setFieldByParams()
  }


  apiRequest = params => {
    let { dispatch } = this.props
    return new Promise(async resolve => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
      let resp1 = await this.getParamsForApplicationList()

      params = { ...params, ...resp1 }
      let finalFilter = {
        ...params,
        regExFilters: [
          'agentName',
          'companyName'
        ]
      }
      this.setState({ filters: finalFilter })
      let resp = await dispatch(accessRequestListFxn({ ...finalFilter }))
      this.setState({ total: resp.total })
      resolve(resp)
    })
  }

  getParamsForApplicationList = () => {
    return new Promise(resolve => {
      let searchParams = new URLSearchParams(window.location.search)
      let agentName = searchParams.get('agentName')
      let companyName = searchParams.get('companyName')
      let obj = {}
      if (agentName) {
        obj.agentName = agentName
      }
      if (companyName) {
        obj.companyName = companyName
      }
      resolve(obj)
    })
  }

  setFieldByParams = async () => {
    let resp = await this.getParamsForApplicationList()
    let obj = {}
    if (resp.agentName) {
      obj.agentName = resp.agentName
    }
    if (resp.companyName) {
      obj.companyName = resp.companyName
    }
    this.setState({ ...obj })
  }

  searchFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    if (this.state.agentName) {
      obj.agentName = this.state.agentName
    }
    if (this.state.companyName) {
      obj.companyName = this.state.companyName
    }
    dispatch(
      getUrlPushWrapper('allRequestedList', {
        ...obj
      })
    )
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }
  clearFxn = async () => {
    let { dispatch } = this.props
    let obj = {}
    this.setState({
      agentName: '',
      companyName: ''
    })
    dispatch(
      getUrlPushWrapper('allRequestedList', {
        ...obj
      })
    )
    setTimeout(() => {
      this.tableRef.current.reload()
    }, 200)
  }


  getUniversityName = (universityId) => {
    let findUniversity = _.find(directUniversityList, item => {
      return item.id == universityId
    })
    if (findUniversity) {
      return findUniversity.name
    } else {
      return null
    }
  }


  render() {
    let { requestState } = this.state
    let { currentUser } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return <React.Fragment>{index + 1}</React.Fragment>
        }
      },
      {
        title: 'Name',
        key: 'agentName',
        sorter: true,
        dataIndex: 'agentName',
        width: 150,
        hidden: currentUser.userType == 'agent',
        render: (item, record) => {
          return item
        }
      },
      {
        title: 'Company Name',
        key: 'companyName',
        sorter: true,
        dataIndex: 'companyName',
        width: 200,
        hidden: currentUser.userType == 'agent',
        render: (item, record) => {
          return item
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // searchTextName: 'email',
        width: 120,
        hidden: currentUser.userType == 'agent',
        render: (item, record) => {
          let { agentId } = record
          return <MobileEmailInfo data={agentId} type={'email'} value={agentId.email} />
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        // searchTextName: 'mobile',
        width: 100,
        hidden: currentUser.userType == 'agent',
        render: (item, record) => {
          let { agentId } = record
          return <MobileEmailInfo data={agentId} type={'mobile'} value={agentId.mobile} />
        }
      },
      /*   {
           title: 'University Deals',
           key: 'deals',
           width: 250,
           render: (dealClose, record) => {
             return (
               <ColumnWidth width={230}>
                 <table className={'table table-bordered table-striped table-sm'}>
                   {dealClose && dealClose.length ? dealClose.map((deal, key) => {
                     return (
                       <tr key={key}>
                         <td width={'50%'}>
                           {deal && deal.universityId ? this.getUniversityName(deal.universityId._id) : ''}
                         </td>
                         <td>
                           {deal && deal.amount ? <><span>CAD {deal.amount}</span></> : null}
                         </td>
                       </tr>
                     )
                   }) : null}
                 </table>

               </ColumnWidth>
             )
           }
         },*/
      {
        title: 'University Deal',
        key: 'deal',
        width: 250,
        render: (dealClose) => {
          let { universityId, amount } = dealClose
          return (
            universityId && universityId.universityName ?
              <ColumnWidth width={230}>
                {universityId.universityName}
                <br />
                <a className={'btn btn-success roundNew xs'}>Deal Amt. : ${amount}</a>
              </ColumnWidth> : null
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        // searchTextName: 'mobile',
        width: 150,
        render: (item, record) => {
          return <>
            {item == 'Agent Consent Pending' ? <label className={'label label-info label-sm'}>{item}</label> : ''}
            {item == 'Agent Consent Approved' ?
              <label className={'label label-success-green label-sm'}>{item}</label> : ''}
            {item == 'Agent Consent Rejected' ? <label className={'label label-danger label-sm'}>{item}</label> : ''}
            {item == 'Access Granted' ? <label className={'label label-success label-sm'}>{item}</label> : ''}
          </>
        }
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        // fixed: 'right',
        width: 100,
        render: (val, record) => {
          return (
            <div>
              {record.status == 'Agent Consent Pending' && currentUser.userType == 'agent' ?
                <a className={'btn btn-default roundNew sm'} onClick={() => {
                  this.events.showRequestDrawer(record)
                }}>
                  Update Status
                </a> : null}
              {record.status == 'Agent Consent Approved' && currentUser.userType == 'admin' ? <>
                <Popconfirm
                  title={'Are you sure, you want to give access?'}
                  onConfirm={() => {
                    this.events.updateAccessFxn(record)
                  }}>
                  <a className={'btn btn-default roundNew sm'}>
                    Access Granted
                  </a>
                </Popconfirm>
              </> : null}
            </div>
          )
        }
      }
    ]

    const getColumns = () => {
      let newColumns = []
      _.each(columns, (item) => {
        if (!item.hidden) {
          newColumns.push(item)
        }
      })
      return newColumns
    }
    const filterBlock = (
      <Row gutter={16} className={'mt10'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Name'
              value={this.state.agentName}
              onChange={e => {
                this.setState({ name: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={4} sm={4} xs={8} lg={4}>
          <div className='search-box-table round ml0'>
            <input
              className='form-control form-control'
              type='search'
              placeholder='Company Name'
              value={this.state.companyName}
              onChange={e => {
                this.setState({ companyName: e.target.value })
              }}
            />
            <img src='/dist/img/search.png' alt='' />
          </div>
        </Col>

        <Col md={8} sm={8} xs={12} lg={4}>
          <Button onClick={() => this.searchFxn()} className={'roundBtn'}>
            Search
          </Button>
          <Button onClick={() => this.clearFxn()} className={'roundBtn'}>
            Clear
          </Button>
        </Col>
      </Row>
    )
    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Requests ({this.state.total})</h5>
                <div className='search-box-table' />

                <div className='sort-box-table mark-btn'>

                </div>

              </div>

              <div className='card-body table-responsive'>
                {currentUser.userType !== 'agent' ? filterBlock : null}
                <TableComp
                  columns={getColumns()}
                  ref={this.tableRef}
                  pagination={DefaultTablePagination()}
                  apiRequest={this.apiRequest}
                />
              </div>
            </div>
          </div>
        </div>

        {requestState.visible ?
          <UpdateRequestStatusComponent
            onClose={this.events.hideRequestDrawer}
            onSubmit={() => {
              this.events.hideRequestDrawer()
              this.tableRef.current.reload()
            }}
            {...requestState} />
          : null}

      </div>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllRequestedList)
