import React, { useState, useEffect } from 'react'
import { Form, notification, Col, Row, Button, Modal } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { InputBox } from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import AgentTypeheadComponent from '../../../components/_utils/AgentTypeheadComponent'
import { addXTravelTransferFxn, addXTravelTransferOtpFxn, getSingleUserFxn } from '../actions'

const XTravelTransfer = (props) => {
  const { form: { getFieldDecorator } } = props
  const dispatch = useDispatch()
  let [agentId, setAgentId] = useState('')
  let [agentData, setAgentData] = useState('')
  let [visibleOtpScreen, setVisibleOtpScreen] = useState(false)
  let [otp, setOtp] = useState('')
  const handleSubmitOtpFxn = async (e) => {
    const { form } = props
    e.preventDefault()
    try {
      const valData = await form.validateFields()
      if (!agentId) {
        notification.warning({ message: 'Please Choose Agent' })
        return
      }
      if (valData.amount && valData.amount <= 0) {
        notification.warning({ message: 'Please Enter Amount Greater Then 0' })
        return
      }
      valData.agentId = agentId
      const data = await dispatch(addXTravelTransferOtpFxn(valData))
      if (data && data.success) {
        setVisibleOtpScreen(true)
      }
    } catch (err) {
      notification.warning({
        message: 'Fill All Required Fields'
      })
    }
  }

  const handleSubmitFxn = async (e) => {
    const { form } = props
    e.preventDefault()
    try {
      const valData = await form.validateFields()
      if (!agentId) {
        notification.warning({ message: 'Please Choose Agent' })
        return
      }
      if (valData.amount && valData.amount <= 0) {
        notification.warning({ message: 'Please Enter Amount Greater Then 0' })
        return
      }
      valData.agentId = agentId
      valData.otp = otp
      valData.transactionType = 'Credit'
      const data = await dispatch(addXTravelTransferFxn(valData))
      if (data.success) {
        setVisibleOtpScreen(false)
        setAgentId('')
        setOtp('')
        form.resetFields()
      }
    } catch (err) {
      notification.warning({
        message: 'Fill All Required Fields'
      })
    }
  }

  const getSingleUserData = async (agentId) => {
    let valData = {}
    valData.id = agentId
    const singleUserData = await dispatch(getSingleUserFxn(valData))
    if (singleUserData) {
      setAgentData(singleUserData.xTravelWallet)
    }
  }

  useEffect(() => {
    if (agentId) {
      getSingleUserData(agentId)
    }
  }, [agentId])

  const inputTypes = {
    fields: [
      {
        label: 'Agent',
        required: true,
        customField: (
          <>
            <InputBox title='* Agent' className='rowFlex' customStyle={{ marginTop: 10 }}>
              <div>
                <AgentTypeheadComponent
                  key={agentId}
                  agentId={agentId}
                  dispatch={dispatch}
                  customStyle=''
                  onSelect={item => setAgentId(item)}
                />
              </div>
            </InputBox>
            <div>
              {(agentId && agentData) ? (
                <div style={{ fontWeight: 'bold', color: '#4CAF50' }}>
                  Agent Current Balance: Rs. {agentData && agentData}
                </div>
              ) : null}
            </div>
          </>
        )
      },
      {
        key: 'amount',
        label: 'Transfer Amount',
        required: true,
        type: 'number',
        placeholder: 'Amount'
      }
    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>Transfer Amount To XTravelWorld Wallet</h5>
          </div>
          <div className='card unizportal'>
            <Form>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <>
                      {item.customField ? !item.hidden &&
                        <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                        <Col span={item.span ? item.span : 8} key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>}
                    </>
                  )
                })}
              </Row>
              <button className='btn' type='submit' onClick={handleSubmitOtpFxn}>Save</button>
            </Form>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleOtpScreen}
        closable={true}
        onCancel={() => {
          setVisibleOtpScreen(false)
        }}
        title={'Enter OTP'}
        footer={false}>
        <div style={{ textAlign: 'center', marginBottom: 10 }}>
          <h6>
            {' '}
            A text message with a 4-digit verification code has been sent to
            your phone number.
          </h6>
          <InputBox title={'Enter OTP'}>
            <input
              className={'form-control mt10'}
              type={'password'}
              minLength={4}
              maxLength={4}
              value={otp}
              onChange={({ target }) => {
                setOtp(target.value)
              }}
            />
          </InputBox>
          <Button
            className={'btn btn-success mt10'}
            onClick={handleSubmitFxn}>
            Submit OTP
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({ currentUserRights: global.currentUserRights })

const mapDispatchToProps = (dispatch) => ({ dispatch })

const ToDoListWrappedComponent = Form.create()(XTravelTransfer)

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListWrappedComponent)
