import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { connect } from 'react-redux'
import {
  getAllDepartmentExpenseFxn
} from './action'
import { dateFilterRange, DefaultTablePagination, displayDate, InputBox } from '../../components/_utils/appUtils'
import { ColumnWidth } from '../WebComponent/columnWidth'
import { push } from 'connected-react-router'
import { Col, DatePicker, Row } from 'antd'
import moment from 'moment'
import S from 'string'

const { RangePicker } = DatePicker

class DepartmentExpenseList extends Component {

  events = {
    showUserRightDrawer: (data) => {
      this.setState({
        visibleUserRightDrawer: true,
        selectedUser: data
      })
    },
    hideUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      })
    },
    closeAndUpdateUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      }, () => {
        this.tableRef.current.reload()
      })
    },
    showAddEmployeeDrawer: (data) => {
      this.setState({
        visibleAddEmployeeDrawer: true,
        selectedUser: data
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      allAgents: [],
      allBranchManager: [],
      allCountry: [],
      selectedUser: {},
      userPointsList: {},
      total: '',
      filterData: {
        fromDate: '',
        toDate: ''
      },
      fromDate: '',
      toDate: ''
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {

  }

  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      if (this.state.fromDate) {
        let date = {}
        date.$gte = new Date(this.state.fromDate)
        date.$lte = new Date(this.state.toDate)
        params.date = date
        delete params['fromDate']
        delete params['toDate']
      }
      params.sortField = '_id'
      params.sortOrder = 'descend'
      let { data } = await getAllDepartmentExpenseFxn({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      })
      this.setState({ total: data.total })
      resolve(data)
    })
  }
  searchFxn = () => {
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }
  clearFxn = () => {
    this.setState({
      fromDate: '',
      toDate: ''
    })
    setTimeout(() => {
      if (this.tableRef && this.tableRef.current) {
        this.tableRef.current.reload()
      }
    }, 200)
  }

  render() {
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Expense Type',
        dataIndex: 'expenseType',
        key: 'expenseType',
        render: (item, record) => {
          return (
            <ColumnWidth width={120}>
              {item ? S(item).humanize().s : null}
              <br />
              {record.noOfStudents ? <a className={'btn btn-default roundNew xs mt5'}>
                No of Students - {record.noOfStudents || ''}
              </a> : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Amount',
        key: 'amount',
        dataIndex: 'amount'
      },
      {
        title: 'Date',
        key: 'date',
        render: (item) => {
          return (
            <ColumnWidth width={120}>{displayDate(item)}</ColumnWidth>
          )
        }
      },
      {
        title: 'Details',
        key: 'date',
        render: (item, record) => {
          return (
            <>
              <ColumnWidth width={120}>{item && item.name && item.name}</ColumnWidth>
              <div>
                {
                  record && (record.expenseType === 'otherExpense' || record.expenseType === 'otherProfit') ?
                    `Other Expense - ${record.expenseCategoryId && record.expenseCategoryId.name && record.expenseCategoryId.name}(${record.departmentName && record.departmentName})` :
                    record && record.expenseType === 'staffSalary' ?
                      `Staff Salary - ${record.employeeId && record.employeeId.name && record.employeeId.name}` :
                      ''
                }
              </div>
            </>
          )
        }
      },
      /* {
         title: 'Employee',
         dataIndex: 'employeeId',
         key: 'employeeId',
         render: (item) => {
           return (
             <ColumnWidth width={120}>{item && item.name && item.name}</ColumnWidth>
           )
         }
       },
       {
         title: 'Expense Category',
         dataIndex: 'expenseCategoryId',
         key: 'expenseCategoryId',
         render: (item) => {
           return (
             <ColumnWidth width={120}>{item && item.name && item.name}</ColumnWidth>
           )
         }
       },
       {
         title: 'Department',
         dataIndex: 'departmentName',
         key: 'departmentName'
       },*/
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      }
      /*{
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        width: 150,
        render: (val, record) => {
          return (
            <React.Fragment>

              {this.props.currentUser.userType == 'hr' ?
                <Tooltip title='Edit Extra info'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleUserExtraInfo: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'edit'} />
                  </button>
                </Tooltip> : null}

              {this.props.currentUser.userType == 'hr' ?
                <Tooltip title='Rating'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleRatingDrawer: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'trophy'} />
                  </button>
                </Tooltip> : null}

              {this.props.currentUser.userType == 'hr' ?
                <Tooltip title='Rating logs'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleRatingLogsDrawer: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'eye'} />
                  </button>
                </Tooltip> : null}

            </React.Fragment>
          )
        }
      }*/
    ]
    let { dispatch } = this.props
    const dateFormat = 'DD/MM/YYYY'

    return (
      <div>
        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>Other Expense ({this.state.total})</h5>
                <div className='search-box-table'>

                </div>
                <div className='sort-box-table mark-btn'>
                  <div>
                    <a className='btn' onClick={() => {
                      dispatch(push('/add-Other-Expense-and-profit'))
                    }}>
                      <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Other Expense & Profit</a>
                  </div>
                </div>
              </div>
              <div className='card-body table-responsive'>
                <Row gutter={12} className={'filter_box'}>
                  <Col span={6}>
                    <InputBox title={'Search by date'}>
                      <RangePicker
                        format={dateFormat}
                        // defaultValue={[filterData.fromDate, filterData.toDate]}
                        value={[this.state.fromDate ? moment(this.state.fromDate, dateFormat) : null, this.state.toDate ? moment(this.state.toDate, dateFormat) : null]}
                        onChange={val => {
                          this.setState({ fromDate: val[0], toDate: val[1] })
                        }}
                        ranges={dateFilterRange}
                      />
                    </InputBox>
                  </Col>
                  <Col span={24}>
                    <div className={'btn_group'}>
                      <a className={'default_btn'} onClick={this.searchFxn}>Search</a>
                      <a className={'default_btn'} onClick={this.clearFxn}>Clear</a>
                    </div>
                  </Col>
                </Row>
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={DefaultTablePagination()}
                           apiRequest={this.apiRequest}
                           extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentExpenseList)
