import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon, Drawer, Popconfirm
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../../routes/routes'
import { listFeedback, deleteFeedback } from '../actions/feedbackActions'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { listAllUsers } from '../../users/actions/user'
import _ from 'lodash'
const AllFeedBacks = (props) => {
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let [agents, setAgents] = useState([])
  let [subAgents, setSubAgents] = useState([])
  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = '_id'
      params.sortOrder = 'descend'
      resolve(dispatch(listFeedback({ ...params, regExFilters: ['feedback'] })))
    })
  }

  useEffect(() => {
    loadUser()
  }, [])

  const loadUser = async () => {
    let agents = []
    let subAgents = []
    let params = {
      results: 200,
      userType: ['agent', 'subAgent'],
      select: ['name', 'userType']
    }
    let { data } = await dispatch(listAllUsers(params))
    _.each(data, (item) => {
      if (item.userType == 'agent') {
        agents.push(item)
      }
      if (item.userType == 'subAgent') {
        subAgents.push(item)
      }
    })
    setAgents(agents)
    setSubAgents(subAgents)
  }

  let columns = [
    {
      title: '#',
      key: '_id',
      dataIndex: '_id',
      width: 50,
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Feedback & Complaints',
      key: 'feedback',
      dataIndex: 'feedback',
      searchTextName: 'feedback'
    },
    /* {
       title: 'Agent',
       dataIndex: 'agentId.name',
       key: 'agentId',
       filters: agents.map(x => ({ value: x._id, text: x.name }))
     },
     {
       title: 'Sub Agent',
       dataIndex: 'subAgentId.name',
       key: 'subAgentId',
       filters: subAgents.map(x => ({ value: x._id, text: x.name }))
     },*/
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (item, record) => {
        return (
          <React.Fragment>
            {item ? displayDate(item) : ''}
          </React.Fragment>
        )
      }
    }
  ]

  if (user && user.userType && user.userType == 'admin') {
    let newColumn = [
      {
        title: 'Added By',
        dataIndex: 'userId.name',
        key: 'userId.name'
      },
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        render: (val, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Delete">

                <Popconfirm title={'Are your sure, you want to delete feedback?'}
                            onConfirm={() => {
                              deleteFeedbackFxn(val)
                            }}
                            okText="Yes" cancelText="No">
                  <Button
                    shape="circle"
                    style={{ marginRight: 6 }}
                    icon="delete"
                  />
                </Popconfirm>
              </Tooltip>
            </React.Fragment>
          )
        }
      }
    ]
    columns = [...columns, ...newColumn]
  }

  const deleteFeedbackFxn = async (_id) => {
    let resp = await dispatch(deleteFeedback(_id))
    tableRef.current.reload()
  }

  return (
    <div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Feedback & Complaints</h5>
              <div className="search-box-table">

              </div>

              <div className="sort-box-table mark-btn">
                <CheckMyRights rightList={['admin', 'branchManager', 'branchUser', 'agent', 'subAgent']}>
                  <a className="btn" href={'/feedback/add'}>
                    <img src={'../dist/icons/plus.png'} className={'plus'}/>
                    Add Feedback & Complaints</a>
                </CheckMyRights>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         pagination={DefaultTablePagination()}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         extraProps={{ scroll: { x: 1000 } }}
              />
            </div>
          </div>
        </div>
      </div>

    </div>)
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFeedBacks)
