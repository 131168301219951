import React, { useState, useEffect } from 'react'
import { Modal, Checkbox, Form, Button, Row, Col, Collapse, Select } from 'antd'
import { commonAmenitiesFxn, commonRoomAmenitiesFxn } from '../action'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import S from 'string'

const { Panel } = Collapse
const { Option } = Select
const AmenitiesModal = (props) => {
  const { visible, onClose, onSubmit, amenities: oldAmenities } = props
  const [amenities, setAmenities] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [amenitiesType, setAmenitiesType] = useState([])
  const dispatch = useDispatch()
  const handleCheckboxChange = (item) => {
    let clonedAmenities = _.clone(amenities)
    let checkedItems = []
    let findD = _.find(clonedAmenities, (v) => {
      return v.type == item.type
    })
    if (findD) {
      clonedAmenities = _.reject(clonedAmenities, (v) => {
        return v.type == item.type
      })
    } else {
      clonedAmenities.push(item)
    }
    _.each(clonedAmenities, (v) => {
      checkedItems.push(v._id)
    })

    setCheckedItems(checkedItems)
    setAmenities(clonedAmenities)
  }

  const getAmenities = async () => {
    // let obj = { count: 1000, results: 1000 }
    // let { data } = await dispatch(commonRoomAmenitiesFxn(obj))
    // let groupedData = _.groupBy(data, 'featureType')
    // let newData = Object.entries(groupedData).map(([groupKey, groupedItems]) => {
    //   return {
    //     name: groupKey, data: groupedItems
    //   }
    // })
    // setAmenitiesType(newData)
    setAmenitiesType(oldAmenities)
  }


  useEffect(() => {

  }, [amenities, amenitiesType])

  useEffect(() => {
    getAmenities()
  }, [])

  const handleSubmit = () => {
    onSubmit(checkedItems)
    onClose()
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title='Amenities'
      width={'70%'}
      footer={[
        <Button key='submit' type='primary' onClick={handleSubmit}>
          Submit
        </Button>
      ]}
    >
      <Form layout='vertical'>
        <Collapse accordion>
          {amenitiesType && amenitiesType.length ? amenitiesType.map((amenitiesData) => {
            let categoryName = S(amenitiesData.name).humanize().s

            return (
              <Panel header={categoryName} key={amenitiesData.name}>
                <Row gutter={[16, 16]}>
                  {amenitiesData.values.map((item) => {
                    return (
                      <Col span={8} key={item._id}>
                        <Checkbox
                          checked={checkedItems.includes(item._id)}
                          onChange={() => handleCheckboxChange(item)}>
                          {item.name}
                        </Checkbox>
                      </Col>
                    )
                  })}
                </Row>
              </Panel>
            )
          }) : null}


          {/*   {AmenitiesType.map((category) => (
                        <Panel header={category.name} key={category.type}>
                            <Row gutter={[16, 16]}>
                                {category.values.map((item) => (
                                    <Col span={8} key={item.type}>
                                        <Checkbox
                                            checked={
                                                (checkedItems[category.type] &&
                                                    checkedItems[category.type][item.type]) ||
                                                false
                                            }
                                            onChange={() => handleCheckboxChange(category.type, item.type)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Panel>
                    ))}*/}
        </Collapse>
      </Form>
    </Modal>
  )
}

export default AmenitiesModal
