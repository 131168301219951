import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Col, Row, Modal, notification } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { addUnizhomeRoomFxn } from '../action'
import { DurationTypes } from '../utils/propertyUtils'
import FileInput from '../../../components/_utils/fileInput'

const AddPropertyRoom = (props) => {
  let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, propertyId, onSubmit } = props

  const dispatch = useDispatch()
  const [featuredImage, setFeaturedImage] = useState({})

  const inputFields = {
    fields: [
      { key: 'id', label: 'Room ID', required: true, placeholder: 'Enter ID', type: 'number' },
      { key: 'name', label: 'Name', required: true, placeholder: 'Enter Name', type: 'text' },
      // { key: 'meta.unit_type', label: 'Type', required: true, placeholder: 'Enter Type', type: 'text' },
      { key: 'source', label: 'Source', placeholder: 'Enter Source', type: 'text' },
      { key: 'source_id', label: 'Source ID', placeholder: 'Enter Source ID', type: 'text' },
      {
        key: 'weekly_price',
        label: 'Weekly Price',
        required: true,
        placeholder: 'Enter Weekly Price',
        type: 'number'
      },
      {
        key: 'deposit',
        label: 'Deposit',
        placeholder: 'Enter Deposit',
        type: 'number',
        required: true
      },

      {
        key: 'meta.floor',
        label: 'Floor',
        placeholder: 'Enter Floor',
        type: 'text'
      },
      {
        key: 'meta.available_from_formatted',
        label: 'Check in',
        placeholder: 'Check in',
        type: 'date',
        required: true
      },
      {
        key: 'meta.available_to_formatted',
        label: 'Check out',
        placeholder: 'Check out',
        type: 'date',
        required: true
      },
      {
        key: 'duration',
        label: 'Duration Unit',
        placeholder: 'Duration Unit',
        type: 'select',
        required: true,
        options: DurationTypes,
        keyAccessor: (x) => `${x.value}`,
        valueAccessor: (x) => `${x.name}`,
        onChange: (value, option) => {
          setFieldsValue({
            'duration': value
          })
        }
      },
      {
        key: 'lease_duration',
        label: 'Duration',
        placeholder: 'Duration',
        type: 'number',
        required: true
      },
      {
        key: 'meta.area_unit',
        label: 'Area Unit',
        placeholder: 'Enter Area Unit',
        type: 'text'
      },
      {
        key: 'meta.available_from',
        label: 'Available From',
        placeholder: 'Enter Date',
        type: 'date'
      }
    ]
  }


  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const formData = new FormData()
        valData.propertyId = propertyId
        formData.append('obj', JSON.stringify(valData))
        if (featuredImage && featuredImage.name) {
          formData.append('featuredImage', featuredImage)
        }
        const resp = await dispatch(addUnizhomeRoomFxn(formData))
        if (resp && resp.success) {
          onSubmit()
        }
      } else {
        notification.warning({ message: 'Validation Error' })
      }
    })
  }

  const handleImageChange = ({ target }) => {
    if (target && target.files && target.files.length) {
      setFeaturedImage(target.files[0])
    }
  }


  return (
    <Modal
      visible={visible}
      title='Add New Room'
      onCancel={onClose}
      footer={null}
      width='65%'>
      <Form onSubmit={handleSubmit}>
        <Row gutter={24}>
          {inputFields.fields.map((field, idx) => (
            <Col key={idx} span={8}>
              <GetEachFormFields
                item={field}
                getFieldDecorator={getFieldDecorator}
                formItemLayout={{ labelCol: { xs: { span: 0 }, sm: { span: 0 } }, wrapperCol: { xs: { span: 24 } } }}
              />
            </Col>
          ))}
          <Col span={8} className={'mt10'}>
            <FileInput
              name={`featuredImage`}
              label={`Featured Image`}
              chooseDocument={(file) => handleImageChange(file)}
            />
          </Col>
        </Row>
        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
          <Button style={{ marginLeft: '10px' }} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default Form.create()(AddPropertyRoom)
