import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Icon,
    Row,
    Col, Form, DatePicker
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {
    fcmtPendingTTApplicationsFxn, marketingUserListForTTFxn
} from '../actions/chooseStudent'
import {
  newFormatDisplayDate,
  filterOption,
  Intakes,
  longDisplayDate,
  departmentObj,
  sortingOptions,
  getDayDiff
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import {listAllUsers} from '../../users/actions/user'
import {listAllCampus} from '../../campus/actions/campus'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import {FcmtNotesComponent, ShowFcmtNote} from "../fcmt/fcmtNotesComponent";
import UploadTTComponent from "../directUniDrawers/uploadTTComponent";
import moment from "moment";
import RevokeComponent from "../../../components/fcmtComponents/revokeComponent";

let {TotalTTComponent} = UploadTTComponent;

const {Option} = Select

const {RangePicker} = DatePicker

const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name} )
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}

const PendingTTApplicationList = (props) => {
    let {universityId, redirectKey, appName, currentUser} = props

    const [studentData, setStudentData] = useState({})
    const [name, setName] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [priority, setPriority] = useState(undefined)
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [marketingUserId, setMarketingUserId] = useState('')
    let [marketingUserList, setMarketingUserList] = useState([])
    const [toDate, setToDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    const [sortBy, setSortBy] = useState('old-to-new')

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    let {email} = user;
    let showFcmtNoteField = ShowFcmtNote(email)

    const tableAppRef = useRef()

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.appUniversityId = universityId
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(fcmtPendingTTApplicationsFxn({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }

    const loadMarketingUsers = async () => {
        let obj = {
            university: universityId
        }
        let {data} = await dispatch(marketingUserListForTTFxn(obj))
        setMarketingUserList(data)
    }

    useEffect(() => {
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        loadMarketingUsers()
        loadAgentData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.priority) {
            setPriority(resp.priority)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.marketingUserId) {
            setMarketingUserId(resp.marketingUserId)
        }
        if (resp.toDate) {
            setToDate(moment(resp.toDate, 'MM-DD-YYYY'))
        }
        if (resp.fromDate) {
            setFromDate(moment(resp.fromDate, 'MM-DD-YYYY'))
        }
        if (resp.sortBy) {
            setSortBy(resp.sortBy)
        }
    }


    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = universityId
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
    }


    const events = {

        reloadFxn: () => {
            tableAppRef.current.reload()
        },

        enterName: (value) => {
            setName(value)

        },


        openDocumentDrawer: async (record) => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async (record) => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },

    }


    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let marketingUserId = searchParams.get('marketingUserId')
            let fromDate = searchParams.get('fromDate')
            let toDate = searchParams.get('toDate')
            let sortBy = searchParams.get('sortBy')
            let priority = searchParams.get('priority')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (marketingUserId) {
                obj.marketingUserId = marketingUserId
            }

            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (sortBy) {
                obj.sortBy = sortBy
            }
            if (priority) {
                obj.priority = priority
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (marketingUserId) {
            obj.marketingUserId = marketingUserId
        }
        if (toDate) {
            obj.toDate = moment(toDate).format('MM-DD-YYYY')
        }
        if (fromDate) {
            obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
        }
        if (sortBy) {
            obj.sortBy = sortBy
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (priority) {
            obj.priority = priority
        }
        dispatch(
            getUrlPushWrapper(redirectKey, {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let obj = {}
        setName('')
        setCampusId('')
        setPriority(undefined)
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setBranchUserId('')
        setMarketingUserId('')
        setToDate('')
        setFromDate('')
        setSortBy('old-to-new')
        setAgentId('')
        dispatch(
            getUrlPushWrapper(redirectKey, {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }


    const columns = [
        {
            title: "Sr.No",
            key: "_id",
            dataIndex: "_id",
            width: 60,
            render: (item, record, index) => {
                return index + 1;
            }
        },
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>

                        {item}
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record;
                let dayObj = applications.statusList && applications.statusList.date ? getDayDiff(applications.statusList.date) : null
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                        {applications.statusList && applications.statusList.date ? (
                            <>
                                <div className={'mt10'}>
                                    <label className={'label label-default label-xs'} style={{fontSize: 11}}>
                                        {dayObj.label}
                                    </label>
                                </div>
                                <div className={'priorityBox'}>
                                    {dayObj.days > 45 ?
                                        <span className={'superPriority'}>
                                        Super Priority
                                    </span> : dayObj.days > 30 ?
                                            <span className={'priority'}>
                                            Priority
                                        </span> : ""}
                                </div>

                            </>
                        ) : null}

                    </div>
                )
            }
        },
        {
            title: 'Revoke',
            key: 'applications._id',
            dataIndex: 'applications._id',
            width: 150,
            hidden: !(currentUser.userType == 'admin' || currentUser.email == "kamal@ciracollege.com"),
            render: (val, record) => {
                return (
                    <div style={{width: 150}}>
                        <RevokeComponent val={val} record={record} reloadFxn={events.reloadFxn} user={user}/>
                    </div>
                )
            }
        },
        {
            title: 'Tuition Fees',
            key: 'tuitionFeeDocument',
            dataIndex: 'tuitionFeeDocument',
            width: 180,
            render: (val, record) => {
                return (
                    <div style={{width: 180}} className={'fcmtDiv'}>
                        <UploadTTComponent
                            student={record}
                            user={user}
                            reloadTable={events.reloadFxn}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Total TT Amount',
            key: 'applications.totalTTAmount',
            dataIndex: 'applications.totalTTAmount',
            width: 150,
            render: (item, record) => {
                return (
                    item ? <div style={{width: 150}} className={'fcmtDiv'}>
                        <TotalTTComponent
                            student={record}
                            user={user}
                            allowDeleteTT={true}
                            reloadTable={events.reloadFxn}
                            showAgentDeal={true}
                        />
                    </div> : null
                )
            }
        },

        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserId && marketingUserId.name ? marketingUserId.name : ""}
                    </React.Fragment>
                )
            }
        },

        {
            title: 'FCMT Note',
            key: 'applications.latestFcmtNote',
            dataIndex: 'applications.latestFcmtNote',
            hidden: showFcmtNoteField,
            width: 200,
            render: (val, record) => {
                let {applications, _id} = record;
                return (
                    <div style={{width: 200}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                        <br/>
                        <FcmtNotesComponent application={applications} studentId={_id} user={user}
                                            reloadTable={events.reloadFxn}/>
                    </div>
                )
            }
        },
        {
            title: 'Documents',
            key: 'document',
            dataIndex: 'document',
            width: 120,
            render: (val, record) => {
                return (
                    <div className={'fcmtDiv'}>
                        <button className={`bs_btn bs-default mt10 bs-xs`}
                                onClick={() => events.openDocumentDrawer(record)}>
                            View Documents
                        </button>
                    </div>
                )
            }
        }

    ]


    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <div className={'mt10 filterBox'}>
            <Row gutter={12} className={'rowWrap'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCampusId(item)}
                                className={'antSelect'}
                                placeholder={'Campus'}
                                allowClear={true}
                                value={campusId || undefined}>
                            {campusList && campusList.length ? campusList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.campusName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Intake' onChange={(item) => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                            {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col span={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <div className='search-box-table round'>
                            <input className='form-control form-control' type='search'
                                   placeholder='course name...' value={courseName} onChange={(e) => {
                                setCourseName(e.target.value)
                            }}/>
                            <img src='/dist/img/search.png' alt=''/>
                        </div>
                    </div>
                </Col>

                {user.department !== departmentObj.marketing ?
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className="sort-box-table custom-sort-box-new">
                            <Select
                                name="Search By Marketing User"
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                placeholder={'Marketing User'}
                                onChange={item => setMarketingUserId(item)}
                                value={marketingUserId || undefined}>
                                {marketingUserList && marketingUserList.length
                                    ? marketingUserList.map((item, key) => {
                                        return (
                                            <Option value={item._id} key={key}>
                                                {item.name}
                                            </Option>
                                        )
                                    })
                                    : null}
                            </Select>
                        </div>
                    </Col> : null}

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Sort"
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            placeholder={'Sort By'}
                            onChange={item => setSortBy(item)}
                            value={sortBy || undefined}>
                            {sortingOptions.map((item, key) => {
                                return (
                                    <Option value={item.value} key={item.value}>
                                        {item.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4} key={fromDate}>
                    <RangePicker
                        defaultValue={[fromDate, toDate]}
                        onChange={val => {
                            setFromDate(val[0])
                            setToDate(val[1])
                        }}
                    />
                </Col>


                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className="sort-box-table custom-sort-box-new">
                        <Select
                            name="Search By Agent"
                            filterOption={filterOption}
                            className={'antSelect'}
                            allowClear={true}
                            placeholder={'Agent'}
                            showSearch={true}
                            onChange={item => setAgentId(item)}
                            value={agentId || undefined}>
                            {allAgent && allAgent.length
                                ? allAgent.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.companyName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Priority' onChange={(item) => setPriority(item)}
                                className={'antSelect'}
                                placeholder={'Choose Priority'}
                                allowClear={true}
                                value={priority || undefined}>
                            {/*<Option value={''}>Choose Priority</Option>*/}
                            <Option value={'priority'}>Priority</Option>
                            <Option value={'super-priority'}>Super Priority</Option>

                        </Select>
                    </div>
                </Col>
            </Row>
            <Row className={'mt10 alignRight'}>
                <Col span={24}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head table-head-new d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h6>{appName} All Pending TT Application </h6>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={loadColumns()}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               showSizeChanger: true,
                                               pageSizeOptions: ['20', '30', '50'],
                                               defaultPageSize: 20,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {visibleDocumentDrawer && studentData.applications && studentData.applications._id ?
                <DocumentDrawer onClose={events.closeDocumentDrawer}
                                reloadTable={events.reloadFxn}
                                studentData={studentData}
                                applicationId={studentData.applications._id}
                                studentId={studentData._id}
                                visible={visibleDocumentDrawer}/> : null}

        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser
})
const PendingTTApplicationListComponent = Form.create()(PendingTTApplicationList)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingTTApplicationListComponent)
