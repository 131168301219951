import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, notification } from 'antd'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import { CountryCodeJson as countryCodes } from '../../../components/_utils/countryUtil'
import { useDispatch } from 'react-redux'
import FileInput from '../../../components/_utils/fileInput'
import _ from 'lodash'
import AmenitiesModal from '../../unizHomeEnquiry/view/addPropertyAmenities'
import FAQsModal from '../../unizHomeEnquiry/view/addPropertyFaqs'
import { getPropertyListUrl } from '../../unizHomeEnquiry/api'
import { currencyList } from '../utils/propertyUtils'
import { addUnizhomePropertyFxn } from '../action'

const amenitiesData = [
  {
    name: 'Common Amenities',
    type: 'common_amenities',
    values: [
      { name: 'On-site Gym', type: 'Gym' },
      { name: 'Fully Furnished Flats', type: 'Fully Furnished' },
      { name: 'Prime Liverpool University Location', type: 'Location Benefit' },
      { name: 'Spacious living area', type: 'Common Social Area' },
      { name: 'Complimentary WiFi & Netflix', type: 'Wifi' },
      { name: 'X1 Loyalty Card', type: 'Others' }
    ]
  },
  {
    name: 'Bills Included',
    type: 'bills_included',
    values: [
      { name: 'Wi-fi', type: 'Wifi' },
      { name: 'Water', type: 'Basic Essentials' },
      { name: 'Electricity', type: 'Electronic Payments' },
      { name: 'Gas', type: 'Gas Stove' }
    ]
  },
  {
    name: 'Safety and Security',
    type: 'safety_security',
    values: [
      { name: 'Overnight Security (8 PM – 6 AM)', type: 'Others' },
      { name: 'Dedicated Account Manager', type: 'Reception & Staff' }
    ]
  }
]

const AddPropertyForm = (props) => {
  const { form } = props
  const { getFieldDecorator, setFieldsValue, resetFields } = form
  const [allCountries, setAllCountries] = useState([])
  const [openAmenities, setOpenAmenities] = useState(false)
  const [amenities, setAmenities] = useState({})
  const [openFaqs, setOpenFaqs] = useState(false)
  const [images, setImages] = useState([{ path: '', type: '', caption: '', base_path: '' }])
  const [featuredImage, setFeaturedImage] = useState({})
  const dispatch = useDispatch()

  const events = {
    getCurrency: (country) => {
      let findCurrency = _.find(currencyList, (item) => {
        return item.name == country
      })
      if (findCurrency && findCurrency.currency) {
        return findCurrency.currency
      }
    }
  }

  /*    const getPropertyList = async() => {
          const resp = await dispatch(getPropertyListFxn())
          console.log(resp, "respppp")
      }


      useEffect(() => {
          getPropertyList();
      },[])*/

  const handleSubmit = (e) => {
    e.preventDefault()

    form.validateFields(async (err, valData) => {
      if (!err) {
        const formData = new FormData()

        valData.location = {
          country: {
            long_name: valData.country,
            short_name: valData.countryShort
          },
          state: { long_name: valData.state },
          route: { long_name: valData.route },
          district: { long_name: valData.district },
          locality: { long_name: valData.locality },
          postal_code: { long_name: valData.postalCode },
          street_number: { long_name: valData.streetNumber }
        }
        valData.pricing = {
          currency: valData.currency,
          duration: valData.duration,
          min_price: valData.min_price,
          max_price: valData.max_price,
          refundable_token: valData.refundable_token,
          max_available_price: valData.max_available_price,
          min_available_price: valData.min_available_price
        }


        formData.append('obj', JSON.stringify(valData))
        if (featuredImage && featuredImage.name) {
          formData.append('featuredImage', featuredImage)
        }
        const resp = await dispatch(addUnizhomePropertyFxn(formData))
        if (resp.success) {
          notification.success({ message: resp.message })
          // form.resetFields()
        } else {
          notification.error({ message: resp.message })
        }
      }
    })
  }


  const loadCountry = () => {
    let designatedArr = []
    _.each(countryCodes, (item) => {
      designatedArr.push({
        name: `${item.code} - ${item.name}`,
        value: item.name
      })
    })
    setAllCountries(designatedArr)
  }

  useEffect(() => {
    loadCountry()
  }, [])

  const inputFields = [
    { key: 'name', label: 'Name', placeholder: 'Name', required: true },

    { key: 'source', label: 'Source', placeholder: 'Source' },
    // { key: 'source_id', label: 'Source Id', placeholder: 'Source Id'},
    { key: 'source_link', label: 'Source Link', placeholder: 'Source Link' },
    { key: 'partner_link', label: 'Partner Link', placeholder: 'Partner Link' },


    {
      key: 'country',
      label: 'Country',
      placeholder: 'Select Country',
      required: true,
      showSearch: true,
      type: 'select',
      options: allCountries,
      keyAccessor: x => `${x.value}`,
      valueAccessor: x => `${x.value}`,
      onChange: (value, option) => {
        let currencyObj = events.getCurrency(value)
        setFieldsValue({
          country: option.props.children,
          countryShort: option.props.value,
          currencyName: currencyObj.code || '',
          currency: currencyObj.symbol || ''
        })
      }
    },
    { key: 'route', label: 'Route', placeholder: 'Route' },
    { key: 'state', label: 'State', placeholder: 'State', required: true },
    { key: 'locality', label: 'Locality', placeholder: 'Locality' },
    { key: 'district', label: 'District', placeholder: 'District' },
    { key: 'street_number', label: 'Street No', placeholder: 'Street No' },
    { key: 'postal_code', label: 'Postal Code', placeholder: 'Postal Code' },
    { key: 'address', label: 'Address', placeholder: 'Address', required: true },
    { key: 'distanceCityCenter', label: 'Distance from City Center', placeholder: 'Distance (km)' },
    { key: 'distanceBus', label: 'Distance by Bus', placeholder: 'Distance (mins)' },
    { key: 'distanceWalk', label: 'Distance by Walk', placeholder: 'Distance (mins)' },
    { key: 'distanceCar', label: 'Distance by Car', placeholder: 'Distance (mins)' },
    { key: 'aboutProperty', label: 'About Property', type: 'textArea' },
    { key: 'roomType', label: 'Room Type', placeholder: 'Room Type', required: true },
    // { key: 'inventory', label: 'Inventory No', placeholder: 'Inventory No' },
    // { key: 'amenities', label: 'Amenities', placeholder: 'Amenities' },
    { key: 'moveIn', label: 'Move-In Date', placeholder: 'Select date', type: 'date', required: true },
    { key: 'moveOut', label: 'Move-Out Date', placeholder: 'Select date', type: 'date', required: true },
    { key: 'duration', label: 'Duration', placeholder: 'Duration' },
    { key: 'currencyName', label: 'Currency', type: 'text', disabled: true, span: 4 },
    { key: 'currency', label: 'Currency Symbol', type: 'text', disabled: true, span: 4 },
    { key: 'weekly_price', label: 'Weekly Price', placeholder: 'Weekly Price', required: true },
    { key: 'max_price', label: 'Max Price', placeholder: 'Max Price' },
    { key: 'min_price', label: 'Min Price', placeholder: 'Min Price' },
    { key: 'refundable_token', label: 'Refundable Token', placeholder: 'Refundable Token' },
    { key: 'property_group', label: 'Property management group', placeholder: 'Property management group' },

    // { key: 'faqs', label: 'FAQs', type: 'textArea' },
    // { key: 'videos', label: 'Videos', placeholder: 'Video' },
  ]

  const formItemLayout = {
    labelCol: { xs: { span: 0 }, sm: { span: 0 }, md: { span: 0 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 } }
  }
  const handleImageChange = ({ target }) => {
    if (target && target.files && target.files.length) {
      setFeaturedImage(target.files[0])
    }
  }


  const handleAddImage = () => {
    setImages([...images, { path: '', type: '', caption: '', base_path: '' }])
  }

  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index)
    setImages(updatedImages)
  }

  return (
    <div>
      <Form layout='vertical' onSubmit={handleSubmit}>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>Add Property</h5>
          </div>

          {/* Main Input Fields Card */}
          <div className='card unizportal'>
            <Row gutter={24}>
              {inputFields.map((item, index) => (
                <Col span={item.span || 8} md={item.span || 8} sm={24} xs={24} key={index}>
                  {item.type === 'file' ? null : (  // No image inputs here
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}
                    />
                  )}
                </Col>
              ))}

              <Col span={8}>
                <FileInput
                  name={`featuredImage`}
                  label={`Featured Image`}
                  chooseDocument={(file) => handleImageChange(file)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <button className='btn' type='submit'>Submit</button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>


      {openAmenities && (
        <AmenitiesModal
          visible={true}
          onClose={() => setOpenAmenities(false)}
          onSubmit={setAmenities}
        />
      )}
      {openFaqs && (
        <FAQsModal
          visible={true}
          onClose={() => setOpenFaqs(false)}
          // onSubmit={setAmenities}
        />
      )}
    </div>
  )
}

export default Form.create()(AddPropertyForm)
