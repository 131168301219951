import React, { useEffect } from 'react'
import { apiUrl, apiUrl2 } from './settings'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { getUserToken, getUserData } from './request'
import IO from 'socket.io-client'

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
// let token = localStorage.getItem('token') ? localStorage.getItem('token') : null

let Socket = IO(apiUrl, {
  secure: false,
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000
})

let Socket2 = IO(apiUrl2, {
  secure: false,
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000
})

if (!Socket.connected) {
  console.log('connct attempted')
  Socket.connect()
}

Socket.on('connect', async () => {
  console.log('conneciton succssful')
  reAuthenticate()
})

Socket.on('disconnect', function() {
  if (user && user._id) {
    Socket.emit('discountUser', user._id)
  }
})

Socket.on('authenticated', () => {
})

Socket.on('unauthorized', () => {
})

Socket.on('connect_error', err => {
  //  socket.disconnect()
  console.log('on disconecced socket', err)
})

Socket.on('connect_failed', err => {
})

Socket.on('got_back', () => {
})


if (!Socket2.connected) {
  console.log('connct 2 attempted')
  Socket2.connect()
}

Socket2.on('connect', async () => {
  console.log('connection 2 successfully')
  reAuthenticate2()
})

Socket2.on('disconnect', function() {
  if (user && user._id) {
    console.log('disconnect 2 successfully')
    Socket2.emit('discountUser', user._id)
  }
})


const reAuthenticate = async () => {
  let userToken = await getUserToken()
  let currentUser = await getUserData()
  if (userToken && Socket.connected && currentUser && currentUser._id) {
    Socket.emit('authenticate', { userToken, userId: currentUser._id })
  }
}
const reAuthenticate2 = async () => {
  let userToken = await getUserToken()
  let currentUser = await getUserData()
  if (userToken && Socket2.connected && currentUser && currentUser._id) {
    Socket2.emit('authenticate', { userToken, userId: currentUser._id })
  }
}


const SocketComponentMain = (props) => {
  const dispatch = useDispatch()


  useEffect(() => {
    Socket.on('reload notification', (data) => {
      // console.log(data, 'notification')

      dispatch({
        type: 'RELOAD_NOTIFICATION',
        value: true,
        socketData: data
      })

    })
    Socket.on('reload dashboard', (data) => {
      dispatch({
        type: 'RELOAD_DASHBOARD',
        value: true,
        userId: data.userId
      })
    })
    Socket.on('active users', ({ liveUsers }) => {
      // console.log(liveUsers, 'liveUsers')
      dispatch({
        type: 'ACTIVE_USERS',
        activeUser: liveUsers && liveUsers.length ? liveUsers : []
      })
    })
    Socket.on('send private message', (data) => {
      dispatch({
        type: 'PRIVATE_MESSAGE',
        value: true,
        socketData: data
      })

    })
    Socket.on('user_comes_online', (resp) => {
      let { currentUser } = props
      if (currentUser && currentUser._id == resp.userId) {

      }
    })
    Socket.on('refresh_chat_list', (payload) => {
      let music = new Audio('https://unizportal.com/assets/chat_tone.mp3')
      // console.log(music)
      music.play()
    })

    Socket.on('counselling enquiry', (data) => {
      dispatch({
        type: 'UPDATE_COUNSELLOR_ENQUIRY',
        value: true,
        socketData: data
      })
    })

    Socket.on('reload reception dashboard', (data) => {
      dispatch({
        type: 'REFRESH_COUNTRY_RECORD',
        value: true,
        countryId: data.countryId,
        counsellorId: data.counsellorId,
        waiting: data.waiting
      })
    })


    Socket.on('reload online agent', (data) => {
      dispatch({
        type: 'RELOAD_ONLINE_AGENT',
        value: true
      })
    })
    Socket.on('reload agent count', (data) => {
      dispatch({
        type: 'REFRESH_ONLINE_AGENTS',
        value: true,
        ...data
      })
    })

    const logoutFxn = (userId) => {
      let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      if (user && user._id && user._id == userId) {
        dispatch({
          type: 'LEAVE_SOCKET',
          leaveRoom: true,
          userId: user && user._id
        })
      }
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('chatId')
      localStorage.removeItem('dontAskOtpForIds')

      document.body.className = ''
      dispatch({
        type: 'SET_CURRENT_USER',
        user: null
      })
      dispatch({
        type: 'SET_CURRENT_USER_RIGHT',
        currentUserRights: {}
      })
      dispatch(push('/login'))
    }


  }, [])

  const { joinRoom, userId, leaveRoom } = useSelector(data => ({
    joinRoom: data.global.joinRoom,
    userId: data.global.userId,
    leaveRoom: data.global.leaveRoom
  }))
  if (joinRoom) {
    /*if (userId && !Socket.connected) {
      Socket.connect()
    }*/
    // reAuthenticate() // dont run, it will crate duplicate socket chat error
    dispatch({
      type: 'JOIN_SOCKET',
      value: false,
      userId: ''
    })
  }
  if (leaveRoom) {
    // console.log('===============')
    console.log('===============leave rom')
    // console.log('===============')
    Socket.emit('leaveAuth', { userId: userId.toString() })
    Socket.disconnect()
    dispatch({
      type: 'LEAVE_SOCKET',
      value: false,
      userId: ''
    })
  }

  return null

}


const mapStateToProps = ({ counter, global }) => ({
  count: counter.count,
  loading: global.buttonLoading,
  joinRoom: global.joinRoom,
  leaveRoom: global.leaveRoom,
  reloadNotification: global.reloadNotification,
  reloadDashboard: global.reloadDashboard,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const SocketComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SocketComponentMain)

export { SocketComponent, Socket, Socket2 }


