import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Button,
  Card, Icon,
  Popconfirm,
  Tooltip
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes/routes'
import { listAllCities } from '../actions/city'
// import { listAllCities } from '../actions/state';
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { listAllStates } from '../../state/actions/state'
import { listAllCountries } from '../../countries/actions/countries'
import { push } from 'connected-react-router'
import { deleteCity } from '../actions/city'

const ListAllCities = () => {
  let [allStates, setAllState] = useState([])
  let [allCountries, setAllCountries] = useState([])
  const { totalCities } = useSelector((state) => ({
    totalCities: state.cityReducers.totalCities
  }))

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = 'cityName'
      params.sortOrder = 'ascend'
      resolve(dispatch(listAllCities({ ...params, regExFilters: ['cityName'] })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const confirmDelete = async (id) => {
    let resp = await dispatch(deleteCity(id))
    if (resp && resp.success) {
      notification.warning({ message: resp.message })
      tableRef.current.reload()
    }
  }

  useEffect(() => {
    loadAllState()
    loadAllCountry()
  }, [])
  const loadAllState = async () => {
    let { data } = await dispatch(listAllStates({
      results: 100,
      sortField: 'stateName',
      sortOrder: 'ascend'
    }))
    // console.log(data)
    setAllState(data)
  }
  const loadAllCountry = async () => {
    let { data } = await dispatch(listAllCountries({
      results: 100,
      sortField: 'countryName',
      sortOrder: 'ascend'
    }))
    // console.log(data)
    setAllCountries(data)
  }


  const columns = [

    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {
        return <a className={'linkPointer'} onClick={() => {
          dispatch(
            getUrlPushWrapper('masters.editCity', {
              id: record._id
            }))
        }}>
          {(itemNo - 1) * 10 + index + 1}
        </a>
      }
    },
    {
      title: 'Name',
      dataIndex: 'cityName',
      width: 150,
      key: 'cityName',
      searchTextName: 'cityName',
      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCity', {
                id: record._id
              }))
          }}>
            {item}
          </a>
        )
      }

    },
    {
      title: 'State',
      width: 150,
      key: 'state',
      dataIndex: 'state.stateName',
      filters: allStates.map(x => ({ value: x._id, text: x.stateName }))
      // searchTextName: 'state',

    },
    {
      title: 'Country',
      key: 'country',
      width: 150,
      dataIndex: 'country.countryName',
      filters: allCountries.map(x => ({ value: x._id, text: x.countryName }))
      // searchTextName: 'country',
    },

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          <Tooltip title="Edit">
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                dispatch(
                  getUrlPushWrapper('masters.editCity', {
                    id: record._id
                  }))
              }}
            >
              <Icon type={'edit'} />
            </button>
          </Tooltip>
          <Tooltip title='Delete'>
            <Popconfirm title={'Are your sure, you want to delete city?'}
                        onConfirm={() => {
                          confirmDelete(record._id)
                        }}
                        okText='Yes' cancelText='No'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'} />
              </button>
            </Popconfirm>
          </Tooltip>

        </React.Fragment>

      }
    }
  ]


  return (
    <div className="row  mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <h5>All Cities: {totalCities || 0}</h5>
            <div className="search-box-table">

            </div>

            <div className="sort-box-table mark-btn">
              <a className="btn" onClick={() => dispatch(push('/masters/add-city'))}>
                <img src={'../dist/icons/plus.png'} className={'plus'}/>
                Add City</a>
            </div>
          </div>
          <div className="card-body table-responsive">
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       pagination={DefaultTablePagination()}
                       ref={tableRef}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListAllCities
