import React, { useState, useEffect, useRef } from 'react'
import { Form, notification, Icon, Select, Col, Row } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { addTrackerFxn, trackerListFxn, updateToDoTask, updateTrackerStatus } from './action'
import { DefaultTablePagination, InputBox } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { listAllCountries } from '../countries/actions/countries'

const { Option } = Select

const TrackerUnizHome = (props) => {
  const { form: { getFieldDecorator } } = props
  const [statusFilter, setStatusFilter] = useState()
  let [allCountry, setAllCountry] = useState([])

  const dispatch = useDispatch()
  const tableRef = useRef()
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }

  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className={''}>
              {text}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Country',
      dataIndex: 'countryId',
      key: 'countryId',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className={''}>
              {text && text.countryName}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (status, record) => {
        return (
          <Select
            defaultValue={status && status}
            className={'small-select'}
            onChange={(value) => events.handleStatusChange(record._id, value)}
          >
            {statusOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                <span className={'statusCss'} style={{ backgroundColor: option.color }} />
                {option.label}
              </Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'POC (Point Of Contact)',
      dataIndex: 'poc',
      key: 'poc'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason'
    }
  ]
  const priorityOptions = [
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Moderate',
      label: 'Moderate',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    }
  ]
  const statusOptions = [
    { value: 'Awaiting Reply', label: 'Awaiting Reply', color: '#6C757D' },
    { value: 'Awaiting Contract', label: 'Awaiting Contract', color: '#007BFF' },
    { value: 'Contract Signed', label: 'Contract Signed', color: '#28A745' },
    { value: 'Not Interested', label: 'Not Interested', color: '#FFA500' }
  ]

  const apiRequest = async (params) => {
    try {
      if (statusFilter) {
        params['status'] = statusFilter
      }
      const { data } = await dispatch(trackerListFxn(params))
      return data
    } catch (error) {
      console.error('Error fetching tasks:', error)
    }
  }

  const handleSubmit = async (e) => {
    const { form } = props
    e.preventDefault()
    try {
      const valData = await form.validateFields()
      const data = await dispatch(addTrackerFxn(valData))
      if (data.success) {
        events.reloadGroup()
        form.resetFields()
      }
    } catch (err) {
      notification.warning({
        message: 'Fill All Required Fields'
      })
    }
  }


  useEffect(() => {
    events.loadCountry()
  }, [])
  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })

  const events = {
    getPriorityColor: (priority) => {
      const priorityOption = priorityOptions.find((option) => option.key === priority)
      return priorityOption ? priorityOption.icon.props.style.color : ''
    },
    reloadGroup: () => {
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    handleStatusChange: async (taskId, status) => {
      const result = await dispatch(updateTrackerStatus(taskId, { status }))
      if (!result.error) {
        events.reloadGroup()
      }
    },
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend'
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    }

  }

  useEffect(() => {
    if (statusFilter !== undefined) {
      events.reloadGroup()
    }
  }, [statusFilter])

  const inputTypes = {
    fields: [
      {
        key: 'companyName',
        label: 'Company Name',
        required: true,
        placeholder: 'Company Name'
      },
      {
        key: 'poc',
        label: 'POC (Point Of Contact)',
        required: true,
        placeholder: 'POC (Point Of Contact)'
      },
      {
        key: 'email',
        label: 'Email',
        required: true,
        placeholder: 'Email'
      },
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x
          })
        }
      },
      {
        key: 'status',
        label: 'Status',
        required: true,
        type: 'select',
        showSearch: true,
        options: statusOptions,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.label}`,
        onChange: x => {
          props.form.setFieldsValue({
            status: x
          })
        }
      },
      {
        key: 'reason',
        label: 'Reason',
        placeholder: 'Reason...',
        type: 'textarea',
        span: 24
      }

    ]
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <div className='row'>

      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>Supply Tracker</h5>
          </div>
          <div className='card unizportal'>
            <Form>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <>
                      {item.customField ? !item.hidden &&
                        <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                        <Col span={item.span ? item.span : 8} key={key}>
                          <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        </Col>}
                    </>
                  )
                })}
              </Row>
              <button className='btn' type='submit' onClick={handleSubmit}>save</button>
            </Form>
          </div>
        </div>
      </div>
      <div className='col-lg-12'>
        <div className='form-box'>
          <div className='card unizportal'>
            <div className={'row mt5'}>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Status'}
                        className={'md-select'}
                        allowClear={true}
                        value={statusFilter}
                        onChange={(value) => {
                          if (value) {
                            setStatusFilter(value)
                          } else {
                            setStatusFilter(null)
                          }
                        }}>
                  {statusOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
            </div>

            <div className={'row'}>
              <div className={'col-md-12'}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10
                }}>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-12'}>
                <TableComp columns={taskColumns}
                           style={tableStyles}
                           apiRequest={apiRequest}
                           pagination={DefaultTablePagination()}
                           ref={tableRef}
                           extraProps={{ scroll: { x: 1200 } }}
                           rowKey='_id' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ global }) => ({ currentUserRights: global.currentUserRights })

const mapDispatchToProps = (dispatch) => ({ dispatch })

const ToDoListWrappedComponent = Form.create()(TrackerUnizHome)

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListWrappedComponent)
