import {
  enquiryDocumentsUrl,
  updateUnizhomePropertyUrl,
  updatePropertyFaqUrl,
  deleteUnizhomeFaqUrl,
  addUnizhomeFaqUrl,
  deleteUnizhomePolicyUrl,
  editUnizhomeRoomUrl,
  deleteUnizhomeRoomUrl,
  commonAmenitiesUrl,
  getUnizHomePropertyListUrl,
  getMediaByRoomIdUrl,
  unizhomeSinglePropertyUrl,
  updateUnizhomePolicyUrl,
  commonRoomAmenitiesUrl,
  updatePropertyActiveStatusUrl,
  addUnizhomePropertyUrl,
  addUnizhomeRoomUrl,
  addUnizhomePolicyUrl,
  getAmenitiesCategoryUrl,
  editUnizhomeActiveChildRoomUrl,
  deleteUnizhomeActiveChildRoomUrl,
  addUnizhomeSubRoomUrl,
  updateRoomActiveStatusUrl,
  updateRoomDescriptionUrl,
  deleteRoomImageUrl,
  deleteRoomMediaImageUrl,
  getAmenitiesUrl,
  getRoomsUrl,
  getMediaUrl,
  addAmenitiesUrl,
  deleteAmenitiesUrl,
  updateFeaturedImageUrl,
  updateRoomFeaturedImageUrl, getRoomUnitTypeUrl, addPropertyImageUrl
} from '../api'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'


export const enquiryDocumentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.put(enquiryDocumentsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const addPropertyImageFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addPropertyImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateUnizhomePropertyFxn = (propertyId, valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateUnizhomePropertyUrl(), { ...valData, propertyId }, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updatePropertyFaqFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updatePropertyFaqUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}


export const deleteUnizhomeFaqFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.delete(deleteUnizhomeFaqUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const addUnizhomeFaqFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUnizhomeFaqUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const deleteUnizhomePolicyFxn = (filters) => async (dispatch) => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.delete(deleteUnizhomePolicyUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const editUnizhomeRoomFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(editUnizhomeRoomUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  return data
}
export const editUnizhomeActiveChildFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(editUnizhomeActiveChildRoomUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const deleteUnizhomeRoomFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteUnizhomeRoomUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const deleteUnizhomeActiveChildRoomFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteUnizhomeActiveChildRoomUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const commonAmenitiesFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(commonAmenitiesUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const getUnizHomePropertyListFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getUnizHomePropertyListUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}

export const unizhomeSinglePropertyFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(unizhomeSinglePropertyUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}
export const updateUnizhomePolicyFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateUnizhomePolicyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addUnizhomePolicyFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUnizhomePolicyUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateRoomDescriptionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateRoomDescriptionUrl(), valData, await getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const commonRoomAmenitiesFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(commonRoomAmenitiesUrl(), config)
  return data.data
}
export const getAmenitiesCategoryFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAmenitiesCategoryUrl(), config)
  dispatch(hidePageLoad())
  return data.data
}
export const updatePropertyActiveStatusFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updatePropertyActiveStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateRoomActiveStatusFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())

  let { data } = await axios.post(updateRoomActiveStatusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getMediaByRoomIdFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getMediaByRoomIdUrl(), valData, getToken())
  dispatch(hidePageLoad())
  // if (!data.error) {
  //   notification.success({
  //     message: data.message || 'Success'
  //   })
  // } else {
  //   notification.error({
  //     message: data.message || 'Error'
  //   })
  // }
  return data
}
export const deleteRoomMediaImageFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteRoomMediaImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addAmenitiesFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addAmenitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const deleteAmenitiesFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteAmenitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addUnizhomePropertyFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUnizhomePropertyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addUnizhomeRoomFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUnizhomeRoomUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const addUnizhomeSubRoomFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addUnizhomeSubRoomUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updateFeaturedImageFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateFeaturedImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getAmenitiesFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getAmenitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getRoomsFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getRoomsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const getRoomUnitTypeFxn = () => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.get(getRoomUnitTypeUrl())
  dispatch(hidePageLoad())
  return data
}

export const getMediaFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(getMediaUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const updateRoomFeaturedImageFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateRoomFeaturedImageUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
