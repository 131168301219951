import _ from 'lodash'

export const groupedAmenitiesFxn = (amenities, groupBy = 'featureName') => {
  let groupedData = _.groupBy(amenities, groupBy)
  let respData = Object.entries(groupedData).map(([groupKey, groupedItems]) => ({
    data: groupedItems,
    name: groupKey
  }))
  return respData
}
