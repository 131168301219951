import React from 'react'
import {
  Col, Icon,
  Row
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes/routes'
import { departmentObj } from '../../../components/_utils/appUtils'

const Reporting = (props) => {
  const dispatch = useDispatch()

  const columns = [
    {
      title: 'Branch Manager',
      userType: 'branchManager',
      managerType: 'null'
    },
    {
      title: 'Branch User',
      userType: 'branchUser'
    },

    {
      title: 'Marketing User',
      userType: 'branchUser',
      department: departmentObj.marketing
    },
    {
      title: 'Unizhome',
      userType: 'onBoardManager'
    }

  ]
  const goToPage = (item) => {
    let obj = {
      userType: item.userType
    }
    if (item.department) {
      obj.department = item.department
    }
    if (item.managerType) {
      obj.managerType = item.managerType
    }
    if (item.userType == 'branchManager' && item.managerType == 'null') {
      obj.managerType = ''
    }
    dispatch(getUrlPushWrapper('reportingList', obj))
  }


  return (
    <div className='row  mt-4'>
      <div className='col-lg-12'>
        <div className='card reportContainer'>
          <div className='table-head d-flex align-items-center'>
            <h4>User Reports</h4>
            <div className='search-box-table'>

            </div>

            <div className='sort-box-table mark-btn'>

            </div>
          </div>
          <div className={'reportBox'}>
            <Row gutter={18}>
              {
                columns.map((item, key) => {
                  return (
                    <a
                      onClick={() => {
                        goToPage(item)
                      }}>
                      <Col md={4}>
                        <div className={'reportingBox'}>
                          <div className={'reportingInnerBox'}>
                            <img src={'../assets/userIcon.png'} />
                            <div className={'reportInfoBox'}>
                              {item.title}
                              <Icon type='right' />
                            </div>
                          </div>
                          {/*<Icon type='right'className={'arrowIcon'}/>*/}
                        </div>
                      </Col></a>
                  )
                })
              }

            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reporting)


