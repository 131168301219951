import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    notification,
    Switch,
    Input, Button, Icon, Drawer, List, Popconfirm
} from 'antd'
import {connect} from 'react-redux'
import {listAllUsers, blockAgent} from '../users/actions/user'
import {
    ConditionOptions,
    DefaultTablePagination,
    departmentList,
    departmentObj
} from '../../components/_utils/appUtils'
import { getPushPathWrapper } from '../../routes/routes'
import { listAllCountries } from '../countries/actions/countries'
import UpdatePassword from '../users/views/changePassword'
import EditUser from '../users/views/edit'
import EditSubAgent from '../users/views/editSubAgent'
import {CheckMyRights} from '../WebComponent/CheckUserRights'
import {ColumnWidth} from '../WebComponent/columnWidth'
import {push} from 'connected-react-router'
import UserRightList from '../users/list/rightsList'
import {CheckUserRight} from '../dashboard/views/DashboardUserWise'
import LogsComponents from '../users/drawer/logsComponents'
import {getInsuranceList, updateInsuranceStatus} from './actions/actions'
import {StudentInsuranceInfoDrawer} from './InsuranceInfoDrawer'
import InsuranceApproveDrawer from './InsuranceApproveDrawer'
import fcmtAddStudent from '../student/views/fcmtAddStudent'
import LoaRequest from '../applications/drawers/loaRequest'
import UploadInsurance from './UploadInsuranceDrawer'
import _ from 'lodash'
import UploadInsuranceReceipt from './UploadReceiptDrawer'

const userTypeOptions = [
    {value: 'branchManager', text: 'Branch Manager'},
    {value: 'branchUser', text: 'Branch User'},
    {value: 'master', text: 'Master'}
    /*{ value: 'agent', text: 'Agent' },
    { value: 'subAgent', text: 'Sub Agent' }*/
]
const commissionType = [
    {text: 'Silver', value: 'silver'},
    {text: 'Gold', value: 'gold'},
    {text: 'Platinum', value: 'platinum'}
]

class AllUsers extends Component {

    events = {
        showUserRightDrawer: (data) => {
            this.setState({
                visibleUserRightDrawer: true,
                selectedUser: data
            })
        },
        hideUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            })
        },
        closeAndUpdateUserRightDrawer: () => {
            this.setState({
                visibleUserRightDrawer: false,
                selectedUser: {}
            }, () => {
                this.tableRef.current.reload()
            })
        },
        openInfoDrawer: (data) => {
            this.setState({
                visibleInfoDrawer: true,
                selectedUser: data
            })
        },
        closeInfoDrawer: () => {
            this.setState({
                visibleInfoDrawer: false,
                selectedUser: {}
            })
        },
        openApproveDrawer: (data) => {
            this.setState({
                visibleApproveDrawer: true,
                selectedUser: data
            })
        },
        closeApproveDrawer: () => {
            this.setState({
                visibleApproveDrawer: false,
                selectedUser: {}
            })
            this.tableRef.current.reload()
        },
        openUploadInsurance: (data, editVal) => {
            this.setState({
                selectedUser: data,
                visibleUploadInsuranceDrawer: true,
                isEditAble: editVal
            })
        },
        closeUploadInsurance: () => {
            this.setState({
                selectedUser: {},
                visibleUploadInsuranceDrawer: false
            })
        },
        openUploadReceipt: (data, editVal) => {
            this.setState({
                selectedUser: data,
                visibleUploadReceiptDrawer: true,
                isEditAble: editVal
            })
        },
        closeUploadReceipt: () => {
            this.setState({
                selectedUser: {},
                visibleUploadReceiptDrawer: false
            })
        }
    }

    constructor(props) {
        super(props)
        this.state = {
            viewInfoDrawer: false,
            selectedRow: {},
            allAgents: [],
            allBranchManager: [],
            allCountry: [],
            selectedUser: {},
            visibleUpdatePassword: false,
            visibleUpdateUser: false,
            visibleUpdateSubAgent: false,
            visibleUserRightDrawer: false,
            visibleInfoDrawer: false,
            visibleApproveDrawer: false,
            showMore: true,
            isEditAble: false,
            visibleUploadInsuranceDrawer: false,
            visibleUploadReceiptDrawer: false
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        this.loadAllAgent()
        this.loadAllBranchManager()
        this.loadAllCountry()
    }

    apiRequest = (params) => {
        let {dispatch, currentUser} = this.props

        return new Promise(async (resolve) => {
            // params.sortField = 'name'
            // params.sortOrder = 'ascend'
            //
            // if (!params.userType || (params.userType && params.userType.length == 0)) {
            //   // params.userType = { $ne: 'student' }
            //   params.userType = ['branchUser']
            // }
            if (currentUser && currentUser.userType == 'agent') {
                params.userId = currentUser._id
            }
            let resp = await dispatch(getInsuranceList({
                ...params, results: 100
            }))
            console.log('resp all', resp)
            resolve(resp)
        })
    }

    async loadAllAgent() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'agent'}))
        this.setState({allAgents: data})
    }

    async loadAllBranchManager() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllUsers({userType: 'branchManager'}))
        this.setState({allBranchManager: data})
    }

    async loadAllCountry() {
        let {dispatch} = this.props
        let {data} = await dispatch(listAllCountries())
        this.setState({allCountry: data})
    }

    viewInfo(record) {
        this.setState({
            selectedRow: record,
            viewInfoDrawer: true
        })
    }

    onClose = () => {
        this.setState({
            selectedRow: {},
            viewInfoDrawer: false
        })
    }

    closeUpdatePassword = () => {
        this.setState({
            selectedUser: {},
            visibleUpdatePassword: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditUser = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateUser: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    closeEditSubAgent = () => {
        this.setState({
            selectedUser: {},
            visibleUpdateSubAgent: false
        }, () => {
            this.tableRef.current.reload()
        })
    }

    updateAgentBlockStatus = async (id) => {
        let {dispatch} = this.props
        let resp = await dispatch(blockAgent({agentId: id}))
        if (resp && resp.success) {
            this.tableRef.current.reload()
        }
    }

    render() {
        let {
            selectedRow,
            allAgents,
            allBranchManager,
            allCountry,
            visibleUpdatePassword,
            selectedUser,
            visibleUpdateUser,
            visibleUpdateSubAgent,
            visibleUserRightDrawer,
            visibleInfoDrawer,
            visibleApproveDrawer,
            visibleUploadInsuranceDrawer,
            visibleUploadReceiptDrawer,
            isEditAble,
            totalBranchUser
        } = this.state

        let {dispatch, currentUser} = this.props

        const columns = [
            {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                width: 50,
                render: (ite, record, index) => {
                    return (
                        <React.Fragment>
                            {index + 1}
                        </React.Fragment>
                    )
                }
            },
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 120
            },
            {
                title: 'Email',
                dataIndex: 'studentEmail',
                key: 'studentEmail',
                searchTextName: 'studentEmail',
                width: 120,
                render: (item) => {
                    return (
                        <ColumnWidth width={120}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Mobile No',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Agent',
                dataIndex: 'addByUserId',
                key: 'addByUserId',
                hidden: currentUser.userType == 'agent',
                width: 150,
                render: (val) => {
                    return (
                        <div>
                            {val ? <>
                                Agent : {val.name ? val.name : ''}
                                {val.companyName ? `(${val.companyName})` : null}
                            </> : null}
                        </div>
                    )
                }
            },
            {
                title: 'Country Of Origin',
                dataIndex: 'countryOfOrigin',
                key: 'countryOfOrigin',
                width: 80,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Insurance Cover For',
                dataIndex: 'insuranceCoverFor',
                key: 'insuranceCoverFor',
                width: 90,
                render: (item) => {
                    return (
                        <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Passport',
                key: 'passport',
                width: 90,
                render: (item, record) => {
                    return (
                        item && item.url ?
                            <Tooltip title={'Download Passport'}>
                                <a download={item.name} href={item.url}>
                                    <img src={'/assets/icons/cloud-computing-download.png'} height={40}/>
                                </a></Tooltip> : ''
                    )
                }
            },
            {
                title: 'Offer Letter',
                key: 'offerLetter',
                width: 90,
                render: (item) => {
                    return (
                        // console.log(item)
                        <>
                            {item && item.url ?
                                <Tooltip title={'Download Offer Letter'}>
                                    <a download={item.name} href={item.url}>
                                        <img src={'/assets/icons/cloud-computing-download.png'} height={40}/>
                                    </a></Tooltip> : ''}
                        </>
                    )
                }
            },
            {
                title: 'Insurance',
                key: 'insuranceFile',
                width: 90,
                render: (item, record) => {
                    let {currentUser} = this.props
                    return (
                        currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'agent' || currentUser.showInsurance) ? <>
                            {item && item.url ?
                                <Tooltip title={'Download Insurance File'}>
                                    <a download={item.name} href={item.url}>
                                        <img src={'/assets/icons/cloud-computing-download.png'} height={40}/>
                                    </a></Tooltip> : ''}
                            <br />
                            <br />
                            {(currentUser.userType == 'admin' || currentUser.showInsurance) && currentUser.userType !== 'agent' ?
                              <a className={'bs-link bs-xs'} onClick={() => {
                                  this.events.openUploadInsurance(record, false)
                              }}>{item && item.url ? 'Re-Upload' : 'Upload'}</a> : null}
                        </> : null
                    )
                }
            },
            {
                title: 'Receipt',
                key: 'insuranceReceipt',
                width: 90,
                render: (item, record) => {
                    let { currentUser } = this.props
                    return (
                      currentUser && (currentUser.userType == 'admin' || currentUser.userType == 'agent' || currentUser.showInsurance) ? <>
                          {item && item.url ?
                            <Tooltip title={'Download Receipt'}>
                                <a download={item.name} href={item.url}>
                                    <img src={'/assets/icons/cloud-computing-download.png'} height={40} />
                                </a></Tooltip> : ''}
                          <br />
                          <br />
                          {(currentUser.userType == 'admin' || currentUser.showInsurance) && currentUser.userType !== 'agent' ?
                            <a className={'bs-link bs-xs'} onClick={() => {
                                this.events.openUploadReceipt(record, false)
                            }}>{item && item.url ? 'Re-Upload' : 'Upload'}</a> : null}
                      </> : null
                    )
                }
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                searchTextName: 'status',
                width: 100,
                render: (item) => {
                    return (
                      <label className={`label ${item == 'Approved' ? 'label-success' : 'label-warning'}`}>
                          {item}
                        </label>
                        // <ColumnWidth width={80}>{item}</ColumnWidth>
                    )
                }
            },
            {
                title: 'Comments',
                dataIndex: 'comment',
                key: 'comment',
                searchTextName: 'comment',
                width: 160,
                render: (item) => {
                    return (
                        <ColumnWidth width={160}>
                            <div>
                                <div className={this.state.showMore ? 'appCommentDotsReplace' : ''}>
                                    {item && item ? item : ''}
                                </div>
                                {item && item.length && item.length > 50 ?
                                    <div className={'alignRight'}>
                                        {this.state.showMore ? <a onClick={() => {
                                            this.setState({showMore: false})
                                        }}>Show More</a> : <a onClick={() => {
                                            this.setState({showMore: true})
                                        }}>Show Less</a>}
                                    </div> : ''}
                            </div>
                        </ColumnWidth>
                    )
                }
            },
            // {
            //   title: 'Block',
            //   dataIndex: 'block',
            //   width: 80,
            //   key: 'block',
            //   filters: ConditionOptions.map(x => ({ value: x.value, text: x.name })),
            //   render: (item, record) => {
            //     return (
            //       <React.Fragment>
            //         {record && record.userType && (record.userType !== 'admin') ?
            //           <div>
            //             <Popconfirm title={`Are your sure, you want to ${item ? 'Un-Block' : 'Block'} this User?`}
            //                         onConfirm={() => {
            //                           this.updateAgentBlockStatus(record._id)
            //                         }}
            //                         okText='Yes' cancelText='No'>
            //               {item ? <div className={'label label-success labelNew'}>Yes</div> :
            //                 <div className={'label label-warning labelNew'}>No</div>}
            //             </Popconfirm>
            //           </div> : null}
            //       </React.Fragment>
            //     )
            //   }
            // }
            {
                title: 'Action',
                key: '_id',
                dataIndex: '_id',
                width: 150,
                render: (val, record) => {
                    return (
                        <React.Fragment>

                            <Tooltip title='View Details'>
                                <button
                                    className={'btn'}
                                    onClick={() => {
                                        this.events.openInfoDrawer(record)
                                    }}>
                                    <Icon type={'eye'}/>
                                </button>
                            </Tooltip>

                            {record && record.status == 'Pending' ?
                                <CheckUserRight user={this.props.currentUser}
                                                rightUserType={['admin', 'branchManager', 'branchUser']}>
                                    <Tooltip title='Approve'>
                                        <button
                                          className={'btn'}
                                          onClick={() => {
                                              this.events.openApproveDrawer(record)
                                          }}
                                        ><Icon type='check' />
                                        </button>
                                    </Tooltip>
                                </CheckUserRight> : null}
                        </React.Fragment>
                    )
                }
            }
        ]

        const getColumns = () => {
            let newCol = _.filter(columns, (item) => {
                return !item.hidden
            })
            return newCol
        }

        return (
            <div>

                <div className='row  mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center'>
                                <h5>All Student Insurance</h5>
                                <div className='search-box-table'>

                                </div>

                                <CheckMyRights rightList={['admin']}>
                                    <div className='sort-box-table mark-btn'>
                                        <div>
                                            <a className='btn' onClick={() => dispatch(push('/studentInsurance'))}>
                                                <img src={'../dist/icons/plus.png'} className={'plus'}/> Add Student
                                                Insurance</a>

                                        </div>
                                    </div>
                                </CheckMyRights>
                            </div>
                            <div className='card-body table-responsive'>
                                <TableComp columns={getColumns()}
                                           ref={this.tableRef}
                                           pagination={DefaultTablePagination()}
                                           apiRequest={this.apiRequest}
                                           extraProps={{scroll: {x: 1000}}}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {/*<Drawer visible={this.state.viewInfoDrawer}*/}
                {/*        width={520}*/}
                {/*        onClose={this.onClose}>*/}
                {/*  <Card title={'Universities'}>*/}
                {/*    {selectedRow && selectedRow.universities && selectedRow.universities.length ?*/}
                {/*      <List*/}
                {/*        size='small'*/}
                {/*        bordered*/}
                {/*        dataSource={selectedRow.universities}*/}
                {/*        renderItem={item => <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}*/}
                {/*      /> : null*/}
                {/*    }*/}

                {/*  </Card>*/}
                {/*</Drawer>*/}


                {visibleUpdateUser ? <EditUser
                    visible={visibleUpdateUser}
                    onClose={() => this.closeEditUser()}
                    userData={selectedUser}/> : ''}
                {visibleUpdateSubAgent ? <EditSubAgent
                    visible={visibleUpdateSubAgent}
                    onClose={() => this.closeEditSubAgent()}
                    userData={selectedUser}/> : ''}

                {visibleUserRightDrawer ? <UserRightList
                    user={selectedUser}
                    visible={visibleUserRightDrawer}
                    onClose={() => this.events.hideUserRightDrawer()}
                    closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()}/> : ''}

                {visibleInfoDrawer ?
                    <StudentInsuranceInfoDrawer
                        user={selectedUser}
                        visible={visibleInfoDrawer}
                        onClose={() => this.events.closeInfoDrawer()}/> : ''}

                {visibleApproveDrawer ?
                    <InsuranceApproveDrawer
                        user={selectedUser}
                        visible={visibleApproveDrawer}
                        onClose={() => this.events.closeApproveDrawer()}/> : ''}

                {visibleUploadInsuranceDrawer ?
                  <UploadInsurance
                    studentData={selectedUser}
                    visible={visibleUploadInsuranceDrawer}
                    editable={isEditAble}
                    reloadTable={() => {
                        this.tableRef.current.reload()
                    }}
                    onClose={() => this.events.closeUploadInsurance()} /> : ''}

                {visibleUploadReceiptDrawer ?
                  <UploadInsuranceReceipt
                    studentData={selectedUser}
                    visible={visibleUploadReceiptDrawer}
                    editable={isEditAble}
                    reloadTable={() => {
                        this.tableRef.current.reload()
                    }}
                    onClose={() => this.events.closeUploadReceipt()} /> : ''}

            </div>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUsers)
