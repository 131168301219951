import React, { useState, useEffect } from 'react'
import { Drawer, Form, Button, Col, Row, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { FileInput } from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  updateUnizhomeContractFxn,
  getSingleContractFxn,
  getFormFieldsFxn
} from './actions'
import moment from 'moment'
import { listAllCountries } from '../countries/actions/countries'


const UpdateContractDrawer = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, setFieldsValue, getFieldValue },
    visible,
    onClose,
    contractId,
    reloadTable
  } = props
  const [file, setFile] = useState(null)
  const [bookingProcessFile, setBookingProcessFile] = useState(null)
  let [oldContract, setOldContract] = useState({})
  let [oldBookingProcessFile, setOldBookingProcessFile] = useState({})
  let [allCountry, setAllCountry] = useState([])
  const initialFormFields = {
    fields: [
      {
        key: 'companyName',
        label: 'Company Name',
        type: 'text',
        required: true
      },
      {
        key: 'contactPersonName',
        label: 'Contact Person Name',
        type: 'text',
        required: true
      },
      {
        key: 'contactMobileNo',
        label: 'Contact Mobile No',
        type: 'text',
        required: true
      },
      {
        key: 'email',
        label: 'Email Id',
        type: 'text',
        required: true
      },
      {
        key: 'uploadContract',
        label: 'Upload Contract',
        type: 'file',
        onChange: ({ target }) => handleFileChange(target),
        file: { url: oldContract && oldContract.path ? oldContract.path : '' }
      },
      {
        key: 'expiryDate',
        label: 'Expiry Date',
        type: 'date'
      },
      {
        key: 'bookingProcessDoc',
        label: 'Booking Process Doc',
        type: 'file',
        onChange: ({ target }) => handleBookingDocument(target),
        file: { url: oldBookingProcessFile && oldBookingProcessFile.path ? oldBookingProcessFile.path : '' }
      },
      {
        key: 'bookingProcessLink',
        label: 'Booking Process Link',
        placeholder: 'Booking Process Link'
      },
      {
        key: 'reconStatus',
        label: 'Recon Status',
        placeholder: 'Recon Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Invoiced' },
          { value: 'Partially Invoiced' },
          { value: 'Pending' },
          { value: 'Sent' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            reconStatus: x
          })
        }
      },
      {
        key: 'contractStatus',
        label: 'Contract Status',
        placeholder: 'Contract Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Active' },
          { value: 'Inactive' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            contractStatus: x
          })
        }
      },
      {
        key: 'status',
        label: 'Status',
        placeholder: 'Select Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'API', label: 'API' },
          { value: 'Manual', label: 'Manual' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            status: x
          })
        }
      },
      {
        key: 'countryId',
        label: 'Country',
        allowClear: true,
        required: true,
        type: 'select',
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x
          })
        }
      },
      {
        key: 'whatsappGroup',
        allowClear: true,
        label: 'WhatsApp Group',
        placeholder: 'Select Yes or No',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            whatsappGroup: x
          })
        }
      },
      {
        key: 'whatsappGroupLink',
        label: 'Whatsapp Group Link',
        placeholder: 'Whatsapp Group Link',
        required: getFieldValue('whatsappGroup') == 'Yes',
        hidden: getFieldValue('whatsappGroup') == undefined || getFieldValue('whatsappGroup') == 'No'
      },
      {
        key: 'contactDetail',
        allowClear: true,
        label: 'Contact Details',
        placeholder: 'Select Yes or No',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            contactDetail: x
          })
        }
      },
      {
        key: 'contactDetailLink',
        label: 'Contact Details Link',
        placeholder: 'Contact Details Link',
        required: getFieldValue('contactDetail') === 'Yes',
        hidden: getFieldValue('contactDetail') === undefined || getFieldValue('contactDetail') === 'No'
      }
    ]
  }
  const [formFields, setFormFields] = useState([])
  const [newFormFields, setNewFormFields] = useState([])
  const dispatch = useDispatch()
  const events = {
    getNewFormFields: async () => {
      const response = await dispatch(getFormFieldsFxn())
      if (response) {
        setNewFormFields(response.data)
      }
    },
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend',
        count: 100, results: 100, active: [true, false],
        unizhomeActive: true
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    }
  }

  useEffect(() => {
    events.loadCountry()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      await events.getNewFormFields() // Fetch new form fields
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchContractData = async () => {
      if (Object.keys(newFormFields).length > 0) {
        const resp = await dispatch(getSingleContractFxn({ contractId: contractId }))
        if (resp) {
          setTimeout(() => {

            let definedFields = {
              companyName: resp.companyName,
              contactPersonName: resp.contactPersonName,
              contactMobileNo: resp.contactMobileNo,
              email: resp.email,
              whatsappGroup: resp.whatsappGroup,
              contactDetail: resp.contactDetail,
              countryId: resp.countryId,
              status: resp.status,
              reconStatus: resp.reconStatus,
              contractStatus: resp.contractStatus,
              bookingProcessLink: resp.bookingProcessLink,
              expiryDate: resp.expiryDate ? moment(resp.expiryDate) : null
            }

            setTimeout(() => {
              if (getFieldValue('whatsappGroup') == 'Yes') {
                setFieldsValue({ whatsappGroupLink: resp.whatsappGroupLink })
              }
              if (getFieldValue('contactDetail') == 'Yes') {
                setFieldsValue({ contactDetailLink: resp.contactDetailLink })
              }
            }, 200)


            newFormFields.forEach((field) => {
              const key = field.name
              if (resp.newFields && resp.newFields[key] !== undefined) {
                definedFields[key] = resp.newFields[key]
              }
            })

            setFieldsValue(definedFields)

            if (resp.contract && resp.contract.path) {
              setOldContract(resp.contract)
            }
            if (resp.bookingProcessDoc && resp.bookingProcessDoc.path) {
              setOldBookingProcessFile(resp.bookingProcessDoc)
            }
          }, 1000)
        }
      }
    }
    fetchContractData()
  }, [newFormFields])

  const handleFileChange = (target) => {
    const file = target.files[0]
    setFile(file)
  }

  const handleBookingDocument = (target) => {
    const file = target.files[0]
    setBookingProcessFile(file)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    validateFieldsAndScroll(async (err, values) => {
      if (err) {
        notification.warning({ message: 'Fill All Required Fields' })
        return
      }

      let newFormFieldData = {}
      newFormFields.forEach((field) => {
        const fieldName = field.name
        if (values.hasOwnProperty(fieldName)) {
          newFormFieldData[fieldName] = values[fieldName]
          delete values[fieldName]
        }
      })
      values.newFields = newFormFieldData
      const fd = new FormData()
      fd.append('obj', JSON.stringify({ ...values, contractId: contractId }))
      if (file && file.name) {
        fd.append('contract', file)
      }
      if (bookingProcessFile && bookingProcessFile.name) {
        fd.append('bookingProcessDoc', bookingProcessFile)
      }

      const response = await dispatch(updateUnizhomeContractFxn(fd))
      if (response && !response.error) {
        notification.success({ message: response.message })
        onClose()
        reloadTable()
      }
    })
  }

  const addNewFields = () => {
    if (newFormFields && Array.isArray(newFormFields)) {
      const additionalFields = newFormFields
        .filter((field) => {
          // Check if the name already exists in the existing fields
          return !formFields.some((existingField) => existingField.key === field.name)
        })
        .map((field) => ({
          key: field.name, // Use the name as the key
          label: field.name
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .charAt(0)
            .toUpperCase() + field.name
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .slice(1),
          placeholder: `Enter ${field.name
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .charAt(0)
            .toUpperCase() + field.name
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .slice(1)}`
        }))

      if (additionalFields.length > 0) {
        setFormFields((prev) => {
          return [
            ...prev,
            ...additionalFields // Append new fields
          ]
        })
      }
    }
  }

  useEffect(() => {
    addNewFields()
  }, [newFormFields, allCountry])

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <Drawer
      title='Update Contract'
      width={'80%'}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >

      <div className='form-box'>
        <div className='card unizportal'>
          <Form onSubmit={handleSubmit}>
            <Row gutter={24} className={'rowWrap'}>
              {initialFormFields.fields.map((item, key) => (
                !item.hidden && <Col span={item && item.span ? item.span : 8} key={key}>
                  {item.type == 'file' && !item.hidden ?
                    <>
                      <FileInput name={item.fileName}
                                 label={item.label}
                                 file={item.file}
                                 className={'mt10'}
                                 chooseDocument={item.onChange} />
                    </> :
                    <GetEachFormFields
                      item={item}
                      getFieldDecorator={getFieldDecorator}
                      formItemLayout={formItemLayout}
                    />
                  }
                </Col>
              ))}

              {formFields.map((item, key) => (
                <Col span={item && item.span ? item.span : 8}
                     key={key}>

                  <GetEachFormFields
                    item={item}
                    getFieldDecorator={getFieldDecorator}
                    formItemLayout={formItemLayout}
                  />

                </Col>
              ))}
            </Row>
            <Form.Item>
              <Button type='primary' htmlType='submit'>Update</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}

const WrappedUpdateContractDrawer = Form.create()(UpdateContractDrawer)
export default WrappedUpdateContractDrawer
