import { apiUrl2 } from '../../settings'

export const addToDoUrl = () => {
  return apiUrl2 + '/toDoList/add-todo'
}
export const addCommentUrl = () => {
  return apiUrl2 + '/toDoList/add-comment'
}

export const toDoListUrl = () => {
  return apiUrl2 + '/toDoList/list'
}
export const commentListUrl = () => {
  return apiUrl2 + '/toDoList/comment-list'
}
export const updateToDoTaskUrl = () => {
  return apiUrl2 + '/toDoList/update'
}
export const assignUsersListUrl = () => {
  return apiUrl2 + '/toDoList/assigned-users'
}
export const addFollowUpUrl = () => {
  return apiUrl2 + '/toDoList/add-follow-up'
}
export const followUpListUrl = () => {
  return apiUrl2 + '/toDoList/follow-ups'
} 
export const addPropertyVisitUrl = () => {
  return apiUrl2 + '/toDoList/add-property-visit'
}
export const propertyVisitListUrl = () => {
  return apiUrl2 + '/toDoList/property-visits'
}
