import { adminRightUser, unizHomeTeamUsers } from '../components/_utils/appUtils'
import AgentEnquiryList from '../containers/unizHomeEnquiry/view/agentEnquiryList'
import UnizhomePropertyList from '../containers/unizHomeEnquiry/view/propertiesList'
import InventoryManagement from '../containers/unizhome/views/inventoryManagement'
import EditUnizhomeProperty from '../containers/unizhome/views/editPropertyForm'
import SingleEnquiryDetails from '../containers/unizHomeEnquiry/view/singleEnquiryDetails'
import SuggestedPropertyList from '../containers/unizHomeEnquiry/view/suggestPropertyList'
import AddPropertyForm from '../containers/unizhome/views/addPropertyForm'

let menu = [
  {
    path: '/uniz-home/enquiry-list',
    name: 'Unizhome Enquiry List',
    title: 'Unizhome Enquiry List',
    restrict: true,
    key: 'agentEnquiryList',
    dontShowOnMenu: false,
    newLink: true,
    authority: ['admin', 'assistantMarketingManager', ...unizHomeTeamUsers, 'branchUser', 'onBoardManager'],
    component: AgentEnquiryList
  },
  {
    path: '/uniz-home/property-list',
    name: 'Unizhome Property List',
    title: 'Unizhome Property List',
    restrict: true,
    key: 'unizhomePropertyList',
    dontShowOnMenu: true,
    newLink: true,
    authority: ['admin', 'onBoardManager', 'unizHomeHead'],
    component: UnizhomePropertyList
  },
  {
    path: '/uniz-home/inventory-management',
    name: 'Inventory Management',
    title: 'Inventory Management',
    restrict: true,
    key: 'unizhomeInventoryManagement',
    dontShowOnMenu: false,
    newLink: true,
    authority: [...adminRightUser, 'onBoardManager', 'unizHomeHead', 'onBoardAssistantManager'],
    component: InventoryManagement
  },
  {
    path: '/edit-unizhome-property',
    title: 'Edit Property',
    name: 'Edit Property',
    icon: 'dashboard',
    key: 'editUnizHomeProperty',
    component: EditUnizhomeProperty,
    dontShowOnMenu: true,
    authority: [...adminRightUser, 'onBoardManager', 'unizHomeHead', 'onBoardAssistantManager']
  },
  {
    path: '/uniz-home/view-enquiry',
    name: 'Enquiry Details',
    title: 'Enquiry Details',
    key: 'singleEnquiryDetails',
    authority: ['admin', 'assistantMarketingManager', ...unizHomeTeamUsers, 'branchUser'],
    dontShowOnMenu: true,
    component: SingleEnquiryDetails
  },
  {
    path: '/uniz-home/suggested-property',
    name: 'Suggested Property List',
    title: 'Suggested Property List',
    key: 'suggestedPropertyList',
    authority: ['admin'],
    dontShowOnMenu: true,
    component: SuggestedPropertyList
  },
  {
    path: '/uniz-home/add-property',
    name: 'Add Property',
    title: 'Add Property',
    key: 'addProperty',
    authority: [...adminRightUser, 'onBoardManager', 'unizHomeHead', 'onBoardAssistantManager'],
    dontShowOnMenu: true,
    component: AddPropertyForm
  }
]
export default menu
