import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Form, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { editUnizhomeActiveChildFxn } from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'

const EditRoomDuration = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, roomId, durationObj, onSubmit } = props

  const setDefaultValues = () => {
    setTimeout(() => {
      setFieldsValue({

        lease_duration: durationObj.meta && durationObj.meta.lease_duration || '',
        move_in_date: durationObj.meta && moment(durationObj.meta.available_from_formatted),

        move_out_date: durationObj.meta && moment(durationObj.meta.available_to_formatted),
        weekly_price: durationObj.weekly_price || ''
      })
    }, 500)
  }
  useEffect(() => {
    setDefaultValues()
  }, [durationObj])
  let inputTypes = {
    fields: [
      {
        key: 'lease_duration',
        label: 'Duration',
        placeholder: 'Enter lease duration',
        type: 'number',
        required: true
      },
      {
        key: 'move_in_date',
        label: 'Move In date',
        type: 'date',
        placeholder: 'Move In date'
      },
      {
        key: 'move_out_date',
        label: 'Move Out date',
        type: 'date',
        placeholder: 'Move Out date'
      },
      {
        key: 'weekly_price',
        label: 'Price',
        placeholder: 'Enter Price'
      }
      // {
      //     key: 'status',
      //     label: 'Status',
      //     placeholder: 'Edit status',
      //     type: 'text',
      //     required: true,
      // },


    ]
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const finalData = {
          ...valData,
          id: durationObj._id,
          roomId: roomId
        }
        console.log(finalData, "finalData")
        const resp = await dispatch(editUnizhomeActiveChildFxn(finalData))
        if (resp && resp.success) {
          notification.success({ message: resp.message })
          form.resetFields()
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Edit Duration`}
        width={'50%'}
        footer={null}>

        <Form onSubmit={handleSubmit}>
          <Row gutter={24} className='wrapBox'>
            {inputTypes.fields.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  {item.customField ? (
                    <Col span={12}>
                      {item.customField}
                    </Col>
                  ) : ((item.hidden == undefined || (!item.hidden)) && (
                    <Col span={12}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout}
                      />
                    </Col>
                  ))}
                </React.Fragment>
              )
            })}
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn mt40'>
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>


      </Modal>
    </>
  )
}

const EditRoomActiveChildModal = Form.create()(EditRoomDuration)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRoomActiveChildModal)
