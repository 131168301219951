import React, { useState, useRef } from 'react'
import { Form, Button, Tooltip } from 'antd'
import { newFormatDisplayDate } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import {
  unizhomeContractListFxn
} from './actions'
import { TableComp } from 'sz-react-utils-lite'
import NotesHistory from './noteHistory'
import WrappedUpdateContractDrawer from './updateUnizhomeContract'
import AddUnizhomeContract from './addUnizhomeContract'
import NewFormFieldsDataDrawer from './newFieldsDataDrawer'

const UnizhomeContract = (props) => {
  let { user } = props
  const [total, setTotal] = useState(0)

  const [isNoteHistoryVisible, setNoteHistoryVisible] = useState({
    visible: false,
    contract: {}
  })
  const [isUpdateDrawerVisible, setUpdateDrawerVisible] = useState({
    visible: false,
    contractId: ''
  })
  const [addContractModal, setAddContractModal] = useState({
    visible: false
  })
  const [otherDataModal, setOtherDataModal] = useState({
    visible: false,
    record: {}
  })
  const dispatch = useDispatch()
  const tableRef = useRef()


  const events = {
    apiRequest: (params) => {
      return new Promise(async (resolve) => {
        params.sortField = '_id'
        params.sortOrder = 'descend'
        let resp = await dispatch(unizhomeContractListFxn({
          ...params
          // regExFilters: ['semester', 'courseName', 'name']
        }))
        setTotal(resp.total)
        resolve(resp)
      })
    },

    showUpdateDrawer: (contractId) => {
      setUpdateDrawerVisible({
        visible: true,
        contractId
      })
    },

    closeUpdateDrawer: () => {
      setUpdateDrawerVisible({
        visible: false,
        contractId: ''
      })
      tableRef.current.reload()
    },

    showAddDrawer: () => {
      setAddContractModal({
        visible: true
      })
    },

    closeAddDrawer: () => {
      setAddContractModal({
        visible: false
      })
      tableRef.current.reload()
    },

    showNoteHistoryModal: (contract) => {
      setNoteHistoryVisible({
        visible: true,
        contract: contract
      })
    },

    closeNoteHistoryModal: () => {
      setNoteHistoryVisible({
        visible: false,
        contract: {}
      })
      tableRef.current.reload()
    },

    showOtherDataModal: (record) => {
      setOtherDataModal({
        visible: true,
        record: record
      })
    },

    closeOtherDataModal: () => {
      setOtherDataModal({
        visible: false,
        record: []
      })
      tableRef.current.reload()
    }

  }

  const columns = [
    {
      title: 'Sr No.',
      dataIndex: 'serialNo',
      width: 80,
      render: (val, record, index) => <div>{index + 1}</div>
    },

    {
      title: 'Company Name',
      key: 'companyName',
      dataIndex: 'companyName'

    },
    {
      title: 'Contact Person',
      key: 'contactPersonName',
      dataIndex: 'contactPersonName'

    },
    {
      title: 'Mobile',
      key: 'contactMobileNo',
      dataIndex: 'contactMobileNo'

    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: 200
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (item) => <div>{newFormatDisplayDate(item)}</div>
    },
    {
      title: 'Added By',
      dataIndex: 'addedBy',
      key: 'addedBy',
      width: 150,
      render: (item, record) => {
        return item && item.name ? item.name : ''
      }
    },
    {
      title: 'Contract',
      dataIndex: 'contract',
      key: 'contract',
      width: 120,
      render: (item) => {
        return (
          <>
            {item && item.path ?
              <a href={item.path} target={'_blank'}>
                Download
              </a>
              : null}
          </>
        )
      }
    },
    {
      title: 'Booking Process',
      dataIndex: 'bookingProcessDoc',
      key: 'bookingProcessDoc',
      width: 180,
      render: (item, record) => {
        return (
          <>
            {item && item.path ?
              <a href={item.path} target={'_blank'} className={'btn btn-default roundNew xs'}>
                {record.companyName && record.companyName} <br />
                Booking Process
              </a>
              : null}
            {record.bookingProcessLink && (
              <a
                href={record.bookingProcessLink.startsWith('http://') || record.bookingProcessLink.startsWith('https://')
                  ? record.bookingProcessLink
                  : `https://${record.bookingProcessLink}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Redirect to Link
              </a>
            )}
          </>
        )
      }
    },
    {
      title: 'Latest Note',
      dataIndex: 'latestNote',
      key: 'latestNote',
      width: 180,
      render: (item, record) => {
        return item && item.note ? item.note : ''
      }
    },
    {
      title: 'Country',
      dataIndex: 'countryId',
      key: 'countryId',
      width: 180,
      render: (item, record) => {
        return <>
          {item && item.countryName ?
            item.countryName
            : null}
        </>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100
    },
    {
      title: 'Recon Status',
      dataIndex: 'reconStatus',
      key: 'reconStatus',
      width: 100
    },
    {
      title: 'Contract Status',
      dataIndex: 'contractStatus',
      key: 'contractStatus',
      width: 100
    },
    {
      title: 'Whatsapp Group Link',
      dataIndex: 'whatsappGroupLink',
      key: 'whatsappGroupLink',
      width: 200,
      render: (item) => {
        if (!item) return ''

        // Ensure the link starts with http:// or https://
        const link = item.startsWith('http://') || item.startsWith('https://') ? item : `https://${item}`
        return (
          <a href={link} target='_blank' rel='noopener noreferrer'>
            Redirect to Link
          </a>
        )
      }
    },
    {
      title: 'Contact Details Link',
      dataIndex: 'contactDetailLink',
      key: 'contactDetailLink',
      width: 200,
      render: (item) => {
        if (!item) return ''

        // Ensure the link starts with http:// or https://
        const link = item.startsWith('http://') || item.startsWith('https://') ? item : `https://${item}`
        return (
          <a href={link} target='_blank' rel='noopener noreferrer'>
            Redirect to Link
          </a>
        )
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 100,
      render: (text, record) => {
        return (
          <>
            <Tooltip title='Update'>
              <Button
                shape='circle'
                size='small'
                icon='edit'
                onClick={() => events.showUpdateDrawer(record._id)}
              />
            </Tooltip>

            <Tooltip title='Notes History'>
              <Button
                shape='circle'
                size='small'
                onClick={() => events.showNoteHistoryModal(record)}
                icon='history'
              />
            </Tooltip>

            <Tooltip title='View Other Data'>
              <Button
                shape='circle'
                size='small'
                onClick={() => events.showOtherDataModal(record)}
                icon='eye'
              />
            </Tooltip>
          </>
        )
      }
    }
  ]

  return (
    <>
      <div className='card mt-1'>
        <div className='table-head d-flex align-items-center justify-content-between'>
          <h5>Contract List: (Total: {total})</h5>
          <div className='sort-box-table mark-btn'>
            <a className='btn' onClick={() => {
              events.showAddDrawer()
            }}>
              <img src={'../dist/icons/plus.png'} className={'plus'} alt={''} /> Add Contract
            </a>
          </div>
        </div>

        <div className='card-body table-responsive'>
          <TableComp
            apiRequest={events.apiRequest}
            columns={columns}
            ref={tableRef}
            extraProps={{ scroll: { x: 2500 } }}
            pagination={{
              position: 'top',
              pageSizeOptions: ['10', '20', '25', '50'],
              defaultPageSize: 10
            }}
          />
        </div>
      </div>
      {isUpdateDrawerVisible.visible && (
        <WrappedUpdateContractDrawer
          visible={isUpdateDrawerVisible.visible}
          onClose={events.closeUpdateDrawer}
          contractId={isUpdateDrawerVisible.contractId}
          reloadTable={() => tableRef.current.reload()}
        />
      )}

      {
        isNoteHistoryVisible.visible ? (
          <NotesHistory
            visible={isNoteHistoryVisible.visible}
            onClose={events.closeNoteHistoryModal}
            contract={isNoteHistoryVisible.contract}
          />
        ) : null
      }

      {addContractModal.visible && (
        <AddUnizhomeContract
          visible={addContractModal.visible}
          onClose={() => {
            events.closeAddDrawer()
          }}
          reloadTable={() => tableRef.current.reload()}
          user={user}
        />
      )}

      {otherDataModal.visible && (
        <NewFormFieldsDataDrawer
          visible={otherDataModal.visible}
          data={otherDataModal.record}
          onClose={() => {
            events.closeOtherDataModal()
          }}
          reloadTable={() => tableRef.current.reload()}
        />
      )}

    </>
  )
}


const UnizhomeContractWrapped = Form.create()(UnizhomeContract)
export default UnizhomeContractWrapped
