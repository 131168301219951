import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Avatar,
    Icon,
    Popconfirm,
    Row,
    Col, notification, Form, Tag
} from 'antd'
import {connect, useDispatch, useSelector} from 'react-redux'
import {exportApplicationsList, ukTuitionFeeApplicationFxn} from '../actions/chooseStudent'
import {
    statusColors,
    filterOption, newFormatDisplayDate, defaultLogoList, longDisplayDate, Intakes, departmentObj
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes/routes'
import { TableComp } from 'sz-react-utils-lite'
import SelectPendency from './selectPendency'
import lodash from 'lodash'
import {
    AllowComponentRightsWise,
    AllowComponentUserWise,
    CheckBranchUserFxn, CheckManagerWiseRight,
    ShowEnrollmentComponent
} from '../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../views/transferApplication'
import {getPendingPendency} from '../../../components/_utils/utils'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import {
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees
} from '../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from './StudentOtherApplication'
import {listAllUniversities, userWiseUniversityList} from '../../university/actions/university'
import {getBranchManagerAgentList, listAllUsers} from '../../users/actions/user'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import UpdateUniversityComponent from '../drawers/updateUniversity'
import {showEnrollmentAction} from '../../../components/_utils/appUtils'
import Enrollment from '../drawers/enrollment'
import AddNote from '../drawers/applicationAddNoteComponent'
import {AgentBranchManagerComponent} from '../../../components/_utils/AgentBranchManagerComponent'
import {countryIds} from "../../../components/_utils/appUtils";
import {ShowTuitionFeesCondition, AddTuitionFeeDrawer} from "../drawers/AddTuitionFeeDrawer";

const managerViewRight = ['admin', 'branchUser']

const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
const RenderComment = (props) => {
    let {item} = props
    let [hideV, setHideV] = useState(true)
    return (
        item && <div>
            {item.addedByUserId && item.addedByUserId.name ?
                <div className={'alignRight colorPrimary font12'}>
                    (Added By : {item.addedByUserId.name})
                </div> : null}
            <div className={hideV ? 'appCommentDotsReplace' : ''}>
                {item && item.text ? item.text : ''}
            </div>
            {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
                {hideV ? <a onClick={() => {
                    setHideV(false)
                }}>Show More</a> : <a onClick={() => {
                    setHideV(true)
                }}>Show Less</a>}
            </div> : ''}

        </div>
    )
}
const ViewStudentApplication = (props) => {
    let {onClose, studentObj = {}, reloadTable} = props
    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [addPendencyDrawer, setAddPendencyDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [universityList, setUniversityList] = useState([])
    const [appUniversityId, setAppUniversityId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    let [intake, setIntake] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [branchManagerList, setBranchManagerList] = useState([])
    let [studentManagerList, setStudentManagerList] = useState([])
    let [branchManagerId, setBranchManagerId] = useState('')
    let [studentManagerId, setStudentManagerId] = useState('')
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    const [draftApplication, setDraftApplication] = useState('')
    const [studentShore, setStudentShore] = useState('')
    const [visibleTuitionFee, setVisibleTuitionFee] = useState(false)

    let viewOnlyRight = CheckBranchUserFxn() // check branch user right

    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [updateUniversity, setUpdateUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })

    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const tableAppRef = useRef()


    const loadBranchManager = async () => {
        let obj = {
            userType: 'branchManager',
            customQuery: {
                'agents.0': {$exists: true}
            },
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setBranchManagerList(data)
    }
    const loadStudentManager = async () => {
        let obj = {
            userType: 'branchManager',
            branchManagerType: 'studentWise',
            results: 100,
            select: ['name', 'email', 'mobile', 'agents', 'countryId']
        }
        let {data} = await dispatch(listAllUsers(obj))
        setStudentManagerList(data)
    }
    const loadCurrentUserData = async () => {
        let params = {
            results: 1000,
            userType: 'branchUser',
            sortField: 'name',
            sortOrder: 'ascend',
            select: ['name', 'userType', 'mobile', 'email'],
            customQuery: {
                $or: [{countryId: countryIds.australia}, {'department': 'Visa Approved Department'}]
            }
        }
        let {data} = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setBranchUserList(data)
        }
    }

    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}

            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(ukTuitionFeeApplicationFxn({...params, regExFilters: ['name']}))

                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }

        })
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        params.results = totalApplication
        let {success, filePath, message} = await dispatch(exportApplicationsList(params))
        if (success) {
            notification.success({message: message})
            // window.open(`${apiUrl}${filePath}`, 'Download')
        } else {
            notification.error({message: message})
        }
    }

    const reloadTableFxn = () => {
        if (tableAppRef && tableAppRef.current) {
            tableAppRef.current.reload()
        }
    }

    useEffect(() => {
        loadAgentData()
        loadUniversityNameList()
        LoadUserWiseUniversity()
        setFieldByParams()
        loadBranchManager()
        loadStudentManager()
        loadCurrentUserData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        setCurrentStatus(resp.status)
        if (resp.name) {
            setName(resp.name)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.appUniversityId) {
            setAppUniversityId(resp.appUniversityId)
        }
        if (resp.branchManagerId) {
            setBranchManagerId(resp.branchManagerId)
        }
        if (resp.draftApplication) {
            setDraftApplication(resp.draftApplication)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.studentManagerId) {
            setStudentManagerId(resp.studentManagerId)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 10000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName'],
            regExFilters: ['companyName']
        }
        let {data} = await dispatch(getBranchManagerAgentList(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadUniversityNameList = async () => {
        let params = {
            results: 5000,
            select: ['universityName'],
            sortField: 'universityName',
            sortOrder: 'ascend',
            regExFilters: ['universityName']
        }
        let resp = await getParamsForApplicationList()

        params.universityCountry = countryIds.australia

        let {data} = await dispatch(listAllUniversities(params))
        if (data && data.length) {
            setUniversityList(data)
        }
    }

    const LoadUserWiseUniversity = async () => {
        let data = await dispatch(userWiseUniversityList({countryId: countryIds.australia}))
        setAppUniversityId('')
        if (data && data.length) {
            setUniversityList(data)
        }
    }
    const events = {
        openAddPendencyDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setAddPendencyDrawer(true)
        },
        reloadAndClose: () => {
            reloadTableFxn()
            setAddPendencyDrawer(false)
        },
        closeAddPendencyDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setAddPendencyDrawer(false)
        },
        reloadFxn: () => {
            reloadTableFxn()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            reloadTableFxn()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            reloadTableFxn()
        },
        refreshApplication: () => {
            reloadTableFxn()
        },

        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            reloadTableFxn()
        },

        enterName: (value) => {
            setName(value)
            /*if(!value){
              setTimeout(()=>{
                reloadTableFxn()
              },500)
            }*/
        },
        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }
        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success, message} = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        showTuitionFeeDrawer: () => {
            setVisibleTuitionFee(true)
        },
        hideTuitionFeeDrawer: () => {
            setVisibleTuitionFee(false)
            tableAppRef.current.reload()
        },
    }


    const reActivateApp = (record) => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        reloadTableFxn()
    }

    const enrollmentApp = (record) => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseEnrollmentApp = () => {
        setEnrollmentObj({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        reloadTableFxn()
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        reloadTableFxn()
    }

    const updateUniversityEvent = {
        openDrawer: (record) => {
            setUpdateUniversity({
                visible: true,
                studentId: record._id,
                studentData: record,
                application: record.applications
            })
        },
        closeDrawer: () => {
            setUpdateUniversity({
                visible: false,
                studentId: '',
                studentData: {},
                application: {}
            })
            reloadTableFxn()
        }
    }

    const enrollmentBtn = (record) => {
        let application = record.applications
        /* return (
             !application.enrollment ? showEnrollmentAction(application.statusList) ?
                 <button className='btn' onClick={() => enrollmentApp(record)}>
                     <img src={'/assets/enrollment.png'}/>
                 </button> : null : null
         )*/
        return null
    }

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let intake = searchParams.get('intake')
            let branchUserId = searchParams.get('branchUserId')

            let branchManagerId = searchParams.get('branchManagerId')
            let draftApplication = searchParams.get('draftApplication')
            let studentShore = searchParams.get('studentShore')
            let studentManagerId = searchParams.get('studentManagerId')
            let obj = {
                status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }
            if (intake) {
                obj.intake = intake
            }
            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (branchManagerId) {
                obj.branchManagerId = branchManagerId
            }
            if (studentManagerId) {
                obj.studentManagerId = studentManagerId
            }
            if (draftApplication) {
                obj.draftApplication = draftApplication
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {
            status: resp.status
        }
        if (name) {
            obj.name = name
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (appUniversityId) {
            obj.appUniversityId = appUniversityId
        }
        if (intake) {
            obj.intake = intake
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (branchManagerId) {
            obj.branchManagerId = branchManagerId
        }
        if (studentManagerId) {
            obj.studentManagerId = studentManagerId
        }
        if (draftApplication) {
            obj.draftApplication = draftApplication
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        dispatch(
            getUrlPushWrapper('ukTuitionFeeApplications', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            reloadTableFxn()
        }, 300)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {
            status: resp.status
        }
        if (resp.draftApplication) {
            obj.draftApplication = resp.draftApplication
        }
        setAppUniversityId('')
        setAgentId('')
        setName('')
        setIntake('')
        setBranchManagerId('')
        setStudentManagerId('')
        setDraftApplication('')
        setStudentShore('')
        setBranchUserId('')
        dispatch(
            getUrlPushWrapper('ukTuitionFeeApplications', {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            reloadTableFxn()
        }, 200)
    }

    const commissionRight = () => {
        if (user.userType === 'admin') {
            return false
        }
        if (user.userType === 'agent') {
            return false
        }
        if (user.userType === 'branchUser') {
            if (user.department === 'Visa Approved Department')
                return false
        }
        return true
    }

    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            searchTextName: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'S.Id',
            key: 'studentId',
            dataIndex: 'studentId',
            width: 60,
            render: (item, record) => {
                return (
                    <div>
                        <label className={'label label-tag'}>
                            ID : {item}
                        </label>
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name'
        },
        {
            title: 'DOB',
            dataIndex: 'dateOfBirth',
            key: 'dateOfBirth',
            width: 90,
            render: (val) => {
                return (
                    <div style={{width: 90}}>
                        {newFormatDisplayDate(val)}
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 80,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <div className={'label label1'}
                             style={{background: statusColors(item), width: 80, padding: 3}}>{item}</div>
                    </React.Fragment>
                )
            }
        },

        {
            title: 'University',
            key: 'applications.courseUniversity',
            dataIndex: 'applications.courseUniversity',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.logo && item.logo.url ?
                            <Avatar src={item.logo.url} size={20}/>
                            : <Avatar src={defaultLogoList.defaultUniversityLogo} size={20}/>}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Country',
            key: 'applications.universityCountry',
            dataIndex: 'applications.universityCountry',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.countryName ? item.countryName : null}, {' '}
                        {record && record.stateName ? record.stateName : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}}>
                        {item}

                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Pendency',
            key: 'applications.pendencyArr',
            dataIndex: 'applications.pendencyArr',
            // width: 100,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        <Tooltip title={'Add Pendency'}>
              <span onClick={() => viewOnlyRight ? events.openAddPendencyDrawer(record) : ''}
                    className='badge'>{item && item.length ? getPendingPendency(item) : 0}</span>

                        </Tooltip>{' '}


                    </React.Fragment>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 150,
            render: (val, record) => {
                let {agentId, statusList, status, addByUserId, enrollment} = record.applications
                return (
                    <React.Fragment>
                        {val && val.name ? val.name : ''}<br/>

                        {agentId && agentId.companyName ?
                            <div>
                                {user.department == departmentObj.assDepartment && user.managerViewOnly ? null : `Agent : ${agentId.companyName}`}
                            </div>
                            : ''}

                        {addByUserId && addByUserId.name ?
                            <div className={'mt5 colorPrimary font12'}>(Added By
                                : {addByUserId && addByUserId.name ? addByUserId.name : ''})</div> : ''}

                        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>

                            {agentId && agentId.mobile ? <div>Mob : {agentId.mobile}</div> : ''}
                        </CheckUserRight>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Student Manager',
            dataIndex: 'branchManagerId',
            hidden: !managerViewRight.includes(user.userType),
            width: 125,
            key: 'branchManagerId',
            render: (item, record) => {
                let {branchManagerId} = record
                return (
                    <React.Fragment>
                        {branchManagerId ? branchManagerId.name : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Managers',
            dataIndex: 'managers',
            hidden: !managerViewRight.includes(user.userType),
            width: 120,
            key: 'managers',
            render: (item, record) => {
                let {agentId, universityCountry} = record.applications
                return (
                    <React.Fragment>
                        {branchManagerList && branchManagerList.length && agentId ?
                            <AgentBranchManagerComponent
                                userId={agentId._id}
                                countryId={universityCountry._id}
                                branchManagers={branchManagerList}/> : null}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Tuition Fees',
            key: 'ukTuitionFee',
            dataIndex: 'applications.ukTuitionFee',
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: (val) => {
                return (
                    <div style={{width: 150}}>
                        {val && val.text ? <RenderComment item={val}/> : null}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                let {applications} = record
                return (
                    <div className={'actionBtnGroup'}>
                        {!record.applications.directClosed &&
                        <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                            <Tooltip title={'View Application'}>
                                <a className='btn'
                                   href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                                ><img src='/dist/img/view.svg' alt='' className={'eyeBtn'}/></a>

                            </Tooltip>{' '}
                        </AllowComponentRightsWise>}

                        <div>
                            <AllowComponentRightsWise
                                rightUserType={['admin', 'branchUser', 'branchManager', 'userManager']}>
                                <Tooltip title={'View Application'}>
                                    <a className='btn'
                                       href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
                                    ><img src='/dist/img/view.svg' alt=''/></a>
                                </Tooltip>

                                <ShowTuitionFeesCondition applications={applications}>
                                    <Tooltip title={'Update Tuition Fees'}>
                                        <button className='btn' onClick={() => {
                                            events.showTuitionFeeDrawer()
                                            setStudentData(record)
                                        }}>
                                            <img src={'../dist/img/tuition.png'}/>
                                        </button>
                                    </Tooltip>
                                </ShowTuitionFeesCondition>

                            </AllowComponentRightsWise>

                            <CheckManagerWiseRight>
                                {record.applications.status !== 'Case Close' &&
                                <Tooltip title={'Case Closed'}>
                                    <Popconfirm title={'Are your sure, you want to Close Case?'}
                                                onConfirm={() => {
                                                    events.closeDirectApp(record)
                                                }}
                                                okText='Yes' cancelText='No'>
                                        <button className='btn'><img src='dist/img/cross.svg' alt=''
                                                                     className={'crossBtn'}/></button>
                                    </Popconfirm>
                                </Tooltip>}
                            </CheckManagerWiseRight>
                            <AllowComponentRightsWise
                                rightUserType={['admin', 'branchUser', 'branchManager', 'userManager']}>
                                <Tooltip title={'View Other Application'}>
                                    <button className='btn' onClick={() => {
                                        events.openOtherAppDrawer(record)
                                    }}><Icon type={'ordered-list'}/></button>

                                </Tooltip>
                                <Tooltip title={'Add Note'}>
                                    <button className='btn' onClick={() => {
                                        events.openAddCommentDrawer(record)
                                    }}><Icon type={'plus'}/></button>
                                </Tooltip>
                            </AllowComponentRightsWise>
                        </div>

                        <AllowComponentUserWise rightUserType={['admin', 'branchUser', 'branchManager', 'userManager']}>
                            <Tooltip title={'Transfer'}>
                                <button className='btn' onClick={() => {
                                    setStudentData(record)
                                    events.showTransferApp()
                                }}>
                                    <img src={'../dist/icons/forwardArrow.png'}/>
                                </button>
                            </Tooltip>{' '}
                        </AllowComponentUserWise>

                        <AllowComponentUserWise rightUserType={['admin', 'branchManager', 'userManager']}>
                            {
                                record.applications.status == 'Case Close' ?
                                    <Tooltip title={'Re-Open Case'}>
                                        <button className='btn' onClick={() => reActivateApp(record)}>
                                            <img src={'/assets/power-button.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentUserWise>

                        <ShowEnrollmentComponent rightUserType={['admin', 'branchUser', 'userManager']}>
                            {enrollmentBtn(record)}
                        </ShowEnrollmentComponent>

                        <AllowComponentUserWise rightUserType={['admin', 'branchManager', 'userManager']}>
                            {
                                record.applications.status !== 'Case Close' ?
                                    <Tooltip title={'Change University'}>
                                        <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                            <img src={'/assets/shuffle-arrows.png'}/>
                                        </button>
                                    </Tooltip> : null
                            }
                        </AllowComponentUserWise>
                    </div>
                )
            }
        }

    ]

    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })

    let loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <Row gutter={16}>
            <Row gutter={12}>
                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'Current User'}
                                    value={branchUserId || undefined}>
                                {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Search By University' onChange={(item) => setAppUniversityId(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'University'}
                                allowClear={true}
                                showSearch={true}
                                value={appUniversityId || undefined}>
                            {universityList && universityList.length ? universityList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.universityName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Intake' onChange={(item) => setIntake(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                allowClear={true}
                                showSearch={true}
                                placeholder={'Intake'}
                                value={intake || undefined}>
                            {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>


                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'userManager']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Agent' onChange={(item) => setAgentId(item)}
                                    value={agentId || undefined}
                                    placeholder={'Agent'}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}>
                                {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.companyName}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>

                <CheckUserRight user={user} rightUserType={['admin', 'branchUser', 'userManager']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Branch Manager'
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    placeholder={'Branch Manager'}
                                    showSearch={true}
                                    onChange={(item) => setBranchManagerId(item)} value={branchManagerId || undefined}>
                                {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>
                <CheckUserRight user={user} rightUserType={['admin', 'branchUser', 'userManager']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Search By Student Manager'
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    placeholder={'Student Manager'}
                                    showSearch={true}
                                    onChange={(item) => setStudentManagerId(item)}
                                    value={studentManagerId || undefined}>
                                {studentManagerList && studentManagerList.length ? studentManagerList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>{item.name}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                </CheckUserRight>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round ml0'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>

                </Col>
                <Col md={12} sm={12} xs={12} lg={6}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>


                </Col>


            </Row>

        </Row>
    )


    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head d-flex align-items-center all-student-search'
                                 style={{height: 60}}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>All Tuition Fees Application </h5>
                                <div className='search-box-table' style={{backgroundColor: 'transparent'}}>
                                    (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>

                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={loadColumns()}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               showSizeChanger: true,
                                               pageSizeOptions: ['10', '20', '50', '100'],
                                               defaultPageSize: 10,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                {addPendencyDrawer ?
                    <SelectPendency visible={addPendencyDrawer}
                                    applicationObj={state.applicationObj}
                                    onAddPendencyClose={events.closeAddPendencyDrawer}
                                    onClose={events.reloadAndClose}
                                    reloadFxn={events.reloadFxn}
                    /> : null}
            </div>
            {transferAppVisible ?
                <TransferApplicationDrawer visible={transferAppVisible}
                                           closeTransferDrawer={() => events.hideTransferApp()}
                                           studentId={studentData._id}
                                           studentData={studentData}
                                           application={studentData.applications}
                /> : null}

            {visibleAddCourseDrawer ? <ApplyCourse
                visible={visibleAddCourseDrawer}
                onClose={() => events.hideAddCourseDrawer()}
                refreshApplication={() => events.refreshApplication()}
                studentId={studentObj._id}/> : null}


            {visibleOtherAppDrawer ?
                <StudentOtherApp visible={visibleOtherAppDrawer}
                                 applicationObj={state.applicationObj}
                                 onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                                 reloadFxn={events.reloadFxn}
                /> : null}


            {selectedApplication.visible ?
                <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate}/> : null}

            {enrollmentObj.visible ?
                <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp}/> : null}


            {changeUniversity.visible ?
                <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn}/> : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
                <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                         addedType={'direct'}
                         reloadFxn={events.reloadFxn}
                         onClose={() => events.closeAddCommentDrawer()}
                         applicationId={applicationObj.applications._id}/> : null}


            {updateUniversity.visible ?
                <UpdateUniversityComponent {...updateUniversity} onClose={updateUniversityEvent.closeDrawer}/> : null}

            {visibleTuitionFee ? <AddTuitionFeeDrawer
                visible={visibleTuitionFee}
                studentId={studentData._id}
                studentData={studentData}
                application={studentData.applications}
                reloadTable={() => {
                    setTimeout(() => {
                        tableAppRef.current.reload()
                    }, 1000)
                }}
                onClose={() => events.hideTuitionFeeDrawer()}/> : ''}
        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedComponent = Form.create()(ViewStudentApplication)
export default connect(
    null,
    mapDispatchToProps
)(WrappedComponent)

