import React, { useEffect, useState } from 'react'
import { CustomRowTable } from '../../components/_utils/RowTable'
import { Drawer, Card } from 'antd'
import { getFormFieldsFxn } from './actions'
import { useDispatch } from 'react-redux'

const NewFormFieldsDataDrawer = (props) => {
  const dispatch = useDispatch()
  const { visible, onClose, data } = props
  const [newFormFields, setNewFormFields] = useState([])

  console.log(data.newFields, 'data.newFields')
  console.log(newFormFields, 'newFormFields')
  const getNewFormFields = async () => {
    const response = await dispatch(getFormFieldsFxn())
    if (response) {
      setNewFormFields(response.data || [])
    }
  }

  useEffect(() => {
    getNewFormFields()
  }, [])

  return (
    <Drawer
      visible={visible}
      title='Other Contract Data'
      width='50%'
      onClose={onClose}
    >
      <Card>
        <div className='infoUni tableBox striped'>
          {newFormFields.map((field, index) => (
            <CustomRowTable
              key={index}
              title={field.label || `Field ${index + 1}`}
              value={
                <div>
                  {data && data.newFields && data.newFields[field.name] ? (
                    <span>{data.newFields[field.name]}</span>
                  ) : (
                    <span style={{ color: 'gray' }}>N/A</span>
                  )}
                </div>
              }
            />
          ))}
        </div>
      </Card>
    </Drawer>
  )
}

export default NewFormFieldsDataDrawer
