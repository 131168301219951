import { hidePageLoad, showPageLoad } from '../../modules/actions'
import { customAxios as axios, getToken } from '../../request'
import { notification } from 'antd'
import {
  addTrackerUrl,
  trackerListUrl, updateTrackerList, updateTrackerListUrl
} from './api'

export const addTrackerFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addTrackerUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const trackerListFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let config = {
    params: { ...valData },
    ...await getToken()
  }
  let { data } = await axios.get(trackerListUrl(), config)
  dispatch(hidePageLoad())
  return data
}

export const updateTrackerStatus = (id, { status }) => async () => {
  try {
    const requestData = { taskId: id, status }
    const response = await axios.post(updateTrackerListUrl(), requestData, getToken())
    if (!response.data.error) {
      notification.success({
        message: response.data.message || 'Success'
      })
    } else {
      notification.error({
        message: response.data.message || 'Error'
      })
    }
    return response.data
  } catch (error) {
    console.error('Error updating task:', error)
    throw error
  }
}
