import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Tooltip,
  Row,
  Col,
  Select, Icon
} from 'antd'
import lodash from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getUrlPushWrapper } from '../../../routes/routes'
import { listAllStudents } from '../actions/student'
import {
  filterOption,
  newFormatDisplayDate,
  StudentTypesFilter,
  StudentType,
  ukApplicationSteps,
  canadaApplicationSteps,
  australiaApplicationSteps,
  usaApplicationSteps,
  Intakes,
  departmentObj, udaanArr, canadaOnShoreApplicationStatusSteps, adminRightUser
} from '../../../components/_utils/appUtils'
import ViewApplication from '../../applications/views/viewApplication'
import Styles from '../views/styles.less'
import { listAllCountries } from '../../countries/actions/countries'
import { exportStudentList } from '../actions/student'
import { CheckUserRight } from '../../dashboard/views/DashboardUserWise'
import TransferToOtherAgent from '../views/transferToAgent'
import { AllowComponentUserWise } from '../../WebComponent/allowComponentRightsWise'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { getBranchManagerAgentList, listAllUsers } from '../../users/actions/user'
import { listAllUniversities } from '../../university/actions/university'
import { useHistory } from 'react-router-dom'
import MobileEmailInfo from '../../../components/commonComponents/mobileEmailInfo'
import { push } from 'connected-react-router'

const { Option } = Select
const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const mainUser = [...adminRightUser]

const AllStudentList = (props) => {
  let { user } = props
  let history = useHistory()

  const { totalStudents } = useSelector((state) => ({
    totalStudents: state.studentReducers.totalStudents
  }))
  const [state, setState] = useState(initialState)
  const [status, setStatus] = useState('')
  let [intake, setIntake] = useState('')
  const [branchUserId, setBranchUserId] = useState('')
  const [agentId, setAgentId] = useState('')
  const [studentShore, setStudentShore] = useState('')
  const [appUniversityId, setAppUniversityId] = useState('')
  const [allCountry, setAllCountry] = useState([])
  const [countryId, setCountryId] = useState(undefined)
  const [params, setParams] = useState({})
  const [totalStudent, setTotalStudent] = useState(0)
  const [name, setName] = useState('')
  let [tableSearch, setTableSearch] = useState(false)
  let [statusList, setStatusList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [branchUserList, setBranchUserList] = useState([])
  const [branchManagerAgentList, setBranchManagerAgentList] = useState([])
  const [universityList, setUniversityList] = useState([])
  const [visibleTransferToOtherAgent, setVisibleTransferToOtherAgent] = useState(false)
  let [marketingUserList, setMarketingUserList] = useState([])
  let [marketingUserId, setMarketingUserId] = useState('')
  let [branchManagerList, setBranchManagerList] = useState([])
  let [armManagerId, setArmManagerId] = useState('')
  let [countryName, setCountryName] = useState('')
  let [stateName, setStateName] = useState('')
  let [email, setEmail] = useState('')
  const [udaan, setUdaan] = useState('')


  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let respa = await getParamsForStudentList()

      params = { ...params, ...respa }
      // params.status = 'Active'

      if (respa.status) {
        params.appStatus = respa.status
      }
      if (countryId) {
        params.appCountryId = countryId
      }
      if (appUniversityId) {
        params.appUniversityId = appUniversityId
      }
      if (countryName) {
        params.countryName = countryName
      }
      if (stateName) {
        params.stateName = stateName
      }
      if (email) {
        params.email = email
      }
      if (params.name) {
        params.name = [params.name]
      } else {
        params.name = []
      }
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      setParams({ ...params })
      let resp = await dispatch(listAllStudents({
        ...params,
        regExFilters: ['name', 'address', 'email', 'mobile', 'countryName', 'stateName', 'cityName', 'universityName']
      }))
      // console.log('google.com....', resp)
      setTableSearch(false)
      setTotalStudent(resp.total)
      console.log(resp, 'eeeeeeeeeeeeeeee')
      resolve(resp)
    })
  }
  useEffect(() => {
    loadAllCountry()
    loadBranchManagerAgentList()
    loadMarketingUsers()
    loadBranchManager()
    setFieldByParams()
  }, [])

  useEffect(() => {
    loadUniversityNameList()
    loadCountryStatus()
    loadCurrentUserData()
  }, [countryId, countryList])

  const loadBranchManager = async () => {
    let obj = {
      userType: 'branchManager',
      customQuery: {
        'agents.0': { $exists: true }
      },
      results: 100,
      select: ['name', 'agents', 'countryId']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setBranchManagerList(data)
  }

  const loadMarketingUsers = async () => {
    let obj = {
      userType: 'branchUser',
      department: departmentObj.marketing,
      results: 100,
      select: ['name', 'email', 'mobile', 'agents', 'assignStateCountry']
    }
    let { data } = await dispatch(listAllUsers(obj))
    setMarketingUserList(data)
  }

  const loadAllCountry = async () => {
    let { data } = await dispatch(listAllCountries({ results: 10 }))
    setAllCountry(data)
    setCountryList(data)
  }


  const exportStudentExcel = async () => {
    let newParams = lodash.clone(params)
    newParams.status = 'Active'
    if (countryId) {
      newParams.appCountryId = countryId
    }
    let obj = {
      ...newParams, count: totalStudent,
      regExFilters: ['name', 'address', 'email', 'mobile', 'countryName', 'stateName', 'cityName']
    }
    let { success, filePath, message } = await dispatch(exportStudentList(obj))
    if (success) {
      notification.success({ message: message })
      // window.open(`${apiUrl}${filePath}`, 'Download')
    } else {
      notification.error({ message: message })
    }
  }

  const loadCountryStatus = async () => {
    let findCountry = lodash.find(countryList, (item) => {
      return item._id == countryId
    })
    if (findCountry) {
      if (findCountry.countryName.toLowerCase() == 'uk') {
        let temp = lodash.filter(ukApplicationSteps, (item) => {
          return item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'canada') {
        let temp = lodash.filter(canadaApplicationSteps, (item) => {
          return item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'australia') {
        let temp = lodash.filter(australiaApplicationSteps, (item) => {
          return item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'usa') {
        let temp = lodash.filter(usaApplicationSteps, (item) => {
          return item.name !== 'New Student'
        })
        setStatusList(temp)
      }
      if (findCountry.countryName.toLowerCase() == 'canada onshore') {
        let temp = lodash.filter(canadaOnShoreApplicationStatusSteps, (item) => {
          return item.name !== 'New Student'
        })
        setStatusList(temp)
      }
    } else {
      setStatusList([])
    }
  }

  const loadCurrentUserData = async () => {
    if (countryId) {
      let params = {
        results: 1000,
        userType: 'branchUser',
        sortField: 'name',
        sortOrder: 'ascend',
        select: ['name', 'userType', 'mobile', 'email'],
        customQuery: {
          $or: [{ countryId: countryId }, { 'department': 'Visa Approved Department' }]
        }
      }
      let { data } = await dispatch(listAllUsers(params))
      if (data && data.length) {
        setBranchUserList(data)
      }
    } else {
      setBranchUserList([])
    }
  }

  const setFieldByParams = async () => {
    let resp = await getParamsForStudentList()
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.countryId) {
      setCountryId(resp.countryId)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.studentShore) {
      setStudentShore(resp.studentShore)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.appUniversityId) {
      setAppUniversityId(resp.appUniversityId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
    if (resp.marketingUserId) {
      setMarketingUserId(resp.marketingUserId)
    }
    if (resp.armManagerId) {
      setArmManagerId(resp.armManagerId)
    }
    if (resp.countryName) {
      setCountryName(resp.countryName)
    }
    if (resp.stateName) {
      setStateName(resp.stateName)
    }
    if (resp.udaan) {
      setUdaan(resp.udaan)
    }
    if (resp.email) {
      setEmail(resp.email)
    }
  }


  const getParamsForStudentList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let countryId = searchParams.get('countryId')
      let name = searchParams.get('name')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let studentShore = searchParams.get('studentShore')
      let appUniversityId = searchParams.get('appUniversityId')
      let branchUserId = searchParams.get('branchUserId')
      let intake = searchParams.get('intake')
      let marketingUserId = searchParams.get('marketingUserId')
      let armManagerId = searchParams.get('armManagerId')
      let countryName = searchParams.get('countryName')
      let stateName = searchParams.get('stateName')
      let udaan = searchParams.get('udaan')
      let email = searchParams.get('email')
      let obj = {}
      if (name) {
        obj.name = name
      }
      if (countryId) {
        obj.countryId = countryId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (studentShore) {
        obj.studentShore = studentShore
      }
      if (status) {
        obj.status = status
      }

      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }

      if (intake) {
        obj.intake = intake
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (armManagerId) {
        obj.armManagerId = armManagerId
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (countryName) {
        obj.countryName = countryName
      }
      if (udaan) {
        obj.udaan = udaan
      }
      if (email) {
        obj.email = email
      }
      resolve(obj)
    })
  }
  const loadBranchManagerAgentList = async () => {
    let { data } = await dispatch(getBranchManagerAgentList())
    if (data && data.length) {
      setBranchManagerAgentList(data)
    } else {
      setBranchManagerAgentList([])
    }
  }

  const loadUniversityNameList = async () => {
    let params = {
      results: 5000,
      select: ['universityName'],
      sortField: 'universityName',
      sortOrder: 'ascend',
      regExFilters: ['universityName'],
      showUniversity: [true, false]
    }
    params.universityCountry = countryId
    let { data } = await dispatch(listAllUniversities(params))
    if (data && data.length) {
      setUniversityList(data)
    }
  }

  const events = {
    viewCourse: (record) => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })

    },
    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    },
    searchData: async () => {
      let obj = {}
      if (name) {
        obj.name = name
      }
      if (countryId) {
        obj.countryId = countryId
      }
      if (status) {
        obj.status = status
      }
      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (intake) {
        obj.intake = intake
      }
      if (appUniversityId) {
        obj.appUniversityId = appUniversityId
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (studentShore) {
        obj.studentShore = studentShore
      }
      if (marketingUserId) {
        obj.marketingUserId = marketingUserId
      }
      if (armManagerId) {
        obj.armManagerId = armManagerId
      }
      if (stateName) {
        obj.stateName = stateName
      }
      if (countryName) {
        obj.countryName = countryName
      }
      if (udaan) {
        obj.udaan = udaan
      }
      if (email) {
        obj.email = email
      }
      dispatch(
        getUrlPushWrapper('allStudent', {
          ...obj
        }))
      setTableSearch(true)
      setTimeout(() => {
        events.reloadTable()
      }, 200)
    },
    showTransferDrawer: (data) => {
      setState({
        ...state,
        studentObj: data
      })
      setVisibleTransferToOtherAgent(true)
    },
    hideTransferDrawer: () => {
      setState({
        ...state,
        studentObj: {}
      })
      setVisibleTransferToOtherAgent(false)
    },
    enterName: (name) => {
      setName(name)
    },
    clearFxn: async () => {
      setCountryId('')
      setName('')
      setMarketingUserId('')
      setArmManagerId('')
      setCountryName('')
      setStateName('')
      setUdaan(null)
      setEmail(null)
      dispatch(
        getUrlPushWrapper('allStudent'))
      setTableSearch(true)
      setTimeout(() => {
        events.reloadTable()
      }, 200)
    },
    goBack: () => {
      // dispatch(getUrlPushWrapper('dashboard'))
      history.goBack()
    }
  }

  const columns = [


    /* {
       title: 'S.No',
       dataIndex: 'sno',
       width: 50,
       key: 'sno',
       render: (value, item, index) => (itemNo - 1) * 10 + index + 1
     },*/
    {
      title: 'ID',
      dataIndex: 'studentId',
      // width: 70,
      searchTextName: 'studentId'
    },
    {
      title: 'Student Type',
      dataIndex: 'studentType',
      key: 'studentType',
      width: 120,
      filters: StudentTypesFilter.map(x => ({ value: x.value, text: x.name })),
      render: (item) => {
        return StudentType[item]
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // searchTextName: 'name',
      // width: 120,
      render: (item, record) => {
        let { profileImage } = record
        return (
          <div className={Styles.userNameBox}>
            {/* {profileImage && profileImage.url ?
              <Avatar size={30} src={profileImage.url}></Avatar> :
              <Avatar size={30} src={defaultLogo}></Avatar>}*/}
            <div>
              {record && record.countryISOCode == 'CA_+1' ?
                <img src={'/assets/starEnable.png'} width={14} style={{ marginRight: 3 }} /> : null
              }
              {item}
            </div>
          </div>
        )
      }
    },

    {
      title: 'Email',
      dataIndex: 'email',
      searchTextName: 'email',
      width: 220,
      render: (item, record) => {
        return (
          <div style={{ width: 220 }}>
            <MobileEmailInfo data={record} type={'email'} value={item} />

            {/*{mainUser.includes(user.userType) ? item : "xxxxxxxx@xxxxx.xxxxx"}*/}
          </div>
        )
      }
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      width: 150,
      render: (item, record) => {
        return (
          <div style={{ width: 150 }}>
            <MobileEmailInfo data={record} type={'mobile'} value={item} />
          </div>
        )
      }
      // searchTextName: 'mobile'
    },
    {
      title: 'DOB',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      // width: 100,
      render: (val) => {
        return (
          <span>
            {newFormatDisplayDate(val)}
          </span>
        )
      }
    },
    {
      title: 'Address',
      key: 'address',
      width: 300,
      dataIndex: 'address',
      searchTextName: 'address',
      render: (item, record) => {
        return (
          item ?
            <div style={{ width: 300 }}>
              {item}, {record.cityName}, {record.stateName}, {record.countryName}
            </div> : ''
        )
      }
    },
    {
      title: 'Agent',
      key: 'agentId',
      width: 300,
      dataIndex: 'agentId',
      render: (item, record) => {
        return (
          item ?
            <div style={{ width: 280 }}>
              {/*{item.name},<br />*/}
              <span style={{ fontWeight: 'bold' }}>{item.companyName}</span><br /> ({item.name})
            </div> : ''
        )
      }
    },
    {
      title: 'App',
      key: 'app',
      dataIndex: 'app',
      // searchTextName: 'address',
      render: (item, record) => {
        return (
          <div>
            {record && record.appCount ?
              <span className='badge'>{record.appCount}</span> : ''}
          </div>
        )
      }
    },
    /* {
         title: 'Marketing',
         dataIndex: 'marketingUser',
         width: 120,
         key: 'marketingUser',
         render: (item, record) => {
             let {agentId} = record;
             return (
                 <React.Fragment>
                     {marketingUserList && marketingUserList.length ?
                         <MarketingUsersComponent
                             userId={agentId._id}
                             branchManagers={marketingUserList}/> : null}
                 </React.Fragment>
             )
         }
     },
     {
         title: 'Managers',
         dataIndex: 'managers',
         width: 120,
         key: 'managers',
         render: (item, record) => {
             let {agentId} = record;
             return (
                 <React.Fragment>
                     {branchManagerList && branchManagerList.length ?
                         <MarketingUsersComponent
                             userId={agentId._id}
                             branchManagers={branchManagerList}/> : null}
                 </React.Fragment>
             )
         }
     },*/
    {
      key: 'actions',
      title: 'Actions',
      // width: 70,
      fixed: 'right',
      height: 120,
      render: (text, record) => {
        return (
          <div style={{ height: 80 }}>
            {user.userType !== 'marketingManager' ?
              <Tooltip title={'View Application'}>
                <button className='btn' onClick={() => events.viewCourse(record)}>
                  <img src='/dist/img/view.svg' alt='' />
                </button>
              </Tooltip> : null}
            <AllowComponentUserWise rightUserType={[...adminRightUser, 'branchManager', 'branchUser']}>
              <Tooltip title={'Transfer'}>
                <button className='btn' onClick={() => {
                  events.showTransferDrawer(record)
                }}>
                  <img src={'../dist/img/forwardArrow.png'} />
                </button>
              </Tooltip>{' '}
            </AllowComponentUserWise>
            {/*<Tooltip title={'generate sop'}>
              <button className='btn' onClick={() => {
                dispatch(push(`/generate-sop?studentId=${record._id}`))
              }}>
                <img src={'../dist/img/reload.png'} />
              </button>
            </Tooltip>*/}
          </div>

        )
      }
    }
  ]


  return (
    <div>

      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center all-student-search'>
              <Button onClick={() => events.goBack()}
                      className={'roundGoBackBtn'} icon={'arrow-left'}>
                Back
              </Button>
              <h5>All Students: {totalStudent || 0}</h5>

              <div className='search-box-table round'>

              </div>

              <div className='sort-box-table mark-btn'>
                <CheckMyRights rightList={['agent', 'subAgent']}>
                  <a className='btn' href={'/add-student'}>Add Student</a>
                </CheckMyRights>
                {/* <ShowExportOption user={user} rightUserType={['admin']}>

                                </ShowExportOption>*/}

                {adminRightUser.includes(user.userType) ?

                  <Tooltip title={'Export Excel'}>
                    <a onClick={() => exportStudentExcel()} className='btn'>Export Excel</a>
                  </Tooltip> : null}
              </div>


            </div>
            <div className='card-body table-responsive'>
              <Row gutter={16} className={'filter_box'}>
                <Col span={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Search By Country' id=''
                            filterOption={filterOption}
                      // className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            value={countryId || undefined}
                            placeholder={'Choose Country'}
                            onChange={(item) => setCountryId(item)} value={countryId}>
                      {allCountry && allCountry.length ? allCountry.map((item, key) => {
                        return (
                          <Option value={item._id} key={key}>{item.countryName}</Option>
                        )
                      }) : null}
                    </Select>
                  </div>
                </Col>
                <Col span={4}>
                  <div className='search-box-table round'>
                    <input className='form-control form-control' type='search'
                           placeholder='Search by name...' value={name} onChange={(e) => {
                      events.enterName(e.target.value)
                    }} />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </Col>
                <Col span={4}>
                  <div className='search-box-table round'>
                    <input className='form-control form-control' type='search'
                           placeholder='Search by email...' value={email} onChange={(e) => {
                      setEmail(e.target.value)
                    }} />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </Col>
                {user.userType !== 'marketingManager' ?
                  <>
                    <Col span={4}>
                      <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by country...' value={countryName}
                               onChange={(e) => {
                                 setCountryName(e.target.value)
                               }} />
                        <img src='/dist/img/search.png' alt='' />
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by state...' value={stateName}
                               onChange={(e) => {
                                 setStateName(e.target.value)
                               }} />
                        <img src='/dist/img/search.png' alt='' />
                      </div>
                    </Col>
                  </> : null}
                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Status' onChange={(item) => setStatus(item)}
                            filterOption={filterOption}
                      // className={'antSelect'}
                            placeholder={'Status'}
                            allowClear={true}
                            showSearch={true}
                            value={status || undefined}>
                      {statusList && statusList.length ? statusList.map((item, key) => {
                        return (
                          <Option value={item.name} key={key}>{item.name}</Option>
                        )
                      }) : null}
                    </Select>
                  </div>
                </Col>
                <CheckMyRights rightList={[...adminRightUser, 'branchManager']}>
                  <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Current User' onChange={(item) => setBranchUserId(item)}
                              filterOption={filterOption}
                        // className={'antSelect'}
                              allowClear={true}
                              showSearch={true}
                              placeholder={'Current User'}
                              value={branchUserId || undefined}>
                        {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                          return (
                            <Option value={item._id} key={key}>{item.name}</Option>
                          )
                        }) : null}
                      </Select>
                    </div>
                  </Col>
                </CheckMyRights>

                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Search By University'
                            onChange={(item) => setAppUniversityId(item)}
                            filterOption={filterOption}
                      // className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'University'}
                            value={appUniversityId || undefined}>
                      {universityList && universityList.length ? universityList.map((item, key) => {
                        return (
                          <Option value={item._id} key={key}>{item.universityName}</Option>
                        )
                      }) : null}
                    </Select>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <Select name='Intake' onChange={(item) => setIntake(item)}
                            filterOption={filterOption}
                      // className={'antSelect'}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'Intake'}
                            value={intake || undefined}>
                      {Intakes && Intakes.length ? Intakes.map((item, key) => {
                        return (
                          <Option value={item} key={key}>{item}</Option>
                        )
                      }) : null}
                    </Select>
                  </div>
                </Col>
                <CheckUserRight user={user}
                                rightUserType={[...adminRightUser, 'branchManager', 'branchUser']}>
                  <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Search By Agent'
                              filterOption={filterOption}
                        // className={'antSelect'}
                              allowClear={true}
                              placeholder={'Agent'}
                              showSearch={true}
                              onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                        {branchManagerAgentList && branchManagerAgentList.length ? branchManagerAgentList.map((item, key) => {
                          let newName = `${item.companyName} (${item.name})`
                          return (
                            <Option value={item._id} key={key}>{newName}</Option>
                          )
                        }) : null}
                      </Select>
                    </div>
                  </Col>

                  <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Search By Marketing User'
                              filterOption={filterOption}
                        // className={'antSelect'}
                              allowClear={true}
                              placeholder={'Marketing User'}
                              onChange={(item) => setMarketingUserId(item)}
                              value={marketingUserId || undefined}>
                        {marketingUserList && marketingUserList.length ? marketingUserList.map((item, key) => {
                          return (
                            <Option value={item._id} key={key}>{item.name}</Option>
                          )
                        }) : null}
                      </Select>
                    </div>
                  </Col>
                  <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Search By ARM'
                              filterOption={filterOption}
                        // className={'antSelect'}
                              allowClear={true}
                              placeholder={'ARM User'}
                              onChange={(item) => setArmManagerId(item)}
                              value={armManagerId || undefined}>
                        {branchManagerList && branchManagerList.length ? branchManagerList.map((item, key) => {
                          return (
                            <Option value={item._id} key={key}>{item.name}</Option>
                          )
                        }) : null}
                      </Select>
                    </div>
                  </Col>
                </CheckUserRight>


                <Col span={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                    <Select
                      name='Choose Udaan'
                      onChange={item => {
                        setUdaan(item)
                      }}
                      value={udaan || undefined}
                      placeholder={'Udaan'}
                      allowClear={true}>
                      {udaanArr && udaanArr.length
                        ? udaanArr.map((item, key) => {
                          return (
                            <Option value={item.value} key={item.value}>
                              {item.name}
                            </Option>
                          )
                        })
                        : null}
                    </Select>
                  </div>
                </Col>


                <Col span={16}>
                  <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                  <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                    Clear
                  </Button>
                </Col>

              </Row>

              <TableComp columns={columns}
                         className={'table table-striped table-valign-middle'}
                         apiRequest={apiRequest}
                         pagination={{
                           position: 'top',
                           pageSizeOptions: mainUser.includes(user.userType) ? ['20', '50', '100', '200'] : ['10', '20', '50'],
                           defaultPageSize: mainUser.includes(user.userType) ? 20 : 10,
                           current: params.page ? params.page : 1
                         }}
                         extraProps={{ scroll: { x: 1700 } }}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>
      {state.viewCourseDrawer ? <ViewApplication
        visible={state.viewCourseDrawer}
        pageType={'student'}
        reloadTable={() => events.reloadTable()}
        onClose={() => events.viewCourseClose()}
        studentObj={state.studentObj} /> : null}

      {visibleTransferToOtherAgent ? <TransferToOtherAgent
        visible={visibleTransferToOtherAgent}
        pageType={'student'}
        reloadTable={() => events.reloadTable()}
        onClose={() => events.hideTransferDrawer()}
        studentObj={state.studentObj} /> : null}


    </div>
  )
}

export default AllStudentList
