import React, { useEffect, useRef, useState } from 'react'
import {
  Select, Button, Tooltip, Icon, Popconfirm, Row, Col, Form
} from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  newFormatDisplayDate,
  longDisplayDate,
  filterOption,
  Intakes,
  statusColors,
  InputBox,
  showOnshoreCanadaEnrollmentAction
} from '../../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../../routes/routes'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import {
  AllowComponentRightsWise,
  CheckManagerWiseRight, ShowEnrollmentComponent
} from '../../../WebComponent/allowComponentRightsWise'
import {
  applicationCloseDirect, moveToDraftApplication
} from '../../../student/actions/student'
import StudentOtherApp from '../../../applications/views/StudentOtherApplication'
import { listAllUsers } from '../../../users/actions/user'
import ReActivateApplication from '../../../applications/drawers/reActivateApplicationDrawer'
import { countryIds } from '../../../../components/_utils/appUtils'
import AddNote from '../../../applications/drawers/createNoteComponent'
import {
  getSelectedCountryApplicationsApi
} from '../../actions/chooseStudent'
import { listAllCampus } from '../../../campus/actions/campus'
import { CheckUserRight } from '../../../dashboard/views/DashboardUserWise'
import { AllowComponentUserWise } from '../../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../../../applications/views/transferApplication'
import ChangeUniversityComponent from '../../../applications/drawers/changeUniversity'
import DraftButtonComponent from '../../../applications/views/draftButtonComponent'
import { uploadAppDocumentAndStatus } from '../../../onShoreCanada/actions'
import AddCommissionDrawer from '../../../universityCommission/drawers/addCommission'
import { getOnShoreApplicationListForCommissionFxn } from '../actions'

const statusList = [
  'Offer Applied',
  'Offer Received',
  'Tuition Fee Paid & TT Received'
]
const { Option } = Select
const initialState = {
  applicationList: [],
  applicationObj: {}
}


const RenderComment = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    item && <div>
      {item.addedByUserId && item.addedByUserId.name ?
        <div className={'alignRight colorPrimary font12'}>
          (Added By : {item.addedByUserId.name} )
        </div> : null}
      <div className={hideV ? 'appCommentDotsReplace' : ''}>
        {item && item.text ? item.text : ''}
      </div>
      {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}
      </div> : ''}

    </div>
  )
}
const CanadaStudentApplication = (props) => {
  const [state, setState] = useState(initialState)
  const [studentData, setStudentData] = useState({})
  const [name, setName] = useState('')
  const [allAgent, setAllAgent] = useState([])
  const [agentId, setAgentId] = useState('')
  const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
  const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
  const [totalApplication, setTotalApplication] = useState(0)
  const [currentStatus, setCurrentStatus] = useState('')
  const [status, setStatus] = useState('')
  let { applicationObj } = state
  let [tableSearch, setTableSearch] = useState(false)
  let [filters, setFilters] = useState({})
  let [campusList, setCampusList] = useState([])
  const [campusId, setCampusId] = useState('')
  const [branchUserId, setBranchUserId] = useState('')
  const [intake, setIntake] = useState('')
  let [courseName, setCourseName] = useState('')
  const [transferAppVisible, setTransferAppVisible] = useState(false)
  const [allBranchUser, setAllBranchUser] = useState([])

  let [changeUniversity, setChangeUniversity] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })

  let [moveStageState, setMoveStageState] = useState({
    visible: false,
    status: [],
    studentData: {}
  })

  let [selectedApplication, setSelectedApplication] = useState({
    visible: false,
    studentId: '',
    application: '',
    studentData: {}
  })
  const [
    visibleUniversityCommission,
    setVisibleUniversityCommission
  ] = useState(false)

  const dispatch = useDispatch()
  let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  const tableAppRef = useRef()

  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await getParamsForApplicationList()
      params = { ...params, ...resp }
      // params.canadaOnshore = true
      if (!params.results) {
        params.results = 50
        params.count = 50
      }
      if (tableSearch) {
        params.page = 1
      }
      params.countryId = countryIds.canadaOnshore
      try {
        setFilters(params)
        let resp = await dispatch(getOnShoreApplicationListForCommissionFxn({ ...params, regExFilters: ['name'] }))
        console.log(resp,"response")
        setTotalApplication(resp.total)
        setTableSearch(false)
        resolve(resp)
      } catch (e) {
      }

    })
  }


  useEffect(() => {
    loadAgentData()
    loadBranchUserData()
    loadCampusList()
    setFieldByParams()
  }, [])

  const setFieldByParams = async () => {
    let resp = await getParamsForApplicationList()
    // setCurrentStatus(resp.status)

    if (resp.campusId) {
      setCampusId(resp.campusId)
    }
    if (resp.status) {
      setStatus(resp.status)
    }
    if (resp.name) {
      setName(resp.name)
    }
    if (resp.agentId) {
      setAgentId(resp.agentId)
    }
    if (resp.intake) {
      setIntake(resp.intake)
    }
    if (resp.courseName) {
      setCourseName(resp.courseName)
    }
    if (resp.branchUserId) {
      setBranchUserId(resp.branchUserId)
    }
  }

  const loadAgentData = async () => {
    let params = {
      results: 10000,
      userType: 'agent',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name', 'companyName']
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllAgent(data)
    }
  }

  const loadBranchUserData = async () => {
    let params = {
      results: 10000,
      userType: 'branchUser',
      sortField: 'companyName',
      sortOrder: 'ascend',
      select: ['name'],
      countryId: user.countryId
    }
    let { data } = await dispatch(listAllUsers(params))
    if (data && data.length) {
      setAllBranchUser(data)
    }
  }
  const loadCampusList = async () => {
    let params = {}
    params.sortField = 'campusName'
    params.sortOrder = 'ascend'
    // params.campusUniversity = fcmtUniversity
    params.campusCountry = countryIds.canada
    params.results = 50
    params.count = 50
    params.select = ['campusName']
    let { data } = await dispatch(listAllCampus({ ...params, regExFilters: ['campusAddress', 'campusName'] }))
    setCampusList(data)
  }


  const events = {

    reloadFxn: () => {
      tableAppRef.current.reload()
    },
    refreshApplication: () => {
      tableAppRef.current.reload()
    },

    closeDirectApp: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(applicationCloseDirect(obj))
      tableAppRef.current.reload()
    },

    enterName: (value) => {
      setName(value)

    },
    moveToDraftApplicationFxn: async (record) => {
      let obj = {
        studentId: record._id,
        applicationId: record.applications._id
      }
      let resp = await dispatch(moveToDraftApplication(obj))
      tableAppRef.current.reload()
    },

    openOtherAppDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleOtherAppDrawer(true)
    },
    closeOtherAppDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleOtherAppDrawer(false)
    },
    openAddCommentDrawer: (value) => {
      setState({
        ...state,
        applicationObj: value
      })
      setVisibleAddCommentDrawer(true)
    },
    closeAddCommentDrawer: () => {
      setState({
        ...state,
        applicationObj: {}
      })
      setVisibleAddCommentDrawer(false)
    },
    showTransferApp: () => {
      setTransferAppVisible(true)
    },
    hideTransferApp: () => {
      setTransferAppVisible(false)
      tableAppRef.current.reload()
    },
    changeUniversityFxn: record => {
      setChangeUniversity({
        visible: true,
        studentId: record._id,
        studentData: record,
        application: record.applications
      })
    },
    onCloseChangeUniversityFxn: () => {
      setChangeUniversity({
        visible: false,
        studentId: '',
        studentData: {},
        application: {}
      })
      tableAppRef.current.reload()
    },

    showUniversityCommission: () => {
      setVisibleUniversityCommission(true)
    },
    hideUniversityCommission: () => {
      setVisibleUniversityCommission(false)
      tableAppRef.current.reload()
    }
  }

  const reActivateApp = (record) => {
    setSelectedApplication({
      visible: true,
      studentId: record._id,
      studentData: record,
      application: record.applications
    })
  }
  const onCloseReActivate = () => {
    setSelectedApplication({
      visible: false,
      studentId: '',
      studentData: {},
      application: {}
    })
    tableAppRef.current.reload()
  }


  const getParamsForApplicationList = () => {
    return new Promise((resolve) => {
      let searchParams = new URLSearchParams(window.location.search)
      let name = searchParams.get('name')
      let campusId = searchParams.get('campusId')
      let status = searchParams.get('status')
      let agentId = searchParams.get('agentId')
      let branchUserId = searchParams.get('branchUserId')
      let intake = searchParams.get('intake')
      let courseName = searchParams.get('courseName')
      let draftApplication = searchParams.get('draftApplication')
      let obj = {
      }

      if (name) {
        obj.name = name
      }

      if (campusId) {
        obj.campusId = campusId
      }
      if (status) {
        obj.status = status
      }
      if (agentId) {
        obj.agentId = agentId
      }
      if (intake) {
        obj.intake = intake
      }
      if (courseName) {
        obj.courseName = courseName
      }

      if (branchUserId) {
        obj.branchUserId = branchUserId
      }
      if (draftApplication) {
        obj.draftApplication = draftApplication
      }
      resolve(obj)
    })
  }

  const searchFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {
    }
    if (resp.draftApplication) {
      obj.draftApplication = resp.draftApplication
    }
    if (name) {
      obj.name = name
    }
    if (campusId) {
      obj.campusId = campusId
    }
    if (status) {
      obj.status = status
    }
    if (agentId) {
      obj.agentId = agentId
    }
    if (intake) {
      obj.intake = intake
    }
    if (courseName) {
      obj.courseName = courseName
    }
    if (branchUserId) {
      obj.branchUserId = branchUserId
    }
    dispatch(
      getUrlPushWrapper('onshoreAccountantDashboard', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
    }, 200)
  }
  const clearFxn = async () => {
    let resp = await getParamsForApplicationList()
    let obj = {

    }
    if (resp.draftApplication) {
      obj.draftApplication = resp.draftApplication
    }
    setName('')
    setCampusId('')
    setStatus('')
    setAgentId('')
    setIntake('')
    setCourseName('')
    setBranchUserId('')
    dispatch(
      getUrlPushWrapper('onshoreAccountantDashboard', {
        ...obj
      }))
    setTableSearch(true)
    setTimeout(() => {
      tableAppRef.current.reload()
    }, 200)
  }


  const actionEvents = {
    moveToNextDesk: async (valData, status) => {
      let fd = new FormData()
      let obj = {
        _id: valData._id,
        status: status,
        studentId: valData._id,
        applicationId: valData.applications._id
      }
      fd.append('obj', JSON.stringify(obj))
      let data = await dispatch(uploadAppDocumentAndStatus(fd))
      if (data && data.success) {
        events.reloadFxn()
      }
    },
    moveAndUpload: (valData) => {
      let { status } = valData.applications
      let currentStatus = []
      if (status == 'Offer Applied') {
        currentStatus = ['Offer Received']
      }
      if (status == 'Offer Received') {
        currentStatus = ['Tuition Fee Paid & TT Received', 'Case Revoked']
      }
      setMoveStageState({
        visible: true,
        studentData: valData,
        status: currentStatus
      })
    }
  }


  const columns = [
    {
      title: 'Sr .No',
      key: '_id',
      dataIndex: '_id',
      width: 80,
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },

    {
      title: 'App#',
      key: 'applications.id',
      dataIndex: 'applications.id',
      width: 60,
      render: (item) => {
        return (
          <div style={{ width: 60 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 120,
      render: (item, record) => {
        return (
          <div style={{ width: 120 }}>
            <Tooltip title={'Edit Student'}>
              <a className={'linkAA'}
                 onClick={() => dispatch(
                   getPushPathWrapper('student.editStudent', { id: record._id }))
                 }>
                {item} <Icon type={'edit'} />
              </a>
            </Tooltip>
            <br />
            {newFormatDisplayDate(record.dateOfBirth)}
          </div>
        )
      }
    },
    {
      title: 'Country',
      key: 'applications.universityCountry',
      dataIndex: 'applications.universityCountry',
      width: 150,
      render: (item, record) => {
        let { applications } = record
        return (
          <React.Fragment>
            {item && item.countryName ? item.countryName : null}
          </React.Fragment>
        )
      }
    },
    {
      title: 'Program',
      key: 'applications.courseName',
      dataIndex: 'applications.courseName',
      width: 150,
      render: (item) => {
        return (
          <div style={{ width: 150 }}>
            {item}
          </div>
        )
      }
    },
    {
      title: 'Intake',
      key: 'applications.intake',
      dataIndex: 'applications.intake',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
          </div>
        )
      }
    },
    {
      title: 'Campus',
      key: 'applications.campusId',
      dataIndex: 'applications.campusId',
      width: 80,
      render: (item, record) => {
        return (
          <div style={{ width: 80 }}>
            {item && item.campusName ? item.campusName : ''}
          </div>
        )
      }
    },
    {
      title: 'Current User',
      key: 'applications.assignedUserId',
      dataIndex: 'applications.assignedUserId',
      width: 100,
      render: (val, record) => {
        let { agentId, statusList, status } = record.applications
        return (
          <div style={{ width: 100 }}>
            {val && val.name ? val.name : ''}<br />
            <div>
              Agent : {agentId && agentId.companyName ? agentId.companyName : ''}
              <br />
              {agentId && agentId.mobile ? <> <Icon type={'phone'} style={{
                position: 'relative',
                bottom: 3
              }} /> {agentId.mobile}</> : ''}
            </div>
            <div>{longDisplayDate(statusList.date)}</div>
          </div>
        )
      }
    },
    {
      title: 'Status',
      key: 'applications.status',
      dataIndex: 'applications.status',
      width: 120,
      render: (item, record) => {
        let { applications } = record
        let statusList = ['Offer Applied', 'Offer Received']
        return (
          <div className={'fcmtDiv'} style={{ width: 120 }}>
            <div>
              <div className={'label label1'}
                   style={{ background: statusColors(item), width: 85, padding: 3 }}>{item}</div>
              <br />
              {applications.statusList && applications.statusList.date ?
                <div>
                  Date : {newFormatDisplayDate(applications.statusList.date)}<br />
                </div> : null}

            </div>
            {user.userType == 'admin' ||
            applications &&
            applications.assignedUserId &&
            applications.assignedUserId._id ?
              (user._id == applications.assignedUserId._id): null ?
              <>
                {statusList.includes(applications.status) ?
                  <>
                    <br />
                    <button
                      style={{ fontSize: 14, verticalAlign: 'center' }}
                      className={'btn btn-default'}
                      onClick={() => {
                        actionEvents.moveAndUpload(record)
                      }}>
                      Change Status<img src={'../dist/icons/forwardArrow.png'}
                                        style={{ height: 16 }} />
                    </button>
                  </> : ''
                }
                {applications.status == 'Waiting To Apply' ?
                  <>
                    <br />
                    <Popconfirm
                      title={'Are you sure, you want to move on Offer Applied?'}
                      onConfirm={() => {
                        actionEvents.moveToNextDesk(record, 'Offer Applied')
                      }}>
                      <button
                        style={{ fontSize: 14, verticalAlign: 'center' }}
                        className={'btn btn-default'}>
                        Change Status<img src={'../dist/icons/forwardArrow.png'}
                                          style={{ height: 16 }} />
                      </button>
                    </Popconfirm>
                  </> : ''
                }
                {applications.status == 'Tuition Fee Paid & TT Received' ?
                  <>
                    <br />
                    <Popconfirm
                      title={'Are you sure, you want to move on LOA/Fee Receipt Issued?'}
                      onConfirm={() => {
                        actionEvents.moveToNextDesk(record, 'LOA/Fee Receipt Issued')
                      }}>
                      <button
                        style={{ fontSize: 14, verticalAlign: 'center' }}
                        className={'btn btn-default'}>
                        Change Status<img src={'../dist/icons/forwardArrow.png'}
                                          style={{ height: 16 }} />
                      </button>
                    </Popconfirm>

                  </> : ''
                }
                {applications.status == 'LOA/Fee Receipt Issued' ?
                  <>
                    <br />
                    <Popconfirm
                      title={'Are you sure, you want to move on Case Approved?'}
                      onConfirm={() => {
                        actionEvents.moveToNextDesk(record, 'Case Approved')
                      }}>
                      <button
                        style={{ fontSize: 14, verticalAlign: 'center' }}
                        className={'btn btn-default'}>
                        Change Status<img src={'../dist/icons/forwardArrow.png'}
                                          style={{ height: 16 }} />
                      </button>
                    </Popconfirm>
                  </> : ''
                }
              </> : null}
          </div>
        )
      }
    },

    {
      title: 'Documents',
      key: 'applications.appDocuments',
      dataIndex: 'applications.appDocuments',
      width: 150,
      render: (val, record) => {
        return (
          <ul style={{ width: 100 }}>
            {val && val.length ? val.map((item, key) => {
              return (
                <li key={key} className={'mb10'}>
                  <a href={item.url}>
                    {item.status}
                  </a>
                </li>
              )
            }) : null}
          </ul>
        )
      }
    },

    {
      title: 'Latest Note',
      key: 'applications.latestComment',
      dataIndex: 'applications.latestComment',
      width: 150,
      render: (val) => {
        return (
          <div style={{ width: 150 }}>
            {val && val.text ? <RenderComment item={val} /> : null}
          </div>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      width: 100,
      render: (val, record) => {
        let { applications } = record
        return (
          <div className={'actionBtnGroup'}>
                {/*{showEnrollmentBtn(record) ? (*/}
                  <Tooltip title={'Create Commission'}>
                    <button
                      className='btn'
                      onClick={() => {
                        setStudentData(record)
                        events.showUniversityCommission()
                      }}>
                      <img src={'../btnImgs/percentage.png'} />
                    </button>
                  </Tooltip>
                {/*) : null}*/}
          </div>
        )
      }
    }

  ]

  const resp = useSelector(state => {
    let { loadNoteRedux } = state.chooseApplication
    if (loadNoteRedux) {
      if (tableAppRef && tableAppRef.current) {
        tableAppRef.current.reload()
      }
      dispatch({ type: 'END_LOAD_NOTE' })
    }
  })

  const loadColumns = () => {
    let columnArr = []
    lodash.each(columns, (item) => {
      if (item.hidden == undefined) {
        columnArr.push(item)
      } else {
        if (!item.hidden) {
          columnArr.push(item)
        }
      }
    })
    return columnArr
  }

  const showEnrollmentBtn = record => {
    let application = record.applications
    return showOnshoreCanadaEnrollmentAction(application.statusList) ? true : false
  }

  const extra = (
    <div>

      <Row gutter={12} className={'filter_box'}>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by name'}>
            <input
              placeholder='Search by name...'
              value={name}
              onChange={(e) => {
                events.enterName(e.target.value)
              }} />
          </InputBox>
        </Col>
        <Col md={4} sm={4} xs={8} lg={4}>
          <InputBox title={'Search by Status'}>
            <Select name='Status' onChange={(item) => setStatus(item)}
                    filterOption={filterOption}
                    placeholder={'Status'}
                    allowClear={true}
                    value={status || undefined}>
              {statusList && statusList.length ? statusList.map((item, key) => {
                return (
                  <Option value={item} key={key}>{item}</Option>
                )
              }) : null}
            </Select>
          </InputBox>
        </Col>


        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'canadaOnshoreAdmin']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <InputBox title={'Search by Team'}>
              <Select name='Search By Team'
                      filterOption={filterOption}
                      allowClear={true}
                      placeholder={'Team'}
                      showSearch={true}
                      onChange={(item) => setBranchUserId(item)} value={branchUserId || undefined}>
                {allBranchUser && allBranchUser.length ? allBranchUser.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>{item.name}</Option>
                  )
                }) : null}
              </Select>
            </InputBox>
          </Col>
        </CheckUserRight>

        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'canadaOnshoreAdmin']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <InputBox title={'Search by Agent'}>
              <Select name='Search By Agent'
                      filterOption={filterOption}
                      allowClear={true}
                      placeholder={'Agent'}
                      showSearch={true}
                      onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                {allAgent && allAgent.length ? allAgent.map((item, key) => {
                  return (
                    <Option value={item._id} key={key}>{item.companyName}</Option>
                  )
                }) : null}
              </Select>
            </InputBox>
          </Col>
        </CheckUserRight>
        <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser', 'canadaOnshoreAdmin']}>
          <Col md={4} sm={4} xs={8} lg={4}>
            <InputBox title={'Search by Intake'}>
              <Select name='Intake' onChange={(item) => setIntake(item)}
                      filterOption={filterOption}
                      allowClear={true}
                      showSearch={true}
                      placeholder={'Intake'}
                      value={intake || undefined}>
                {Intakes && Intakes.length ? Intakes.map((item, key) => {
                  return (
                    <Option value={item} key={key}>{item}</Option>
                  )
                }) : null}
              </Select>
            </InputBox>
          </Col>
          <Col md={4} sm={4} xs={8} lg={4}>
            <InputBox title={'Search by Course name'}>
              <input
                type='search'
                placeholder='course name...' value={courseName} onChange={(e) => {
                setCourseName(e.target.value)
              }} />
            </InputBox>
          </Col>
        </CheckUserRight>


        <Col span={24}>
          <div className={'btn_group'}>
            <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
            <Button onClick={() => clearFxn()} className={'roundBtn'}>
              Clear
            </Button>
          </div>
        </Col>


      </Row>

    </div>
  )

  return (
    <React.Fragment>
      <div>
        <div className='row mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center all-student-search'
                   style={{ height: 60 }}>
                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                  Back
                </Button>
                <h5>All Canada OnShore Applications </h5>
                <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
                  {currentStatus} (Total - {totalApplication})
                </div>
                <div className='sort-box-table mark-btn'>

                </div>
              </div>
              <div className='card-body table-responsive'>
                {extra}
                <TableComp columns={loadColumns()}
                           rowKey={(item, index) => {
                             return index
                           }}
                           apiRequest={apiRequest}
                           pagination={{
                             position: 'top',
                             showSizeChanger: true,
                             pageSizeOptions: ['50', '100', '200', '500'],
                             defaultPageSize: 50,
                             current: filters.page ? filters.page : 1
                           }}
                           ref={tableAppRef}
                />
              </div>
            </div>
          </div>
        </div>
      </div>


      {visibleOtherAppDrawer ?
        <StudentOtherApp visible={visibleOtherAppDrawer}
                         applicationObj={state.applicationObj}
                         onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                         reloadFxn={events.reloadFxn}
        /> : null}

      {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
        <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                 addedType={'direct'}
                 onClose={() => events.closeAddCommentDrawer()}
                 applicationId={applicationObj.applications._id} /> : null}


      {selectedApplication.visible ?
        <ReActivateApplication
          {...selectedApplication}
          canadaOnshore={true}
          onClose={onCloseReActivate} /> : null}


      {transferAppVisible ? (
        <TransferApplicationDrawer
          visible={transferAppVisible}
          closeTransferDrawer={() => events.hideTransferApp()}
          studentId={studentData._id}
          studentData={studentData}
          canadaOnshore={false}
          application={studentData.applications}
        />
      ) : null}


      {changeUniversity.visible ? (
        <ChangeUniversityComponent
          {...changeUniversity}
          canadaOnshore={true}
          onClose={events.onCloseChangeUniversityFxn}
        />
      ) : null}


      {visibleUniversityCommission ? (
        <AddCommissionDrawer
          visible={visibleUniversityCommission}
          studentData={studentData}
          onClose={() => events.hideUniversityCommission()}
        />
      ) : null}
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
const WrappedComponent = Form.create()(CanadaStudentApplication)
export default connect(
  null,
  mapDispatchToProps
)(WrappedComponent)
