import React, { useEffect, useState } from 'react'
import { Drawer, Form, Button, Col, Row, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { FileInput } from '../../components/_utils/appUtils'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { addUnizhomeContractFxn, getFormFieldsFxn } from './actions'
import AddNewFormFields from './addNewFormFieldDrawer'
import { listAllCountries } from '../countries/actions/countries'

const AddUnizhomeContract = (props) => {
  const { form: { getFieldDecorator, getFieldValue }, reloadTable, onClose, visible, user } = props
  const dispatch = useDispatch()
  const [file, setFile] = useState(null)
  const [bookingProcessFile, setBookingProcessFile] = useState(null)
  let [allCountry, setAllCountry] = useState([])

  const formFields = {
    fields: [
      {
        key: 'companyName',
        label: 'Company Name',
        placeholder: 'Enter Company Name',
        type: 'text',
        required: true

      },
      {
        key: 'contactPersonName',
        label: 'Contact Person Name',
        placeholder: 'Enter Contact Person Name',
        type: 'text',
        required: true
      },
      {
        key: 'contactMobileNo',
        label: 'Contact Mobile No',
        placeholder: 'Enter Mobile No',
        type: 'text',
        required: true

      },
      {
        key: 'email',
        label: 'Email Id',
        placeholder: 'Enter Email',
        type: 'text',
        required: true

      },
      {
        key: 'uploadContract',
        label: 'Upload Contract',
        required: true,
        placeholder: 'Upload Contract',
        type: 'file',
        fileName: 'contract',
        onChange: ({ target }) => {
          events.handleFileChange(target)
        }
      },
      {
        key: 'expiryDate',
        label: 'Expiry Date',
        placeholder: 'Expiry Date',
        type: 'date'
      },
      {
        key: 'bookingProcessDoc',
        label: 'Booking Process Doc',
        required: true,
        placeholder: 'Upload Booking Process Doc',
        type: 'file',
        fileName: 'bookingProcessDoc',
        onChange: ({ target }) => {
          events.handleBookingDocument(target)
        }
      },
      {
        key: 'bookingProcessLink',
        label: 'Booking Process Link',
        placeholder: 'Booking Process Link'
      },
      {
        key: 'reconStatus',
        label: 'Recon Status',
        placeholder: 'Recon Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Invoiced' },
          { value: 'Partially Invoiced' },
          { value: 'Pending' },
          { value: 'Sent' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            reconStatus: x
          })
        }
      },
      {
        key: 'contractStatus',
        label: 'Contract Status',
        placeholder: 'Contract Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Active' },
          { value: 'Inactive' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            contractStatus: x
          })
        }
      },
      {
        key: 'status',
        label: 'Status',
        placeholder: 'Select Status',
        type: 'select',
        allowClear: true,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'API', label: 'API' },
          { value: 'Manual', label: 'Manual' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            status: x
          })
        }
      },
      {
        key: 'countryId',
        label: 'Country',
        allowClear: true,
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            countryId: x
          })
        }
      },
      {
        key: 'whatsappGroup',
        allowClear: true,
        label: 'WhatsApp Group',
        placeholder: 'Select Yes or No',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            whatsappGroup: x
          })
        }
      },
      {
        key: 'whatsappGroupLink',
        label: 'Whatsapp Group Link',
        placeholder: 'Whatsapp Group Link',
        required: getFieldValue('whatsappGroup') === 'Yes',
        hidden: getFieldValue('whatsappGroup') === undefined || getFieldValue('whatsappGroup') === 'No'
      },
      {
        key: 'contactDetail',
        allowClear: true,
        label: 'Contact Details',
        placeholder: 'Select Yes or No',
        type: 'select',
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.value}`,
        options: [
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' }
        ],
        required: true,
        onChange: x => {
          props.form.setFieldsValue({
            contactDetail: x
          })
        }
      },
      {
        key: 'contactDetailLink',
        label: 'Contact Details Link',
        placeholder: 'Contact Details Link',
        required: getFieldValue('contactDetail') === 'Yes',
        hidden: getFieldValue('contactDetail') === undefined || getFieldValue('contactDetail') === 'No'
      }
    ]
  }
  const initialFormFields = {
    fields: []
  }
  const [additionalFormFields, setAdditionalFormFields] = useState(initialFormFields)
  const [newFormFields, setNewFormFields] = useState({})
  const [addNewFieldDrawer, setAddNewFieldDrawer] = useState({
    visible: false
  })

  const events = {
    handleFileChange: (target) => {
      const file = target.files[0]
      setFile(file)
    },
    handleBookingDocument: (target) => {
      const file = target.files[0]
      setBookingProcessFile(file)
    },
    handleSubmit: async (e) => {
      e.preventDefault()
      const { form } = props
      form.validateFieldsAndScroll(async (err, valData) => {
        if (err) {
          notification.warning({ message: 'Fill All Required Fields' })
          return
        }
        let newFormFieldData = {}
        newFormFields.forEach((field) => {
          const fieldName = field.name
          if (valData.hasOwnProperty(fieldName)) {
            newFormFieldData[fieldName] = valData[fieldName]
            delete valData[fieldName]
          }
        })

        valData.newFields = newFormFieldData
        const fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (file && file.name) {
          fd.append('contract', file)
        }
        if (bookingProcessFile && bookingProcessFile.name) {
          fd.append('bookingProcessDoc', bookingProcessFile)
        }

        const response = await dispatch(addUnizhomeContractFxn(fd))
        if (response && !response.error) {
          form.resetFields()
          setFile(null)
          setBookingProcessFile(null)
          notification.success({ message: response.message })
          reloadTable()
          onClose()
        }
      })
    },
    getNewFormFields: async () => {
      const response = await dispatch(getFormFieldsFxn())
      if (response) {
        setNewFormFields(response.data)
      }
    },
    toCamelCase: (str) => {
      return str
        .replace(/([^\w\s])+/g, '')  // Remove non-word characters
        .split(/[\s_]+/)             // Split by spaces or underscores
        .map((word, index) =>
          index === 0
            ? word.toLowerCase()     // First word remains lowercase
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize subsequent words
        )
        .join('')
    },
    showAddFormFieldDrawer: () => {
      setAddNewFieldDrawer({
        visible: true
      })
    },
    closeAddFormFieldDrawer: () => {
      setAddNewFieldDrawer({
        visible: false
      })
      reloadTable()
    },
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend', active: [true, false], count: 100, results: 100,
        unizhomeActive: true
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    }
  }


  useEffect(() => {
    events.getNewFormFields()
    events.loadCountry()
  }, [])


  const addNewFields = () => {
    if (newFormFields && Array.isArray(newFormFields)) {
      const additionalFields = newFormFields
        .filter((field) => {
          // Check if the name already exists in the existing fields
          return !additionalFormFields.fields.some((existingField) => existingField.key === field.name)
        })
        .map((field) => ({
          key: field.name,
          label: field.label,
          placeholder: `Enter ${field.label}`
        }))

      if (additionalFields.length > 0) {
        setAdditionalFormFields((prev) => ({
          ...prev,
          fields: [...additionalFields, ...prev.fields] // Append new fields
        }))
      }
    }
  }


  useEffect(() => {
    addNewFields()
  }, [newFormFields])


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }


  return (
    <Drawer
      title='Add Contract'
      width={'80%'}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >

      <div className='row'>
        <div className='col-lg-12'>
          <Form onSubmit={events.handleSubmit}>
            <div className='form-box mt20'>
              <div className='d-flex align-items-center mb-3 heading-form justify-content-between'>
                <h5>Unizhome Contract</h5>
                {user && user.userType == 'itTeam' &&
                <div className='sort-box-table mark-btn'>
                  <a className='btn' onClick={() => {
                    events.showAddFormFieldDrawer()
                  }}>
                    <img src={'../dist/icons/plus.png'} className={'plus'} alt={''} /> Add Extra Field
                  </a>
                </div>
                }

              </div>
              <div className='card unizportal'>
                <Row gutter={24} className={'rowWrap'}>
                  {formFields.fields.map((item, key) => {
                    return (
                      !item.hidden && (
                        <Col
                          span={item.span ? item.span : 8}
                          md={item.span ? item.span : 8}
                          sm={24}
                          xs={24}
                          key={key}
                        >
                          {item.type === 'file' ? (
                            <FileInput
                              name={item.fileName}
                              label={item.label}
                              className={'mt10'}
                              chooseDocument={item.onChange}
                            />
                          ) : (
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout}
                            />
                          )}
                        </Col>
                      )
                    )
                  })}
                </Row>
              </div>
              {/*<div className='table-head d-flex align-items-center justify-content-between'>
                <h5>
                  <b>
                    Extra Fields
                  </b>
                </h5>
              </div>*/}
              <div className='card unizportal'>
                <Row gutter={24}>
                  {additionalFormFields.fields.map((item, key) => {
                    return (
                      <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={24}
                           xs={24}
                           key={key}>
                        {item.type == 'file' && !item.hidden ?
                          <FileInput name={item.fileName}
                                     label={item.label}
                                     className={'mt10'}
                                     chooseDocument={item.onChange} />
                          : <GetEachFormFields
                            item={item}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout} />
                        }
                      </Col>
                    )
                  })}
                </Row>
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='btn'>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
      {addNewFieldDrawer.visible && (
        <AddNewFormFields
          visible={addNewFieldDrawer.visible}
          onClose={() => {
            events.closeAddFormFieldDrawer()
            events.getNewFormFields()
          }}
        />
      )}
    </Drawer>
  )
}

const WrappedUpdateContractDrawer = Form.create()(AddUnizhomeContract)
export default WrappedUpdateContractDrawer
