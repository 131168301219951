import React, {useState, useRef} from 'react'

import {
  Tooltip,
  Avatar, Tag, Icon
} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import { listAllCountries } from '../actions/countries'
import { getUrlPushWrapper } from '../../../routes/routes'
import { DefaultTablePagination } from '../../../components/_utils/appUtils'
import { push } from 'connected-react-router'

const ListAllCountries = () => {
  const { totalCountries } = useSelector((state) => ({
    totalCountries: state.countryReducers.totalCountries
  }))

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.sortField = 'countryName'
      params.sortOrder = 'ascend'
      resolve(dispatch(listAllCountries({
        ...params, active: [true, false],
        regExFilters: ['countryName', 'enrollmentBasic', 'enrollmentProcedure', 'fundSponsors']
      })))
    })
  }
  const [itemNo, setItemNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const confirmDelete = async (id) => {

  }

  const loadFilterCountry = async () => {
    let countryFilter = {
      results: 100
    }
    let resp = await listAllCountries(countryFilter)(dispatch)
  }


  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      fixed: true,
      render: (item, record, index) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCountry', {
                id: record._id
              }))
          }}>
            {(itemNo - 1) * 10 + index + 1}
          </a>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'countryName',
      // width: 150,
      key: 'countryName',
      searchTextName: 'countryName',
      fixed: true,

      render: (item, record) => {
        return (
          <a className={'linkPointer'} onClick={() => {
            dispatch(
              getUrlPushWrapper('masters.editCountry', {
                id: record._id
              }))
          }}>
            {item}
          </a>
        )
      }

    },

    /* {
       title: 'Enrollment Procedure',
       key: 'enrollmentProcedure',
       width: 150,
       dataIndex: 'enrollmentProcedure',
       searchTextName: 'enrollmentProcedure'
     },
     {
       title: 'Fund Acceptable',
       dataIndex: 'fundAcceptable',
       width: 150,
       key: 'fundAcceptable'
       // searchTextName: 'fundAcceptable',

     },
     {
       title: 'Fund Sponsors',
       dataIndex: 'fundSponsors',
       width: 150,
       key: 'fundSponsors',
       searchTextName: 'fundSponsors'

     },
     {
       title: 'Fund Education',
       dataIndex: 'fundEducation',
       width: 150,
       key: 'fundEducation'
       // searchTextName: 'fundEducation',

     },*/


    {
      title: 'Currency',
      dataIndex: 'currency',
      // width: 150,
      key: 'currency'
      // searchTextName: 'tutionFee',
    },


    /* {
       title: 'Tution Fee',
       dataIndex: 'tutionFee',
       width: 150,
       key: 'tutionFee'
       // searchTextName: 'tutionFee',
     },

     {
       title: 'Visa Fee (Main Applicant)',
       dataIndex: 'visaFeeMainApplicant',
       width: 150,
       key: 'visaFeeMainApplicant'
       // searchTextName: 'visaFeeMainApplicant',
     },*/

    {
      title: 'Living cost',
      dataIndex: 'livingCostMainApplicant',
      // width: 150,
      key: 'livingCostMainApplicant'
      // searchTextName: 'livingCostMainApplicant',
    },
    {
      title: 'Flag',
      dataIndex: 'flag',
      key: 'flag',
      render: (item) => {
        return (
          <React.Fragment>
            {item && item.url ? <Avatar src={item.url}/> : ''}
          </React.Fragment>
        )
      }

    },
    {
      title: 'Interview',
      dataIndex: 'interview',
      key: 'interview'
    },

    {
      title: 'Documents',
      dataIndex: 'documents',
      key: 'documents',
      render: (value) => {
        return (
          <React.Fragment>
            {value && value.length ? value.map((item, key) => {
              return (
                <Tag key={key}>{item}</Tag>
              )
            }) : ''}
          </React.Fragment>
        )
      }
    },

    {
      key: 'actions',
      title: 'Actions',
      // fixed: 'right',
      width: 80,
      render: (text, record) => {
        return <React.Fragment>
          <Tooltip title="Edit">
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                dispatch(
                  getUrlPushWrapper('masters.editCountry', {
                    countryId: record._id
                  }))
              }}>
              <Icon type={'edit'}/>
            </button>
          </Tooltip>
          <Tooltip title="Delete">
            <button
              className={'btn'}
              style={{ marginRight: 6 }}
              onClick={() => {
                confirmDelete(record._id)
              }}>
              <Icon stype={'delete'}/>
            </button>
          </Tooltip>

        </React.Fragment>

      }
    }
  ]


  return (
    <div className="row mt-4">
      <div className="col-lg-12">
        <div className="card">
          <div className="table-head d-flex align-items-center">
            <h5>All Countries: {totalCountries || 0}</h5>
            <div className="search-box-table">

            </div>
            <div className="sort-box-table mark-btn">
              <a className="btn" onClick={() => dispatch(push('/masters/add-country'))}>
                <img src={'../dist/icons/plus.png'} className={'plus'} />
                Add Country</a>
            </div>
          </div>
          <div className="card-body table-responsive">
            <TableComp columns={columns}
                       apiRequest={apiRequest}
                       ref={tableRef}
                       pagination={DefaultTablePagination()}
              // extraProps={{ scroll: { x: 600 } }}

            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListAllCountries
