import React, {useEffect, useRef, useState} from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Row,
    Col, notification, Form
} from 'antd'
import { DatePicker } from 'antd';
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    exportFcmtApplicationsList, generateInvoiceCommissionFxn, getInvoiceCommissionFxn,
    getPendingFCMTApplications
} from '../actions/chooseStudent'
import {
  newFormatDisplayDate, filterOption, Intakes, longDisplayDate
} from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import moment from 'moment'
import {listAllCampus} from '../../campus/actions/campus'
import {CheckUserRight, ShowExportOption} from '../../dashboard/views/DashboardUserWise'
import DocumentDrawer from '../drawers/studentDocumentDrawer'
import UploadTTComponent from "../directUniDrawers/uploadTTComponent";


let {UploadedDocuments, TotalTTComponent} = UploadTTComponent;
const { MonthPicker } = DatePicker;
const statusList = [
    'Offer Received',
    'Rqst LOA',
    'LOA Generated',
    'File pending for submission',
    'File Submitted',
    'Visa Approved',
    'Visa Rejected'
]
const {Option} = Select

const PaidApplicationList = (props) => {
    let { universityId, redirectKey, appName } = props
    const [studentData, setStudentData] = useState({})
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [agentId, setAgentId] = useState('')
    const [totalApplication, setTotalApplication] = useState(0)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    const tableAppRef = useRef()




    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.appUniversityId = universityId;
            params.verifiedTuitionFeeDocuments = true;
            params.sortBy = "verifiedTuitionFeeDate";

            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }

            try {
                setFilters(params)
                let resp = await dispatch(getPendingFCMTApplications({...params}))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }

    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = {...params, ...resp}
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        params.courseUniversity = universityId

        let { success, message } = await dispatch(exportFcmtApplicationsList(params))
        if (success) {
            notification.success({message: message})
        } else {
            notification.error({message: message})
        }
    }

    useEffect(() => {
        // loadAgentData()
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        // loadCurrentUserData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
    }


    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = universityId
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let {data} = await dispatch(listAllCampus({...params, regExFilters: ['campusAddress', 'campusName']}))
    }
    const events = {

        reloadFxn: () => {
            tableAppRef.current.reload()
        },

        refreshApplication: () => {
            tableAppRef.current.reload()
        },


        enterName: (value) => {
            setName(value)

        },

        openDocumentDrawer: async (record) => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async (record) => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },

    }


    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        dispatch(
            getUrlPushWrapper(redirectKey, {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setBranchUserId('')
        dispatch(
            getUrlPushWrapper(redirectKey, {
                ...obj
            }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }
    const columns = [

        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                    <div style={{width: 60}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>

                        {item}
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                    </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                    <div style={{width: 150}}>
                        {item}
                    </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                    <div style={{width: 85}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}

                    </div>
                )
            }
        },

        {
            title: 'Tuition Fees',
            key: 'tuitionFeeDocument',
            dataIndex: 'tuitionFeeDocument',
            width: 180,
            render: (val, record) => {
                return (
                    <div style={{width: 180}} className={'fcmtDiv'}>
                        <UploadTTComponent
                            student={record}
                            user={user}
                            reloadTable={events.reloadFxn}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Total TT Amount',
            key: 'applications.totalTTAmount',
            dataIndex: 'applications.totalTTAmount',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}} className={'fcmtDiv'}>
                        <TotalTTComponent
                            student={record}
                            user={user}
                            reloadTable={events.reloadFxn}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Marketing',
            dataIndex: 'marketingUser',
            width: 120,
            key: 'marketingUser',
            render: (item, record) => {
                let {marketingUserId} = record.applications
                return (
                    <React.Fragment>
                        {marketingUserId && marketingUserId.name ? marketingUserId.name : ""}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Travel Documents',
            key: 'travelDocument',
            dataIndex: 'travelDocument',
            width: 140,
            render: (val, record) => {
                return (
                    <UploadedDocuments
                        user={user}
                        applications={record.applications}
                        _reGenerateEnrollmentFunc={() => {
                            events.reGenerateEnrollmentFunc(record)
                        }}/>
                )
            }
        },
        {
            title: 'Login Credentials',
            key: 'travelLoginEmail',
            dataIndex: 'travelLoginEmail',
            width: 150,
            render: (item, record) => {
                return (
                    <div style={{width: 150}} className={'fcmtDiv'}>
                        {item ? <>
                            Email : {item}<br/>
                            Pass : {record.travelLoginPassword}
                        </> : null}
                    </div>
                )
            }
        },
        {
            title: 'Documents',
            key: 'document',
            dataIndex: 'document',
            width: 120,
            render: (val, record) => {
                return (
                    <div className={'fcmtDiv'}>
                        <button className={`bs_btn bs-default mt10 bs-xs`}
                                onClick={() => events.openDocumentDrawer(record)}>
                            View Documents
                        </button>
                    </div>
                )
            }
        }

    ]
    const resp = useSelector(state => {
        let {loadNoteRedux} = state.chooseApplication
        if (loadNoteRedux) {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
            dispatch({type: 'END_LOAD_NOTE'})
        }
    })

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [records, setRecords] = useState([]);
    const fetchRecord = async (month, year) => {
        const response = await getInvoiceCommissionFxn({ month, year });
        console.log(response, "aaaaaaaaaaaaaaaaaa")
        setRecords(response.data || []);
    };

    useEffect(() => {
        if(selectedMonth){
            fetchRecord(selectedMonth.month() + 1, selectedMonth.year());
        }

    }, [selectedMonth]);


    const extra = (
        <div className={'mt10'}>
            <Row gutter={12}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input className='form-control form-control' type='search'
                               placeholder='Search by name...' value={name} onChange={(e) => {
                            events.enterName(e.target.value)
                        }}/>
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setStatus(item)}
                                filterOption={filterOption}
                                className={'antSelect'}
                                placeholder={'Status'}
                                allowClear={true}
                                value={status || undefined}>
                            {statusList && statusList.length ? statusList.map((item, key) => {
                                return (
                                    <Option value={item} key={key}>{item}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select name='Status' onChange={(item) => setCampusId(item)}
                                className={'antSelect'}
                                placeholder={'Campus'}
                                allowClear={true}
                                value={campusId || undefined}>
                            {campusList && campusList.length ? campusList.map((item, key) => {
                                return (
                                    <Option value={item._id} key={key}>{item.campusName}</Option>
                                )
                            }) : null}
                        </Select>
                    </div>
                </Col>

                <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <Select name='Intake' onChange={(item) => setIntake(item)}
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'Intake'}
                                    value={intake || undefined}>
                                {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>{item}</Option>
                                    )
                                }) : null}
                            </Select>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <div className='search-box-table round'>
                                <input className='form-control form-control' type='search'
                                       placeholder='course name...' value={courseName} onChange={(e) => {
                                    setCourseName(e.target.value)
                                }}/>
                                <img src='/dist/img/search.png' alt=''/>
                            </div>
                        </div>
                    </Col>
                </CheckUserRight>


                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>


            </Row>

        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='table-head table-head-new d-flex align-items-center all-student-search'
                                 style={{ height: 60 }}>
                                <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                        className={'roundGoBackBtn'} icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h6>{appName} All Paid Application </h6>
                                <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>

                                       <span className={'sort-box-table-right'}>



      </span>
                                </div>

                                <div className='sort-box-table mark-btn'>
                                    <ShowExportOption user={user} rightUserType={['admin']}>
                                      <span className={'sort-box-table-right'}>
                                        <Tooltip title={'Export Excel'}>
                                          {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                                        </Tooltip>
                                      </span>
                                    </ShowExportOption>
                                </div>

                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp columns={loadColumns()}
                                           rowKey={(item, index) => {
                                               return index
                                           }}
                                           apiRequest={apiRequest}
                                           pagination={{
                                               position: 'top',
                                               showSizeChanger: true,
                                               pageSizeOptions: ['20', '30', '50'],
                                               defaultPageSize: 20,
                                               current: filters.page ? filters.page : 1
                                           }}
                                           ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {visibleDocumentDrawer && studentData.applications && studentData.applications._id ?
                <DocumentDrawer onClose={events.closeDocumentDrawer}
                                reloadTable={events.reloadFxn}
                                studentData={studentData}
                                applicationId={studentData.applications._id}
                                studentId={studentData._id}
                                visible={visibleDocumentDrawer}/> : null}

        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const PaidApplicationListComponent = Form.create()(PaidApplicationList)
export default connect(
    null,
    mapDispatchToProps
)(PaidApplicationListComponent)
