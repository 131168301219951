import {
  Button,
  Col,
  Form,
  Row,
  notification, Radio, Icon, Tooltip
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import {
  LoadState
} from '../../components/_utils/countryUtil'
import { addDepartmentExpenseFxn } from './action'
import AddExpenseCategoryDrawer from './addExpenseCategory'
import { InputBox } from '../../components/_utils/appUtils'
import { listAllCountries } from '../countries/actions/countries'
import ExpenseCategoryTypeheadComponent from './expenseCategoryTypeHead'
import EmployeeTypeheadComponent from './employeeTypeHead'

let initialState = {
  uploadKey: moment(),
  allStates: [],
  allCities: []
}

const AddDepartmentExpense = props => {
  const {
    form: { getFieldDecorator, setFieldsValue }
  } = props
  const dispatch = useDispatch()
  const [state, setState] = useState(initialState)
  const [expenseType, setExpenseType] = useState('')
  const [countryList, setCountryList] = useState([])
  const [countryId, setCountryId] = useState('')
  const [addExpenseCategoryModal, setAddExpenseCategoryModal] = useState({
    visible: false
  })
  const [expenseCategoryId, setExpenseCategoryId] = useState('')
  const [employeeId, setEmployeeId] = useState('')

  const loadCountryList = async () => {
    let filters = {
      sortField: 'countryName', sortOrder: 'ascend'
    }
    let { data } = await dispatch(listAllCountries(filters))
    let otherData = [
      {
        _id: '',
        countryName: 'Forex'
      },
      {
        _id: '',
        countryName: 'Gic'
      },
      {
        _id: '',
        countryName: 'Unizhome'
      },
      {
        _id: '',
        countryName: 'Xtravel'
      },
      {
        _id: '',
        countryName: 'International'
      },
      {
        _id: '',
        countryName: 'Canada Private'
      },
      {
        _id: '',
        countryName: 'Other'
      }
    ]
    data.push(...otherData)
    setCountryList(data)
  }

  useEffect(
    () => {
      setState({
        ...state,
        allStates: 101 ? LoadState(101) : []
      })
      loadCountryList()
      props.form.setFieldsValue({
        date: moment()
      })
    }, [])


  const inputTypes = {
    fields: [
      {
        key: 'expenseCategory',
        hidden: !expenseType || expenseType == 'staffSalary',
        span: 5,
        customField: (
          <ExpenseCategoryTypeheadComponent
            key={expenseCategoryId || expenseType}
            expenseCategoryId={expenseCategoryId}
            expenseType={expenseType}
            dispatch={dispatch}
            onSelect={item => {
              setExpenseCategoryId(item)
            }}
          />
        )
      },
      {
        key: 'plusIcon',
        hidden: !expenseType || expenseType == 'staffSalary',
        span: 1,
        customField: (
          <div className={'col-2 col-md-2 col-sm-2 mt30'}
               onClick={
                 () => {
                   setAddExpenseCategoryModal({
                     visible: true
                   })
                 }}
          >
            <Tooltip title={'Add Expense Category'}>
              <Icon type='plus' className='plusButtonAddExpenseCss' />
            </Tooltip>
          </div>
        )
      },
      {
        key: 'departmentName',
        allowClear: true,
        label: 'Department',
        placeholder: 'Department',
        type: 'select',
        showSearch: true,
        options: countryList,
        required: (expenseType == 'otherExpense' || expenseType == 'otherProfit'),
        hidden: !expenseType || expenseType == 'staffSalary',
        keyAccessor: x => x.countryName,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          props.form.setFieldsValue({
            departmentName: x
          })
          let countryData = countryList.find(country => country.countryName == x)
          if (countryData) {
            setCountryId(countryData._id)
          }
        },
        span: 6
      },
      {
        key: 'employeeId',
        hidden: !expenseType || expenseType != 'staffSalary',
        required: expenseType == 'staffSalary',
        span: 8,
        customField: (
          <EmployeeTypeheadComponent
            key={employeeId}
            employeeId={employeeId}
            dispatch={dispatch}
            onSelect={item => {
              setEmployeeId(item)
            }}
          />
        )
      },
      {
        key: 'date',
        label: 'Date',
        type: 'date',
        required: true,
        span: expenseType == 'staffSalary' ? 8 : 6,
        placeholder: 'Date'
      },
      {
        key: 'amount',
        label: 'Amount',
        type: 'number',
        required: true,
        span: expenseType == 'staffSalary' ? 8 : 6,
        placeholder: 'Enter Amount'
      },
      {
        key: 'noOfStudents',
        label: 'No Of Students',
        type: 'number',
        hidden: expenseType != 'otherProfit',
        span: 6,
        placeholder: 'Enter No of Students'
      },
      {
        key: 'description',
        label: 'Description',
        placeholder: 'Description...',
        type: 'textarea',
        span: expenseType == 'otherProfit' ? 18 : 24
      }

    ]
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        if (!valData.date) {
          notification.warning({
            message: 'Please choose date.'
          })
          return
        }
        if ((expenseType == 'otherExpense' || expenseType == 'otherProfit') && !expenseCategoryId) {
          notification.warning({
            message: 'Please Choose Expense Category.'
          })
          return
        }
        if (expenseType == 'staffSalary' && !employeeId) {
          notification.warning({
            message: 'Please choose Employee.'
          })
          return
        }
        if (countryId) {
          valData.departmentCountryId = countryId
        } else {
          valData.departmentCountryId = undefined
        }

        if (employeeId) {
          valData.employeeId = employeeId
        }
        if (expenseCategoryId) {
          valData.expenseCategoryId = expenseCategoryId
        }

        if (expenseType) {
          valData.expenseType = expenseType
        } else {
          notification.warning({
            message: 'Please choose Expense Type.'
          })
          return
        }
        let data = await dispatch(addDepartmentExpenseFxn(valData))
        if (data && !data.error) {
          form.resetFields()
          setCountryId('')
          setEmployeeId('')
          setExpenseCategoryId('')
        }
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }

  const handleRadioChange = (e) => {
    setExpenseType(e.target.value)
    setExpenseCategoryId('')
    setEmployeeId('')
  }

  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Form onSubmit={handleSubmit}>
          <div className='form-box mt-4'>
            <div className='d-flex align-items-center mb-3 heading-form'>
              <h5>Add Other Expense & Profit</h5>
            </div>
            <div className='card unizportal'>
              <Form.Item label='Select Type'>
                <Radio.Group onChange={handleRadioChange}>
                  <Radio value='staffSalary'>Staff Salary</Radio>
                  <Radio value='otherExpense'>Other Expense</Radio>
                  <Radio value='otherProfit'>Other Profit</Radio>
                </Radio.Group>
              </Form.Item>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <>
                      {item.customField ? !item.hidden &&
                        <Col span={item.span ? item.span : 8}>
                          {item.customField}
                        </Col> : !item.hidden &&
                        <Col span={item.span ? item.span : 8} key={key}>
                          <InputBox title={item.label1 ? item.label1 : ''}>
                            <GetEachFormFields
                              item={item}
                              getFieldDecorator={getFieldDecorator}
                              formItemLayout={formItemLayout} />
                          </InputBox>
                        </Col>}
                    </>
                  )
                })}
              </Row>
              <Form.Item>
                <Button type='primary' htmlType='submit' className='btn'>
                  SAVE
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      {addExpenseCategoryModal.visible && expenseType &&
      <AddExpenseCategoryDrawer
        visible={addExpenseCategoryModal.visible}
        onClose={() => {
          setAddExpenseCategoryModal({
            ...addExpenseCategoryModal,
            visible: false
          })
        }}
        expenseType={expenseType}
      />
      }
    </div>
  )
}

const WrappedDepartmentExpense = Form.create()(AddDepartmentExpense)
export default WrappedDepartmentExpense
