import {
    Avatar,
    Button,
    Col,
    DatePicker,
    Form,
    Icon,
    Popconfirm,
    Row,
    Select,
    Tooltip,
    notification
} from 'antd'
import lodash from 'lodash'
import moment from 'moment'
import React, {useEffect, useRef, useState} from 'react'
import {connect, useDispatch,} from 'react-redux'
import {TableComp} from 'sz-react-utils-lite'
import {
    CasaHCollege,
    defaultLogoList,
    filterOption,
    longDisplayDate,
    newFormatDisplayDate,
    kasaRights
} from '../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes/routes'
import { AllowComponentRightsWise } from '../../WebComponent/allowComponentRightsWise'
import {listAllCampus} from '../../campus/actions/campus'
import {CheckUserRight} from '../../dashboard/views/DashboardUserWise'
import {
    applicationCloseDirect,
    reCalculateCommission,
    updateApplicationTuitionFees
} from '../../student/actions/student'
import ApplyCourse from '../../student/views/applyForApplicationDrawer'
import {listAllUsers} from '../../users/actions/user'
import {
    ReCreateFcmtLoaLetter,
    ReCreateFcmtOfferLetter,
    ReCreateFcmtPaymentReceipt,
    exportApplicationsList,
    fcmtSendMailFxn,
    fcmtSendOfferMailFxn,
    fcmtSendReceiptMailFxn,
    getDirectUniversityApplicationsFxn, FcmtStatusUpdate
} from '../actions/chooseStudent'
import ChangeUniversityComponent from '../drawers/changeUniversity'
import AddNote from '../drawers/createNoteComponent'
import ReActivateApplication from '../drawers/reActivateApplicationDrawer'
import StudentOtherApp from '../views/StudentOtherApplication'
import TransferApplicationDrawer from '../views/transferApplication'
import ChangeNameDrawer from "../drawers/changeNameDrawer";
import AgentTypeheadComponent from "../../../components/_utils/AgentTypeheadComponent";

let {RangePicker} = DatePicker
const statusList = [
    'Offer Received',
    'Visa Approved'
]
const {Option} = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
const OfferActionComponent = props => {
    let {file} = props
    return (
        <div>
            {file && file.path ? (
                <Tooltip title={'Download Offer Letter'}>
                    <a download={file.fileName} href={file.path}>
                        <img src={'../assets/icons/cloud-computing-download.png'}/>
                    </a>
                </Tooltip>
            ) : null}
        </div>
    )
}

const DirectUniversityApplication = props => {
    let {studentObj = {}} = props

    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [name, setName] = useState('')

    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let {applicationObj} = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})

    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserId, setBranchUserId] = useState('')
    const [appUniversityId, setAppUniversityId] = useState('')
    const [toDate, setToDate] = useState('')
    const [fromDate, setFromDate] = useState('')
    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })

    let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)

    const dispatch = useDispatch()
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const tableAppRef = useRef()

    let apiRequest = params => {
        return new Promise(async resolve => {
            let resp = await getParamsForApplicationList()
            params = {...params, ...resp}
            params.appUniversityId = CasaHCollege
            params.australiaOnshore = false
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(
                    getDirectUniversityApplicationsFxn({...params})
                )
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }


    useEffect(() => {
        loadCampusList()
        setFieldByParams()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
        if (resp.toDate) {
            setToDate(moment(resp.toDate, 'MM-DD-YYYY'))
        }
        if (resp.fromDate) {
            setFromDate(moment(resp.fromDate, 'MM-DD-YYYY'))
        }
    }


    const loadCampusList = async () => {
        let searchParams = new URLSearchParams(window.location.search)
        let appUniversityId = searchParams.get('appUniversityId')
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = appUniversityId
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let {data} = await dispatch(
            listAllCampus({
                ...params,
                regExFilters: ['campusAddress', 'campusName']
            })
        )
        setCampusList(data)
    }

    const events = {
        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: value => {
            setName(value)
        },
        openOtherAppDrawer: value => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: value => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: data => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let {commissionAmount} = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({commissionAmount})
            }
        },
        updateApplicationTuitionFeesFxn: async () => {
            let {success,} = await dispatch(
                updateApplicationTuitionFees(feesObj)
            )
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        sendFcmtMail: async (record, type) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            if (type == 'receipt') {
                let {success,} = await dispatch(fcmtSendReceiptMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else if (type == 'offer') {
                let {success,} = await dispatch(fcmtSendOfferMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else {
                let {success,} = await dispatch(fcmtSendMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            }
        },

        moveToVisaStage: async (record) => {
            let {_id: studentId, applications} = record;
            let obj = {
                studentId, applicationId: applications._id, status: "Visa Approved"
            }
            let resp = await dispatch(FcmtStatusUpdate(obj))
            if (resp && resp.success) {
                events.reloadFxn()
            }
        },
        reCreateOfferLetter: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreateLoaLetter: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreatePaymentReceipt: async record => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },

        changeNameOpen: async record => {
            setStudentData(record)
            setVisibleChangeNameDrawer(true)
        },
        closeChangeName: async record => {
            setStudentData({})
            setVisibleChangeNameDrawer(false)
        },
    }
    const reActivateApp = record => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }


    const changeUniversityFxn = record => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentBtn = record => {
        /* let application = record.applications
             return (
                 !application.enrollment ? showEnrollmentAction(application.statusList) ?
                     <button className='btn' onClick={() => enrollmentApp(record)}>
                         <img src={'/assets/enrollment.png'}/>
                     </button> : null : null
             )*/
        return null
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let fromDate = searchParams.get('fromDate')
            let toDate = searchParams.get('toDate')

            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (toDate) {
                obj.toDate = toDate
            }
            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let searchParams = new URLSearchParams(window.location.search)
        let obj = {}
        if (name) {
            obj.name = name
        }

        if (appUniversityId) {
            obj.appUniversityId = appUniversityId
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        if (toDate) {
            obj.toDate = moment(toDate).format('MM-DD-YYYY')
        }
        if (fromDate) {
            obj.fromDate = moment(fromDate).format('MM-DD-YYYY')
        }
        dispatch(
            getUrlPushWrapper('casaApplicationList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let obj = {}
        setName('')
        setAppUniversityId('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setBranchUserId('')
        setToDate('')
        setFromDate('')
        dispatch(
            getUrlPushWrapper('casaApplicationList', {
                ...obj
            })
        )
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }

    const reCreateOfferLetter = async record => {
        let obj = {
            studentId: record._id,
            applicationId: record.applications._id
        }
        let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
        if (resp && resp.success) {
            tableAppRef.current.reload()
        }
    }
    const reCreatePaymentReceipt = async record => {
        let obj = {
            studentId: record._id,
            applicationId: record.applications._id
        }
        let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
        if (resp && resp.success) {
            tableAppRef.current.reload()
        }
    }
    const columns = [
        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: item => {
                return <div style={{width: 60}}>{item}</div>
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                    <div style={{width: 120}}>
                        <Tooltip title={'Edit Student'}>
                            <a
                                className={'linkAA'}
                                onClick={() =>
                                    dispatch(
                                        getPushPathWrapper('student.editStudent', {
                                            id: record._id
                                        })
                                    )
                                }>
                                {item} <Icon type={'edit'}/>
                            </a>
                        </Tooltip>
                        <br/>
                        {newFormatDisplayDate(record.dateOfBirth)}
                        <label className={'label label-tag'}>
                            {record.studentShore ? `(${record.studentShore})` : null}
                        </label>
                    </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                    <div style={{width: 100}}>
                        {item && item ? (
                            <div>
                                Family Name : <b>{item}</b>
                            </div>
                        ) : null}
                        {record && record.applications && record.applications.givenName ? (
                            <div>
                                Given Name :{' '}
                                <b>{record.applications.givenName}</b>
                            </div>
                        ) : null}
                        <Icon
                            type={'edit'}
                            onClick={() => events.changeNameOpen(record)}
                        />
                    </div>
                )
            }
        },

        {
            title: 'File No',
            key: 'applications.studentIdNo',
            dataIndex: 'applications.studentIdNo',
            width: 120,
            render: (item) => {
                return (
                    <React.Fragment>
                        {item}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'University',
            key: 'applications.courseUniversity',
            dataIndex: 'applications.courseUniversity',
            width: 150,
            render: (item, record) => {
                return (
                    <React.Fragment>
                        {item && item.logo && item.logo.url ? (
                            <Avatar src={item.logo.url} size={40}/>
                        ) : (
                            <Avatar src={defaultLogoList.defaultUniversityLogo} size={40}/>
                        )}
                        {item.universityName ? `  ${item.universityName}` : ''}
                    </React.Fragment>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: item => {
                return <div style={{width: 150}}>{item}</div>
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.month && item.year
                            ? `${item.month}, ${item.year}`
                            : ''}
                    </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 80,
            render: (item, record) => {
                return (
                    <div style={{width: 80}}>
                        {item && item.campusName ? item.campusName : ''}
                    </div>
                )
            }
        },

        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let {agentId, statusList, status} = record.applications
                return (
                    <div style={{width: 100}}>
                        {val && val.name ? val.name : ''}
                        <br/>
                        <div>
                            Agent :{' '}
                            {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br/>
                            {agentId && agentId.mobile ? (
                                <>
                                    {' '}
                                    <Icon
                                        type={'phone'}
                                        style={{
                                            position: 'relative',
                                            bottom: 3
                                        }}
                                    />{' '}
                                    {agentId.mobile}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                        <div>{longDisplayDate(statusList.date)}</div>
                    </div>
                )
            }
        },
        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                    <div style={{width: 100}} className={'fcmtDiv'}>
                        <OfferActionComponent file={val} record={record} events={events}/>

                        {kasaRights.offerLetter(user) ?
                            <Popconfirm
                                title={'Are your sure, you want to Re-Create Offer Letter?'}
                                onConfirm={() => reCreateOfferLetter(record)}
                                okText='Yes'
                                cancelText='No'>
                                <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
                            </Popconfirm> : null}
                    </div>
                )
            }
        },

        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let {applications} = record
                return (
                    <div className={'fcmtDiv'} style={{width: 150}}>
                        {item.includes('Visa') ? (
                            <div>
                                {item}
                                <br/>
                                {applications.statusList && applications.statusList.date ? (
                                    <div>
                                        Date : {newFormatDisplayDate(applications.statusList.date)}
                                        <br/>
                                    </div>
                                ) : null}
                            </div>
                        ) : kasaRights.offerLetter(user) ? (<Tooltip title={'Update Status'}>
                                <Popconfirm title={'Are you sure, you want to move to Visa Approved?'}
                                            onConfirm={() => {
                                                events.moveToVisaStage(record)
                                            }}>
                                    <a><img src={'../assets/icons/updated.png'} className={'sm'}/></a>
                                </Popconfirm>
                            </Tooltip>

                        ) : ""}
                    </div>
                )
            }
        },


        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                return (
                    <div className={'actionBtnGroup'}>
                        {!record.applications.directClosed && (
                            <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                                <Tooltip title={'View Application'}>
                                    <a
                                        className='btn'
                                        href={`/application/view?appId=${
                                            record.applications._id
                                        }&studentId=${record._id}`}>
                                        <img
                                            src='../dist/img/view.svg'
                                            alt=''
                                            className={'eyeBtn'}
                                        />
                                    </a>
                                </Tooltip>{' '}
                            </AllowComponentRightsWise>
                        )}

                        <div>
                            <AllowComponentRightsWise
                                rightUserType={['admin', 'branchUser', 'branchManager']}>
                                <Tooltip title={'View Application'}>
                                    <a
                                        className='btn'
                                        href={`/student/application?studentId=${record._id}&appId=${
                                            record.applications._id
                                        }`}>
                                        <img src='../dist/img/view.svg' alt=''/>
                                    </a>
                                </Tooltip>

                                {record.applications.status !== 'Case Close' && (
                                    <Tooltip title={'Case Closed'}>
                                        <Popconfirm
                                            title={'Are your sure, you want to Close Case?'}
                                            onConfirm={() => {
                                                events.closeDirectApp(record)
                                            }}
                                            okText='Yes'
                                            cancelText='No'>
                                            <button className='btn'>
                                                <img
                                                    src='../dist/img/cross.svg'
                                                    alt=''
                                                    className={'crossBtn'}
                                                />
                                            </button>
                                        </Popconfirm>
                                    </Tooltip>
                                )}

                                <Tooltip title={'View Other Application'}>
                                    <button
                                        className='btn'
                                        onClick={() => {
                                            events.openOtherAppDrawer(record)
                                        }}>
                                        <Icon type={'ordered-list'}/>
                                    </button>
                                </Tooltip>

                                <Tooltip title={'Add Note'}>
                                    <button
                                        className='btn'
                                        onClick={() => {
                                            events.openAddCommentDrawer(record)
                                        }}>
                                        <Icon type={'plus'}/>
                                    </button>
                                </Tooltip>
                            </AllowComponentRightsWise>
                        </div>

                        <AllowComponentRightsWise
                            rightUserType={['admin', 'branchManager']}>
                            {record.applications.status == 'Case Close' ? (
                                <Tooltip title={'Re-Open Case'}>
                                    <button className='btn' onClick={() => reActivateApp(record)}>
                                        <img src={'/assets/power-button.png'}/>
                                    </button>
                                </Tooltip>
                            ) : null}
                        </AllowComponentRightsWise>
                        <AllowComponentRightsWise
                            rightUserType={['admin', 'branchManager']}>
                            {record.applications.status !== 'Case Close' ? (
                                <Tooltip title={'Change University'}>
                                    <button
                                        className='btn'
                                        onClick={() => changeUniversityFxn(record)}>
                                        <img src={'/assets/shuffle-arrows.png'}/>
                                    </button>
                                </Tooltip>
                            ) : null}
                        </AllowComponentRightsWise>

                        <AllowComponentRightsWise rightUserType={['admin']}>
                            {enrollmentBtn(record)}
                        </AllowComponentRightsWise>
                    </div>
                )
            }
        }
    ]


    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, item => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
        <div>
            <Row gutter={12} className={'filterBox'}>
                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='search-box-table round'>
                        <input
                            className='form-control form-control'
                            type='search'
                            placeholder='Search by name...'
                            value={name}
                            onChange={e => {
                                events.enterName(e.target.value)
                            }}
                        />
                        <img src='/dist/img/search.png' alt=''/>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select
                            name='Status'
                            onChange={item => setStatus(item)}
                            filterOption={filterOption}
                            className={'antSelect'}
                            placeholder={'Status'}
                            allowClear={true}
                            value={status || undefined}>
                            {statusList && statusList.length
                                ? statusList.map((item, key) => {
                                    return (
                                        <Option value={item} key={key}>
                                            {item}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <Col md={4} sm={4} xs={8} lg={4}>
                    <div className='sort-box-table custom-sort-box-new'>
                        <Select
                            name='Status'
                            onChange={item => setCampusId(item)}
                            className={'antSelect'}
                            placeholder={'Campus'}
                            allowClear={true}
                            value={campusId || undefined}>
                            {campusList && campusList.length
                                ? campusList.map((item, key) => {
                                    return (
                                        <Option value={item._id} key={key}>
                                            {item.campusName}
                                        </Option>
                                    )
                                })
                                : null}
                        </Select>
                    </div>
                </Col>

                <CheckUserRight
                    user={user}
                    rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Col md={4} sm={4} xs={8} lg={4}>
                        <div className='sort-box-table custom-sort-box-new'>
                            <AgentTypeheadComponent
                                key={agentId}
                                agentId={agentId}
                                dispatch={dispatch}
                                onSelect={item => {
                                    setAgentId(item)
                                }}
                            />
                        </div>
                    </Col>
                </CheckUserRight>
                <Col md={4} sm={4} xs={12} lg={4} key={fromDate}>
                    <RangePicker
                        defaultValue={[fromDate, toDate]}
                        onChange={val => {
                            setFromDate(val[0])
                            setToDate(val[1])
                        }}
                    />
                </Col>
                <Col md={4} sm={4} xs={12} lg={4}>
                    <Button onClick={() => searchFxn()} className={'roundBtn'}>
                        Search
                    </Button>
                    <Button onClick={() => clearFxn()} className={'roundBtn'}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <React.Fragment>
            <div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div
                                className='table-head d-flex align-items-center all-student-search'
                                style={{height: 60}}>
                                <Button
                                    onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                    className={'roundGoBackBtn'}
                                    icon={'arrow-left'}>
                                    Back
                                </Button>
                                <h5>Casa School All Application </h5>
                                <div
                                    className='search-box-table'
                                    style={{backgroundColor: 'transparent'}}>
                                    {currentStatus} (Total - {totalApplication})
                                </div>
                                <div className='sort-box-table mark-btn'>

                                </div>
                            </div>
                            <div className='card-body table-responsive'>
                                {extra}
                                <TableComp
                                    columns={loadColumns()}
                                    rowKey={(item, index) => {
                                        return index
                                    }}
                                    apiRequest={apiRequest}
                                    pagination={{
                                        showSizeChanger: true,
                                        pageSizeOptions: ['20', '50', '100'],
                                        defaultPageSize: 20,
                                        current: filters.page ? filters.page : 1
                                    }}
                                    ref={tableAppRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {transferAppVisible ? (
                <TransferApplicationDrawer
                    visible={transferAppVisible}
                    closeTransferDrawer={() => events.hideTransferApp()}
                    studentId={studentData._id}
                    studentData={studentData}
                    application={studentData.applications}
                />
            ) : null}

            {visibleAddCourseDrawer ? (
                <ApplyCourse
                    visible={visibleAddCourseDrawer}
                    onClose={() => events.hideAddCourseDrawer()}
                    refreshApplication={() => events.refreshApplication()}
                    studentId={studentObj._id}
                />
            ) : null}

            {visibleOtherAppDrawer ? (
                <StudentOtherApp
                    visible={visibleOtherAppDrawer}
                    applicationObj={state.applicationObj}
                    onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                    reloadFxn={events.reloadFxn}
                />
            ) : null}

            {visibleAddCommentDrawer && applicationObj && applicationObj._id ? (
                <AddNote
                    visible={visibleAddCommentDrawer}
                    studentData={applicationObj}
                    addedType={'direct'}
                    onClose={() => events.closeAddCommentDrawer()}
                    applicationId={applicationObj.applications._id}
                />
            ) : null}

            {selectedApplication.visible ? (
                <ReActivateApplication
                    {...selectedApplication}
                    onClose={onCloseReActivate}
                />
            ) : null}


            {changeUniversity.visible ? (
                <ChangeUniversityComponent
                    {...changeUniversity}
                    onClose={onCloseChangeUniversityFxn}
                />
            ) : null}


            {visibleChangeNameDrawer ? (
                <ChangeNameDrawer
                    onClose={events.closeChangeName}
                    reloadTable={events.reloadFxn}
                    studentData={studentData}
                    applicationId={
                        studentData.applications ? studentData.applications._id : null
                    }
                    studentId={studentData._id}
                    visible={visibleChangeNameDrawer}
                />
            ) : null}
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

const WrappedComponent = Form.create()(DirectUniversityApplication)
export default connect(
    null,
    mapDispatchToProps
)(WrappedComponent)
