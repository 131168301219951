import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, DatePicker, Drawer, Input, notification, Select } from 'antd'
import { displayDate, InputBox, nameAvatar } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { displayTime } from '../../components/_utils/appUtils'
import { addFollowUpFxn, followUpListFxn } from './action'

const { TextArea } = Input
const { Option } = Select

const SingleComment = (props) => {
  let { comment } = props
  return (
    <>
      <div className='comment' key={comment._id}>
        <Avatar className={'textUpp avatar_text'} size='large'>
          {nameAvatar(comment.userId.name)}
        </Avatar>

        <div className='comment-text'>
          <p className='comment-author'>
            <span className={'name'}>{comment.userId.name} ({comment.status})</span>
            <span className={'date'}>{displayDate(comment.date)}</span>
          </p>
          <div className={'mb5'}>
            {comment.details}
          </div>
          <div className={'time-span'}>
            {displayDate(comment.date)}
          </div>
        </div>

      </div>
    </>
  )
}

const ComponentListDrawer = (comments) => {
  return (
    <>
      <div id={'dd'}>
        {comments && comments.data.length ? comments.data.map((comment, index) => {
          return (
            <SingleComment comment={comment} />
          )
        }) : null}
      </div>
    </>
  )
}


const FollowUpDrawer = (props) => {
  let dispatch = useDispatch()
  let { onClose, visible, taskId, task, onSubmit } = props
  let [followUpList, setFollowUpList] = useState([])
  let [date, setDate] = useState('')
  let [details, setDetails] = useState('')

  useEffect(() => {
    events.loadFollowUpList()
  }, [taskId])
  let events = {
    handleFollowUpSave: async () => {
      if (!date) {
        notification.warning({ message: 'Please select date' })
        return
      }
      if (!details) {
        notification.warning({ message: 'Please enter details' })
        return
      }
      if (taskId) {
        let resp = await dispatch(addFollowUpFxn({ date, details, taskId }))
          if (resp && resp.success) {
          setDate('')
          setDetails('')
        }
        onSubmit()
        events.loadFollowUpList()
      }
    },
    loadFollowUpList: async () => {
      let { data } = await dispatch(followUpListFxn({ taskId }))
      if (data && data.followUps && data.followUps.length) {
        setFollowUpList(data.followUps)
      }
    }
  }


  return (
    <Drawer
      title={`Add Follow Up`}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='40%'
      style={{ transition: 'all 0.3s' }}
    >
      <div className={'card unizportal'} style={{ padding: '10px 20px' }}>
        <Card title=''>
          <div className='row'>
            <div className='col-md-12'>
              <InputBox title={'Property Visit Date'}>
                <DatePicker
                  selected={date}
                  value={date}
                  onChange={(value) => {
                    setDate(value)
                  }}
                />
              </InputBox>

              <InputBox title={'Enter follow up details'}>
                <TextArea
                  rows={4}
                  placeholder='Enter follow up details'
                  className='form-control'
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </InputBox>
            </div>
            <div className={'col-md-12 alignRight'}>
              <Button
                className='btn btn-primary roundNew md'
                onClick={() => events.handleFollowUpSave()}>
                Submit
              </Button>
            </div>
          </div>
        </Card>
        <Card title={'Follow Up List'} className={'mt10'}>
          <div className={'todo-comments-box'}>
            {followUpList && followUpList.length ? <ComponentListDrawer data={followUpList} /> : null}
          </div>
        </Card>

      </div>
    </Drawer>
  )
}
export default FollowUpDrawer
