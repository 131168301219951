import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import S from 'string'
import { getUrlParams } from '../../routes/routes'
import {
    AutoComplete,
    Button,
    Card, Checkbox, Col, DatePicker,
    Form, Input, Modal,

    notification, Row, Select, Table, Tooltip
} from 'antd'
import {
    boardList,
    countryIds,
    DocumentTypes, EnglishExamTypes, getEducationData,
    InputBox,
    qualificationOptions,
    streamsList
} from '../../components/_utils/appUtils'

import GetEachFormFields from '../../components/_utils/appFormUtils'
import {Debounce} from '../../components/_utils/debounce'
import {eduBoardAndUniversity, getStudent} from '../student/actions/student'
import {listAllCountries, listTemplateCountries} from '../countries/actions/countries'
import {courseCategoryList, listAllCourse} from '../course/actions/courseAction'
import {listAllUniversities, listAllUniversitiesAccTemplate} from '../university/actions/university'
import {GenerateSOPFxn, sopListFxn, templateSopFxn} from './template/action/template'
import {Link, useLocation} from 'react-router-dom'
import {SingleEventsFxn} from '../Events/action'
import moment from 'moment'
import {apiUrl2} from '../../settings'

const initialState = {
    countryId: "",
    universityId: "",
    courseId: "",
    name: "",
    dateOfBirth: "",
    dateOfMarriage: "",
    gender: "",
    maritalStatus: "",
    address: "",
    mobile: "",
    email: "",
    education: {},
    board: "",
    passingYear: "",
    percentage: "",
    math: "",
    english: "",
    examType: "",
    englishRequirement: {},
};

const eduStateInit = {
    qualification: "",
    math: "",
    english: "",
    percentage: "",
    board: "",
    stream: "",
    passingYear: "",
};
const workStateInit = {
    companyName: "",
    position: "",
    time: "",
    experienceCertificate: {},

};

let boardFields = ["XII + Diploma", "XII", "X + 3 Year Diploma", "X"];


const EducationComponent = (props) => {
    let {datas, index, eduEvents, education, deleteItem} = props;
    let {Option} = Select
    const [eduList, setEduList] = useState([])
    const loadBoard = async (x) => {
        let obj = {
            qualification: datas.qualification,
            board: x
        }
        console.log(obj, "_____________dsfdsfds")
        let {data} = await eduBoardAndUniversity(obj)
        setEduList(data)
    }
    // useEffect(() => {
    //   loadBoard()
    // },[datas.qualification])
    return (
        <>
            <div className={index > 0 ? "repeat-sec" : ""}>
                <div className={"row mb-2"} key={index}>
                    <div className={"col-md-2"}>
                        <InputBox title={"Qualification"}>
                            <select
                                className={"form-select form-control"}
                                value={datas.qualification}
                                onChange={({target}) => {
                                    const newValue = target.value;
                                    eduEvents.updateState(
                                        {qualification: newValue},
                                        index
                                    );
                                    // loadBoard(newValue);
                                }}

                            >
                                <option value={""}>Choose Qualification</option>
                                {qualificationOptions.map((item) => {
                                    return (
                                        <option key={item} value={item}>
                                            {item}
                                        </option>
                                    );
                                })}
                            </select>
                        </InputBox>
                    </div>
                    {datas.qualification && boardFields.includes(datas.qualification) ? (
                        <div className={"col-md-1"} name="board">
                            <InputBox title={"Board"}>
                                {/*<select*/}
                                {/*  className={"form-control"}*/}
                                {/*  placeholder="Board"*/}
                                {/*  onChange={({ target }) => {*/}
                                {/*    const newValue = target.value;*/}
                                {/*    eduEvents.updateState(*/}
                                {/*      { board: newValue },*/}
                                {/*      index*/}
                                {/*    );*/}
                                {/*  }}*/}
                                {/*  value={datas.board}*/}
                                {/*>*/}
                                {/*  <option value={""}>Choose Board</option>*/}

                                {/*  {eduList.map((item) => {*/}
                                {/*    return (*/}
                                {/*      <option key={item} value={item}>*/}
                                {/*        {item}*/}
                                {/*      </option>*/}
                                {/*    );*/}
                                {/*  })}*/}
                                {/*</select>*/}
                                <AutoComplete
                                    className={'custom-autocomplete'} onSearch={loadBoard}
                                    value={datas.board}
                                    allowClear

                                    onChange={(value) => {
                                        eduEvents.updateState(
                                            {board: value},
                                            index
                                        );
                                    }}
                                >
                                    {eduList.map((item) => {
                                        return (
                                            <Option key={item} value={item}>
                                                {item}
                                            </Option>
                                        );
                                    })}
                                </AutoComplete>
                            </InputBox>
                        </div>
                    ) : null}

                    {datas.qualification == "Bachelor Degree" ||
                    datas.qualification == "Post Graduation" ? (
                        <>
                            <div className={"col-md-2"} name="board">
                                <InputBox title={"University"}>
                                    <input
                                        className={"form-control"}
                                        placeholder="University"
                                        onChange={({target}) => {
                                            eduEvents.updateState(
                                                {university: target.value},
                                                index
                                            );
                                        }}
                                        value={datas.university}
                                    >

                                    </input>
                                </InputBox>
                            </div>
                            <div className={"col-md-2"}>
                                <InputBox title={"Degree Name"}>
                                    <input
                                        className={"form-control"}
                                        placeholder="Degree Name"
                                        onChange={({target}) => {
                                            eduEvents.updateState(
                                                {courseName: target.value},
                                                index
                                            );
                                        }}
                                        value={datas.courseName}
                                    />
                                </InputBox>
                            </div>
                        </>
                    ) : null}

                    <div className={"col-md-2"} name="passingYear">
                        <InputBox title={"Passing Year"}>
                            <input
                                className={"form-control"}
                                placeholder="Passing Year"
                                onChange={({target}) => {
                                    eduEvents.updateState({passingYear: target.value}, index);
                                }}
                                value={datas.passingYear}
                            />
                        </InputBox>
                    </div>

                    <div className={"col-md-2"} name="Percentage">
                        <InputBox title={"Percentage"}>
                            <input
                                placeholder="percentage"
                                className={"form-control"}
                                onChange={({target}) => {
                                    eduEvents.updateState({percentage: target.value}, index);
                                }}
                                value={datas.percentage}
                            />
                        </InputBox>
                    </div>

                    {datas.qualification == "XII" ||
                    datas.qualification == "XII + Diploma" ? (
                        <div className={"col-md-2 "} name="math">
                            <InputBox title={"Stream"}>
                                <input
                                    className={"form-control"}
                                    placeholder="Stream"
                                    onChange={({target}) => {
                                        eduEvents.updateState({stream: target.value}, index);
                                    }}
                                    value={datas.stream}
                                >
                                    {/* <option value={""}>Choose</option>

                    {streamsList.map((item) => {
                      return (
                        <option key={item.option} value={item.option}>
                          {item.option}
                        </option>
                      );
                    })} */}
                                </input>
                            </InputBox>
                        </div>
                    ) : null}

                    {datas.qualification == "XII" ? (
                        <div className={"col-md-2 "} name="englishScore">
                            <InputBox title={"English Score"}>
                                <input
                                    className={"form-control"}
                                    placeholder="English Score"
                                    onChange={({target}) => {
                                        eduEvents.updateState({english: target.value}, index);
                                    }}
                                    value={datas.english}
                                />
                            </InputBox>
                        </div>
                    ) : null}
                    {datas.qualification == "XII" ? (
                        <div className={"col-md-2"} name="math">
                            <InputBox title={"Math Score"}>
                                <input
                                    className={"form-control"}
                                    placeholder="Math Score"
                                    onChange={({target}) => {
                                        eduEvents.updateState({math: target.value}, index);
                                    }}
                                    value={datas.math}
                                />
                            </InputBox>
                        </div>
                    ) : null}


                    {index !== 0 ? (
                        <button className="btn delete mt-4" onClick={() => deleteItem(index)}>
                            <i className="fa fa-times-circle "></i>
                        </button>
                    ) : null}
                </div>
            </div>
        </>
    );
};


const WrkExpComponent = (props) => {
    let {data, index, wrkEvents, education, deleteItems} = props;

    return (
        <>
            <div className={index > 0 ? "repeat-sec" : ""}>
                <div className={"row mb-2"} key={index}>

                    <div className={"col-md-2"} name="passingYear">
                        <InputBox title={"Company Name"}>
                            <input
                                className={"form-control"}
                                placeholder="Company Name"
                                onChange={({target}) => {
                                    wrkEvents.updateState({companyName: target.value}, index);
                                }}
                                value={data.companyName}
                            />
                        </InputBox>
                    </div>

                    <div className={"col-md-2"} name="Percentage">
                        <InputBox title={"Position"}>
                            <input
                                placeholder="Position"
                                className={"form-control"}
                                onChange={({target}) => {
                                    wrkEvents.updateState({position: target.value}, index);
                                }}
                                value={data.position}
                            />
                        </InputBox>
                    </div>

                    <div className={"col-md-2"} name="Percentage">
                        <InputBox title={"Time Period"}>
                            <input
                                placeholder="Time Period"
                                className={"form-control"}
                                onChange={({target}) => {
                                    wrkEvents.updateState({time: target.value}, index);
                                }}
                                value={data.time}
                            />
                        </InputBox>
                    </div>


                    {index !== 0 ? (
                        <button className="btn delete mt-4" onClick={() => deleteItems(index)}>
                            <i className="fa fa-times-circle "></i>
                        </button>
                    ) : null}
                </div>
            </div>
        </>
    );
};

const GenerateSOP = (props) => {
    let [submitState, setSubmitState] = useState({
        visible: false,
        taskId: '',

    })
    const deadlineEvents = {

        showSubmitModal: (data) => {
            setSubmitState({
                visible: true,

            })
        },
        hideSubmitModal: () => {
            setSubmitState({
                visible: false,

            })
        }
    }
    let dispatch = useDispatch();
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('studentId')
    const {getFieldDecorator, getFieldValue, setFieldsValue} = props.form;
    const [eduState, setEduState] = useState(eduStateInit);
    const [wrkState, setWrkState] = useState(workStateInit);
    const [countryList, setCountryList] = useState([]);
    const [courseList, setCourseList] = useState([]);
    const [universityList, setUniversityList] = useState([]);
    const [education, setEducation] = useState([eduStateInit]);
    const [workExp, setWorkExp] = useState([workStateInit]);
    const [templateData, setTemplateData] = useState({});
    const [randomSection, setRandomSection] = useState([]);
    const [generatePath, setGeneratePath] = useState({});
    const [requiredFields, setRequiredFields] = useState([]);
    const [sopId, setSopId] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [studentDatas, setStudentDatas] = useState({});
    const scrollToIds = (element) => {
        document.getElementById(element).scrollIntoView({
            behavior: 'smooth'
        })
    }
    const AllData = () => {
        setEducation([...education, eduStateInit]);
    };
    const deleteItem = (itemId) => {
        const updatedItems = education.filter((item, key) => key !== itemId);
        setEducation(updatedItems);
    };
    const AllWrkData = () => {
        setWorkExp([...workExp, workStateInit]);
    };
    const deleteItems = (itemId) => {
        const updatedItem = workExp.filter((item, key) => key !== itemId);
        setWorkExp(updatedItem);
    };

    const loadCountryFxn = async () => {

        let x = await dispatch(listAllUniversitiesAccTemplate());
        setCountryList(x);
    };

    const apiRequest = async (id) => {
        let params = {
            countryId: id
        }
        let x = await dispatch(listAllUniversitiesAccTemplate(params));
        setUniversityList(x)
    };
    const apiRequest1 = async (id) => {
        let params = {
            universityId: id
        }
        let x = await dispatch(listAllUniversitiesAccTemplate(params));
        setCourseList(x)
    };
    useEffect(() => {
        loadCountryFxn();
    }, []);

    const loadTemplateData = async (courseId) => {
        let obj = {};

        obj.countryId = getFieldValue("countryId");
        obj.universityId = getFieldValue("universityId");
        obj.courseId = courseId;

        let {data, success, requiredFields} = await templateSopFxn(obj);
            console.log(data, "drwedvdfgdf")
        if (success) {
            setTemplateData(data);
            setRandomSection(data.sections);
            setRequiredFields(requiredFields);
        }
    };
    const ExamConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') == 'I don\'t have this'))
    const Exam12ThConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') !== 'I don\'t have this'))
    const testLoginShow = (
        (getFieldValue('englishRequirments.examType') == 'PTE' || getFieldValue('englishRequirments.examType') == 'IELTS') && getFieldValue('countryId') == countryIds.australia
    )
    const showStreamField = (
        (getFieldValue('education.qualification') && (getFieldValue('education.qualification') == 'XII' || getFieldValue('education.qualification') == 'XII + Diploma'))
    )


    let inputTypes = {
        fields: [
            {
                key: "countryId",
                label: "Country",
                required: true,
                placeholder: "Country",
                type: "select",
                span: 12,
                options: countryList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.countryName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        countryId: x,

                    });
                    apiRequest(x);
                },
            },

            {
                key: "universityId",
                label: "University",
                required: true,
                placeholder: "University",
                type: "select",
                span: 12,
                options: universityList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.universityName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        universityId: x,
                    });
                    apiRequest1(x);
                },
            },

            {
                key: "courseId",
                label: "Course",
                required: true,
                placeholder: "Course",
                type: "select",
                span: 12,
                options: courseList,
                keyAccessor: (x) => x._id,
                valueAccessor: (x) => `${x.courseName}`,
                onChange: (x) => {
                    props.form.setFieldsValue({
                        courseId: x,
                    });
                    loadTemplateData(x);
                },
            },
        ],
        generateInputs: [
            {key: 'name', label: 'Name', required: true, placeholder: 'Enter Name'},

            {
                key: 'dateOfBirth', label: 'Date Of Birth',
                required: true, type: 'date',
                placeholder: 'Choose Date of Birth'
            },

            {key: 'passportNumber', label: 'Passport Number', placeholder: 'Enter Passport Number'},
            {
                key: 'gender', label: 'Gender',
                type: 'select',
                options: ['Male', 'Female'],
                required: true, onChange: x => {
                    props.form.setFieldsValue({
                        gender: x
                    })
                }
            },
            {
                key: 'maritalStatus', label: 'Marital Status',
                type: 'select', options: ['Single', 'Married'],
                required: true, onChange: x => {
                    props.form.setFieldsValue({
                        maritalStatus: x
                    })
                }
            },
            {
                key: 'dateOfMarriage', label: 'Marriage Date',
                required: true, type: 'date',
                placeholder: 'Marriage Date',
                hidden: !(
                  getFieldValue("maritalStatus") &&
                  getFieldValue("maritalStatus") == "Married"
                ),
            },

            {key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address'},
            {key: 'mobile', span: 5, label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No'},
            {key: 'email', label: 'Email', type: 'email', placeholder: 'Enter Email'},
        ],

        testInput: [
            {
                key: 'englishRequirments.examType',
                label: 'English Exam Type',
                type: 'select',
                allowClear: true,
                // required: getFieldValue('countryName') == 'India',
                showSearch: true,
                options: EnglishExamTypes,
                onChange: x => {
                    let obj = {
                        examType: x,
                        overall: '',
                        listening: '',
                        reading: '',
                        writing: '',
                        speaking: '',
                        english12ThMarks: ''
                    }
                    props.form.setFieldsValue({
                        englishRequirments: obj
                    })
                },
                span: 6
            },

            {
                key: 'englishRequirments.examinationDate',
                label: 'Examination Date',
                placeholder: 'Examination Date',
                type: 'date',
                hidden: ExamConditions,
                // required: !ExamConditions,
                span: 6
            },
            {
                key: 'englishRequirments.overall',
                label: 'Overall',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                // required: !ExamConditions,
                placeholder: 'Overall'
            }
        ],
        testInputFields: [

            {
                key: 'englishRequirments.listening',
                label: 'Listening',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                // required: !ExamConditions,
                placeholder: 'Listening'
            },
            {
                key: 'englishRequirments.reading',
                label: 'Reading',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                // required: !ExamConditions,
                placeholder: 'Reading'
            },
            {
                key: 'englishRequirments.writing',
                label: 'Writing',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                // required: !ExamConditions,
                placeholder: 'Writing'
            },
            {
                key: 'englishRequirments.speaking',
                label: 'Speaking',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                // required: !ExamConditions,
                placeholder: 'Speaking'
            },
            {
                key: 'englishRequirments.english12ThMarks',
                label: 'English 12th Marks',
                type: 'number',
                placeholder: 'English 12th Marks',
                hidden: Exam12ThConditions,
                // required: !Exam12ThConditions,
                span: 6
            },
        ],
    };

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    const eduEvents = {
        updateState: (data, index) => {
            let cloneEdu = _.clone(education);
            cloneEdu[index] = {...cloneEdu[index], ...data};
            setEducation(cloneEdu);
        },
        addEdu: () => {
            if (education) {
                if (education.percentage < 1) {
                    notification.warning({
                        message: "Enter greater then 0 Percentage",
                    });
                    return;
                }
                if (education.qualification == "XII") {
                    if (education.math < 1) {
                        notification.warning({
                            message: "Enter greater then 0 Math score",
                        });
                        return;
                    }
                    if (education.english < 1) {
                        notification.warning({
                            message: "Enter greater then 0 English score",
                        });
                        return;
                    }
                }
            }
            setEducation((preState) => {
                return [...preState, eduState];
            });
            eduEvents.updateState(eduStateInit);
        },
    };

    const wrkEvents = {
        updateState: (data, index) => {
            let clonewrk = _.clone(workExp);
            clonewrk[index] = {...clonewrk[index], ...data};
            setWorkExp(clonewrk);
        },
        addEdu: () => {

            setWorkExp((preState) => {
                return [...preState, wrkState];
            });
            wrkEvents.updateState(workStateInit);
        },
    };
    const apiRequest2 = (params) => {
        return new Promise(async (resolve) => {
            let data = await sopListFxn({
                ...params,
                sortField: '_id',
                sortOrder: 'descend',
                regExFilters: [
                    "country",
                    "university",
                    "course",
                    "name",
                    "gender",
                    "maritalStatus",
                    "address",
                    "mobile",
                    "email",
                    "examType",
                ],
            });

            resolve(data);
        });
    };
    const handleSubmit = (e) => {
        const {form} = props;
        e.preventDefault();

        form.validateFields(async (err, valData) => {
            if (!err) {
                let refineFieldObj = await getEducationData({
                    education,
                    englishRequirement: valData.englishRequirments,
                });
                valData.countryName = templateData && templateData.countryName
                valData.universityName = templateData.universityId && templateData.universityId.universityName
                valData.courseName = templateData.courseId && templateData.courseId.courseName
                valData.stateName = templateData.stateId && templateData.stateId.stateName
                valData.courseDuration = templateData.courseId && templateData.courseId.courseDuration
                valData.templateId = templateData.templateId;
                valData.sections = randomSection
                valData.studentId = id
                valData.id = id
                valData.stateName = studentDatas.stateName
                valData.englishRequirement = valData.englishRequirments
                valData = {...valData, education, ...refineFieldObj};

                let findRequire = _.find(requiredFields, (item) => {
                    return !valData[item];
                });
                if (findRequire) {
                    let fieldName = S(findRequire).humanize().s;
                    notification.error({
                        message: `${fieldName} is empty. Please enter.`,
                    });
                    return;
                }
                let x = await dispatch(GenerateSOPFxn(valData));
                if (x && x.success) {
                    setSopId(x.sopId)
                    setSuccessMessage(x.message)
                    setGeneratePath(x)
                    // setEducation([eduStateInit])
                    loadStudentData(id)
                    // form.resetFields()
                    // form.setFieldsValue({
                    //     countryId:'',
                    //     universityId:'',
                    //     courseId:''
                    // })
                    setTimeout(() => {
                        deadlineEvents.showSubmitModal();
                    }, 2000);
                }
            } else {
                notification.warning({
                    message: "Fill All Required Fields",
                });
            }
        });
    };
    useEffect(() => {
        loadStudentData()
    }, [id])

    const loadStudentData = async (params) => {

        let data = await dispatch(getStudent(id))
        console.log(data, "datadatadatadatadata")
            setStudentDatas(data)

        setTimeout(() => {
            let obj = {}
            _.each(data, (item, key) => {
                obj[key] = item
            })
            setFieldsValue({name: obj.name})

            if (obj.dateOfBirth) {
                obj.dateOfBirth = moment(obj.dateOfBirth)
            }
            if (obj.dateOfMarriage) {
                obj.dateOfMarriage = moment(obj.dateOfMarriage)
            }
            console.log(obj.dateOfMarriage, "obj.dateOfMarriage")
            if (obj.englishRequirments && obj.englishRequirments.examinationDate) {
                obj.englishRequirments.examinationDate = moment(obj.englishRequirments.examinationDate)
            }
            delete obj.universityId;
            delete obj.countryId;
            setFieldsValue({...obj})
            setTimeout(() => {


                setFieldsValue({englishRequirments: obj.englishRequirments})
            }, 500)


        }, 500)

    }

    const handleClick = () => {
        window.open(`${apiUrl2}/unizView/${sopId}`, '_blank');
    };
    return (
        <>
            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off' onSubmit={handleSubmit}>

                        <div className='form-box mt-4'>
                            <>
                                <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                     id={'generalInformation'}>
                                    <h5>Generate SOP</h5>
                                </div>
                                <div className='card unizportal'>


                                    <Row gutter={24} className={'rowWrap'}>


                                        {inputTypes.fields.map((item, key) => {
                                            return (
                                              <>
                                                <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>

                                                </Col>

                                              </>
                                            )
                                        })}

                                    </Row>
                                    {
                                        templateData.templateId &&
                                        <a  style={{float:'right'}} className={"btn btn-primary btn-xs"}
                                            href={`${apiUrl2}/template/${templateData.templateId}`}>
                                            View sample
                                        </a>
                                    }
                                </div>
                            </>

                            <>
                                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                                     id={'uploadDocuments'}>
                                    <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span>
                                        Student Basic Information</h5>

                                </div>

                                <div className='card unizportal'>
                                    <div className='inner-form'>
                                        <Row gutter={24} className={'rowWrap'}>

                                            {inputTypes.generateInputs.map((item, key) => {
                                                return (
                                                    <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </Col>
                                                )
                                            })}


                                        </Row>
                                    </div>
                                </div>

                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                                         id={'uploadDocuments'}>
                                        <h5 style={{margin: 0}}>
    <span className='img'>
      <img src='/dist/img/check.svg' alt=''/>
    </span>
                                            Education
                                        </h5>
                                    </div>
                                    <Button type="primary" onClick={AllData} className="mb-3">
                                        Add Multiple Education
                                    </Button>
                                </div>


                                <div className='card unizportal'>
                                    <div className='inner-form'>

                                        <Row gutter={24}>

                                            {education && education.map((datas, index) => {
                                                return (
                                                    <EducationComponent
                                                        datas={datas}
                                                        key={index}
                                                        index={index}
                                                        education={education}
                                                        eduEvents={eduEvents}
                                                        deleteItem={deleteItem}
                                                    />
                                                );
                                            })}

                                        </Row>
                                    </div>
                                </div>


                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                                         id={'uploadDocuments'}>
                                        <h5 style={{margin: 0}}>
    <span className='img'>
      <img src='/dist/img/check.svg' alt=''/>
    </span>
                                            Work Experience
                                        </h5>
                                    </div>
                                    <Button type="primary" onClick={AllWrkData} className="mb-3">
                                        Add Work Experience
                                    </Button>
                                </div>


                                <div className='card unizportal'>
                                    <div className='inner-form'>

                                        <Row gutter={24}>

                                            {workExp.map((data, index) => {
                                                return (
                                                    <WrkExpComponent
                                                        data={data}
                                                        key={index}
                                                        index={index}
                                                        education={workExp}
                                                        wrkEvents={wrkEvents}
                                                        deleteItems={deleteItems}
                                                    />
                                                );
                                            })}

                                        </Row>
                                    </div>
                                </div>
                                <div className='heading-form stuHeader  d-flex align-items-center mt-4 mb-3'
                                     id={'uploadDocuments'}>
                                    <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span>
                                        Test Scores</h5>

                                </div>
                                <div className='card unizportal'>
                                    <div className='inner-form'>
                                        <Row gutter={24}>
                                            {inputTypes.testInput.map((item, key) => {
                                                return (
                                                    <Col span={item.span ? item.span : 8}
                                                         md={item.span ? item.span : 8}
                                                         sm={12} xs={24} key={key}
                                                         className={'mb10'}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                        <Row gutter={24}>
                                            {inputTypes.testInputFields.map((item, key) => {
                                                return (
                                                    <Col span={item.span ? item.span : 8}
                                                         md={item.span ? item.span : 8}
                                                         sm={12} xs={24} key={key}
                                                         className={'mb10'}>
                                                        <GetEachFormFields
                                                            item={item}
                                                            getFieldDecorator={getFieldDecorator}
                                                            formItemLayout={formItemLayout}/>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                    <button className='btn' type='submit'>save
                                    </button>
                                </div>
                            </>
                        </div>
                    </Form>
                    {submitState.visible ?
                    <Modal width={500} height={800}

                            visible={submitState.visible}
                            onCancel={deadlineEvents.hideSubmitModal}
                            footer={null}

                     >
                        <div className="sopModalRow">
                            <p className="successMessage">{successMessage}</p>
                            <div className="buttonContainer">
                                <button className="viewButton" onClick={handleClick}>View</button>
                                <button className="closeButton" onClick={deadlineEvents.hideSubmitModal}>Close</button>
                            </div>
                        </div>

                        </Modal> : null}
                </div>
            </div>
        </>
    );
};

export default Form.create()(GenerateSOP);
