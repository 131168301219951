import React, { useEffect } from 'react'
import { Button, Col, Modal, Form, notification, Row, Select } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  addUnizhomePolicyFxn,
  updatePropertyFaqFxn,
  updateRoomDescriptionFxn,
  updateUnizhomePolicyFxn
} from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import moment from 'moment'
import {
  CountryOptions, policyApplicableOptions,
  policyOptions,
  ProgramLevel,
  StudentType,
  StudentTypesFilter
} from '../../../components/_utils/appUtils'

const { Option } = Select

const EditRoomDescription = (props) => {

  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    form: { getFieldDecorator, setFieldsValue,getFieldValue },
    propertyId,
    roomId,
    policy,
    onSubmit,
  } = props


  let inputTypes = {
    fields: [


      {
        key: 'name',
        label: 'Name',
        placeholder: 'Add Name',
        type: 'text',
        span: 12,
      },
      {
        key: 'display_name',
        label: 'Display Name',
        placeholder: 'Add Display Name',
        type: 'text',
        span: 12
      },



      {
        key: 'value',
        label: 'Description',
        placeholder: 'Add Description',
        type: 'editor',
        required: true,
        rows: 4,
        span: 24
      }
    ]
  }
  const setDefaultValues = () => {
    setTimeout(() => {
      setFieldsValue({
        name: policy.name,
        display_name: policy.display_name || '',
        value: policy.value  || '',

      })
    }, 500)
  }
  useEffect(() => {
    setDefaultValues()
  }, [policy])

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {


        valData.policy_applicable = false;

        const modifiedData = {
          ...valData,
          propertyId,
          roomId,
        _id:  policy._id
        };

        const resp = await dispatch(updateRoomDescriptionFxn(modifiedData))
        if (resp && resp.success) {
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }



  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Edit Description`}
        width={'85%'}
        footer={null}>
        <div className='form-box commissionBox'>
          <div className='card'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={item.span ? item.span : 24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={{
                            labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                            wrapperCol: { xs: { span: 24 } }
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary' htmlType='submit' className={'btn mt40'}>
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const EditRoomDescriptionModal = Form.create()(EditRoomDescription)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditRoomDescriptionModal)
