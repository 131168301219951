import React, { useEffect, useState } from 'react'
import { Avatar, Button, Card, Drawer, Input, Select, DatePicker, notification } from 'antd'
import { displayDate, InputBox, nameAvatar } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import { addPropertyVisitFxn, propertyVisitListFxn } from './action'
import moment from 'moment'

const { TextArea } = Input
const { Option } = Select

const SingleComment = (props) => {
  let { comment } = props
  return (
    <>
      <div className='comment' key={comment._id}>
        <Avatar className={'textUpp avatar_text'} size='large'>
          {nameAvatar(comment.userId.name)}
        </Avatar>

        <div className='comment-text'>
          <p className='comment-author'>
            <span className={'name'}>{comment.userId.name}</span>
            <span className={'date'}>{displayDate(comment.date)}</span>
          </p>
          <div className={'mb5'}>
            {comment.purpose}
          </div>
          <div className={'time-span'}>
            {displayDate(comment.date)}
          </div>
        </div>

      </div>
    </>
  )
}

const ComponentListDrawer = (comments) => {
  return (
    <>
      <div id={'dd'}>
        {comments && comments.data.length ? comments.data.map((comment, index) => {
          return (
            <SingleComment comment={comment} />
          )
        }) : null}
      </div>
    </>
  )
}


const PropertyVisitDrawer = (props) => {
  let dispatch = useDispatch()
  let { onClose, visible, taskId, task, onSubmit } = props
  let [propertyVisitList, setPropertyVisitList] = useState([])
    let [date, setDate] = useState('')
    let [purpose, setPurpose] = useState('')

  useEffect(() => {
    events.loadPropertyVisitList()
  }, [taskId])
  let events = {
    handlePropertyVisitSave: async () => {
      if (taskId) {
        if (!date) {
          return notification.warning({ message: 'Please choose date.' })
        }
        if (!purpose) {
          return notification.warning({ message: 'Please enter purpose.' })
        }
        let resp = await dispatch(addPropertyVisitFxn({ date, purpose, taskId }))
        if (resp && resp.success) {
          setDate('')
          setPurpose('')
          onSubmit()
          events.loadPropertyVisitList()
        }
      }
    },
    loadPropertyVisitList: async () => {
      let { data } = await dispatch(propertyVisitListFxn({ taskId }))
      if (data && data.propertyVisits && data.propertyVisits.length) {
        setPropertyVisitList(data.propertyVisits)
      }
    }
  }


  return (
    <Drawer
      title={`Add Property Visit`}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={visible}
      width='40%'
      style={{ transition: 'all 0.3s' }}
    >
      <div className={'card unizportal'} style={{ padding: '10px 20px' }}>
        <Card title=''>
          <div className='row'>
            <div className='col-md-12'>
              <InputBox title={'Property Visit Date'}>
                <DatePicker
                  selected={date}
                  value={date}
                  onChange={(value) => {
                    setDate(value)
                  }}
                />
              </InputBox>
              <InputBox title={'Purpose of Visit'}>
                <TextArea
                  rows={4}
                  placeholder='Enter purpose of visit'
                  className='form-control'
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                />
              </InputBox>
            </div>
            <div className={'col-md-12 alignRight'}>
              <Button
                className='btn btn-primary roundNew md'
                onClick={() => events.handlePropertyVisitSave()}>
                Submit
              </Button>
            </div>
          </div>
        </Card>
        <Card title={'Property Visit List'} className={'mt10'}>
          <div className={'todo-comments-box'}>
            {propertyVisitList && propertyVisitList.length ? <ComponentListDrawer data={propertyVisitList} /> : null}
          </div>
        </Card>

      </div>
    </Drawer>
  )
}
export default PropertyVisitDrawer
