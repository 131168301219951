import React, { useState, useEffect } from 'react'
import { Button, Drawer, Table, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { appliedEventsListFxn } from '../action'
import { getUrlPushWrapper } from '../../../routes/routes'
import { ShowExportOption } from '../../dashboard/views/DashboardUserWise'
import { newFormatDisplayDate } from '../../../components/_utils/appUtils'

const StudentDrawer = (props) => {
  const { visible, onClose, eventId } = props
  const dispatch = useDispatch()
  const [students, setStudents] = useState([])
  const [totalStudent, setTotalStudent] = useState('')

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let resp = await dispatch(appliedEventsListFxn({ ...params, eventId }))
      setTotalStudent(resp.total)
      resolve(resp)
    })
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (item, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Name',
      dataIndex: 'studentId.name',
      key: 'name',
      width: 200
    },
    {
      title: 'DOB',
      dataIndex: 'studentId.dateOfBirth',
      key: 'dateOfBirth',
      width: 100,
      render: (val, record) => {
        return (
          <div>
            {newFormatDisplayDate(val)}
          </div>
        )
      }
    },
    {
      title: 'Mobile',
      dataIndex: 'studentId.mobile',
      key: 'mobile',
      render: (item, record) => {
        let { studentId } = record
        return (
          <>
            {studentId && studentId.countryCode ? `${studentId.countryCode}-` : ''} {item}
          </>
        )
      }
    },
    {
      title: 'Email',
      dataIndex: 'studentId.email',
      key: 'email'
    },
    {
      title: 'State',
      dataIndex: 'studentId.stateName',
      key: 'stateName'
    },
    {
      title: 'City',
      dataIndex: 'studentId.cityName',
      key: 'cityName'
    }

  ]

  return (
    <Drawer
      title={`Students (Total - ${totalStudent})`}
      placement='right'
      onClose={onClose}
      visible={visible}
      width={'65%'}>
      <div className='card'>
        <div className='card-body table-responsive'>
          <TableComp
            columns={columns}
            apiRequest={apiRequest}
          />
        </div>
      </div>

    </Drawer>
  )
}

export default StudentDrawer
