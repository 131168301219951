import React, {useEffect, useState} from "react"
import {studentDiversityFxn} from "../actions/student";
import {useDispatch} from "react-redux";
import {Row, Col, Select, Statistic, Card} from "antd";
import { filterOption, InputBox, currentUdaan } from '../../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../../routes/routes'
import { CountryCodeFlagJson } from '../../../components/_utils/countryUtil'
import _ from "lodash"
import Styles from "./styles.less"
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";

import moment from "moment";

let {Option} = Select
let options = [
    {name: "Country Wise", value: "countryWise"},
    {name: "Within India State Wise", value: "stateWise"},
]
const colors = {
    "Canada": "#F3CD01",
    "UK": "#ff0066",
    "USA": "#0A3161",
}

const ChartComponent = (props) => {
    let {record, country} = props;
    let [data, setData] = useState([])
    let setChartData = () => {
        let dataArr = []
        _.each(record, (item) => {
            if (item && item.count) {
                dataArr.push({
                    name: item.countryId.countryName,
                    value: item.count,
                    totalFees: item.totalFees,
                    totalVisa: item.totalVisa
                })
            }
        })
        setData(dataArr)
    }
    useEffect(() => {
        setChartData()
    }, [record])

    const CustomTooltip = ({active, payload, label}) => {
        if (active) {
            let finalPayload = payload && payload.length ? payload[0].payload : {}

            let value = finalPayload && finalPayload.value ? finalPayload.value : 0
            let totalVisa = finalPayload && finalPayload.totalVisa ? finalPayload.totalVisa : 0
            let totalFees = finalPayload && finalPayload.totalFees ? finalPayload.totalFees : 0
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{label}</p><br/><br/>
                    <div className='count'>{`Total Student : ${value}`}</div>
                    <div className='count'>{`Total Fees : ${totalFees}`}</div>
                    <div className='count'>{`Total Visa : ${totalVisa}`}</div>
                </div>
            )
        }
        return null
    }


    return (
        <>
            <ResponsiveContainer height={150} width={"90%"}>
                <BarChart
                    // width={250}
                    data={data}
                >
                    <CartesianGrid strokeDasharray='2 2'/>
                    <XAxis dataKey="name"/>
                    <YAxis width={40} ticks={[0, 5, 10, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]}/>

                    <Tooltip content={<CustomTooltip/>}/>
                    {/*<Legend layout='horizontal'/>*/}

                    <Bar dataKey="value" fill="#49457B" barSize={5}/>
                    <Bar dataKey="totalVisa" fill="#FF7C78" barSize={5}/>
                    <Bar dataKey="totalFees" fill="#FFC107" barSize={5}/>


                    {/*
                    <Bar dataKey='value' barSize={5}>
                        {data && data.length ? data.map((entry, index) => (
                            <Cell key={`cell-${index}-${moment()}`} fill={colors[entry.name]}/>
                        )) : null}
                    </Bar>*/}
                </BarChart>

            </ResponsiveContainer>

            {/*  <ul className={'barLabel_ul'}>
                {data && data.length ? data.map((entry, index) => (
                    <li key={`label-${index}`}>
                        <div className={'colorBox'} style={{background: colors[index]}}></div>
                        {entry.name}
                    </li>
                )) : null}
            </ul>*/}

        </>
    )
}


const SingleBlock = (props) => {
    let {item, filter} = props;
    let dispatch = useDispatch();
    let {records, country} = item;
    let RenderLabel = (item) => {
        let findCountryFlag = _.find(CountryCodeFlagJson, (eachV) => {
            return eachV.name.match(new RegExp(item._id, 'ig'));
        })
        return (
            <div style={{fontSize: 14, color: "#555", fontWeight: 600, marginBottom: 15}}>
                {item._id && findCountryFlag && findCountryFlag.flag ?
                    <img src={findCountryFlag.flag} style={{height: 25, marginRight: 10}}/> : null}
                {item._id ? item._id : "Unknown"} {/*(Total - {item.total})*/} (Total - {item.totalStudent})
            </div>
        )
    }
    let openPage = (value, countryId) => {
        let obj = {
            countryId,
            udaan: currentUdaan
        }
        if (value) {
            if (filter == 'stateWise') {
                obj.stateName = value
            } else {
                obj.countryName = value
            }
        }
        dispatch(
            getUrlPushWrapper('allStudent', {
                ...obj
            }))
    }
    return (
        <>
            <div>
                {RenderLabel(item)}
                <ChartComponent record={records} country={country}/>
            </div>
        </>
    )
}


const StudentDiversity = (props) => {
    let dispatch = useDispatch();
    let [filter, setFilter] = useState("countryWise")
    let [state, setState] = useState([])
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null

    let loadData = async () => {
        let {data} = await dispatch(studentDiversityFxn({diversityType: filter}))
        setState(data)
    }
    useEffect(() => {
        loadData()
    }, [filter])


    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>Student Records</h5>
                        </div>
                        <div className='card-body table-responsive'>
                            <>
                                {user.userType == "admin" ?
                                    <Row gutter={12} className={'filter_box'}>
                                        <Col span={4}>
                                            <InputBox title={'Choose Diversity'}>
                                                <Select
                                                    name="Choose Diversity"
                                                    filterOption={filterOption}
                                                    placeholder={'Choose Diversity'}
                                                    onChange={item => {
                                                        setFilter(item)
                                                    }}
                                                    value={filter || undefined}>
                                                    {options && options.length
                                                        ? options.map((item, key) => {
                                                            return (
                                                                <Option value={item.value} key={key}>
                                                                    {item.name}
                                                                </Option>
                                                            )
                                                        })
                                                        : null}
                                                </Select>
                                            </InputBox>
                                        </Col>
                                    </Row> : null}

                                <Card className={'mt20'}>
                                    <div className={'mt20'}>
                                        <ul className={'barLabel_ul'}>
                                            {Object.keys(colors).map((entry, index) => (
                                                <li key={`label-${index}`} className={'lg'}>
                                                    <div className={'colorBox'}
                                                         style={{background: colors[entry]}}></div>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <Row gutter={12} className={'mt10'}>
                                        {state && state.length ? state.map((item) => {
                                            return (
                                                <Col key={item._id} span={6}>
                                                    <Card className={'mb10'}>
                                                        <SingleBlock item={item} filter={filter}/>
                                                    </Card>
                                                </Col>
                                            )
                                        }) : null}
                                    </Row>
                                </Card>


                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StudentDiversity
