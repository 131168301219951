import React from 'react'
import { Button, Col, Modal, Form, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addUnizhomePolicyFxn, updatePropertyFaqFxn, updateUnizhomePolicyFxn } from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import {
  policyOptions
} from '../../../components/_utils/appUtils'

const AddPaymentPolicy = (props) => {

  let dispatch = useDispatch()
  let {
    visible,
    onClose,
    form: { getFieldDecorator, setFieldsValue,getFieldValue },
    propertyId,
    policy,
    onSubmit,
    policyType
  } = props
  let inputTypes = {
    fields: [
      {
        key: 'type',
        label: 'Type',
        type: 'select',
        required: true,
        options: policyOptions,

        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        span: 12,
        onChange: x => {
          setFieldsValue({
            type: x
          });
          if (x == 'payment' || x == 'cancellation_policy') {
            setFieldsValue({
              name: x
            });

          } else {
            setFieldsValue({
              name: ''
            });
          }

        },

      },

      {
        key: 'name',
        label: 'Name',
        placeholder: 'Add Name',
        type: 'text',
        span: 12,
      },
      {
        key: 'display_name',
        label: 'Display Name',
        placeholder: 'Add Display Name',
        type: 'text',
        span: 24,
      },


      {
        key: 'short_text',
        label: 'Short Description',
        placeholder: 'Short Description',
        hidden: !(getFieldValue('type') != 'Description'),
        type: 'editor',

        span: 12
      },
      {
        key: 'value',
        label: 'Description',
        placeholder: 'Add Description',
        type: 'editor',
        required: true,
        rows: 4,
        span: !(getFieldValue('type') != "Description") ? 24 : 12
      }
    ]
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        if (valData.name === 'payment' || valData.name === 'cancellation_policy') {
          valData.policy_applicable = true;
        } else {
          valData.policy_applicable = false;
        }
        const modifiedData = {
          ...valData,

          propertyId
        };
      console.log(modifiedData, "modifiedData")


        const resp = await dispatch(addUnizhomePolicyFxn(modifiedData))
        if (resp && resp.success) {
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }



  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Add Policy`}
        width={'85%'}
        footer={null}>
        <div className='form-box commissionBox'>
          <div className='card'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={item.span ? item.span : 24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={{
                            labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                            wrapperCol: { xs: { span: 24 } }
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary' htmlType='submit' className={'btn mt40'}>
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const AddPaymentPolicyModal = Form.create()(AddPaymentPolicy)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentPolicyModal)
