import { apiUrl, apiUrl2 } from '../../../settings'

export const allCommissionListUrl = () => {
  return apiUrl + '/api/commissionList'
}

export const agentCommissionCountUrl = () => {
  return apiUrl + '/api/agentCommissionCount'
}

export const getCurrentBalanceUrl = () => {
  return apiUrl + '/api/getCurrentBalance'
}
export const payCommissionUrl = () => {
  return apiUrl + '/api/payCommission'
}
export const commissionInvoiceListUrl = () => {
  return apiUrl2 + "/invoiceCommission/getAll";
};
export const commissionInvoiceListByStudentUrl = () => {
  return apiUrl2 + "/pendingLOAFCMTApplications";
};
