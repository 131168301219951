import React from 'react'
import { useState } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import { useDispatch } from 'react-redux'

import { useRef } from 'react'
import { Button, Col, Drawer, Form, Icon, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useEffect } from 'react'
import moment from 'moment'

import { push } from 'connected-react-router'
import { commissionInvoiceListFxn } from '../actions'
import { displayDate } from '../../../components/_utils/appUtils'
import { templateListFxn } from '../../SOP/template/action/template'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import AddCourseCategory from '../../CourseCategory/views/add'
import CourseCategory from '../../course/drawers/courseCategory'
import CommissionInvoiceDrawer from '../commissionInvoiceDrawer'


let initalState = {
  topBanner: '',
  registrationBanner: '',
  chiefGuestProfile: ''
}

const CommissionInvoiceList = (props) => {

  const { form: { getFieldDecorator, getFieldValue }, user } = props
  const [addEventVisible, setAddEventVisible] = useState(false)
  const [editEventVisible, seteditEventVisible] = useState(false)
  const [state, setState] = useState(initalState)
  const [viewVisible, setViewVisible] = useState(false)
  const [editEventId, seteditEventId] = useState('')
  const [allApplication, setAllApplication] = useState([])
  const [editEvent, seteditEvent] = useState([])
  const [galleryState, setGalleryState] = useState({
    visible: false,
    eventId: '',
    title: ''
  })
  const [leadState, setLeadState] = useState({
    visible: false,
    eventId: '',
    name: ''
  })
  let [invoiceDrawer, setInvoiceDrawer] = useState({

    visible: false
  })

  const dispatch = useDispatch()
  const tableRef = useRef()

  const apiRequest = (params) => {

    return new Promise(async (resolve) => {
      let {data} = await commissionInvoiceListFxn({
        ...params,
      });
      setAllApplication(data.data)
      resolve({data:data && data.data});
    });
  };




  const columns = [
    {
      title: 'Serial No',
      key: 'Serial No',
      dataIndex: 'serialNo',
      render: (ite, record, index) => {
        return (
          <React.Fragment>
            {index + 1}
          </React.Fragment>
        )
      }
    },
    {
      title: "Month/Year",
      dataIndex: "intake",
      key: "intake",
      render: (item, record) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthName = months[record.intake.month - 1];

        return (
          <>
            {monthName}, {record.intake.year}
          </>
        );
      }
    },
    {
      title: "Invoice Date",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      render: (item, record) => {

        return (
          <>
            {displayDate(item)}
          </>
        )
      }
    },
    {
      title: "Added By",
      dataIndex: "InvoiceDate",
      key: "InvoiceDate",
      render: (item, record) => {

        return (
          <>
            {record.addedBy.name}
          </>
        )
      }
    },
    {
      title: "Action",
      dataIndex: "invoice",
      key: "invoice",
      render: (item, record) => {

        return (
          <>
            {record && record.invoice.path &&
            <div className='sort-box-table'>

              <a className={'roundBtn'} href={record.invoice.path} target={'_blank'}>
              Download Invoice
              </a>
            </div>

            }

          </>
        )
      }
    },
  ];

  const showInvoiceDrawer = () => {
    setInvoiceDrawer({
      visible: true
    })
  }
  const hideInvoiceDrawer = () => {
    setInvoiceDrawer({
      visible: false
    })
    tableRef.current.reload()
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Commission Invoice List: </h5>

                <div className='sort-box-table mark-btn row mark-btn70'>
                  <div>
                    <a className='btn' onClick={() => showInvoiceDrawer()} >
                Generate Invoice</a>
                  </div>

                </div>


            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}



              />
              {invoiceDrawer.visible ?
                <CommissionInvoiceDrawer
                  {...invoiceDrawer}
                  allApplication={allApplication}
                  onClose={() => {
                    hideInvoiceDrawer()
                  }}
                />
                : null}

            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default Form.create()(CommissionInvoiceList)
