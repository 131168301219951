import React from 'react'
import AddEvent from './AddEvent'
import { useState } from 'react'
import { DefaultTablePagination, displayDate, displayTime } from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { DownloadLink } from '../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import InputBox from '../../components/_utils/InputBox'
import GetEachFormFields from '../../components/_utils/appFormUtils'
import { useRef } from 'react'
import { SingleEventsFxn, listAllEvents, updateEventFxn, updateEventStatus, updateRegistrationStatus } from './action'
import { Button, Col, Drawer, Form, Icon, Input, Modal, notification, Popconfirm, Row, Tooltip } from 'antd'
import { useEffect } from 'react'
import moment from 'moment'
import DownloadLinkComponent from '../../components/_utils/DownloadLinkComponent'
import ViewDrawer from './ViewDrawer'
import EventGallery from './eventGallery'
import { getUrlPushWrapper } from '../../routes/routes'
// let initalState = ({
//     topBanner: {},
//     registrationBanner: {},
//     chiefGuestProfile:{}
// })

let initalState = {
  topBanner: '',
  registrationBanner: '',
  chiefGuestProfile: ''
}

const EventList = (props) => {
  const { form: { getFieldDecorator, getFieldValue }, user } = props
  const [addEventVisible, setAddEventVisible] = useState(false)
  const [editEventVisible, seteditEventVisible] = useState(false)
  const [state, setState] = useState(initalState)
  const [viewVisible, setViewVisible] = useState(false)
  const [editEventId, seteditEventId] = useState('')
  const [editEvent, seteditEvent] = useState([])
  const [galleryState, setGalleryState] = useState({
    visible: false,
    eventId: '',
    title: ''
  })
  const [leadState, setLeadState] = useState({
    visible: false,
    eventId: '',
    name: ''
  })


  const dispatch = useDispatch()
  const tableRef = useRef()
  const events = {
    hideDrawer: () => {
      setAddEventVisible(false)
      //   tableRef.current.reload()
    },
    EditDrawer: () => {
      seteditEventVisible(false)
      //   tableRef.current.reload()
    },
    showDrawer: (id) => {
      seteditEventVisible(true)
      seteditEventId(id)
      getSingleEditEvent(id)
    },
    onClose: () => {
      setViewVisible(false)
    },
    reload: () => {
      tableRef.current.reload()
    },
    showEventLeadList: (record) => {
      setLeadState({
        visible: true,
        eventId: record._id,
        name: record.name
      })
    },
    hideEventLeadList: () => {
      setLeadState({
        visible: false,
        eventId: '',
        name: ''
      })
    },
    shareLink: (data) => {
      if (data.registration) {
        let url = `https://unizportal.com/singleWebinar?_id=${data._id}`
        navigator.clipboard.writeText(url)
        notification.success({ message: 'Event share link copied successfully.' })
      } else {
        Modal.warning({
          title: 'Share Event Link',
          content: 'Event Registration closed, are you sure you want to share direct access link? ',
          width: '40%',
          okText: 'Copy',
          cancelText: 'Cancel',
          onOk: () => {
            let url = `https://unizportal.com/singleWebinar?_id=${data._id}&access=1`
            navigator.clipboard.writeText(url)
            notification.success({ message: 'Event share link copied successfully.' })
          }
        })
      }
    }

  }
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      /*  params.sortField = "date"
        params.sortOrder = "ascend"*/
      params.sortField = '_id'
      params.sortOrder = 'descend'
      resolve(dispatch(listAllEvents({
        ...params,
        regExFilters: ['name', 'location', 'eventType', 'chiefGuestName']
      })))
    })
  }

  const handleSubmit = (e) => {
    const { form } = props
    e.preventDefault()
    form.validateFields(async (err, valData) => {
      if (!err) {
        valData.eventId = editEventId
        let fd = new FormData()
        fd.append('obj', JSON.stringify(valData))
        if (state.topBanner && state.topBanner.name) {
          fd.append('topBanner', state.topBanner)
        }
        if (state.registrationBanner && state.registrationBanner.name) {
          fd.append('registrationBanner', state.registrationBanner)
        }
        if (state.chiefGuestProfile && state.chiefGuestProfile.name) {
          fd.append('chiefGuestProfile', state.chiefGuestProfile)
        }

        let data = await dispatch(updateEventFxn(fd))
        if (data && data.success) {
          events.EditDrawer()
          events.reload()
        }

      }
    })
  }

  const getSingleEditEvent = async (id) => {
    let data = await dispatch(SingleEventsFxn(id))
    seteditEvent(data)
    setTimeout(() => {
      props.form.setFieldsValue({
        name: data.name,
        details: data.details,
        designation: data.designation,
        eventType: data.eventType,
        chiefGuest: data.chiefGuest,
        date: moment(data.date),
        time: moment(data.time)
      })
      setTimeout(() => {
        props.form.setFieldsValue({
          location: data.location
        })
      }, 200)
    }, 200)
  }

  const EventStatus = async (id) => {
    let resp = await dispatch(updateEventStatus({ eventId: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const registrationStatusFxn = async (id) => {
    let resp = await dispatch(updateRegistrationStatus({ eventId: id }))
    if (resp && resp.success) {
      tableRef.current.reload()
    }
  }

  const chooseDocument = (e) => {
    let { name, files } = e.target
    if (files && files.length) {
      setState({
        ...state,
        [name]: files[0]
      })
    }
  }

  const galleryEvent = {
    showGalleryState: (data) => {
      setGalleryState({
        visible: true,
        eventId: data._id,
        title: data.name
      })
    },
    hideGalleryState: () => {
      setGalleryState({
        visible: false,
        eventId: '',
        title: ''
      })
    }
  }

  const columns = [
    {
      title: 'S.no',
      dataIndex: 'sno',
      width: 50,
      key: 'sno',
      render: (item, record, index) => {

        return <div style={{}}>{index + 1}</div>

      }
    },
    {
      title: 'Title',
      dataIndex: 'name',
      width: 200,
      key: 'name'
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 100,
      key: 'location'
    },
    {
      key: 'eventType',
      label: 'Event Type',
      type: 'select',
      width: 80
    },
    {
      key: 'chiefGuest',
      // title: 'Chief Guest',
      // dataIndex:'chiefGuest',
      width: 100,
      render: (record, item) => {
        return (
          <>
            <div>
              {item.chiefGuest} <br />
              {item.designation}
            </div>
          </>
        )
      }
    },


    // {
    //     title: 'Details',
    //     dataIndex: 'details',
    //     width: 50,
    //     key: 'details',
    // },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 100,
      key: 'date',
      render: (record, item) => {
        return (
          <>
            {displayDate(item.date)}<br />
            {displayTime(item.time)}
          </>
        )
      }
    },


    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      key: 'status',
      render: (item, record) => {
        return (
          <div style={{ width: '100px' }}>
            <div>
              <Popconfirm
                title={`Are your sure, you want to ${item ? 'Non-Active' : 'Active'} this status?`}
                onConfirm={() => {
                  EventStatus(record._id)
                }}
                okText='Yes' cancelText='No'>
                {item ? <a className={'btn btn-primary btn-xs'}>Active</a> :
                  <a className={'btn btn-danger btn-xs'}>Non-Active</a>}
              </Popconfirm>
            </div>
          </div>
        )
      }
    },

    {
      title: ' Registration',
      dataIndex: 'registration',
      width: 100,
      key: 'registration',
      render: (item, record) => {
        return (
          <div>
            <Popconfirm
              title={`Are your sure, you want to ${item ? 'Close' : 'Open'} this status?`}
              onConfirm={() => {
                registrationStatusFxn(record._id)
              }}
              okText='Yes' cancelText='No'>
              {item ? <a className={'btn btn-success-green btn-xs'}>Open</a> :
                <a className={'btn btn-danger btn-xs'}>Close</a>}
            </Popconfirm>
          </div>
        )
      }
    },
    {
      title: ' Action',
      dataIndex: 'action',
      width: 100,
      render: (text, record) => {

        return (
          <div>
            <Tooltip title={' Edit Application'}>
              <button
                onClick={() => events.showDrawer(record._id)}
                className={'btn'}>
                <Icon type={'edit'} />
              </button>

            </Tooltip>

            {/*   <Tooltip title='Delete'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'delete'} />
              </button>
            </Tooltip>*/}

            <Tooltip title='View'>
              <button
                className={'btn'}
                style={{ marginRight: 6 }}>
                <Icon type={'eye'}
                      onClick={() => events.showEventLeadList(record)} />
              </button>
            </Tooltip>
            <Tooltip title='Gallery'>
              <button className={'btn'}
                      style={{ marginRight: 6 }}>
                <Icon type={'file-image'}
                      onClick={() => galleryEvent.showGalleryState(record)} />
              </button>
            </Tooltip>

            {user && (user.userType == 'admin' || user.userType == 'userManager') ?
              <Tooltip title='Share'>
                <button className={'btn'}
                        style={{ marginRight: 6 }}>
                  <Icon type={'share-alt'}
                        onClick={() => events.shareLink(record)} />
                </button>
              </Tooltip> : null}
          </div>

        )
      }
    }
  ]

  let inputTypes = {
    fields: [
      { key: 'name', label: 'Title', type: 'text', required: true },

      {
        key: 'eventType',
        label: 'Event Type',
        type: 'select',
        options: ['Zoom', 'Face To Face'],
        required: true,
        span: 12,
        onChange: x => {
          props.form.setFieldsValue({
            eventType: x
          })
        }
      },
      { key: 'date', label: 'Date', type: 'date', required: true, span: 6 },
      { key: 'time', label: 'Time', type: 'time', required: true, span: 6 },
      {
        key: 'zoomMeeting',
        label: 'Zoom Meeting Details',
        type: 'textArea',
        className: 'textAreaHeight',
        span: 24,
        rows: 7,
        required: true,
        hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Face To Face')
      },
      {
        key: 'location', label: 'Location', type: 'text', required: true,
        hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom')
      },

      //   {
      //     key: 'showReference',
      //     label: 'show Marketing User Reference',
      //     required: true,
      //     hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom'),
      //     span: 12,
      //     type: 'select',
      //     options: [{name: 'Yes', value: true}, {name: 'No', value: false}],
      //     keyAccessor: x => x.value,
      //     valueAccessor: x => `${x.name}`,
      //     onChange: x => {
      //       props.form.setFieldsValue({
      //         showReference: x
      //       })
      //       console.log(x)

      //     }
      //   },
      //   {
      //     key: 'showAdditionalMembers',
      //     label: 'Show Additional Members accompanying ',
      //     required: true,
      //     hidden: !(getFieldValue('eventType') && getFieldValue('eventType') !== 'Zoom'),
      //     span: 12,
      //     type: 'select',
      //     options: [{name: 'Yes', value: true}, {name: 'No', value: false}],
      //     keyAccessor: x => x.value,
      //     valueAccessor: x => `${x.name}`,
      //     onChange: x => {
      //       props.form.setFieldsValue({
      //         showAdditionalMembers: x
      //       })

      //     }
      //   },
      {
        key: 'chiefGuest',
        label: 'Chief Guest Name',
        type: 'text',
        width: 20,
        required: true,
        span: 12
      },
      {
        key: 'designation',
        label: 'Designation',
        required: true,
        span: 12,
        rows: 7
      },
      {
        key: 'details',
        label: 'Details',
        type: 'textArea',
        className: 'textAreaHeight',
        span: 24,
        rows: 7

      },
      {
        label: 'Top Banner',
        span: 12,
        required: true,
        customField: (
          <InputBox title={'Top Banner'} extra={'(1920px × 1080px)'}>
            <Input type={'file'} name={'topBanner'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
            {/* <DownloadLinkComponent file={state.topBanner}/>  */}
          </InputBox>
        )
      },
      {
        label: 'Registration Banner',
        span: 12,
        customField: (
          <InputBox title={'Registration Banner'} extra={'(1048px × 1280px)'}>
            <Input type={'file'} name={'registrationBanner'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
            {/* <DownloadLinkComponent file={state.registrationBanner}/>  */}
          </InputBox>
        )
      },
      {
        label: 'Chief Guest Profile',
        span: 24,
        customField: (
          <InputBox title={'Chief Guest Profile'}>
            <Input type={'file'} name={'chiefGuestProfile'}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseDocument(e)
                   }} />
            {/* <DownloadLinkComponent file={state.chiefGuestProfile}/>  */}
          </InputBox>
        )
      }

    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }
  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>All Events: </h5>
              <div className='search-box-table'>

              </div>

              <div className='sort-box-table mark-btn'>
                {user && (user.userType == 'admin' || user.userType == 'userManager') ?
                  <button className='btn' onClick={() => setAddEventVisible(true)}>
                    <img src={'../dist/icons/plus.png'} className={'plus'} />
                    Add Event
                  </button> : null}
              </div>
            </div>
            <div className='card-body table-responsive'>
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         ref={tableRef}
                         pagination={DefaultTablePagination()}


              />
            </div>
          </div>
        </div>
        <Drawer
          visible={editEventVisible}
          width={'75%'}
          placement='right'
          onClose={events.EditDrawer}
          title={'Edit Event'}>
          <div className='row'>
            <div className='col-lg-12'>
              <Form onSubmit={handleSubmit}>
                <div className='form-box'>

                  <div className='card unizportal'>
                    <Row gutter={24}>
                      {inputTypes.fields.map((item, key) => {
                        return (
                          item.customField && !item.hidden ?
                            <Col span={item.span} md={item.span} sm={item.span} xs={24}
                                 key={item.label}>
                              {item.customField}
                            </Col> :
                            <>
                              <Col span={item.span ? item.span : 24} key={key}>
                                <GetEachFormFields
                                  item={item}
                                  formItemLayout={formItemLayout}
                                  getFieldDecorator={getFieldDecorator}
                                />

                              </Col>
                            </>
                        )
                      })}
                    </Row>
                    <Form.Item>
                      <Button type='primary' htmlType='submit' className='btn'>
                        SAVE
                      </Button>
                    </Form.Item>

                  </div>
                </div>

              </Form>
            </div>
          </div>
        </Drawer>
      </div>
      {leadState.visible ? <ViewDrawer
          {...leadState}
          events={events} />
        : null}
      {addEventVisible ? <AddEvent visible={addEventVisible} events={events} onSubmit={() => {
        events.hideDrawer()
        events.reload()
      }} /> : ''}
      {galleryState.visible ?
        <EventGallery
          {...galleryState}
          onClose={galleryEvent.hideGalleryState}
          onSubmit={() => {
            galleryEvent.hideGalleryState()
            events.reload()
          }} /> : ''
      }
    </>
  )
}

export default Form.create()(EventList)
