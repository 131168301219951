import { apiUrl, apiUrl2 } from "../../../settings"

export const addUnizhomeContractUrl = () => {
    return apiUrl2 + '/unizhome-contract/add'
}

export const updateUnizhomeContractUrl = () => {
    return apiUrl2 + '/unizhome-contract/update'
}

export const unizhomeContractListUrl = () => {
    return apiUrl2 + '/unizhome-contract/list'
}

export const unizhomeContractNoteUrl = () => {
    return apiUrl2 + '/unizhome-contract/add-note'
}

export const unizhomeNoteListUrl = () => {
    return apiUrl2 + '/unizhome-contract/notes'
}

export const unizhomeActivityUrl = () => {
    return apiUrl2 + '/unizhome-contract/activities'
}

export const getSingleContractUrl = () => {
    return apiUrl2 + '/unizhome-contract'
}

export const addFormFieldsUrl = () => {
    return apiUrl2 + '/unizhome-contract/addFormFields'
}
export const getNewFormFieldsUrl = () => {
    return apiUrl2 + '/unizhome-contract/getNewFormFields'
}
