import React, { useEffect, useRef, useState } from 'react'
import {
    Select,
    Button,
    Tooltip,
    Icon,
    Popconfirm,
    Row,
    Col, notification, Form, Tag
} from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    allRoyalHighSchoolApplicationFxn,
    exportStudentRoyalArtsCourseFxn,
    getStudentRoyalArtsCourseFxn
} from '../../actions/chooseStudent'
import {
    newFormatDisplayDate,
    longDisplayDate,
    filterOption,
    Intakes,
    RoyalArtsCollege, fcmtRevokeStatus
} from '../../../../components/_utils/appUtils'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../../routes/routes'
import { TableComp } from 'sz-react-utils-lite'
import lodash from 'lodash'
import {
    AllowComponentRightsWise,
    CheckBranchUserFxn,
    CheckManagerWiseRight
} from '../../../WebComponent/allowComponentRightsWise'
import TransferApplicationDrawer from '../../views/transferApplication'
import ApplyCourse from '../../../student/views/applyForApplicationDrawer'
import {
    applicationCloseDirect,
    reCalculateCommission, updateApplicationTuitionFees, moveToDraftApplication
} from '../../../student/actions/student'
import moment from 'moment'
import StudentOtherApp from '../StudentOtherApplication'
import { listAllUsers } from '../../../users/actions/user'
import ReActivateApplication from '../../drawers/reActivateApplicationDrawer'
import ChangeUniversityComponent from '../../drawers/changeUniversity'
import { showEnrollmentAction, countryIds } from '../../../../components/_utils/appUtils'
import Enrollment from '../../drawers/enrollment'
import AddNote from '../../drawers/createNoteComponent'
import LoaRequest from '../../drawers/loaRequest'
import FcmtStatusComponent from '../../drawers/fcmtStatus'
import ApprovedLoaRequest from '../../drawers/royalArtsApprovedLoaRequest'
import ChangeNameDrawer from '../../drawers/changeNameDrawer'
import {
    ReCreateFcmtOfferLetter,
    ReCreateFcmtLoaLetter,
    ReCreateFcmtPaymentReceipt,
    fcmtSendMailFxn,
    fcmtSendOfferMailFxn,
    fcmtSendReceiptMailFxn
} from '../../actions/chooseStudent'
import { listAllCampus } from '../../../campus/actions/campus'
import { CheckUserRight, ShowExportOption } from '../../../dashboard/views/DashboardUserWise'
import DocumentDrawer from '../../drawers/studentDocumentDrawer'
import GrKeyDrawer from '../../drawers/gcKeyDrawer'
import { FcmtNotesComponent, ShowFcmtNote } from '../../fcmt/fcmtNotesComponent'
import { allowReCreateOffer } from '../../../../components/_utils/utils'
// '6399fdef426246509da8a24b' kamal account
const hideAgentInfoForUsers = ['651c12e0b1cce568947d4eb9', '659fcfe1a7a863811db6c879']
const statusList = [
    'Offer Received',
    'Rqst LOA',
    'LOA Generated',
    'File pending for submission',
    'File Submitted',
    'Visa Approved',
    'Visa Rejected'
]
const { Option } = Select
const initialState = {
    applicationList: [],
    applicationObj: {}
}
const commissionObj = {
    commissionAmount: 0,
    tuitionFee: 0,
    studentId: '',
    applicationId: ''
}
let viewOnlyRight = CheckBranchUserFxn() // check branch user right

const RenderComment = (props) => {
    let { item } = props
    let [hideV, setHideV] = useState(true)
    return (
      item && <div>
          {item.addedByUserId && item.addedByUserId.name ?
            <div className={'alignRight colorPrimary font12'}>
                (Added By : {item.addedByUserId.name} )
            </div> : null}
          <div className={hideV ? 'appCommentDotsReplace' : ''}>
              {item && item.text ? item.text : ''}
          </div>
          {item && item.text.length && item.text.length > 50 ? <div className={'alignRight'}>
              {hideV ? <a onClick={() => {
                  setHideV(false)
              }}>Show More</a> : <a onClick={() => {
                  setHideV(true)
              }}>Show Less</a>}
          </div> : ''}

      </div>
    )
}
const OfferActionComponent = (props) => {
    let { file, reCreateOfferLetter, record, events } = props
    let { applications } = record
    return (
      <div>
          {file && file.path ?
            <Tooltip title={'Download Offer Letter'}>
                <a download={file.fileName} href={file.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'} />
                </a></Tooltip> : ''}


          <div className={'sprBox'}>
              {viewOnlyRight ? <>
                  {allowReCreateOffer(applications) ?
                    <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                                onConfirm={() => events.reCreateOfferLetter(record)}
                                okText='Yes' cancelText='No'>
                        <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
                    </Popconfirm> : null}
                  <div>
                      <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                          {applications.offerSent != false ?
                            null :
                            <Popconfirm title={'Are your sure, you want to Send Offer Letter?'}
                                        onConfirm={() => events.sendFcmtMail(record, 'offer')}
                                        okText='Yes' cancelText='No'>
                                <button className={`bs_btn bs-default mt10 bs-xs`}>
                                    Send Offer
                                </button>
                            </Popconfirm>
                          }
                      </AllowComponentRightsWise>
                  </div>
              </> : null}
              <div className={'mt10'}>
                  {applications && applications.offerSendDate ?
                    `Send On : ${newFormatDisplayDate(applications.offerReSendDate ? applications.offerReSendDate : applications.offerSendDate)}`
                    : null}
              </div>
          </div>


      </div>
    )
}
const LoaRequestActionComponent = (props) => {
    let { record, val, events } = props
    return (
      <div>
          {viewOnlyRight ? <>
              {record.applications && record.applications.status == 'Offer Received' ?
                <Tooltip title={'Rqst LOA'}>
                    <a onClick={() => events.openLoaDrawer(record, false)}>
                        <img src={'/assets/icons/cloud-computing-upload.png'} />
                    </a></Tooltip> : null}
          </> : null}

          {val ?
            <Tooltip title={val}><a>
                <img src={`${val == 'Pending' ? '/assets/icons/pendingCheck.png' : '/assets/icons/doneCheck.png'}`}
                     className={'sm'} />
            </a></Tooltip> : null}


          {viewOnlyRight ? <>
              {record.applications && record.applications.status !== 'Offer Received' ?
                <div className={'mt10'}>
                    <a className={'bs-link bs-xs'} onClick={() => events.openLoaDrawer(record, true)}>Re-Upload
                        LOA</a>
                </div>
                : ''}

              <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                  {val == 'Pending' ?
                    <button className={`bs_btn bs-default mt10 bs-xs`}
                            onClick={() => events.approveRequest(record)}>
                        Approve
                    </button>
                    : null}
              </AllowComponentRightsWise>
          </> : null}
      </div>
    )
}
const LoaActionComponent = (props) => {
    let { record, val, events } = props
    let { applications } = record
    return (
      val && val.path ?
        <>
            <Tooltip title={'Download LOA'}>
                <a download={val.fileName} href={val.path}>
                    <img src={'/assets/icons/cloud-computing-download.png'} />
                </a></Tooltip>
            {viewOnlyRight ? <div className={'sprBox'}>
                <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                    <Popconfirm title={'Are your sure, you want to Re-Create LOA?'}
                                onConfirm={() => events.reCreateLoaLetter(record)}
                                okText='Yes' cancelText='No'>
                        <a className={'bs-link bs-xs'}>Re-Create LOA</a>
                    </Popconfirm>

                    {applications && applications.mailSent ?
                      null :
                      <Popconfirm title={'Are your sure, you want to Send LOA?'}
                                  onConfirm={() => events.sendFcmtMail(record, 'loa')}
                                  okText='Yes' cancelText='No'>
                          <button className={`bs_btn bs-default mt10 bs-xs`}>
                              Send LOA
                          </button>
                      </Popconfirm>
                    }
                    <br />
                </AllowComponentRightsWise>
                {applications && applications.mailDate ?
                  `Mail Date : ${newFormatDisplayDate(applications.mailReSendDate ? applications.mailReSendDate : applications.mailDate)}`
                  : null}
            </div> : null}
        </> : ''
    )
}
const ReceiptActionComponent = (props) => {
    let { record, val, events, user } = props
    let { applications } = record

    return (
      val && val.path ?
        <div>
            {(viewOnlyRight) ?
              <AllowComponentRightsWise rightUserType={['admin', 'branchManager', 'branchUser']}>
                  <Tooltip title={'Download Receipt'}>
                      <a download={val.fileName} href={val.path}>
                          <img src={'/assets/icons/cloud-computing-download.png'} />
                      </a></Tooltip>
                  <div className={'sprBox'}>
                      <Popconfirm title={'Are your sure, you want to Re-Create Receipt?'}
                                  onConfirm={() => events.reCreatePaymentReceipt(record)}
                                  okText='Yes' cancelText='No'>
                          <a className={'bs-link bs-xs'}>Re-Create Receipt</a>
                      </Popconfirm>

                      {applications && applications.receiptSent ?
                        null :
                        <Popconfirm title={'Are your sure, you want to Send Receipt?'}
                                    onConfirm={() => events.sendFcmtMail(record, 'receipt')}
                                    okText='Yes' cancelText='No'>
                            <button className={`bs_btn bs-default mt10 bs-xs`}>
                                Send Receipt
                            </button>
                        </Popconfirm>
                      }
                  </div>
              </AllowComponentRightsWise> : null}
            <AllowComponentRightsWise rightUserType={['agent', 'subAgent']}>
                <div>
                    Receipt Generated
                </div>
            </AllowComponentRightsWise>

            {hideAgentInfoForUsers.includes(user._id) ? <>
                <Tooltip title={'Download Receipt'}>
                    <a download={val.fileName} href={val.path}>
                        <img src={'/assets/icons/cloud-computing-download.png'} />
                    </a></Tooltip>
            </> : null}


            {applications && applications.receiptSendDate ?
              applications.receiptReSendDate ? `Mail Date : ${newFormatDisplayDate(applications.receiptReSendDate)}` : `Mail Date : ${newFormatDisplayDate(applications.receiptSendDate)}`
              : null}


        </div> : ''
    )
}

const AllRoyalHighSchoolApplicationsList = (props) => {
    let { onClose, studentObj = {}, reloadTable } = props

    const [state, setState] = useState(initialState)
    const [transferAppVisible, setTransferAppVisible] = useState(false)
    const [studentData, setStudentData] = useState({})
    const [visibleAddCourseDrawer, setVisibleAddCourseDrawer] = useState(false)
    const [name, setName] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [allAgent, setAllAgent] = useState([])
    const [agentId, setAgentId] = useState('')
    const [visibleOtherAppDrawer, setVisibleOtherAppDrawer] = useState(false)
    const [visibleAddCommentDrawer, setVisibleAddCommentDrawer] = useState(false)
    const [totalApplication, setTotalApplication] = useState(0)
    const [visibleFeesModal, setVisibleFeesModal] = useState(false)
    const [feesObj, setFeesObj] = useState(commissionObj)
    const [currentStatus, setCurrentStatus] = useState('')
    const [status, setStatus] = useState('')
    let { applicationObj } = state
    let [tableSearch, setTableSearch] = useState(false)
    let [filters, setFilters] = useState({})
    let [visibleLoaReq, setVisibleLoaReq] = useState(false)
    let [visibleFcmtStatus, setVisibleFcmtStatus] = useState(false)
    let [visibleApprovalDrawer, setVisibleApprovalDrawer] = useState(false)
    let [visibleChangeNameDrawer, setVisibleChangeNameDrawer] = useState(false)
    let [isEditAble, setIsEditAble] = useState(false)
    let [campusList, setCampusList] = useState([])
    const [campusId, setCampusId] = useState('')
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserId, setBranchUserId] = useState('')
    const [intake, setIntake] = useState('')
    let [courseName, setCourseName] = useState('')
    let [studentShore, setStudentShore] = useState('')
    let [visibleDocumentDrawer, setVisibleDocumentDrawer] = useState(false)
    let [visibleGcKey, setVisibleGcKey] = useState(false)


    let [selectedApplication, setSelectedApplication] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [enrollmentObj, setEnrollmentObj] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    let [changeUniversity, setChangeUniversity] = useState({
        visible: false,
        studentId: '',
        application: '',
        studentData: {}
    })
    const dispatch = useDispatch()
    let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
    const tableAppRef = useRef()
    let apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let resp = await getParamsForApplicationList()
            params = { ...params, ...resp }
            if (!params.results) {
                params.results = 50
                params.count = 50
            }
            if (tableSearch) {
                params.page = 1
            }
            try {
                setFilters(params)
                let resp = await dispatch(allRoyalHighSchoolApplicationFxn({ ...params }))
                setTotalApplication(resp.total)
                setTableSearch(false)
                resolve(resp)
            } catch (e) {
            }
        })
    }
    let exportExcel = async () => {
        let params = {}
        let resp = await getParamsForApplicationList()
        params = { ...params, ...resp }
        if (name) {
            params.name = name
        }
        if (dateOfBirth) {
            params.dateOfBirth = moment(dateOfBirth)
        }

        params.count = totalApplication
        params.courseUniversity = RoyalArtsCollege

        let { success, filePath, message } = await dispatch(exportStudentRoyalArtsCourseFxn(params))
        if (success) {
            notification.success({ message: message })
        } else {
            notification.error({ message: message })
        }
    }

    useEffect(() => {
        loadAgentData()
        loadCampusList()
        setFieldByParams()
        loadFcmtCourse()
        // loadCurrentUserData()
    }, [])

    const setFieldByParams = async () => {
        let resp = await getParamsForApplicationList()
        // setCurrentStatus(resp.status)

        if (resp.campusId) {
            setCampusId(resp.campusId)
        }
        if (resp.status) {
            setStatus(resp.status)
        }
        if (resp.agentId) {
            setAgentId(resp.agentId)
        }
        if (resp.intake) {
            setIntake(resp.intake)
        }
        if (resp.courseName) {
            setCourseName(resp.courseName)
        }
        if (resp.studentShore) {
            setStudentShore(resp.studentShore)
        }
        if (resp.branchUserId) {
            setBranchUserId(resp.branchUserId)
        }
    }

    const loadAgentData = async () => {
        let params = {
            results: 15000,
            userType: 'agent',
            sortField: 'companyName',
            sortOrder: 'ascend',
            select: ['name', 'companyName']
        }
        let { data } = await dispatch(listAllUsers(params))
        if (data && data.length) {
            setAllAgent(data)
        }
    }
    const loadCampusList = async () => {
        let params = {}
        params.sortField = 'campusName'
        params.sortOrder = 'ascend'
        params.campusUniversity = RoyalArtsCollege
        params.results = 50
        params.count = 50
        params.select = ['campusName']
        let { data } = await dispatch(listAllCampus({ ...params, regExFilters: ['campusAddress', 'campusName'] }))
        setCampusList(data)
    }

    const loadFcmtCourse = async () => {
        let params = {}
        params.select = ['campusName']
        let { data } = await dispatch(listAllCampus({ ...params, regExFilters: ['campusAddress', 'campusName'] }))
    }

    const events = {

        reloadFxn: () => {
            tableAppRef.current.reload()
        },
        showTransferApp: () => {
            setTransferAppVisible(true)
        },
        hideTransferApp: () => {
            setTransferAppVisible(false)
            tableAppRef.current.reload()
        },
        showAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(true)
        },
        hideAddCourseDrawer: () => {
            setVisibleAddCourseDrawer(false)
            tableAppRef.current.reload()
        },
        refreshApplication: () => {
            tableAppRef.current.reload()
        },

        closeDirectApp: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(applicationCloseDirect(obj))
            tableAppRef.current.reload()
        },

        enterName: (value) => {
            setName(value)

        },
        moveToDraftApplicationFxn: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(moveToDraftApplication(obj))
            tableAppRef.current.reload()
        },

        openOtherAppDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleOtherAppDrawer(true)
        },
        closeOtherAppDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleOtherAppDrawer(false)
        },
        openAddCommentDrawer: (value) => {
            setState({
                ...state,
                applicationObj: value
            })
            setVisibleAddCommentDrawer(true)
        },
        closeAddCommentDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleAddCommentDrawer(false)
        },
        hideVisibleFeesModal: () => {
            setVisibleFeesModal(false)
        },
        setCommissionLocal: (data) => {
            setFeesObj({
                ...feesObj,
                ...data
            })
        },
        reCalculateCommissionFxn: async () => {
            let { commissionAmount } = await dispatch(reCalculateCommission(feesObj))
            if (commissionAmount) {
                events.setCommissionLocal({ commissionAmount })
            }

        },
        updateApplicationTuitionFeesFxn: async () => {
            let { success, message } = await dispatch(updateApplicationTuitionFees(feesObj))
            if (success) {
                setFeesObj(commissionObj)
                setVisibleFeesModal(false)
                events.reloadFxn()
            }
        },
        sendFcmtMail: async (record, type) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            if (type == 'receipt') {
                let { success, message } = await dispatch(fcmtSendReceiptMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else if (type == 'offer') {
                let { success, message } = await dispatch(fcmtSendOfferMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            } else {
                let { success, message } = await dispatch(fcmtSendMailFxn(obj))
                if (success) {
                    events.reloadFxn()
                }
            }

        },
        openLoaDrawer: (record, editVal) => {
            setStudentData(record)
            setVisibleLoaReq(true)
            setIsEditAble(editVal)
        },
        closeLoaDrawer: () => {
            setStudentData({})
            setVisibleLoaReq(false)
        },
        approveRequest: async (record) => {
            setStudentData(record)
            setVisibleApprovalDrawer(true)
        },
        closeApproveRequest: async (record) => {
            setStudentData({})
            setVisibleApprovalDrawer(false)
        },
        changeNameOpen: async (record) => {
            setStudentData(record)
            setVisibleChangeNameDrawer(true)
        },
        closeChangeName: async (record) => {
            setStudentData({})
            setVisibleChangeNameDrawer(false)
        },
        openFcmtStatusDrawer: (record) => {
            setStudentData(record)
            setVisibleFcmtStatus(true)
        },
        closeFcmtStatusDrawer: () => {
            setStudentData({})
            setVisibleFcmtStatus(false)
        },
        reCreateOfferLetter: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtOfferLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreateLoaLetter: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtLoaLetter(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        reCreatePaymentReceipt: async (record) => {
            let obj = {
                studentId: record._id,
                applicationId: record.applications._id
            }
            let resp = await dispatch(ReCreateFcmtPaymentReceipt(obj))
            if (resp && resp.success) {
                tableAppRef.current.reload()
            }
        },
        openDocumentDrawer: async (record) => {
            setStudentData(record)
            setVisibleDocumentDrawer(true)
        },
        closeDocumentDrawer: async (record) => {
            setStudentData({})
            setVisibleDocumentDrawer(false)
        },
        showGcKeyDrawer: (record) => {
            setState({
                ...state,
                applicationObj: record
            })
            setVisibleGcKey(true)
        },
        closeGcKeyDrawer: () => {
            setState({
                ...state,
                applicationObj: {}
            })
            setVisibleGcKey(false)
            tableAppRef.current.reload()

        }
    }
    const reActivateApp = (record) => {
        setSelectedApplication({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseReActivate = () => {
        setSelectedApplication({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentApp = (record) => {
        setEnrollmentObj({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseEnrollmentApp = () => {
        setEnrollmentObj({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const changeUniversityFxn = (record) => {
        setChangeUniversity({
            visible: true,
            studentId: record._id,
            studentData: record,
            application: record.applications
        })
    }
    const onCloseChangeUniversityFxn = () => {
        setChangeUniversity({
            visible: false,
            studentId: '',
            studentData: {},
            application: {}
        })
        tableAppRef.current.reload()
    }

    const enrollmentBtn = (record) => {
        /* let application = record.applications
         return (
             !application.enrollment ? showEnrollmentAction(application.statusList) ?
                 <button className='btn' onClick={() => enrollmentApp(record)}>
                     <img src={'/assets/enrollment.png'}/>
                 </button> : null : null
         )*/
        return null
    }

    const getParamsForApplicationList = () => {
        return new Promise((resolve) => {
            let searchParams = new URLSearchParams(window.location.search)
            let appUniversityId = searchParams.get('appUniversityId')
            let name = searchParams.get('name')
            let campusId = searchParams.get('campusId')
            let status = searchParams.get('status')
            let agentId = searchParams.get('agentId')
            let branchUserId = searchParams.get('branchUserId')
            let intake = searchParams.get('intake')
            let courseName = searchParams.get('courseName')
            let studentShore = searchParams.get('studentShore')
            let obj = {}

            if (appUniversityId) {
                obj.appUniversityId = appUniversityId
            }
            if (name) {
                obj.name = name
            }

            if (campusId) {
                obj.campusId = campusId
            }
            if (status) {
                obj.status = status
            }
            if (agentId) {
                obj.agentId = agentId
            }
            if (intake) {
                obj.intake = intake
            }
            if (courseName) {
                obj.courseName = courseName
            }
            if (studentShore) {
                obj.studentShore = studentShore
            }

            if (branchUserId) {
                obj.branchUserId = branchUserId
            }

            resolve(obj)
        })
    }

    const searchFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        if (name) {
            obj.name = name
        }
        if (campusId) {
            obj.campusId = campusId
        }
        if (status) {
            obj.status = status
        }
        if (agentId) {
            obj.agentId = agentId
        }
        if (intake) {
            obj.intake = intake
        }
        if (courseName) {
            obj.courseName = courseName
        }
        if (studentShore) {
            obj.studentShore = studentShore
        }
        if (branchUserId) {
            obj.branchUserId = branchUserId
        }
        dispatch(
          getUrlPushWrapper('allRoyalHighSchoolApplicationsList', {
              ...obj
          }))
        setTableSearch(true)
        setTimeout(() => {
            if (tableAppRef && tableAppRef.current) {
                tableAppRef.current.reload()
            }
        }, 200)
    }
    const clearFxn = async () => {
        let resp = await getParamsForApplicationList()
        let obj = {}
        setName('')
        setCampusId('')
        setStatus('')
        setAgentId('')
        setIntake('')
        setCourseName('')
        setStudentShore('')
        setBranchUserId('')
        dispatch(
          getUrlPushWrapper('allRoyalHighSchoolApplicationsList', {
              ...obj
          }))
        setTableSearch(true)
        setTimeout(() => {
            tableAppRef.current.reload()
        }, 200)
    }


    const columns = [

        {
            title: 'App#',
            key: 'applications.id',
            dataIndex: 'applications.id',
            width: 60,
            render: (item) => {
                return (
                  <div style={{ width: 60 }}>
                      {item}
                  </div>
                )
            }
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            width: 120,
            render: (item, record) => {
                return (
                  <div style={{ width: 120 }}>
                      <Tooltip title={'Edit Student'}>
                          <a className={'linkAA'}
                             onClick={() => dispatch(
                               getPushPathWrapper('student.editStudent', { id: record._id }))
                             }>
                              {item} <Icon type={'edit'} />
                          </a>
                      </Tooltip>
                      <br />
                      {newFormatDisplayDate(record.dateOfBirth)}
                      <label className={'label label-tag'}>
                          {record.studentShore ? `(${record.studentShore})` : null}
                      </label>
                  </div>
                )
            }
        },
        {
            title: 'Program',
            key: 'applications.courseName',
            dataIndex: 'applications.courseName',
            width: 150,
            render: (item) => {
                return (
                  <div style={{ width: 150 }}>
                      {item}
                  </div>
                )
            }
        },
        {
            title: 'Intake',
            key: 'applications.intake',
            dataIndex: 'applications.intake',
            width: 80,
            render: (item, record) => {
                return (
                  <div style={{ width: 80 }}>
                      {item && item.month && item.year ? `${item.month}, ${item.year}` : ''}
                  </div>
                )
            }
        },
        {
            title: 'Campus',
            key: 'applications.campusId',
            dataIndex: 'applications.campusId',
            width: 85,
            render: (item, record) => {
                return (
                  <div style={{ width: 85 }}>
                      {item && item.campusName ? item.campusName : ''}
                  </div>
                )
            }
        },
        {
            title: 'Current User',
            key: 'applications.assignedUserId',
            dataIndex: 'applications.assignedUserId',
            width: 100,
            render: (val, record) => {
                let { agentId, statusList, status } = record.applications
                return (
                  <div style={{ width: 100 }}>
                      {val && val.name ? val.name : ''}<br />
                      {!hideAgentInfoForUsers.includes(user._id) ?
                        <div>
                            Agent : {agentId && agentId.companyName ? agentId.companyName : ''}
                            <br />
                            {agentId && agentId.mobile ? <> <Icon type={'phone'} style={{
                                position: 'relative',
                                bottom: 3
                            }} /> {agentId.mobile}</> : ''}
                        </div> : null}

                      <div>{longDisplayDate(statusList.date)}</div>
                  </div>
                )
            }
        },
        {
            title: 'Family / Given Name',
            key: 'applications.familyName',
            dataIndex: 'applications.familyName',
            width: 100,
            render: (item, record) => {
                return (
                  <div style={{ width: 100 }}>
                      {item && item ? <p>Family Name : <p style={{ fontWeight: 'bold' }}>{item}</p></p> : ''}
                      {record && record.applications && record.applications.givenName ?
                        <p>Given Name : <p style={{ fontWeight: 'bold' }}>{record.applications.givenName}</p>
                        </p> : ''}
                      {record && record.applications && record.applications.loaRequested === 'Approved' ?
                        <Icon type={'edit'} onClick={() => events.changeNameOpen(record)} /> : null}
                  </div>
                )
            }
        },
        // {
        //   title: 'Given Name',
        //   key: 'applications.givenName',
        //   dataIndex: 'applications.givenName',
        //   width: 80,
        //   render: (item, record) => {
        //     return (
        //       <div style={{ width: 80 }}>
        //         {item && item.givenName ? item.givenName : ''}
        //       </div>
        //     )
        //   }
        // },
        {
            title: 'Conditional Offer Letter',
            key: 'applications.offerReceiveLetter',
            dataIndex: 'applications.offerReceiveLetter',
            width: 100,
            render: (val, record) => {
                return (
                  <div style={{ width: 100 }} className={'fcmtDiv'}>
                      {/*{val && val.path ?
              <Tooltip title={'Download Offer Letter'}>
                <a download={val.fileName} href={val.path}>
                  <img src={'/assets/icons/cloud-computing-download.png'}/>
                </a></Tooltip> : ''}
            <br/>
            <Popconfirm title={'Are your sure, you want to Re-Create Offer Letter?'}
                        onConfirm={() => events.reCreateOfferLetter(record)}
                        okText="Yes" cancelText="No">
              <a className={'bs-link bs-xs'}>Re-Create Offer Letter</a>
            </Popconfirm>*/}
                      <OfferActionComponent file={val} record={record} events={events} />
                  </div>
                )
            }
        },
        {
            title: 'Rqst LOA',
            key: 'applications.loaRequested',
            dataIndex: 'applications.loaRequested',
            width: 100,
            render: (val, record) => {
                return (
                  <div style={{ width: 100 }} className={'fcmtDiv'}>
                      <LoaRequestActionComponent val={val} record={record} events={events} />
                  </div>
                )
            }
        },
        {
            title: 'Download LOA',
            key: 'applications.loaLetter',
            dataIndex: 'applications.loaLetter',
            width: 100,
            render: (val, record) => {
                return (
                  <div style={{ width: 100 }} className={'fcmtDiv'}>
                      <LoaActionComponent val={val} record={record} events={events} />
                  </div>
                )
            }
        },

        {
            title: 'Receipt',
            key: 'applications.receipt',
            dataIndex: 'applications.receipt',
            width: 80,
            render: (val, record) => {
                return (
                  <div style={{ width: 80 }} className={'fcmtDiv'}>
                      <ReceiptActionComponent val={val} record={record} events={events} user={user} />

                  </div>
                )
            }
        },
        {
            title: 'Status',
            key: 'applications.status',
            dataIndex: 'applications.status',
            width: 150,
            render: (item, record) => {
                let { applications } = record
                let { assignedUserId } = applications
                return (
                  <div className={'fcmtDiv'} style={{ width: 150 }}>
                      {item.includes('File') || item.includes('Visa') || item.includes('Revoked') ? <div>
                          {item}<br />
                          {applications.statusList && applications.statusList.date ?
                            <div>
                                Date : {newFormatDisplayDate(applications.statusList.date)}<br />
                            </div> : null}
                      </div> : null}

                      {applications && applications.loaRequested && applications.loaRequested == 'Approved' && applications.status !== fcmtRevokeStatus ?
                        <>
                            {user && assignedUserId && user._id == assignedUserId._id ?
                              <Tooltip title={'Update Status'}>
                                  <a onClick={() => events.openFcmtStatusDrawer(record)}>
                                      <img src={'/assets/icons/updated.png'} className={'sm'} />
                                  </a>
                              </Tooltip> : null}
                        </>
                        : null}


                      {applications && applications.status == fcmtRevokeStatus ?
                        <div className={'mt10'}>
                            <label className={'label label-danger sm'}>
                                {fcmtRevokeStatus}
                            </label>
                        </div> : null
                      }


                  </div>
                )
            }
        },
        {
            title: 'GCKey',
            key: 'applications.gcKey',
            dataIndex: 'applications.gcKey',
            width: 150,
            render: (val, record) => {
                return (
                  <div style={{ width: 150 }}>
                      {val ? <RenderComment item={{ text: val }} /> : null}
                      <Tooltip title={'Add GCKey'}>
                          <Icon type={'edit'} onClick={() => events.showGcKeyDrawer(record)} />
                      </Tooltip>
                  </div>
                )
            }
        },
        {
            title: 'Latest Note',
            key: 'applications.latestComment',
            dataIndex: 'applications.latestComment',
            width: 150,
            render: (val) => {
                return (
                  <div style={{ width: 150 }}>
                      {val && val.text ? <RenderComment item={val} /> : null}
                  </div>
                )
            }
        },
        /*  {
              title: 'FCMT Note',
              key: 'applications.latestFcmtNote',
              dataIndex: 'applications.latestFcmtNote',
              hidden: showFcmtNoteField,
              width: 200,
              render: (val, record) => {
                  let {applications, _id} = record;
                  return (
                      <div style={{width: 200}}>
                          {val && val.text ? <RenderComment item={val}/> : null}
                          <br/>
                          <FcmtNotesComponent application={applications} studentId={_id} user={user}
                                              reloadTable={events.reloadFxn}/>
                      </div>
                  )
              }
          },*/
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            width: 100,
            render: (val, record) => {
                return (
                  <div className={'actionBtnGroup'}>

                      <div>
                          <AllowComponentRightsWise rightUserType={['admin', 'branchUser', 'branchManager']}>
                              {user && user.userType == 'admin' ?
                                <Tooltip title={'View Application'}>
                                    <a className='btn'
                                       href={`/student/application?studentId=${record._id}&appId=${record.applications._id}`}
                                    ><img src='../../dist/img/view.svg' alt='' /></a>
                                </Tooltip> :
                                <>
                                    <button className={`bs_btn bs-default mt10 bs-xs`}
                                            onClick={() => events.openDocumentDrawer(record)}>
                                        View Documents
                                    </button>
                                    {' '}
                                </>
                              }
                              {user && user.userType == 'admin' ? '' : <>
                                  {!record.applications.directClosed && user.showAllFcmtList &&
                                  <Tooltip title={'View Application '}>
                                      <a className='btn'
                                         href={`/application/view?appId=${record.applications._id}&studentId=${record._id}`}
                                      ><img src='../../dist/img/view.svg' alt='' className={'eyeBtn'} /></a>
                                  </Tooltip>}
                              </>}

                              <Tooltip title={'View Other Application'}>
                                  <button className='btn' onClick={() => {
                                      events.openOtherAppDrawer(record)
                                  }}><Icon type={'ordered-list'} /></button>

                              </Tooltip>

                              <Tooltip title={'Add Note'}>
                                  <button className='btn' onClick={() => {
                                      events.openAddCommentDrawer(record)
                                  }}><Icon type={'plus'} /></button>
                              </Tooltip>
                          </AllowComponentRightsWise>

                          <CheckManagerWiseRight>
                              {record.applications.status !== 'Case Close' &&
                              <Tooltip title={'Case Closed'}>
                                  <Popconfirm title={'Are your sure, you want to Close Case?'}
                                              onConfirm={() => {
                                                  events.closeDirectApp(record)
                                              }}
                                              okText='Yes' cancelText='No'>
                                      <button className='btn'><img src='../../dist/img/cross.svg' alt=''
                                                                   className={'crossBtn'} /></button>
                                  </Popconfirm>
                              </Tooltip>}
                          </CheckManagerWiseRight>

                      </div>


                      <AllowComponentRightsWise rightUserType={['admin', 'branchManager']}>
                          {
                              record.applications.status == 'Case Close' ?
                                <Tooltip title={'Re-Open Case'}>
                                    <button className='btn' onClick={() => reActivateApp(record)}>
                                        <img src={'/assets/power-button.png'} />
                                    </button>
                                </Tooltip> : null
                          }
                      </AllowComponentRightsWise>
                      <AllowComponentRightsWise rightUserType={['admin', 'branchManager']}>
                          {
                              record.applications.status !== 'Case Close' ?
                                <Tooltip title={'Change University'}>
                                    <button className='btn' onClick={() => changeUniversityFxn(record)}>
                                        <img src={'/assets/shuffle-arrows.png'} />
                                    </button>
                                </Tooltip> : null
                          }
                      </AllowComponentRightsWise>

                      <AllowComponentRightsWise rightUserType={['admin']}>
                          {enrollmentBtn(record)}
                      </AllowComponentRightsWise>


                  </div>
                )
            }
        }

    ]

    const resp = useSelector(state => {
          let { loadNoteRedux } = state.chooseApplication
          if (loadNoteRedux) {
              if (tableAppRef && tableAppRef.current) {
                  tableAppRef.current.reload()
              }
              dispatch({ type: 'END_LOAD_NOTE' })
          }
      }
    )

    const loadColumns = () => {
        let columnArr = []
        lodash.each(columns, (item) => {
            if (item.hidden == undefined) {
                columnArr.push(item)
            } else {
                if (!item.hidden) {
                    columnArr.push(item)
                }
            }
        })
        return columnArr
    }

    const extra = (
      <div>
          <Row gutter={12}>
              <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='search-box-table round'>
                      <input className='form-control form-control' type='search'
                             placeholder='Search by name...' value={name} onChange={(e) => {
                          events.enterName(e.target.value)
                      }} />
                      <img src='/dist/img/search.png' alt='' />
                  </div>
              </Col>
              <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Status' onChange={(item) => setStatus(item)}
                              filterOption={filterOption}
                              className={'antSelect'}
                              placeholder={'Status'}
                              allowClear={true}
                              value={status || undefined}>
                          {statusList && statusList.length ? statusList.map((item, key) => {
                              return (
                                <Option value={item} key={key}>{item}</Option>
                              )
                          }) : null}
                      </Select>
                  </div>
              </Col>

              <Col md={4} sm={4} xs={8} lg={4}>
                  <div className='sort-box-table custom-sort-box-new'>
                      <Select name='Status' onChange={(item) => setCampusId(item)}
                              className={'antSelect'}
                              placeholder={'Campus'}
                              allowClear={true}
                              value={campusId || undefined}>
                          {campusList && campusList.length ? campusList.map((item, key) => {
                              return (
                                <Option value={item._id} key={key}>{item.campusName}</Option>
                              )
                          }) : null}
                      </Select>
                  </div>
              </Col>
              {/*<Col md={4} sm={4} xs={8} lg={4}>
          <div className="sort-box-table custom-sort-box-new">
            <Select name="Current User" onChange={(item) => setBranchUserId(item)}
                    filterOption={filterOption}
                    className={'antSelect'}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Current User'}
                    value={branchUserId || undefined}>
              {branchUserList && branchUserList.length ? branchUserList.map((item, key) => {
                return (
                  <Option value={item._id} key={key}>{item.name}</Option>
                )
              }) : null}
            </Select>
          </div>
        </Col>*/}
              <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                  <Col md={4} sm={4} xs={8} lg={4}>
                      <div className='sort-box-table custom-sort-box-new'>
                          <Select name='Search By Agent'
                                  filterOption={filterOption}
                                  className={'antSelect'}
                                  allowClear={true}
                                  placeholder={'Agent'}
                                  showSearch={true}
                                  onChange={(item) => setAgentId(item)} value={agentId || undefined}>
                              {allAgent && allAgent.length ? allAgent.map((item, key) => {
                                  return (
                                    <Option value={item._id} key={key}>{item.companyName}</Option>
                                  )
                              }) : null}
                          </Select>
                      </div>
                  </Col>
              </CheckUserRight>
              <CheckUserRight user={user} rightUserType={['admin', 'branchManager', 'branchUser']}>
                  <Col md={4} sm={4} xs={8} lg={4}>
                      <div className='sort-box-table custom-sort-box-new'>
                          <Select name='Intake' onChange={(item) => setIntake(item)}
                                  filterOption={filterOption}
                                  className={'antSelect'}
                                  allowClear={true}
                                  showSearch={true}
                                  placeholder={'Intake'}
                                  value={intake || undefined}>
                              {Intakes && Intakes.length ? Intakes.map((item, key) => {
                                  return (
                                    <Option value={item} key={key}>{item}</Option>
                                  )
                              }) : null}
                          </Select>
                      </div>
                  </Col>
                  <Col span={5}>
                      <div className='sort-box-table custom-sort-box-new'>
                          <div className='search-box-table round'>
                              <input className='form-control form-control' type='search'
                                     placeholder='course name...' value={courseName} onChange={(e) => {
                                  setCourseName(e.target.value)
                              }} />
                              <img src='/dist/img/search.png' alt='' />
                          </div>
                      </div>
                  </Col>

                  <Col md={4} sm={4} xs={8} lg={4}>
                      <div className='sort-box-table custom-sort-box-new'>
                          <Select name='Search By Student Shore'
                                  filterOption={filterOption}
                                  className={'antSelect'}
                                  allowClear={true}
                                  placeholder={'Student Shore'}
                                  onChange={(item) => setStudentShore(item)}
                                  value={studentShore || undefined}>
                              <Option value={'OnShore'}>OnShore</Option>
                              <Option value={'OffShore'}>OffShore</Option>
                          </Select>
                      </div>
                  </Col>

              </CheckUserRight>


              <Col md={4} sm={4} xs={12} lg={4}>
                  <Button onClick={() => searchFxn()} className={'roundBtn'}>Search</Button>
                  <Button onClick={() => clearFxn()} className={'roundBtn'}>
                      Clear
                  </Button>
              </Col>


          </Row>

      </div>
    )

    return (
      <React.Fragment>
          <div>
              <div className='row mt-4'>
                  <div className='col-lg-12'>
                      <div className='card'>
                          <div className='table-head d-flex align-items-center all-student-search'
                               style={{ height: 60 }}>
                              <Button onClick={() => dispatch(getUrlPushWrapper('dashboard'))}
                                      className={'roundGoBackBtn'} icon={'arrow-left'}>
                                  Back
                              </Button>
                              <h5>Royal Arts All Application List</h5>
                              <div className='search-box-table' style={{ backgroundColor: 'transparent' }}>
                                  {currentStatus} (Total - {totalApplication})
                              </div>
                              <div className='sort-box-table mark-btn'>
                                  {/*'branchManager', 'branchUser'*/}
                                  <ShowExportOption user={user}
                                                    rightUserType={['admin']}>
              <span className={'sort-box-table-right'}>
                <Tooltip title={'Export Excel'}>
                  {' '} <Button onClick={() => exportExcel()} className={'roundBtn'}>Export Excel</Button>
                </Tooltip>
              </span>
                                  </ShowExportOption>
                              </div>
                          </div>
                          <div className='card-body table-responsive'>
                              {extra}
                              <TableComp columns={loadColumns()}
                                         rowKey={(item, index) => {
                                             return index
                                         }}
                                         apiRequest={apiRequest}
                                         pagination={{
                                             position: 'top',
                                             showSizeChanger: true,
                                             pageSizeOptions: ['10', '20', '50', '100'],
                                             defaultPageSize: 10,
                                             current: filters.page ? filters.page : 1
                                         }}
                                         ref={tableAppRef}
                              />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {transferAppVisible ?
            <TransferApplicationDrawer visible={transferAppVisible}
                                       closeTransferDrawer={() => events.hideTransferApp()}
                                       studentId={studentData._id}
                                       studentData={studentData}
                                       application={studentData.applications}
            /> : null}

          {visibleAddCourseDrawer ? <ApplyCourse
            visible={visibleAddCourseDrawer}
            onClose={() => events.hideAddCourseDrawer()}
            refreshApplication={() => events.refreshApplication()}
            studentId={studentObj._id} /> : null}


          {visibleOtherAppDrawer ?
            <StudentOtherApp visible={visibleOtherAppDrawer}
                             applicationObj={state.applicationObj}
                             onStudentOtherAppClose={() => events.closeOtherAppDrawer()}
                             reloadFxn={events.reloadFxn}
            /> : null}

          {visibleAddCommentDrawer && applicationObj && applicationObj._id ?
            <AddNote visible={visibleAddCommentDrawer} studentData={applicationObj}
                     addedType={'direct'}
                     onClose={() => events.closeAddCommentDrawer()}
                     applicationId={applicationObj.applications._id} /> : null}


          {selectedApplication.visible ?
            <ReActivateApplication {...selectedApplication} onClose={onCloseReActivate} /> : null}

          {enrollmentObj.visible ?
            <Enrollment {...enrollmentObj} onClose={onCloseEnrollmentApp} /> : null}

          {visibleLoaReq && studentData.applications && studentData.applications._id ?
            <LoaRequest onClose={events.closeLoaDrawer}
                        reloadTable={events.reloadFxn}
                        editAble={isEditAble}
                        applicationId={studentData.applications._id}
                        studentId={studentData._id}
                        studentData={studentData}
                        visible={visibleLoaReq} /> : null}

          {visibleFcmtStatus && studentData.applications && studentData.applications._id ?
            <FcmtStatusComponent onClose={events.closeFcmtStatusDrawer}
                                 reloadTable={events.reloadFxn}
                                 university={'Royal Arts'}
                                 applicationId={studentData.applications._id}
                                 studentId={studentData._id}
                                 studentData={studentData}
                                 visible={visibleFcmtStatus} /> : null}

          {visibleApprovalDrawer && studentData.applications && studentData.applications._id ?
            <ApprovedLoaRequest onClose={events.closeApproveRequest}
                                reloadTable={events.reloadFxn}
                                studentData={studentData}
                                applicationId={studentData.applications._id}
                                studentId={studentData._id}
                                visible={visibleApprovalDrawer} /> : null}

          {visibleChangeNameDrawer ?

            <ChangeNameDrawer onClose={events.closeChangeName}
                              reloadTable={events.reloadFxn}
                              studentData={studentData}
                              applicationId={studentData.applications ? studentData.applications._id : null}
                              studentId={studentData._id}
                              visible={visibleChangeNameDrawer} /> : null}

          {changeUniversity.visible ?
            <ChangeUniversityComponent {...changeUniversity} onClose={onCloseChangeUniversityFxn} /> : null}

          {visibleDocumentDrawer && studentData.applications && studentData.applications._id ?
            <DocumentDrawer onClose={events.closeDocumentDrawer}
                            reloadTable={events.reloadFxn}
                            studentData={studentData}
                            applicationId={studentData.applications._id}
                            studentId={studentData._id}
                            visible={visibleDocumentDrawer} /> : null}

          {visibleGcKey && applicationObj && applicationObj._id ?
            <GrKeyDrawer visible={visibleGcKey} studentData={applicationObj}
                         onClose={() => events.closeGcKeyDrawer()}
                         applicationId={applicationObj.applications._id} /> : null}

      </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedAllRoyalHighSchoolApplicationsList = Form.create()(AllRoyalHighSchoolApplicationsList)
export default connect(
  null,
  mapDispatchToProps
)(WrappedAllRoyalHighSchoolApplicationsList)
