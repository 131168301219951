import React, { useEffect } from 'react'
import { Button, Col, Modal, Form, notification, Row } from 'antd'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updatePropertyFaqFxn } from '../action'
import GetEachFormFields from '../../../components/_utils/appFormUtils'


const EditPropertyFaq = (props) => {
  let dispatch = useDispatch()
  let { visible, onClose, form: { getFieldDecorator, setFieldsValue }, faq, propertyId, onSubmit } = props
  const setDefaultValues = () => {

    setTimeout(() => {
      setFieldsValue({
        question: faq.question,
        answer: faq.answer
      })
    }, 100)
  }
  let inputTypes = {
    fields: [
      {
        key: 'question',
        label: 'Question',
        placeholder: 'Edit Question',
        type: 'text',
        required: true
      },
      {
        key: 'answer',
        label: 'Answer',
        placeholder: 'Edit Answer',
        type: 'editor',
        required: true,
        rows: 4
      }
    ]
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { form } = props
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        const updatedFaq = {
          ...valData,
          faqId: faq._id
        }
        const resp = await dispatch(updatePropertyFaqFxn(updatedFaq))
        if (resp && resp.success) {
          onSubmit()
        }
      } else {
        notification.warning({
          message: 'Please fill in all required fields'
        })
      }
    })
  }


  useEffect(() => {
    setDefaultValues()
  }, [visible])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onClose}
        title={`Edit Faq`}
        width={'60%'}
        footer={null}>
        <div className='form-box commissionBox'>
          <div className='card'>
            <Form onSubmit={handleSubmit}>
              <Row gutter={24} className={'wrapBox'}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      <Col span={24} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={{
                            labelCol: { xs: { span: 0 }, sm: { span: 0 } },
                            wrapperCol: { xs: { span: 24 } }
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )
                })}
                <Col span={4}>
                  <Form.Item>
                    <Button
                      type='primary' htmlType='submit' className={'btn'}>
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  )
}

const EditPropertyFaqModal = Form.create()(EditPropertyFaq)

const mapStateToProps = ({ global, router }) => ({
  currentUser: global.currentUser
})

const mapDispatchToProps = dispatch => ({
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPropertyFaqModal)
