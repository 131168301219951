import React, {useEffect, useState} from 'react'
import {
    Form,
    Steps,
    Row,
    Col,
    notification,
    Popover
} from 'antd'
import {connect, useDispatch} from 'react-redux'
import {
    qualificationOptions,
    EnglishExamTypes,
    InputBox,
    countryIds,
    onShoreOptions
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import moment from 'moment'
import { LoadState, CountryJson, CountryCodeWithFlag } from '../../../components/_utils/countryUtil'
import { getUrlParams } from '../../../routes/routes'
import { eduBoardAndUniversity, getStudent, updateStudent } from '../../student/actions/student'
import {Debounce} from '../../../components/_utils/debounce'


const {Step} = Steps

let initialState = {
    currentStep: 0,
    fileList: [],
    uploadKey: moment(),
    allCountries: CountryJson,
    allStates: [],
    allCities: [],
    countryName: '',
    stateName: '',
    cityName: '',
    studentId: '',
    documents: []
}

const UpdateStudentFxn = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}, currentUser} = props
    const paramsData = getUrlParams('student.editStudent', props.pathname)
    const dispatch = useDispatch()
    const [state, setState] = useState(initialState)
    const [hideEmail, setHideEmail] = useState(true)
    const {allCountries, allStates, allCities, documents} = state
    const [countryCode, setCountryCode] = useState('')
    let [eduList, setEduList] = useState([])
    let {enabledStudentLogin} = currentUser;

    const getDocumentObj = (name) => {
        let findObj = _.find(documents, (item) => {
            return item.documentType == name
        })
        return findObj
    }

    const loadBoard = async (x) => {
        let obj = {
            qualification: getFieldValue('education.qualification'),
            board: x
        }
        let {data} = await eduBoardAndUniversity(obj)
        setEduList(data)
    }

    const ExamConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') == 'I don\'t have this'))
    const Exam12ThConditions = (!getFieldValue('englishRequirments.examType') || (getFieldValue('englishRequirments.examType') && getFieldValue('englishRequirments.examType') !== 'I don\'t have this'))

    const testLoginShow = (
        (getFieldValue('englishRequirments.examType') == 'PTE' || getFieldValue('englishRequirments.examType') == 'IELTS') && getFieldValue('countryId') == countryIds.australia
    )
    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    let formFields = {
        generateInputs: [
            {key: 'name', label: 'Student Name', required: true, placeholder: 'Enter Name'},
            {
                key: 'studentShore',
                label: 'Student Shore',
                type: 'select',
                showSearch: true,
                options: onShoreOptions,
                keyAccessor: x => x.value,
                valueAccessor: x => x.name,
                onChange: x => {
                    props.form.setFieldsValue({
                        studentShore: x
                    })
                }
            },
            {
                key: 'dateOfBirth',
                label: 'Date Of Birth',
                required: true,
                type: 'date',
                placeholder: 'Choose Date of Birth'
            },
            {
                key: 'countryOfCitizenShip',
                label: 'Country of Citizenship',
                required: true,
                type: 'select',
                showSearch: true,
                options: allCountries,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        countryOfCitizenShip: x
                    })
                }
            },
            {key: 'passportNumber', label: 'Passport Number', placeholder: 'Enter Passport Number'},
            {
                key: 'gender', label: 'Gender',
                type: 'select',
                options: ['Male', 'Female'],
                required: true, onChange: x => {
                    props.form.setFieldsValue({
                        gender: x
                    })
                }
            },
            {
                key: 'maritalStatus', label: 'Marital Status',
                type: 'select', options: ['Single', 'Married'],
                required: true, onChange: x => {
                    props.form.setFieldsValue({
                        maritalStatus: x
                    })
                }
            },
        ],
        addressInput: [
            {key: 'address', label: 'Address', required: true, span: 16, placeholder: 'Enter Address'},
            {
                key: 'countryName',
                label: 'Country',
                required: true,
                type: 'select',
                showSearch: true,
                options: allCountries,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        countryName: x
                    })
                    events.chooseCountry(x)
                }
            },
            {
                key: 'stateName',
                label: 'Province/State',
                required: true,
                type: 'select',
                showSearch: true,
                options: allStates,
                keyAccessor: x => x.name,
                valueAccessor: x => `${x.name}`,
                onChange: x => {
                    props.form.setFieldsValue({
                        stateName: x
                    })
                    events.chooseState(x)
                }
            },
            {
                key: 'cityName',
                label: 'City/Town',
                required: true,
                /* type: 'select',
                 showSearch: true,
                 options: allCities,
                 keyAccessor: x => x.name,
                 valueAccessor: x => `${x.name}`,*/
                onChange: x => {
                    props.form.setFieldsValue({
                        cityName: x.target.value
                    })
                    events.chooseCity(x.target.value)
                }
            },
            {
                key: 'postalCode',
                label: 'Postal/Zip Code',
                // type: 'number',
                placeholder: 'Postal/Zip Code',
                required: true
            },
            {
                key: 'countryCode',
                span: 3,
                customField: (
                    <div style={{marginTop: -9}}>
                        <CountryCodeWithFlag
                            countryCode={countryCode}
                            chooseCode={(val) => {
                                setCountryCode(val)
                            }}/>
                    </div>
                )
            },
            {key: 'mobile', span: 5, label: 'Phone', required: true, type: 'number', placeholder: 'Enter Phone No'},
            {
                key: 'email',
                label: 'Email',
                required: hideEmail,
                type: 'email',
                placeholder: 'Enter Email',
                hidden: hideEmail
            }
        ],
        educationInput: [
            {
                key: 'education.qualification',
                label: 'Qualification',
                type: 'select',
                required: true,
                options: qualificationOptions,
                onChange: x => {
                    let obj = {
                        qualification: x,
                        passingYear: '',
                        percentage: '',
                        maths: '',
                        english: '',
                        board: ''
                    }
                    props.form.setFieldsValue({
                        education: obj
                    }, () => {
                        loadBoard()
                    })
                },
                span: 6
            },
            {
                key: 'education.board',
                label: 'Board/University',
                showSearch: true,
                type: 'select',
                onSearch: (v) => {
                    Debounce(() => loadBoard(v), 500)
                },
                required: getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma',
                hidden: !(getFieldValue('education.qualification') && getFieldValue('education.qualification') !== 'X + 3 Year Diploma'),
                options: eduList,
                onChange: x => {
                    props.form.setFieldsValue({
                        'education.board': x
                    })
                },
                span: 10
            }
        ],
        educationInputField: [
            {
                key: 'education.passingYear',
                label: 'Passing Year',
                type: 'number',
                span: 6,
                required: true
            },

            {
                key: 'education.percentage',
                label: 'Percentage',
                type: 'number',
                span: 6,
                required: true
            },
            {
                key: 'education.maths',
                label: 'Math Score',
                type: 'number',
                span: 6,
                hidden: getFieldValue('education.qualification') !== 'XII',
                required: getFieldValue('education.qualification') == 'XII'
            },
            {
                key: 'education.english',
                label: 'English Score',
                type: 'number',
                span: 6,
                hidden: getFieldValue('education.qualification') !== 'XII',
                required: getFieldValue('education.qualification') == 'XII'
            }


        ],
        testInput: [
            {
                key: 'englishRequirments.examType',
                label: 'English Exam Type',
                type: 'select',
                allowClear: true,
                showSearch: true,
                options: EnglishExamTypes,
                onChange: x => {
                    let obj = {
                        examType: x,
                        overall: '',
                        listening: '',
                        reading: '',
                        writing: '',
                        speaking: '',
                        english12ThMarks: ''
                    }
                    props.form.setFieldsValue({
                        englishRequirments: obj
                    })
                },
                span: 8
            },
            {
                key: 'englishRequirments.english12ThMarks',
                label: 'English 12th Marks',
                type: 'number',
                placeholder: 'English 12th Marks',
                hidden: Exam12ThConditions,
                required: !Exam12ThConditions,
                span: 6
            },
            {
                key: 'englishRequirments.examinationDate',
                label: 'Examination Date',
                placeholder: 'Examination Date',
                type: 'date',
                hidden: ExamConditions,
                required: !ExamConditions,
                span: 6
            },
            {
                key: 'englishRequirments.overall',
                label: 'Overall',
                type: 'number',
                span: 8,
                hidden: ExamConditions,
                required: !ExamConditions,
                placeholder: 'Overall'
            }
        ],
        testInputFields: [
            {
                key: 'englishRequirments.listening',
                label: 'Listening',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                required: !ExamConditions,
                placeholder: 'Listening'
            },
            {
                key: 'englishRequirments.reading',
                label: 'Reading',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                required: !ExamConditions,
                placeholder: 'Reading'
            },
            {
                key: 'englishRequirments.writing',
                label: 'Writing',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                required: !ExamConditions,
                placeholder: 'Writing'
            },
            {
                key: 'englishRequirments.speaking',
                label: 'Speaking',
                type: 'number',
                span: 6,
                hidden: ExamConditions,
                required: !ExamConditions,
                placeholder: 'Speaking'
            }
        ],
        backgroundInfoInput: [
            {
                key: 'refusedVisa',
                label: 'Have you been refused a visa from Canada, the USA, the United Kingdom, New Zealand or Australia?',
                type: 'radioGroup',
                options: ['Yes', 'No'],
                span: 24,
                onChange: x => {
                    props.form.setFieldsValue({
                        refusedVisa: x.target.value
                    })
                }
            },
            {
                key: 'details',
                label: 'If you answered "Yes" to any of the following questions above, please provide more details below:',
                placeholder: 'Provide details... ',
                type: 'textArea',
                span: 24,
                rows: 3
            }
        ]
    }

    useEffect(() => {
        loadStudentData(paramsData)
        document.body.className = ''
    }, [])

    const loadStudentData = async (params) => {
        if (params && params.id) {
            let data = await dispatch(getStudent(params.id))

            setTimeout(() => {
                let obj = {}
                _.each(data, (item, key) => {
                    obj[key] = item
                })
                if (obj.dateOfBirth) {
                    obj.dateOfBirth = moment(obj.dateOfBirth)
                }
                if (obj.englishRequirments && obj.englishRequirments.examinationDate) {
                    obj.englishRequirments.examinationDate = moment(obj.englishRequirments.examinationDate)
                }
                setFieldsValue({...obj})
                setTimeout(() => {
                    setFieldsValue({education: obj.education})
                    setFieldsValue({englishRequirments: obj.englishRequirments})
                }, 500)
              if (!data || (data && !data.email)) {
                setHideEmail(false)
              }
                setState({
                    ...state,
                    studentId: data._id,
                    email: data.email,
                    documents: obj.document,
                    countryName: obj.countryName,
                    stateName: obj.stateName,
                    cityName: obj.cityName
                })
                setCountryCode(data.countryISOCode)
            }, 500)
        }
    }

    const events = {
        stepChange: (val = 0) => {
            let activeId = ''

            switch (val) {
                case 0:
                    activeId = 'generalInformation'
                    break
                case 1:
                    activeId = 'educationHistory'
                    break
                case 2:
                    activeId = 'testScore'
                    break
                case 3:
                    activeId = 'backgroundInformation'
                    break
                default:
                    activeId = 'generalInformation'
            }
            // console.log(activeId)
            document.getElementById(activeId).scrollIntoView({
                behavior: 'smooth'
            })
        },
        handleSubmit: (e) => {
            const {form} = props
            e.preventDefault()
            form.validateFieldsAndScroll(async (err, valData) => {
                if (!err) {
                    let {education, englishRequirments} = valData
                    let {countryName, stateName, cityName, studentId} = state

                    if (education) {
                        if (education.percentage < 1) {
                            notification.warning({
                                message: 'Enter greater then 0 Percentage'
                            })
                            return
                        }
                        if (education.qualification == 'XII') {
                            if (education.maths < 1) {
                                notification.warning({
                                    message: 'Enter greater then 0 Math score'
                                })
                                return
                            }
                            if (education.english < 1) {
                                notification.warning({
                                    message: 'Enter greater then 0 English score'
                                })
                                return
                            }
                        }
                    }
                    if (englishRequirments && englishRequirments.examType) {
                        if (englishRequirments.examType == 'I don\'t have this') {
                            if (englishRequirments.english12ThMarks <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 English 12Th marks'
                                })
                                return
                            }
                        } else {
                            if (englishRequirments.overall <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 Overall Rank'
                                })
                                return
                            }
                            if (englishRequirments.listening <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 Listening Rank'
                                })
                                return
                            }
                            if (englishRequirments.reading <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 Reading Rank'
                                })
                                return
                            }
                            if (englishRequirments.writing <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 Writing Rank'
                                })
                                return
                            }
                            if (englishRequirments.speaking <= 0) {
                                notification.warning({
                                    message: 'Enter greater then 0 Speaking Rank'
                                })
                                return
                            }
                        }
                    }

                    if (state.fileList && state.fileList.length) {
                        valData.document = state.fileList
                    }
                    if (countryCode) {
                        valData.countryISOCode = countryCode
                    }
                    valData = {...valData, countryName, stateName, cityName, hideEmail}
                    let data = await dispatch(updateStudent(valData, studentId))
                    if (data && data.data && data.data.email) {
                        setHideEmail(true)
                    }
                    loadStudentData(paramsData)
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    })
                }
            })
        },

        chooseCountry: (name) => {
            let countryName = _.find(allCountries, (item) => {
                return item.name == name
            })
            if (countryName) {
                setState({
                    ...state,
                    countryName: countryName.name ? countryName.name : '',
                    allStates: countryName.id ? LoadState(countryName.id) : []
                })
            }
        },
        chooseState: (name) => {
            let stateName = _.find(allStates, (item) => {
                return item.name == name
            })
            if (stateName) {
                setState({
                    ...state,
                    stateName: stateName.name ? stateName.name : ''
                    // allCities: stateName.id ? LoadCity(stateName.id) : []
                })
            }
        },
        chooseCity: (name) => {
            setState({
                ...state,
                cityName: name ? name : ''
            })
        }
    }

    const customDot = (dot, {status, index}) => (
        <Popover
            content={
                <span>
          step {index} status: {status}
        </span>
            }>
            {dot}
        </Popover>
    )

    useEffect(() => {
        /*window.onscroll = function() {
          myFunction()
        }

        let header = document.getElementById('header')
        let sticky = header.offsetTop

        function myFunction() {
          if (window.pageYOffset > sticky) {
            header.classList.add('sticky')
          } else {
            header.classList.remove('sticky')
          }
        }*/
    })


    const scrollToIds = (element) => {
        document.getElementById(element).scrollIntoView({
            behavior: 'smooth'
        })
    }

    return (
        <div>

            <div className='row'>
                <div className='col-lg-12'>
                    <Form className={'vertical-form'} autoComplete='off'>
                        <div className='student-form-tab card'>
                            <ul className={'pointerUl'}>
                                <li onClick={() => scrollToIds('generalInformation')}>
                  <span className='img'>
                    <img src={'../../dist/icons/checkBlue.png'} className={'stuIcon'}/>
                  </span> general information
                                </li>
                                <li onClick={() => scrollToIds('education')}>
                  <span className='img'>
                    <img src={'../../dist/icons/checkBlue.png'} className={'stuIcon'}/>
                  </span> education
                                </li>
                                <li onClick={() => scrollToIds('testScore')}>
                  <span className='img'>
                    <img src={'../../dist/icons/checkBlue.png'} className={'stuIcon'}/>
                  </span> test scores
                                </li>
                                <li onClick={() => scrollToIds('backgroundInformation')}>
                  <span className='img'>
                    <img src={'../../dist/icons/checkBlue.png'} className={'stuIcon'}/>
                  </span> background information
                                </li>

                                <li onClick={() => scrollToIds('uploadDocuments')}>
                  <span className='img'>
                    <img src={'../../dist/icons/checkBlue.png'} className={'stuIcon'}/>
                  </span> upload documents
                                </li>
                            </ul>
                        </div>

                        <div className='form-box mt-4'>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                 id={'generalInformation'}>
                                <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span> general
                                    information</h5>
                            </div>

                            <div className='card unizportal'>
                                {/*general*/}
                                <Row gutter={24}>
                                    <Col span={24} className={'header mb10'}>
                    <span>Personal Information {state.email ?
                        <small style={{color: 'black'}}>({state.email})</small> : ''}</span>
                                    </Col>
                                    {formFields.generateInputs.map((item, key) => {
                                        return (
                                            <Col span={8} md={8} sm={12} xs={24} key={key} className={'mb10'}>
                                                <GetEachFormFields
                                                    item={item}
                                                    getFieldDecorator={getFieldDecorator}
                                                    formItemLayout={formItemLayout}/>
                                            </Col>
                                        )
                                    })}
                                    {formFields.addressInput.map((item, key) => {
                                        return (
                                            // <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8} sm={12} xs={24} key={key}
                                            //      className={'mb10'}>
                                            //   <GetEachFormFields
                                            //     item={item}
                                            //     getFieldDecorator={getFieldDecorator}
                                            //     formItemLayout={formItemLayout}/>
                                            // </Col>
                                            <React.Fragment key={key}>
                                                {item.customField ? <Col span={item.span ? item.span : 8}>
                                                    {item.customField}
                                                </Col> : !item.hidden &&
                                                    <Col span={item.span ? item.span : 8} key={key} className={'mb10'}>
                                                        <InputBox title={item.label1 ? item.label1 : ''}>
                                                            <GetEachFormFields
                                                                item={item}
                                                                getFieldDecorator={getFieldDecorator}
                                                                formItemLayout={formItemLayout}/>
                                                        </InputBox>
                                                    </Col>}
                                            </React.Fragment>
                                        )
                                    })}

                                </Row>
                            </div>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3' id={'education'}>
                                <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span> education</h5>
                            </div>

                            <div className='card unizportal'>
                                <div className='inner-form'>
                                    <Row gutter={24}>
                                        {formFields.educationInput.map((item, key) => {
                                            return (
                                                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                                                     sm={12} xs={24} key={key}
                                                     className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    <Row gutter={24}>
                                        {formFields.educationInputField.map((item, key) => {
                                            return (
                                                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                                                     sm={12} xs={24} key={key}
                                                     className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </div>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3' id={'testScore'}>
                                <h5><span className='img'><img src='/dist/img/check.svg' alt=''/></span> test scores
                                </h5>
                            </div>

                            <div className='card unizportal'>
                                <div className='inner-form'>
                                    <Row gutter={24}>
                                        {formFields.testInput.map((item, key) => {
                                            return (
                                                !item.hidden &&
                                                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                                                     sm={12} xs={24} key={key}
                                                     className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                    <Row gutter={24}>
                                        {formFields.testInputFields.map((item, key) => {
                                            return (
                                                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                                                     sm={12} xs={24} key={key}
                                                     className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </div>

                            <div className='heading-form stuHeader  d-flex align-items-center mb-3'
                                 id={'backgroundInformation'}>
                                <h5><span>4</span> background information</h5>
                            </div>

                            <div className='card unizportal'>
                                <div className='inner-form'>
                                    {/*background information*/}
                                    <Row gutter={24}>
                                        {formFields.backgroundInfoInput.map((item, key) => {
                                            return (
                                                <Col span={item.span ? item.span : 8} md={item.span ? item.span : 8}
                                                     sm={12} xs={24} key={key}
                                                     className={'mb10'}>
                                                    <GetEachFormFields
                                                        item={item}
                                                        getFieldDecorator={getFieldDecorator}
                                                        formItemLayout={formItemLayout}/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                                <button className='btn' type='submit' onClick={events.handleSubmit}>save</button>
                            </div>


                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({global, router}) => ({
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
const WrappedAddStudent = Form.create()(UpdateStudentFxn)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedAddStudent)


