import React, {useEffect, useState} from 'react'
import {getAllCountryStatusListCount} from './actions'
import {
    australiaApplicationStatusSteps,
    australiaOnshoreApplicationStatusSteps,
    canadaApplicationStatusSteps,
    franceApplicationStatusSteps,
    germanyApplicationStatusSteps,
    netherlandsApplicationStatusSteps,
    singaporeApplicationSteps, switzerlandApplicationStatusSteps,
    ukApplicationStatusSteps,
    usaApplicationStatusSteps
} from '../../components/_utils/ApplicationStatusSteps'
import {
    australia_L1ApplicationSteps,
    canadaOnShoreApplicationStatusSteps,
    countryIds, InputBox
} from '../../components/_utils/appUtils'
import { getUrlPushWrapper } from '../../routes/routes'
import { useDispatch } from 'react-redux'
import {Col, Row, Select} from 'antd'
import {getMarketingTeamFxn} from '../users/actions/user'
import _ from 'lodash'
import {countryWiseDashboard, getCountryStatusWiseFxn} from "../dashboard/actions/dashboardAction";

let {Option} = Select
const initialState = {
    ukData: {},
    canadaData: {},
    australiaData: {},
    australiaL1Data: {},
    usaData: {},
    germanyData: {},
    singaporeData: {},
    canadaOnshoreData: {},
    australiaOnshoreData: {}
}
const CountryWiseStatusCount = () => {
    const dispatch = useDispatch()
    let [newData, setNewData] = useState([])
    const [marketingUserList, setMarketingUserList] = useState([])
    const [userId, setUserId] = useState(undefined)
    let [state, setState] = useState(initialState)
    let [allCountryRecords, setAllCountryRecords] = useState({})


    const loadMarketingUsers = async () => {
        let data = await dispatch(getMarketingTeamFxn())
        setMarketingUserList(data.users)
    }
    useEffect(() => {
        // loadMarketingUsers()
        loadCountryWiseData()
    }, [])

    const loadCountryWiseData = async () => {
        let resp = await dispatch(getCountryStatusWiseFxn())
        let records = {}
        _.each(resp, (item) => {
            records[item.countryId] = item.countArr
        })
        setAllCountryRecords(records)
    }


    const countryStatuses = [
        {
            country: 'UK',
            countryId: countryIds.uk,
            statuses: ukApplicationStatusSteps
        },
        {
            country: 'Canada',
            countryId: countryIds.canada,
            statuses: canadaApplicationStatusSteps
        },
        {
            country: 'Canada OnShore',
            countryId: countryIds.canadaOnshore,
            statuses: canadaOnShoreApplicationStatusSteps
        },
        {
            country: 'Australia',
            countryId: countryIds.australia,
            statuses: australiaApplicationStatusSteps
        },
        {
            country: 'Australia L2',
            countryId: countryIds.australiaL2,
            statuses: australia_L1ApplicationSteps
        },
        {
            country: 'Australia Onshore',
            countryId: countryIds.australiaOnshore,
            statuses: australiaOnshoreApplicationStatusSteps
        },
        {
            country: 'USA',
            countryId: countryIds.usa,
            statuses: usaApplicationStatusSteps
        },
        {
            country: 'Germany',
            countryId: countryIds.germany,
            statuses: germanyApplicationStatusSteps
        },
        {
            country: 'Singapore',
            countryId: countryIds.singapore,
            statuses: singaporeApplicationSteps
        },
        {
            country: 'France',
            countryId: countryIds.france,
            statuses: franceApplicationStatusSteps
        },
        {
            country: 'Netherlands',
            countryId: countryIds.netherlands,
            statuses: netherlandsApplicationStatusSteps
        },
        {
            country: 'Switzerland',
            countryId: countryIds.switzerland,
            statuses: switzerlandApplicationStatusSteps
        }
    ]
    let apiRequest = async (userId) => {
        return new Promise(async () => {
            let params = {}
            if (userId) {
                params.userId = userId
            }
            let resp = await getAllCountryStatusListCount(params)
            if (resp && resp.success) {
                let oldData = _.cloneDeep(countryStatuses)
                resp.data.forEach((item) => {
                    const country = oldData.find(c => c.countryId == item.countryId)
                    if (country) {
                        item.statuses.forEach(outputStatus => {
                            const countryStatus = country.statuses.find(cStatus => cStatus.name == outputStatus.status)
                            if (countryStatus) {
                                countryStatus.count = outputStatus.count  // Update the count
                            }
                        })
                    }
                })
                if (oldData) {
                    setNewData(oldData)
                }
            }
        })
    }
    useEffect(() => {
        apiRequest()
    }, [])

    let openApplicationList = data => {
        let newObj = {
            countryId: data.countryId,
            status: data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status
        }
        if (userId) {
            newObj.marketingUserId = userId
        }

        if (data.countryId == countryIds.uk) {
            dispatch(
                getUrlPushWrapper('applicationsListLiveReporting', {
                    ...newObj
                })
            )
        } else if (data.countryId == countryIds.australia) {
            dispatch(
                getUrlPushWrapper('applicationsListLiveReporting', {
                    ...newObj
                })
            )
        } else {
            dispatch(
                getUrlPushWrapper('applicationsListLiveReporting', {
                    ...newObj
                })
            )
        }
    }


    return (
        <>
            <div className='row  mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>All Status Count for Each Country</h5>
                        </div>
                        <div className='country-status-list'>
                            <Row gutter={12} className={'filter_box'}>
                                <Col span={6}>
                                    <InputBox title={'Marketing Users'}>
                                        <Select
                                            placeholder={'Marketing Users'}
                                            value={userId}
                                            onChange={(value) => {
                                                setUserId(value)
                                            }}>
                                            {marketingUserList && marketingUserList.length ? marketingUserList.map((item) => {
                                                return (
                                                    <Option value={item._id}>{item.name}</Option>
                                                )
                                            }) : null}
                                        </Select>
                                    </InputBox>
                                </Col>
                                <Col span={24}>
                                    <div className={'btn_group'}>
                                        <a className={'default_btn'}
                                           onClick={() => {
                                               apiRequest(userId)
                                           }}
                                        >Search</a>
                                        <a className={'default_btn'}
                                           onClick={() => {
                                               setUserId('')
                                               apiRequest()
                                           }}
                                        >clear</a>
                                    </div>
                                </Col>
                            </Row>


                            {countryStatuses.map((countryData, index) => {
                                let countArr = allCountryRecords[countryData.countryId]
                                return (
                                    <>
                                        <div key={index} className='country-section'>
                                            <h6 className='country-name'>{countryData.country}</h6>
                                            <div className={'country-status-section'}>
                                                <div className='status-list-grid'>
                                                    {countryData.statuses.map((status, statusIndex) => {
                                                        let statusName = status && status.name && status.name;
                                                        return (
                                                            statusName !== 'New Student' && <>
                                                                <div
                                                                    onClick={() => {
                                                                        openApplicationList({
                                                                            countryId: countryData.countryId,
                                                                            status: status.name
                                                                        })
                                                                    }} key={statusIndex} className='status-item-grid'>
                                                                    <div className={'status-box'}>
                                                                        <div className='status-name'>
                                                                            {statusName}
                                                                        </div>
                                                                        <div className='status-count'>
                                                                            {countArr && countArr[statusName] ? countArr[statusName] : 0}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}

                            {/*{newData.map((countryData, index) => {
                                return (
                                    <>
                                        <div key={index} className='country-section'>
                                            <h6 className='country-name'>{countryData.country}</h6>
                                            <div className={'country-status-section'}>
                                                <div className='status-list-grid'>
                                                    {countryData.statuses.map((status, statusIndex) => (
                                                        status !== 'New Student' && <>
                                                            <div
                                                                onClick={() => {
                                                                    openApplicationList({
                                                                        countryId: countryData.countryId,
                                                                        status: status.name
                                                                    })
                                                                }} key={statusIndex} className='status-item-grid'>
                                                                <div className={'status-box'}>
                                                                    <div className='status-name'>
                                                                        {status && status.name && status.name}
                                                                    </div>
                                                                    <div className='status-count'>
                                                                        {status && status.count ? status.count : 0}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CountryWiseStatusCount
