import {
    unizHomeEnquiryUrl,
    enquiryListUrl,
    singleEnquiryUrl,
    addEnquiryNoteUrl,
    enquiryNotesUrl,
    enquiryActivitiesUrl,
    addEnquiryCommentsUrl,
    enquiryDocumentsUrl,
    getEnquiryDocsUrl,
    enquiryStatusUrl, emergencyDetailsUrl,
    guarantorDetailsUrl,
    singlePropertyUrl,
    propertyListUrl,
    searchPropertyUrl,
    updateSinglePropertyUrl,
    suggestedPropertyUrl, getSuggestPropertyUrl,
    deleteEnquiryDocUrl,
    enquiryActivityUrl,
    addUnizhomePropertyUrl,
    savePropertyUrl,
    getPropertyListUrl,
    unizhomePropertyUrl,
    updateUnizhomePropertyUrl,
    updatePropertyFaqUrl,
    updatePropertyFeatureUrl,
    deleteUnizhomeFaqUrl,
    addUnizhomeFaqUrl,
    deleteUnizhomePolicyUrl,
    addUnizhomeRoomUrl,
    editUnizhomeRoomUrl,
    deleteUnizhomeRoomUrl,
    commonAmenitiesUrl, propertyActiveRoomsUrl, propertyRoomsUrl,
    propertyFaqsUrl, propertyImagesUrl, propertyVideosUrl, propertyDescriptionUrl, propertyAmenitiesUrl
} from '../api'
import {hidePageLoad, showPageLoad} from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { notification } from 'antd'
import {addEnquiryUrl} from "../../../unizHome/apis";


export const unizHomeEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
    }
    let {data} = await axios.get(unizHomeEnquiryUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const enquiryListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const singleEnquiryFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singleEnquiryUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const addEnquiryNoteFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEnquiryNoteUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const enquiryNotesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryNotesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const enquiryActivitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(enquiryActivitiesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const addEnquiryCommentsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(addEnquiryCommentsUrl(), valData, getToken())
    dispatch(hidePageLoad())

    return data
}

export const enquiryDocumentFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(enquiryDocumentsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const getEnquiryDocsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getEnquiryDocsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}



export const enquiryStatusFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(enquiryStatusUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}
export const emergencyDetailsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(emergencyDetailsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    }
    return data
}
export const guarantorDetailsFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(guarantorDetailsUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        // notification.success({
        //     message: data.message || 'Success'
        // })
    }
    return data
}

export const singlePropertyFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(singlePropertyUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const propertyListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyListUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const searchPropertyFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(searchPropertyUrl(), config)
    dispatch(hidePageLoad())
    if (data.error) {
        notification.error({
            message: data.message || 'Error'
        })
    }
    return data.data
}

export const updateSinglePropertyFxn = (valData) => async (dispatch) => {
    dispatch(showPageLoad())
    let {data} = await axios.post(updateSinglePropertyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    if (data.error) {
        notification.success({
            message: data.message || 'Success'
        })
    }
    return data
}

export const addEnquiryFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(addEnquiryUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const suggestedPropertyFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(suggestedPropertyUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}

export const getSuggestPropertyFxn = filters => async dispatch => {

    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getSuggestPropertyUrl(), config)
    dispatch(hidePageLoad())

    return data.data
}


export const deleteEnquiryDocFxn = (filters) => async (dispatch) => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let { data } = await axios.delete(deleteEnquiryDocUrl(), config)
    dispatch(hidePageLoad())
    return data
}

export const enquiryActivityFxn = valData => async dispatch => {
    dispatch(showPageLoad())
    let { data } = await axios.post(enquiryActivityUrl(), valData, getToken())
    dispatch(hidePageLoad())
    return data
}


export const savePropertyFxn = filters => async dispatch => {

    // dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(savePropertyUrl(), config)
    // dispatch(hidePageLoad())

    return data.data
}

export const getPropertyListFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(getPropertyListUrl(), config)
    dispatch(hidePageLoad())

    return data.data
}


export const commonAmenitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(commonAmenitiesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyActiveRoomsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyActiveRoomsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyRoomsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyRoomsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyFaqsFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyFaqsUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyAmenitiesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyAmenitiesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyImagesFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyImagesUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyVideosFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyVideosUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}

export const propertyDescriptionFxn = filters => async dispatch => {
    dispatch(showPageLoad())
    let config = {
        params: {...filters},
        ...(await getToken())
    }
    let {data} = await axios.get(propertyDescriptionUrl(), config)
    dispatch(hidePageLoad())
    return data.data
}
