import React, { useState, useEffect, useRef } from 'react'
import {
  Button,
  Form,
  Input,
  notification, Tooltip, Icon, Modal, List, Select
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import { addToDo, toDoListFxn, updateToDoTask } from './action'
import moment from 'moment'
import {
  DefaultTablePagination
} from '../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import CommentDrawerToDoList from './commentDrawer'
import { assignUsersListFxn } from './action'
import PropertyVisitDrawer from './propertyVisitDrawer'
import FollowUpDrawer from './followUpDrawer'

const { TextArea } = Input
const { Option } = Select

const ToDoList = (props) => {
  let { currentUser } = props
  const [newTodo, setNewTodo] = useState('')
  let [commentState, setCommentState] = useState({
    visible: false,
    taskId: '',
    task: ''
  })
  let [followUpState, setFollowUpState] = useState({
    visible: false,
    taskId: '',
    task: ''
  })
  let [propertyVisitState, setPropertyVisitState] = useState({
    visible: false,
    taskId: '',
    task: ''
  })
  const [priority, setPriority] = useState('Moderate')
  const [status, setStatus] = useState()
  const [region, setRegion] = useState()
  const [priorityFilter, setPriorityFilter] = useState()
  const [statusFilter, setStatusFilter] = useState()
  const [regionFilter, setRegionFilter] = useState()
  const [assignee, setAssignee] = useState([])
  let [assignedUsers, setAssignedUsers] = useState([])
  let [priorityState, setPriorityState] = useState({
    visible: false,
    taskId: ''
  })
  let [assigneeState, setAssigneeState] = useState({
    visible: false,
    taskId: ''
  })

  const dispatch = useDispatch()
  const tableRef = useRef()
  const tableStyles = {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px'
  }

  const taskColumns = [
    {
      key: '_id',
      title: '#',
      dataIndex: '_id',
      width: 50,
      render: (item, record, index) => {
        return (
          <>
            {pageState.count * (pageState.page - 1) + (index + 1)}
          </>
        )
      }
    },
    {
      title: 'TO DO',
      dataIndex: 'toDoTask',
      key: 'toDoTask',
      width: 400,
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div className={''}>
              {text}
              {/*<RenderTask text={text} data={record} refreshTable={toDoListFxn()} />*/}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (status, record) => {
        return (
          <Select
            defaultValue={status}
            className={'small-select'}
            onChange={(value) => events.handleStatusChange(record._id, value)}
          >
            {statusOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                <span className={'statusCss'} style={{ backgroundColor: option.color }} />
                {option.label}
              </Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      width: 170,
      render: (region, record) => {
        return (
          <Select
            defaultValue={region}
            className={'small-select'}
            onChange={(value) => events.handleRegionChange(record._id, value)}
          >
            {regionOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                <img className={'regionFlagCss'} src={`./../dist/img/${option.flag}`} />
                {option.label}
              </Option>
            ))}
          </Select>
        )
      }
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 120,
      render: (taskPriority, record) => {
        const priorityColor = events.getPriorityColor(taskPriority)
        return (
          <Button type='link' onClick={() => {
            events.handleOpenPriorityModal(record._id)
          }}>
            <Icon type={'flag'} theme={'filled'}
                  style={{ color: priorityColor, fontSize: '14px' }} />
            {taskPriority && <span style={{ marginLeft: '8px', fontSize: '13px' }}>{taskPriority}</span>}
          </Button>
        )
      }
    },
    {
      title: 'Assignee',
      dataIndex: 'assignedUsers',
      key: 'assignedUsers',
      width: 130,
      render: (assignees, record) => (
        <div className={'linkAA'} onClick={() => events.showAssigneeModal(record._id)}>
          {assignees && assignees.length > 0 ? (
            assignees.map((assignee) => (
              <div key={assignee} className={'aic'}>
                <Icon type={'user'} />&nbsp; {assignee}
              </div>
            ))
          ) : (
            <div className={'aic'}>
              <Icon type={'user'} />&nbsp; Assignee
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Last Comment Date',
      dataIndex: 'lastCommentDate',
      key: 'lastCommentDate',
      width: 170,
      render: (text, record) => {
        let { noOfComments } = record
        return (
          <div>
            <Tooltip title={'View Comments'}>
              <a className={'btn btn-default roundNew xs'} onClick={() => {
                events.showCommentDrawer(record._id, record.toDoTask)
              }}>{noOfComments || 0} Comments</a>
            </Tooltip>
            <div className={'btn btn-link xs'}>
              {text ? moment(text).format('DD MMM YY,  HH:MM') : ''}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Follow Up',
      dataIndex: 'lastCommentDate',
      key: 'lastCommentDate',
      width: 150,
      render: (text, record) => {
        let { noOfFollowUps } = record
        return (
          <div>
            <Tooltip title={'Follow Up'}>
              <a className={'btn btn-default roundNew xs'} onClick={() => {
                events.showFollowUpDrawer(record._id, record.toDoTask)
              }}>{noOfFollowUps || 0} Follow Ups</a>
            </Tooltip>
            <div className={'btn btn-link xs'}>
              {text ? moment(text).format('DD MMM YY,  HH:MM') : ''}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Property Visit',
      dataIndex: 'lastCommentDate',
      key: 'lastCommentDate',
      width: 150,
      render: (text, record) => {
        let { noOfVisits } = record
        return (
          <div>
            <Tooltip title={'Property Visits'}>
              <a className={'btn btn-default roundNew xs'} onClick={() => {
                events.showPropertyVisitDrawer(record._id, record.toDoTask)
              }}>
                Property Visits
              </a>
            </Tooltip>
            <div className={'btn btn-link xs'}>
              {text ? moment(text).format('DD MMM YY,  HH:MM') : ''}
            </div>
          </div>
        )
      }
    },
    {
      title: 'Date/Added by',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date, record) => {
        let { userId } = record
        return (
          <>
            {moment(date).format('DD MMM YY, HH:MM')}<br />
            {userId && userId.name ? <div className={'font11 textCap'}>({userId.name})</div> : ''}
          </>
        )
      }
    }
  ]
  const priorityOptions = [
    {
      key: 'High',
      label: 'High',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#eca500', fontSize: '16px' }} />
    },
    {
      key: 'Moderate',
      label: 'Moderate',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: '#4466ff', fontSize: '16px' }} />
    },
    {
      key: 'Low',
      label: 'Low',
      icon: <Icon type={'flag'} theme={'filled'} style={{ color: 'gray', fontSize: '16px' }} />
    }
  ]
  const regionOptions = [
    { value: 'uk', label: 'UK', flag: 'united-kingdomFlag.png' },
    { value: 'australia', label: 'Australia', flag: 'australiaFlag.png' },
    { value: 'ireland', label: 'Ireland', flag: 'irelandFlag.png' },
    { value: 'canada', label: 'Canada', flag: 'canadaFlag.png' },
    { value: 'usa', label: 'USA', flag: 'usaFlag.png' },
    { value: 'germany', label: 'Germany', flag: 'germanyFlag.png' }
  ]
  const statusOptions = [
    { value: 'created', label: 'Created', color: '#ba14e0' },
    { value: 'contacted', label: 'Contacted', color: '#FFA500' },
    { value: 'inProgress', label: 'In Progress', color: '#007BFF' },
    { value: 'opportunity', label: 'Opportunity', color: '#28A745' },
    { value: 'onboarded', label: 'Onboarded', color: '#6C757D' }
  ]

  const fetchAllTasks = async (params) => {
    try {
      if (priorityFilter) {
        params['priority'] = priorityFilter
      }
      if (statusFilter) {
        params['status'] = statusFilter
      }
      if (regionFilter) {
        params['region'] = regionFilter
      }
      const { data } = await dispatch(toDoListFxn(params))
      return data
    } catch (error) {
      console.error('Error fetching tasks:', error)
    }
  }

  const addTodo = async () => {
    if (!newTodo) {
      notification.warning({ message: 'Task cannot be empty' })
      return
    }
    if (!priority) {
      notification.warning({ message: 'Please Select Priority' })
      return
    }
    if (!status) {
      notification.warning({ message: 'Please Select Status' })
      return
    }
    if (!region) {
      notification.warning({ message: 'Please Select Region' })
      return
    }
    let formData = { toDoTask: newTodo, priority: priority, status: status, region: region }
    if (assignee.length > 0) {
      formData.assignedUsers = assignee
    }
    await dispatch(addToDo(formData))
    fetchAllTasks()
    tableRef.current.reload()
    setNewTodo('')
    setRegion('')
    setStatus('')
    setPriority('')
    setAssignee([])
  }
  const fetchAssignedUsers = async () => {
    const { data } = await dispatch(assignUsersListFxn())
    setAssignedUsers(data)
  }
  useEffect(() => {
    fetchAllTasks()
    fetchAssignedUsers()
  }, [])

  let [pageState, setPageState] = useState({ count: 10, page: 1, groupCount: 10, groupPage: 1 })

  const events = {
    showCommentDrawer: (taskId, task) => {
      setCommentState({
        taskId: taskId,
        visible: true,
        task: task
      })
    },
    hideCommentDrawer: () => {
      setCommentState({
        taskId: '',
        visible: false,
        task: ''
      })
    },
    showFollowUpDrawer: (taskId, task) => {
      setFollowUpState({
        taskId: taskId,
        visible: true,
        task: task
      })
    },
    hideFollowUpDrawer: () => {
      setFollowUpState({
        taskId: '',
        visible: false,
        task: ''
      })
    },
    showPropertyVisitDrawer: (taskId, task) => {
      setPropertyVisitState({
        taskId: taskId,
        visible: true,
        task: task
      })
    },
    hidePropertyVisitDrawer: () => {
      setPropertyVisitState({
        taskId: '',
        visible: false,
        task: ''
      })
    },
    handleOpenPriorityModal: (taskId = '') => {
      setPriorityState({
        taskId: taskId,
        visible: true
      })
    },
    handleClosePriorityModal: () => {
      setPriorityState({
        taskId: '',
        visible: false
      })
    },
    handleSelectPriority: async (priority) => {
      events.handleClosePriorityModal()
      if (priorityState.taskId) {
        try {
          const result = await dispatch(updateToDoTask(priorityState.taskId, { priority }))
          if (!result.error) {
            events.reloadGroup()
          } else {
          }
        } catch (error) {
          console.error('Error updating task priority:', error)
        }
      } else {
        setPriority(priority)
      }
    },
    getPriorityColor: (priority) => {
      const priorityOption = priorityOptions.find((option) => option.key === priority)
      return priorityOption ? priorityOption.icon.props.style.color : ''
    },
    reloadGroup: () => {
      if (tableRef.current && tableRef.current.reload) {
        tableRef.current.reload()
      }
    },
    handleStatusChange: async (taskId, status) => {
      const result = await dispatch(updateToDoTask(taskId, { status }))
      if (!result.error) {
        events.reloadGroup()
      }
    },
    handleRegionChange: async (taskId, region) => {
      const result = await dispatch(updateToDoTask(taskId, { region }))
      if (!result.error) {
        events.reloadGroup()
      }
    },
    handleAssignedUsersUpdate: async (taskId) => {
      try {
        const updatedResult = await dispatch(updateToDoTask(taskId, { assignedUsers: assignee }))
        if (updatedResult.success) {
          setAssignee([])
          events.reloadGroup()
        }
      } catch (error) {
        console.error('Error updating assigned users:', error)
      }
    },
    showAssigneeModal: (taskId = '') => {
      setAssigneeState({
        taskId: taskId,
        visible: true
      })
    },
    hideAssigneeModal: () => {
      setAssigneeState({
        taskId: '',
        visible: false
      })
    }
  }

  useEffect(() => {
    if (priorityFilter !== undefined || statusFilter !== undefined || regionFilter !== undefined) {
      events.reloadGroup()
    }
  }, [regionFilter, priorityFilter, statusFilter])


  return (
    <div className='row'>

      <div className='col-lg-12'>
        <div className='form-box mt-4'>
          <div className='d-flex align-items-center mb-3 heading-form'>
            <h5>To Do List Unizhome</h5>
          </div>
          <div className='card unizportal'>
            <div className={'row'}>
              <div className={'col-md-10'}>
                <TextArea
                  placeholder='Enter new TO-DO'
                  value={newTodo}
                  style={{ minHeight: 170 }}
                  onChange={(e) => setNewTodo(e.target.value)}
                  className='input-hover'
                />
              </div>
              <div className={'col-md-2'}>
                <div className={'row'}>
                  <div
                    onClick={() => {
                      events.handleOpenPriorityModal()
                    }}
                    className='col-md-12'
                  >
                    <Input
                      placeholder='Priority'
                      value={priority}
                      // readOnly={true}
                      onChange={(e) => setPriority(e.target.value)}
                      suffix={<Icon type='flag' style={{ color: '#eca500', fontSize: '16px' }} />}
                      className={'custom-disabled-input'}
                    />
                  </div>
                  <div className={'col-md-12 mt10'}>
                    <Select placeholder={'Filter by Status'}
                            className={'md-select'}
                            allowClear={true}
                            value={status}
                            onChange={(value) => {
                              if (value) {
                                setStatus(value)
                              } else {
                                setStatus(null)
                              }
                            }}>
                      <Option disabled={true} value={''}>Select Status</Option>
                      {statusOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className={'col-md-12 mt10'}>
                    <Select placeholder={'Select Region'}
                            className={'md-select'}
                            allowClear={true}
                            value={region}
                            onChange={(value) => {
                              if (value) {
                                setRegion(value)
                              } else {
                                setRegion(null)
                              }
                            }}>
                      <Option disabled={true} value={''}>Select Region</Option>
                      {regionOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className={'col-md-12 mt5'}
                       onClick={() => {
                         events.showAssigneeModal()
                       }}
                       style={{ cursor: 'pointer' }}
                  >
                    <Input
                      placeholder='Assignee'
                      value={assignee}
                      readOnly={true}
                      onChange={(e) => setAssignee(e.target.value)}
                      suffix={<Icon type={'usergroup-add'}
                                    style={{ color: '#0f52ba', fontSize: '16px', cursor: 'pointer' }}
                                    onClick={() => {
                                      events.showAssigneeModal()
                                    }} />}
                      className={'custom-disabled-input'}
                    />
                  </div>
                </div>
                <div className={'row mt10'}>
                  <div className={'col-md-12 alignRight'}>
                    <Button
                      type='primary'
                      onClick={addTodo}
                      className={'btn btn-success roundNew md ml5'}>
                      Add TO-DO
                    </Button>
                    <Button
                      className={'btn btn-default roundNew md ml5'}
                      onClick={() => {
                        setNewTodo('')
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='col-lg-12'>
        <div className='form-box'>
          <div className='card unizportal'>
            <div className={'row mt5'}>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Status'}
                        className={'md-select'}
                        allowClear={true}
                        value={statusFilter}
                        onChange={(value) => {
                          if (value) {
                            setStatusFilter(value)
                          } else {
                            setStatusFilter(null)
                          }
                        }}>
                  {statusOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Priority'}
                        className={'md-select'}
                        allowClear={true}
                        value={priorityFilter}
                        onChange={(value) => {
                          if (value) {
                            setPriorityFilter(value)
                          } else {
                            setPriorityFilter(null)
                          }
                        }}>
                  {priorityOptions.map((option) => (
                    <Option key={option.key} value={option.label}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
              <div className={'col-md-2'}>
                <Select placeholder={'Filter by Region'}
                        className={'md-select'}
                        allowClear={true}
                        value={regionFilter}
                        onChange={(value) => {
                          if (value) {
                            setRegionFilter(value)
                          } else {
                            setRegionFilter(null)
                          }
                        }}>
                  {regionOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
            </div>

            <div className={'row'}>
              <div className={'col-md-12'}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10
                }}>
                </div>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-12'}>
                <TableComp columns={taskColumns} style={tableStyles}
                           apiRequest={fetchAllTasks}
                           pagination={DefaultTablePagination()}
                           ref={tableRef}
                           extraProps={{ scroll: { x: 1680 } }}
                           rowKey='_id' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {commentState.visible ?
        <CommentDrawerToDoList
          {...commentState}
          onClose={() => {
            events.hideCommentDrawer()
          }}
          onSubmit={() => {
            tableRef.current.reload()
          }}
          currentUser={currentUser} /> : ''}

      {followUpState.visible ?
        <FollowUpDrawer
          {...followUpState}
          onClose={() => {
            events.hideFollowUpDrawer()
          }}
          onSubmit={() => {
            tableRef.current.reload()
          }}
          currentUser={currentUser} /> : ''}

      {propertyVisitState.visible ?
        <PropertyVisitDrawer
          {...propertyVisitState}
          onClose={() => {
            events.hidePropertyVisitDrawer()
          }}
          onSubmit={() => {
            tableRef.current.reload()
          }}
          currentUser={currentUser} /> : ''}

      {priorityState.visible ? <Modal
        title='Priority'
        visible={priorityState.visible}
        onCancel={events.handleClosePriorityModal}
        footer={null}
        width={200}>
        <List dataSource={priorityOptions}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => events.handleSelectPriority(item.key)}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0',
                    borderBottom: index === 3 ? '1px solid #d9d9d9' : 'none',
                    backgroundColor: priority === item.key ? '#e6f7ff' : 'inherit'
                  }}>
                  {item.icon && <span style={{ marginRight: '8px' }}>{item.icon}</span>}
                  <div style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#000'
                  }}>{item.label}</div>
                </List.Item>
              )}
        />

      </Modal> : null}
      {assigneeState.visible ? (
        <Modal
          title='Assignee'
          visible={assigneeState.visible}
          onCancel={events.hideAssigneeModal}
          bodyStyle={{ maxHeight: '800px', overflow: 'auto' }}
          width={400}
          footer={[
            <Button key='submit' type='primary' onClick={() => {
              events.handleAssignedUsersUpdate(assigneeState.taskId)
              events.hideAssigneeModal()
            }}>
              Submit
            </Button>
          ]}
          onOk={() => {
            events.handleAssignedUsersUpdate(assigneeState.taskId)
            events.hideAssigneeModal()
          }}
        >
          <Select
            mode='multiple'
            style={{ width: '100%' }}
            placeholder='Select assignees'
            value={assignee}
            onChange={(selectedUsers) => setAssignee(selectedUsers)}
          >
            {assignedUsers.map((user) => (
              <Option key={user._id} value={user.name}>
                <Icon type={'user'} style={{ color: '#0f52ba' }} /> {user.name}
              </Option>
            ))}
          </Select>
        </Modal>
      ) : null}
    </div>
  )
}

const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights
})

const mapDispatchToProps = (dispatch) => ({ dispatch })

const ToDoListWrappedComponent = Form.create()(ToDoList)

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListWrappedComponent)
