import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes/routes'
import { Avatar, Icon } from 'antd'
import {GetCountryCountRight} from '../../dashboard/views/DashboardUserWise'
// import { CanadaInnerBlock, InnerBlockNew } from '../../dashboard/views/countryCount'
import {CanadaInnerBlock, InnerBlockNew} from './reportingCountryCount'
import {
    australiaApplicationStatusSteps, australiaReportingStatus,
    canadaApplicationStatusSteps, canadaReportingStatus,
    ukApplicationStatusSteps, ukReportingStatus,
    usaApplicationStatusSteps, usaReportingStatus
} from '../../../components/_utils/ApplicationStatusSteps'
import moment from 'moment'
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, YAxis} from 'recharts'
import {departmentObj, StatusNameObj} from '../../../components/_utils/appUtils'


const COLORS = [
    '#49457B',
    '#7DB3FF',
    '#FFC107',
    '#FF7C78',
    '#FED3D0',
    '#6F76D9',
    '#9ADFB4',
    '#2E7987',
    '#138D75',
    '#2E4053',
    '#148F77',
    '#3498DB',
    '#239B56',
    '#212F3C',
    '#21618C',
    '#5B2C6F',
    '#512E5F'
]
const $ = window.$
const ReportDashboard = (props) => {
    let {
        events,
        userCountryName,
        userData,
        state,
        countryManager,
        department,
        userId,
        toDate,
        fromDate,
        userType
    } = props
    let [showMenu, setSHowMenu] = useState(false)
    let [country, setCountry] = useState('Canada')
    let countryName = country.toLowerCase()
    let dispatch = useDispatch()
    let countryColors = {
        'UK': '#ff0066',
        'USA': '#ff0066',
        'Australia': '#003399',
        'Canada': '#ad2125'
    }
    // console.log(props)
    let chooseCountry = (value) => {
        setCountry(value)
        setSHowMenu(false)
    }

    useEffect(() => {
        if (userData.userType == 'branchManager' && userData.branchManagerType !== 'marketingManager' && userData.branchManagerType !== 'studentWise') {
            if (userData.countryId && userData.countryId.countryName) {
                chooseCountry(userData.countryId.countryName)
            }
        } else if (userData.userType == 'branchUser' && userData.department !== 'Visa Approved Department') {
            if (userData.countryId && userData.countryId.countryName) {
                chooseCountry(userData.countryId.countryName)
            }
        }
    }, [userData.countryId])

    let openApplicationList = (data) => {
        dispatch(
            getUrlPushWrapper('reportingApplicationList', {
                countryId: data.countryId,
                userId: userId,
                department: department,
                userType: userType,
                toDate: moment(toDate).format('MM-DD-YYYY'),
                fromDate: moment(fromDate).format('MM-DD-YYYY'),
                status: data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status
            }))
    }

    if (countryName == 'uk') {
        countryName = 'kingdom'
    }

    const getWidth = () => {
        setTimeout(() => {
            return $('#universityChart').width()
        }, 500)
    }
    const CustomTooltip = ({active, payload, label}) => {
        if (active) {
            let value = payload && payload.length ? payload[0] && payload[0].value ? payload[0].value : 0 : 0
            return (
                <div className='custom-tooltip'>
                    <p className='label'>{label}</p><br/>
                    <p className='count'>{`Total Applications : ${value}`}</p>
                </div>
            )
        }

        return null
    }


    return (
        <React.Fragment>
            <div className='d-md-flex pattern-img align-items-center'>
                <div className='flex-fill map-section' style={{overflow: 'hidden'}}>
                    <div className='tab-content' id='v-pills-tabContent'>
                        <div className='tab-pane fade show active' id='v-pills-canada' role='tabpanel'
                             aria-labelledby='v-pills-canada-tab'>
                            <div className='p-1 flex-fill map-section' style={{overflow: 'hidden'}}>
                                <img src={`/dist/img/${countryName}-map.png`} alt=''/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-pane-right listing-section' style={{width: '35%'}}>
                    <div className='nav flex-column nav-pills w-100' id='v-pills-tab' role='tablist'
                         aria-orientation='vertical'>
                        {/*</div>*/}
                        <a className={`nav-link ${countryName == 'canada' ? 'active' : ''}`} id='v-pills-canada-tab'
                           data-toggle='pill' href='#v-pills-canada' role='tab'
                           onClick={() => chooseCountry('Canada')}
                           aria-controls='v-pills-canada' aria-selected='true'>canada</a>
                        <a className={`nav-link ${countryName == 'australia' ? 'active' : ''}`}
                           id='v-pills-australia-tab'
                           data-toggle='pill' href='#v-pills-australia' role='tab'
                           onClick={() => chooseCountry('Australia')}
                           aria-controls='v-pills-australia' aria-selected='false'>australia</a>
                        <a className={`nav-link ${countryName == 'kingdom' ? 'active' : ''}`} id='v-pills-kingdom-tab'
                           data-toggle='pill' href='#v-pills-kingdom' role='tab'
                           onClick={() => chooseCountry('UK')}
                           aria-controls='v-pills-kingdom' aria-selected='false'>united kingdom</a>
                        <a className={`nav-link ${countryName == 'usa' ? 'active' : ''}`} id='v-pills-kingdom-tab'
                           data-toggle='pill' href='#v-pills-kingdom' role='tab'
                           onClick={() => chooseCountry('USA')}
                           aria-controls='v-pills-kingdom' aria-selected='false'>USA</a>
                    </div>
                </div>
            </div>

            {userData ?
                <div>
                    {country == 'UK' && userData && userData.UK && userData.UK.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'uk'}>
                            <InnerBlockNew data={userData.UK} statusList={ukReportingStatus}
                                           userData={userData}
                                           userType={userType}
                                           department={department}
                                           userCountryName={country}
                                           callback={(countryId, status) => {
                                               openApplicationList({countryId: userData.UK.countryId, status})
                                               // events.loadCountryApplications({ countryId: state.ukData.countryId, status })
                                           }}/>
                        </GetCountryCountRight> : ''}

                    {country == 'USA' && userData && userData.USA && userData.USA.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'usa'}>
                            <InnerBlockNew data={userData.USA} statusList={usaReportingStatus}
                                           userData={userData}
                                           userType={userType}
                                           department={department}
                                           userCountryName={country}
                                           callback={(countryId, status) => {
                                               openApplicationList({countryId: userData.USA.countryId, status})
                                           }}/>
                        </GetCountryCountRight> : ''}


                    {country == 'Canada' && userData && userData.Canada && userData.Canada.countryId ?
                        <GetCountryCountRight user={userData}
                                              country={country}
                                              userCountryName={userCountryName}
                                              countryName={'canada'}>
                            <CanadaInnerBlock data={userData.Canada} statusList={canadaReportingStatus}
                                              userData={userData}
                                              userType={userType}
                                              department={department}
                                              userCountryName={country}
                                              callback={(countryId, status) => {
                                                  openApplicationList({countryId: userData.Canada.countryId, status})
                                                  // events.loadCountryApplications({ countryId: state.canadaData.countryId, status })
                                              }}/>
                        </GetCountryCountRight> : ''}

                    {country == 'Australia' && userData && userData.Australia && userData.Australia.countryId ?
                        <GetCountryCountRight user={userData} country={country}
                                              userCountryName={userCountryName}
                                              countryName={'australia'}>
                            <InnerBlockNew data={userData.Australia}
                                           userData={userData}
                                           userType={userType}
                                           department={department}
                                           userCountryName={country}
                                           statusList={australiaReportingStatus}
                                           callback={(countryId, status) => {
                                               openApplicationList({countryId: userData.Australia.countryId, status})
                                               // events.loadCountryApplications({ countryId: state.australiaData.countryId, status })
                                           }}/>
                        </GetCountryCountRight> : ''}

                    {/*{userData && userData.Canada*/}
                    {/*  ? <div className='col-lg-12'>*/}
                    {/*  <div className='card'>*/}
                    {/*    <div className='table-head d-flex align-items-center'>*/}
                    {/*      <h5>Number of Cases with each Status</h5>*/}
                    {/*      <div className='search-box-table'>*/}
                    {/*      </div>*/}

                    {/*      <div className='sort-box-table'>*/}

                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    <div className='card-body table-responsive barChart' id={'universityChart'}>*/}

                    {/*      <ResponsiveContainer width={getWidth()} height={500}>*/}
                    {/*        <BarChart*/}
                    {/*          width={500}*/}
                    {/*          height={300}*/}
                    {/*          data={userData.Canada.countArr}*/}
                    {/*        >*/}
                    {/*          <CartesianGrid strokeDasharray='10 3' />*/}
                    {/*          /!*<XAxis dataKey="name"/>*!/*/}
                    {/*          <YAxis />*/}
                    {/*          <Tooltip content={<CustomTooltip />} />*/}
                    {/*          <Legend layout='vertical' />*/}

                    {/*          <Bar dataKey='value' barSize={15}>*/}
                    {/*            {Object.keys(userData.Canada.countArr).map((entry, index) => (*/}
                    {/*              <Cell key={`cell-${index}-${moment()}`} fill={COLORS[index]} />*/}
                    {/*            ))}*/}
                    {/*          </Bar>*/}
                    {/*        </BarChart>*/}


                    {/*      </ResponsiveContainer>*/}

                    {/*      <ul className={'barLabel_ul'}>*/}
                    {/*        {userData.Canada && Object.keys(userData.Canada.countArr).map((entry, index) => (*/}
                    {/*          <li key={`label-${index}`}>*/}
                    {/*            <div className={'colorBox'} style={{ background: COLORS[index] }}></div>*/}
                    {/*            {StatusNameObj[entry.name]}*/}
                    {/*          </li>*/}
                    {/*        ))}*/}
                    {/*      </ul>*/}

                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>: null}*/}

                </div>
                : ''}


        </React.Fragment>
    )
}

export default ReportDashboard
