import { notification } from 'antd'
import { hidePageLoad, showPageLoad } from '../../../modules/actions'
import { customAxios as axios, getToken } from '../../../request'
import { moveToDraftUrl } from '../../student/api/student'
import {
  allRoyalHighSchoolApplicationUrl,
  exportStudentRoyalArtsCourseUrl, getCiraAgentsUrl, getCiraHighSchoolApplicationListUrl, getCornerstoneAgentsUrl,
  getPendingCambieApplicationsUrl,
  getPendingRoyalArtsApplicationsUrl,
  getStudentCambieCourseUrl,
  getStudentRoyalArtsCourseUrl, pendingLOARoyalHighSchoolUrl
} from '../api/cambieApi'
import {
  addAppCommentUrl,
  addApplicationsPendency,
  addCourseInStudentUrl,
  addSelectedCourseUrl,
  applicationCopyOfDraftUrl,
  calculateCommissionUrl,
  communicationActivitiesUrl,
  deleteDocumentUrl,
  deleteTuitionFeesDocumentUrl,
  departmentUserByStatusUrl,
  directOnshoreApplyCourseUrl,
  directUniversityApplicationReportUrl,
  editCommissionUrl,
  emailActivitiesUrl,
  enrollOnShoreStudentUrl,
  exportAllApplicationsListUrl,
  exportApplicationsListUrl,
  exportCambieApplicationsListUrl,
  exportDirectUniversityRecordsCountUrl,
  exportFcmtApplicationsListUrl,
  exportMarketingRecordsCountUrl,
  exportStudentListCountryWiseUrl,
  fcmtApplicationSendToApprovalUrl,
  privateCollegePendingForSubmissionsAppUrl,
  fcmtPendingTTApplicationsUrl,
  fcmtSendMailUrl,
  fcmtSendOfferMailUrl,
  fcmtSendReceiptMailUrl,
  fcmtStatusUpdateUrl,
  generateStudentLoginUrl,
  getApplicationActivityUrl,
  getApplicationNotesUrl,
  getApplicationSingleNotesUrl,
  getCanadaLoaApplicationUrl,
  getDirectUniversityApplicationsUrl,
  getFcmtDocumentsUrl,
  getInterviewStudentCoursesUrl,
  getPendingFCMTApplicationsUrl,
  getPendingOxFordApplicationsUrl,
  getRyanAgentsUrl,
  getSelectedApplications,
  getSelectedCountryApplications,
  getSingleAppCommentUrl,
  getStudentApplicationDocumentsUrl,
  getStudentFcmtCourseUrl,
  getStudentLesterCourseUrl,
  getStudentListCountryWiseUrl,
  getStudentOxFordCourseUrl,
  interviewStatusUpdateUrl,
  listStudentSelectedCourseUrl,
  marketingUserListForTTUrl,
  oshcApplicationsUrl,
  oxfordSignedVisaSendForApprovalUrl,
  reCreateFcmtLoaLetterUrl,
  reCreateFcmtOfferLetterUrl,
  reCreateFcmtPaymentReceiptUrl,
  requestLoaApprovedUrl,
  requestLoaUrl,
  rollbackToTTUploadUrl,
  sendEmailUrl,
  sendOpenApplicationMailUrl,
  sendSmsUrl,
  singleSelectedCourseUrl,
  smsActivitiesUrl,
  studentDocumentsUrl,
  studentLoginExistsUrl,
  studentOtherApplicationUrl,
  submitCommissionUrl,
  transferApplicationToOtherDepartmentUrl,
  transferApplicationUrl,
  ukTuitionFeeApplicationUrl,
  updateAppPendencyUrl,
  updateCampusUrl,
  updateGcKeyUrl,
  updateIntakeUrl,
  updateOshcInsuranceUrl,
  updatePaymentUrl,
  uploadSignedVisaDraftUrl,
  verificationCertificateAustraliaUrl,
  visaSendToApprovalUrl,
  reCreateEnrollmentLetterUrl,
  calculateCommissionByIntakeUrl,
  getPaidTTApplicationUrl,
  reCreateFcmtOnshoreLoaLetterUrl,
  onshoreApplicationListUrl,
  exportStudentCornerStoneCourseUrl,
  searchSingleApplications,
  searchUniversityCommissionUrl,
  getAgentUniversityCommission,
  getAgentUniversityCommissionUrl,
  studentCommissionsListUrl,
  getBloomsburyApplicationsUrl,
  getUniversityCommissionActivityUrl,
  updateGicStatusInStudentUrl, getApplicationsForLiveReportingUrl, generateCommissionInvoiceUrl, getCommissionInvoiceUrl
} from '../api/chooseStudent'
import { getNationalCollegeAgentsUrl } from '../../DirectColleges/apis/nationalCollege'
import { getCanadaApplicationsListUrl } from '../canadaApplications/api'

export const addSelectedCourse = valData => async dispatch => {
  dispatch({ type: 'SELECTED_COURSE_SHOW_LOADER' })
  let { data } = await axios.post(addSelectedCourseUrl(), valData, getToken())
  dispatch({ type: 'SELECTED_COURSE_HIDE_LOADER' })
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}
export const addCourseInStudent = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addCourseInStudentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}

export const listStudentSelectedCourse = filters => async dispatch => {
  dispatch({ type: 'SELECTED_COURSE_SHOW_LOADER' })
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(listStudentSelectedCourseUrl(), config)
  // console.log('data from backend is all', data)
  dispatch({ type: 'SELECTED_COURSE_HIDE_LOADER' })
  dispatch({ type: 'LIST_ALL_SELECTED_COURSE', payload: data.data })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getSingleSelectedCourse = valData => async dispatch => {
  // dispatch({ type: 'SELECTED_COURSE_SHOW_LOADER' })
  dispatch(showPageLoad())
  let { data } = await axios.post(
    singleSelectedCourseUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  // dispatch({ type: 'SELECTED_COURSE_HIDE_LOADER' })
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
    // dispatch({ type: 'LIST_SELECTED_COURSE', payload: data })
  }
  return data.data
}
export const updateSelectedCourse = (valData, id) => async dispatch => {
  dispatch({ type: 'SELECTED_COURSE_SHOW_LOADER' })
  let { data } = await axios.put(
    singleSelectedCourseUrl(id),
    valData,
    getToken()
  )
  dispatch({ type: 'SELECTED_COURSE_HIDE_LOADER' })
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

/*export const studentSelectedApplications = (valData) => async (dispatch) => {
  let { data } = await axios.post(getSelectedApplications(), valData, getToken())
  return data
}*/

export const studentSelectedApplications = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...await getToken()
  }
  // console.log(filters)
  let { data } = await axios.get(getSelectedApplications(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}
export const studentSelectedApplicationsNew = async filters => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  let { data } = await axios.get(getSelectedApplications(), config)
  return data
}
export const submitApplicationsPendency = valData => async dispatch => {
  let { data } = await axios.post(
    addApplicationsPendency(),
    valData,
    getToken()
  )
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getApplicationNotes = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(getApplicationNotesUrl(), valData, getToken())
  dispatch(hidePageLoad())

  return data
}
export const getApplicationSingleNotes = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(
    getApplicationSingleNotesUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())

  return data
}

export const getApplicationActivity = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(
    getApplicationActivityUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const sendSms = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendSmsUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const sendEmail = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(sendEmailUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateIntakeInApplication = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateIntakeUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const communicationActivities = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(
    communicationActivitiesUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  /*if (!data.error) {
        notification.success({
          message: data.message || 'Success'
        })
      } else {
        notification.error({
          message: data.message || 'Error'
        })
      }*/
  return data
}
export const emailActivities = valData => async dispatch => {
  let { data } = await axios.post(emailActivitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const smsActivities = valData => async dispatch => {
  let { data } = await axios.post(smsActivitiesUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const loadStudentDocuments = valData => async dispatch => {
  let { data } = await axios.post(studentDocumentsUrl(), valData, getToken())
  return data
}

export const loadStudentApplicationDocuments = valData => async dispatch => {
  let { data } = await axios.post(
    getStudentApplicationDocumentsUrl(),
    valData,
    getToken()
  )
  return data
}

export const updateAppPendency = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateAppPendencyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const transferApplication = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(transferApplicationUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const departmentUserByStatus = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    departmentUserByStatusUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const getStudentOtherApplication = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    studentOtherApplicationUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const addAppComment = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(addAppCommentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Added Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const getSingleAppComment = valData => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(getSingleAppCommentUrl(), valData, getToken())
  // dispatch(hidePageLoad())
  return data
}

export const exportApplicationsList = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportApplicationsListUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  // console.log(data)
  return data
}

export const calculateCommission = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(calculateCommissionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}
export const submitCommission = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(submitCommissionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const editCommission = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(editCommissionUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}

export const getSelectedCountryApplicationsApi = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getSelectedCountryApplications(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const updateCampusInApplication = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateCampusUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const RequestLoa = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(requestLoaUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const UploadSignedVisaFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    uploadSignedVisaDraftUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const RequestLoaApproved = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(requestLoaApprovedUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const FcmtStatusUpdate = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(fcmtStatusUpdateUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const ReCreateFcmtOfferLetter = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    reCreateFcmtOfferLetterUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const loadFcmtApplicationDocuments = valData => async dispatch => {
  let { data } = await axios.post(getFcmtDocumentsUrl(), valData, getToken())
  return data
}
export const ReCreateFcmtLoaLetter = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    reCreateFcmtLoaLetterUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const ReCreateFcmtPaymentReceipt = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    reCreateFcmtPaymentReceiptUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const fcmtSendMailFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(fcmtSendMailUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const fcmtSendOfferMailFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(fcmtSendOfferMailUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const fcmtSendReceiptMailFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(fcmtSendReceiptMailUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const transferApplicationToOtherDepartment = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    transferApplicationToOtherDepartmentUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || `Updated Successfully`
    })
  } else {
    notification.error({
      message: data.message || 'Error updating'
    })
  }
  return data
}

export const SendOpenApplicationMail = async valData => {
  let { data } = await axios.post(
    sendOpenApplicationMailUrl(),
    valData,
    getToken()
  )
  return data
}

export const interviewStatusUpdate = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    interviewStatusUpdateUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const getStudentFcmtCourseFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }

  let { data } = await axios.get(getStudentFcmtCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getPendingFCMTApplications = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getPendingFCMTApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getInterviewStudentCourses = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getInterviewStudentCoursesUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const exportAllApplicationsList = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportAllApplicationsListUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const exportFcmtApplicationsList = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportFcmtApplicationsListUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const exportCambieApplicationsList = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportCambieApplicationsListUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const getDirectUniversityApplicationsFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }
  // console.log(filters)
  let { data } = await axios.get(getDirectUniversityApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}
export const deleteDocument = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}

export const enrollOnShoreStudent = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    enrollOnShoreStudentUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const updatePayment = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updatePaymentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}

export const fcmtApplicationSendToApprovalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    fcmtApplicationSendToApprovalUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const oxfordSignedVisaSendForApproval = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    oxfordSignedVisaSendForApprovalUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const updateGcKeyFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateGcKeyUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const oshcApplicationsFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(oshcApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const updateOshcInsuranceFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateOshcInsuranceUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const ukTuitionFeeApplicationFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(ukTuitionFeeApplicationUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}
export const studentLoginExistsFxn = async valData => {
  let { data } = await axios.post(studentLoginExistsUrl(), valData)
  return data
}
export const generateStudentLoginFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    generateStudentLoginUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getRyanAgentsFxn = async valData => {
  let { data } = await axios.post(getRyanAgentsUrl(), valData)
  return data
}

//#################### cambie collage func ###################

export const getStudentCambieCourseFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }

  let { data } = await axios.get(getStudentCambieCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getPendingCambieApplications = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getPendingCambieApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getStudentListCountryWiseFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getStudentListCountryWiseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const exportStudentListCountryWiseFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportStudentListCountryWiseUrl(),
    filters,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const verificationCertificateAustraliaFxn = filters => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    verificationCertificateAustraliaUrl(),
    filters,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getStudentRoyalArtsCourseFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }

  let { data } = await axios.get(getStudentRoyalArtsCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getPendingRoyalArtsApplications = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getPendingRoyalArtsApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const exportStudentRoyalArtsCourseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportStudentRoyalArtsCourseUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}
export const exportDirectUniversityRecordsCountFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportDirectUniversityRecordsCountUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const exportMarketingRecordsCountFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportMarketingRecordsCountUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const getStudentOxFordCourseFxn = filters => async dispatch => {
  let config = { params: { ...filters }, ...(await getToken()) }
  let { data } = await axios.get(getStudentOxFordCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getPendingOxFordApplications = filters => async dispatch => {
  let config = { params: { ...filters }, ...(await getToken()) }
  let { data } = await axios.get(getPendingOxFordApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const requestCopyOfDraftFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    applicationCopyOfDraftUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const visaSendToApprovalFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(visaSendToApprovalUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const directUniversityApplicationReportFxn = filters => async dispatch => {
  let config = { params: { ...filters }, ...(await getToken()) }
  let { data } = await axios.get(directUniversityApplicationReportUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getStudentLesterCourseFxn = filters => async dispatch => {
  let config = { params: { ...filters }, ...(await getToken()) }
  let { data } = await axios.get(getStudentLesterCourseUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getCanadaLoaApplicationsFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getCanadaLoaApplicationUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}
export const directOnshoreApplyCourseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(directOnshoreApplyCourseUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (valData.showNotification) {
    if (!data.error) {
      notification.success({
        message: data.message || 'Success'
      })
    } else {
      notification.error({
        message: data.message || 'Error'
      })
    }
  }
  return data
}

export const fcmtPendingTTApplicationsFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(fcmtPendingTTApplicationsUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const deleteTuitionFeesDocumentFxn = valData => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(deleteTuitionFeesDocumentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const rollbackToTTUploadFxn = valData => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(rollbackToTTUploadUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const marketingUserListForTTFxn = valData => async (dispatch) => {
  let { data } = await axios.post(marketingUserListForTTUrl(), valData, getToken())
  return data
}
export const privateCollegePendingForSubmissionsAppFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(privateCollegePendingForSubmissionsAppUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const reCreateEnrollmentLetterFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reCreateEnrollmentLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}
export const calculateCommissionByIntakeFxn = filters => async dispatch => {
  // dispatch(showPageLoad())
  let { data } = await axios.post(calculateCommissionByIntakeUrl(), filters, getToken())
  dispatch(hidePageLoad())
  return data
}


export const getPaidTTApplicationFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getPaidTTApplicationUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const reCreateFcmtOnshoreLoaLetterFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(reCreateFcmtOnshoreLoaLetterUrl(), valData, getToken())
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data
}

export const getCiraAgentsFxn = async () => {
  let { data } = await axios.post(getCiraAgentsUrl(), {}, getToken())
  return data
}

export const getCiraHighSchoolApplicationListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }

  let { data } = await axios.get(getCiraHighSchoolApplicationListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}
export const pendingLOARoyalHighSchoolFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(pendingLOARoyalHighSchoolUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const allRoyalHighSchoolApplicationFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }

  let { data } = await axios.get(allRoyalHighSchoolApplicationUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const getCornerstoneAgentsFxn = async () => {
  let { data } = await axios.post(getCornerstoneAgentsUrl(), {}, getToken())
  return data
}

export const getNationalCollegeAgentsFxn = async () => {
  let { data } = await axios.post(getNationalCollegeAgentsUrl(), {}, getToken())
  return data
}
export const onshoreApplicationListFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(onshoreApplicationListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data.data
}

export const exportStudentCornerStoneCourseFxn = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    exportStudentCornerStoneCourseUrl(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  return data
}

export const searchSingleSelectedCourse = valData => async dispatch => {
  dispatch(showPageLoad())
  let { data } = await axios.post(
    searchSingleApplications(),
    valData,
    getToken()
  )
  dispatch(hidePageLoad())
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  } else {
  }
  return data.data
}

export const searchUniversityCommissionFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(searchUniversityCommissionUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const getAgentUniversityCommissionFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getAgentUniversityCommissionUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}

export const studentCommissionsListFxn = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(studentCommissionsListUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data.data
}
export const getBloomsburyApplicationsFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getBloomsburyApplicationsUrl(), config)
  return data.data
}
export const getUniversityCommissionActivityFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getUniversityCommissionActivityUrl(), config)
  return data
}

export const updateGicStatusInStudentFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let { data } = await axios.post(updateGicStatusInStudentUrl(), valData, getToken())
  dispatch(hidePageLoad())
  return data
}


export const getApplicationsForLiveReporting = filters => async dispatch => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getApplicationsForLiveReportingUrl(), config)
  if (data.error) {
    notification.error({
      message: data.message || 'Error'
    })
  }

  return data
}
export const generateInvoiceCommissionFxn = (valData) => async (dispatch) => {
  dispatch(showPageLoad())
  let {data} = await axios.post(generateCommissionInvoiceUrl(), valData , await getToken());
  dispatch(hidePageLoad())
  if (!data.error) {
    notification.success({
      message: data.message || 'Success'
    })
  } else {
    notification.error({
      message: data.message || 'Error'
    })
  }
  return data;
};
export const getInvoiceCommissionFxn = async (filters) => {
  let config = {
    params: { ...filters },
    ...(await getToken())
  }
  let { data } = await axios.get(getCommissionInvoiceUrl(), config)
  return data
}
