import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes/routes'
import { CanadaInnerBlock, InnerBlockNew, UkInnerBlock, CyprusInnerBlock } from './reportCountryWise'
import moment from 'moment'
import { GetCountryCountRight } from '../views/DashboardUserWise'
import {
  departmentObj,
  europeCountries
} from '../../../components/_utils/appUtils'
import {
  canadaApplicationSteps,
  ukApplicationSteps,
  usaApplicationSteps
} from '../../../components/_utils/appUtils'
import { getUser } from '../../users/actions/user'
import _ from 'lodash'
import {
  singaporeApplicationSteps,
  franceApplicationStatusSteps,
  netherlandsApplicationStatusSteps,
  germanyApplicationStatusSteps, switzerlandApplicationStatusSteps, canadaOnShoreApplicationStatusSteps
} from '../../../components/_utils/ApplicationStatusSteps'

const europeCountryList = [
  'France', 'Germany', 'Netherlands', 'Switzerland'
]


const ReportingComponent = (props) => {
  let {
    userCountryName,
    userData,
    department,
    userId,
    toDate,
    fromDate,
    userType,
    directUniversityCount,
    currentUser
  } = props
  let [showMenu, setSHowMenu] = useState(false)
  let [country, setCountry] = useState('')
  let [marketingCountries, setMarketingCountries] = useState([])
  let countryName = country.toLowerCase()
  let flagName = country.toLowerCase()
  let dispatch = useDispatch()
  let chooseCountry = (value) => {
    setCountry(value)
    setSHowMenu(false)
  }
  let events = {
    loadSingleUser: async () => {
      let countryList = []
      if (currentUser && currentUser._id) {
        let { marketingCountries } = await dispatch(getUser(currentUser._id))
        _.each(marketingCountries, (item) => {
          countryList.push(item.countryId)
        })

        if (countryList && countryList.length) {
          let loCountryName = countryList[0].countryName
          setCountry(loCountryName)
          countryName = loCountryName.toLowerCase()
          flagName = loCountryName.toLowerCase()
        } else {
          setCountry(userCountryName)
        }
        setMarketingCountries(countryList)
      }
    }
  }

  useEffect(() => {
    events.loadSingleUser()
  }, [currentUser])


  let openApplicationList = (data) => {
    let params = {
      countryId: data.countryId,
      userId: userId,
      department: departmentObj.marketing,
      userType: userType,
      status: data.status == 'Draft Applications' ? 'Waiting To Apply' : data.status
    }
    if (fromDate) {
      params.fromDate = moment(fromDate).format('MM-DD-YYYY')
      params.toDate = moment(toDate).format('MM-DD-YYYY')
    }
    dispatch(
      getUrlPushWrapper('marketingReportingApplicationList', params))
  }

  if (countryName == 'uk') {
    // countryName = 'kingdom'
  }
  if (flagName == 'uk') {
    flagName = 'kingdom'
  } else if (europeCountries.includes(flagName)) {
    flagName = 'europe'
  } else if (flagName == 'canada onshore') {
    flagName = 'canada'
  }

  let openDirectUniversity = ({ universityId, status }) => {
    let params = {
      userId: userId,
      department: departmentObj.marketing,
      userType: userType,
      appUniversityId: universityId
    }
    if (status) {
      params.status = status
    }
    if (fromDate) {
      params.fromDate = moment(fromDate).format('MM-DD-YYYY')
      params.toDate = moment(toDate).format('MM-DD-YYYY')
    }
    dispatch(getUrlPushWrapper('directUniversityMarketingReport', params))
  }

  const getStatusList = (lowerName) => {
    if (lowerName == 'france') {
      return franceApplicationStatusSteps
    } else if (lowerName == 'germany') {
      return germanyApplicationStatusSteps
    } else if (lowerName == 'netherlands') {
      return netherlandsApplicationStatusSteps
    } else if (lowerName == 'switzerland') {
      return switzerlandApplicationStatusSteps
    }
  }


  return (
    <React.Fragment>
      <div className='d-md-flex pattern-img align-items-center'>
        <div className='flex-fill map-section' style={{ overflow: 'hidden' }}>
          <div className='tab-content' id='v-pills-tabContent'>
            <div className='tab-pane fade show active' role='tabpanel'
                 aria-labelledby='v-pills-canada-tab'>
              <div className='p-1 flex-fill map-section' style={{ overflow: 'hidden' }}>
                <img src={`/dist/img/${flagName}-map.png`} alt='' />
              </div>
            </div>
          </div>
        </div>
        <div className='card-pane-right listing-section' style={{ width: '35%' }}>
          <div className='nav flex-column nav-pills w-100' id='v-pills-tab' role='tablist'
               aria-orientation='vertical'>
            {marketingCountries && marketingCountries.length ? marketingCountries.map((item) => {
              let lowerCaseName = item.countryName.toLowerCase()
              return (
                <>
                  <a className={`nav-link ${countryName == lowerCaseName ? 'active' : ''}`}
                     id={`v-pills-${lowerCaseName}-tab`}
                     data-toggle='pill' href={`#v-pills-${lowerCaseName}`} role='tab'
                     onClick={() => chooseCountry(item.countryName)}
                     aria-controls='v-pills-canada' aria-selected='true'>{lowerCaseName}</a>
                </>
              )
            }) : null}
          </div>
        </div>
      </div>

      {userData ?
        <div>
          {country == 'UK' && userData && userData.UK && userData.UK.countryId ?
            <GetCountryCountRight user={userData} country={country}
                                  userCountryName={userCountryName}
                                  countryName={'uk'}>
              <UkInnerBlock data={userData.UK} statusList={ukApplicationSteps}
                            userData={userData}
                            userType={userType}
                            department={department}
                            directUniversityCount={directUniversityCount}
                            userCountryName={country}
                            callback={(countryId, status) => {
                              openApplicationList({ countryId: userData.UK.countryId, status })
                            }} />
            </GetCountryCountRight> : ''}

          {country == 'USA' && userData && userData.USA && userData.USA.countryId ?
            <GetCountryCountRight user={userData} country={country}
                                  userCountryName={userCountryName}
                                  countryName={'usa'}>
              <InnerBlockNew data={userData.USA} statusList={usaApplicationSteps}
                             userData={userData}
                             userType={userType}
                             department={department}
                             userCountryName={country}
                             callback={(countryId, status) => {
                               openApplicationList({ countryId: userData.USA.countryId, status })
                             }} />
            </GetCountryCountRight> : ''}


          {country == 'Canada' && userData && userData.Canada && userData.Canada.countryId ?
            <GetCountryCountRight user={userData}
                                  country={country}
                                  userCountryName={userCountryName}
                                  countryName={'canada'}>
              <CanadaInnerBlock data={userData.Canada} statusList={canadaApplicationSteps}
                                userData={userData}
                                userType={userType}
                                department={department}
                                dispatch={dispatch}
                                directUniversityCount={directUniversityCount}
                                userCountryName={country}
                                openDirectUni={(data) => {
                                  openDirectUniversity(data)
                                }}
                                callback={(countryId, status) => {
                                  openApplicationList({ countryId: userData.Canada.countryId, status })
                                }} />
            </GetCountryCountRight> : ''}

          {country == 'Canada Onshore' && userData && userData['Canada Onshore'] && userData['Canada Onshore'].countryId ?
            <GetCountryCountRight user={userData}
                                  country={country}
                                  userCountryName={userCountryName}
                                  countryName={'canada onshore'}>
              <CanadaInnerBlock data={userData['Canada Onshore']} statusList={canadaOnShoreApplicationStatusSteps}
                                userData={userData}
                                userType={userType}
                                department={department}
                                dispatch={dispatch}
                                directUniversityCount={directUniversityCount}
                                userCountryName={country}
                                openDirectUni={(data) => {
                                  openDirectUniversity(data)
                                }}
                                callback={(countryId, status) => {
                                  openApplicationList({ countryId: userData['Canada Onshore'].countryId, status })
                                }} />
            </GetCountryCountRight> : ''}

          {country == 'Cyprus' ?
            <GetCountryCountRight user={userData}
                                  country={country}
                                  userCountryName={userCountryName}
                                  countryName={'cyprus'}>
              <CyprusInnerBlock data={userData.Canada}
                                userData={userData}
                                userType={userType}
                                department={department}
                                dispatch={dispatch}
                                directUniversityCount={directUniversityCount}
                                userCountryName={country}
                                openDirectUni={(data) => {
                                  openDirectUniversity(data)
                                }} />
            </GetCountryCountRight> : ''}

          {country == 'Singapore' && userData && userData.Singapore && userData.Singapore.countryId ? (
            <>
              <GetCountryCountRight
                user={userData}
                country={country}
                userCountryName={userCountryName}
                countryName={'singapore'}>
                <InnerBlockNew
                  data={userData.Singapore}
                  statusList={singaporeApplicationSteps}
                  callback={(countryId, status) => {
                    openApplicationList({
                      countryId: userData.Singapore.countryId,
                      status
                    })
                  }}
                />
              </GetCountryCountRight>
            </>
          ) : null}


          {europeCountryList.map((eachEuro) => {
            let lowerName = eachEuro.toLowerCase()
            return (
              country == eachEuro && userData && userData[eachEuro] && userData[eachEuro].countryId ? (
                <>
                  <GetCountryCountRight
                    user={userData}
                    country={country}
                    userCountryName={userCountryName}
                    countryName={lowerName}>
                    <InnerBlockNew
                      data={userData[eachEuro]}
                      statusList={getStatusList(lowerName)}
                      callback={(countryId, status) => {
                        openApplicationList({
                          countryId: userData[eachEuro].countryId,
                          status
                        })
                      }}
                    />
                  </GetCountryCountRight>
                </>
              ) : null
            )
          })}

        </div>
        : ''}


    </React.Fragment>
  )
}

export default ReportingComponent
