import React, { useState, useEffect, useRef } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Avatar,
  Button,
  Card,
  Popconfirm,
  Tooltip, Icon
} from 'antd'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { notification } from 'antd'
import { getPushPathWrapper, getUrlPushWrapper } from '../../../routes/routes'
import { listQuickAddedStudents } from '../actions/student'
import moment from 'moment'
import { DefaultTablePagination, displayDate, nameAvatar } from '../../../components/_utils/appUtils'
import studentReducers from '../reducers/student'
import ViewApplication from '../../applications/views/viewApplication'
import ApplyCourse from '../views/applyForApplicationDrawer'
import defaultLogo from '../../../assets/profile-user.png'
import Styles from '../views/styles.less'
import { listAllUsers } from '../../users/actions/user'
import { listAllCountries } from '../../countries/actions/countries'

const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const RenderUniversityList = (props) => {
  let { item } = props
  let [hideV, setHideV] = useState(true)
  return (
    <React.Fragment>
      <ul className={hideV ? 'dotsReplace' : ''}>
        {item && item.length && item.map((aa, key) => {
          return (
            <li key={key}> {aa && aa.universityName ? aa.universityName : ''}</li>
          )
        })}
      </ul>
      {item && item.length && item.length > 2 ? <div className={'alignRight'}>
        {hideV ? <a onClick={() => {
          setHideV(false)
        }}>Show More</a> : <a onClick={() => {
          setHideV(true)
        }}>Show Less</a>}

      </div> : ''}

    </React.Fragment>
  )
}

const ListQuickAddedAllStudents = (props) => {
  const { totalStudents } = useSelector((state) => ({
    totalStudents: state.studentReducers.totalStudents
  }))
  const [state, setState] = useState(initialState)
  const [allUser, setAllUser] = useState([])
  const [allCountry, setAllCountry] = useState([])

  const tableRef = useRef()
  const dispatch = useDispatch()
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      params.status = 'Active'
      resolve(dispatch(listQuickAddedStudents({ ...params, regExFilters: ['name'] })))
    })
  }
  useEffect(() => {
    loadUser()
    loadCountry()
  }, [])
  const [itemNo, setItemNo] = useState(1)
  const [loading, setLoading] = useState(false)
  const confirmDelete = async (id) => {
  }

  const loadCountry = async () => {
    let userList = []
    let { data } = await dispatch(listAllCountries({ results: 1000000 }))
    _.each(data, (item) => {
      userList.push({ text: item.countryName, value: item._id })
    })
    setAllCountry(userList)
  }


  const loadUser = async () => {
    let userList = []
    let { data } = await dispatch(listAllUsers({ results: 1000000, userType: 'branchManager' }))
    _.each(data, (item) => {
      userList.push({ text: item.name, value: item._id })
    })
    setAllUser(userList)
  }

  const events = {
    viewCourse: (record) => {
      setState({
        ...state,
        viewCourseDrawer: true,
        studentObj: record
      })
    },
    viewCourseClose: () => {
      setState({
        ...state,
        viewCourseDrawer: false,
        studentObj: {}
      })

    },
    reloadTable: () => {
      tableRef.current.reload()
    }


  }


  const columns = [


    /* {
       title: 'S.No',
       dataIndex: 'sno',
       width: 50,
       key: 'sno',
       render: (value, item, index) => (itemNo - 1) * 10 + index + 1
     },*/
    {
      title: 'Student Id',
      dataIndex: 'studentId',
      width: 120,
      searchTextName: 'studentId'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      searchTextName: 'name',
      width: 120,
      render: (item, record) => {
        let { profileImage } = record
        return (
          <div className={Styles.userNameBox}>
            {profileImage && profileImage.url ?
              <Avatar size={30} src={profileImage.url}></Avatar> :
              <Avatar size={30} src={defaultLogo}></Avatar>}
            <div>
              {item}
            </div>
          </div>
        )
      }
    },

    {
      title: 'Email / Mobile',
      dataIndex: 'email',
      searchTextName: 'email',
      width: 150,
      render: (item, record) => {
        return (
          <div>
            {record.email}
            <div>
              {record.mobile}
            </div>

          </div>
        )
      }
    },
    {
      title: 'Date Of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      width: 150,
      render: (val) => {
        return (
          <span>
            {displayDate(val)}
          </span>
        )
      }
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      searchTextName: 'comment',
      width: 150
    },
    {
      title: 'Branch Manager',
      dataIndex: 'branchManagerId',
      key: 'branchManagerId',
      filters: allUser,
      width: 150,
      render: (item) => {
        return (
          item && item.name ? item.name : ''
        )
      }
    },
    /* {
       title: 'Address',
       key: 'address',
       width: 150,
       dataIndex: 'address',
       render: (item, record) => {
         return (
           item ?
             <React.Fragment>
               {item}, {record.cityName}, {record.stateName}, {record.countryName}
             </React.Fragment> : ''
         )
       }
     },*/
    {
      title: 'Country',
      key: 'countryId',
      dataIndex: 'countryId',
      filters: allCountry,
      width: 150,
      render: (item, record) => {
        return (
          <React.Fragment>
            <ul>
              {item && item.length && item.map((aa, key) => {
                return (
                  <li key={key}> {aa && aa.countryName ? aa.countryName : ''}</li>
                )
              })}
            </ul>
          </React.Fragment>
        )
      }
    },
    {
      title: 'University',
      key: 'universityId',
      dataIndex: 'universityId',
      width: 200,
      render: (item, record) => {
        return (
          <RenderUniversityList item={item}/>
        )
      }
    },
    {
      title: 'App',
      key: 'app',
      dataIndex: 'app',
      // searchTextName: 'address',
      render: (item, record) => {
        return (
          <div>
            {record && record.applications && record.applications.length ?
              <span className="badge">{record.applications.length}</span> : ''}
          </div>
        )
      }
    },
    {
      key: 'actions',
      title: 'Actions',
      width: 100,
      height: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip title={'View Application'}>
              <button className={'btn'} onClick={() => events.viewCourse(record)}>
                <Icon type={'eye'}/>

              </button>
            </Tooltip>
          </React.Fragment>

        )
      }
    }
  ]


  return (
    <div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="table-head d-flex align-items-center">
              <h5>All Quick Added Students: {totalStudents || 0}</h5>
              <div className="search-box-table">
                <input className="form-control form-control" type="search" placeholder="Search Student"/>
                <img src="/dist/img/search.png" alt=""/>
              </div>

              <div className="sort-box-table">
                <select name="Sort By" id="">
                  <option>Sort By</option>
                  <option value="1">Date</option>
                  <option value="2">Time</option>
                </select>
              </div>
            </div>
            <div className="card-body table-responsive">
              <TableComp columns={columns}
                         apiRequest={apiRequest}
                         pagination={DefaultTablePagination()}
                         ref={tableRef}
              />
            </div>
          </div>
        </div>
      </div>

      {state.viewCourseDrawer ? <ViewApplication
        visible={state.viewCourseDrawer}
        pageType={'student'}
        reloadTable={() => events.reloadTable()}
        onClose={() => events.viewCourseClose()}
        studentObj={state.studentObj}/> : null}


    </div>
  )
}

export default ListQuickAddedAllStudents
