import React, { Component } from 'react'
import { TableComp } from 'sz-react-utils-lite'
import {
  Card,
  Tooltip, Icon, Drawer, List
} from 'antd'
import { connect } from 'react-redux'
import {
  listAllUsers,
  blockAgent,
  updateDefaultReferralApi,
  updateShareReferralAllowApi,
  getUserRating, listAllEmployees
} from '../actions/user'
import { adminRightUser, DefaultTablePagination, displayDate } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import UpdatePassword from '../views/changePassword'
import RatingDrawer from '../views/ratingDrawer'
import EditUser from '../views/edit'
import EditUserExtraInfo from '../views/editExtraInfo'
import { CheckMyRights } from '../../WebComponent/CheckUserRights'
import { ColumnWidth } from '../../WebComponent/columnWidth'
import UserRightList from '../list/rightsList'
import lodash from 'lodash'
import RatingLogsDrawer from './RatingLogsDrawer'
import AddEmployee from './addEmployee'

const userTypeOptions = [
  { value: 'branchManager', text: 'Branch Manager' },
  { value: 'branchUser', text: 'Branch User' },
  { value: 'master', text: 'Master' }
]
const commissionType = [
  { text: 'Silver', value: 'silver' },
  { text: 'Gold', value: 'gold' },
  { text: 'Platinum', value: 'platinum' }
]
const branchManagerObj = {
  agentWise: 'Agent Wise',
  universityWise: 'University Wise',
  studentWise: 'Student Wise'
}

class AllManagersAndUsers extends Component {

  events = {
    showUserRightDrawer: (data) => {
      this.setState({
        visibleUserRightDrawer: true,
        selectedUser: data
      })
    },
    hideUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      })
    },
    closeAndUpdateUserRightDrawer: () => {
      this.setState({
        visibleUserRightDrawer: false,
        selectedUser: {}
      }, () => {
        this.tableRef.current.reload()
      })
    },
    showAddEmployeeDrawer: (data) => {
      this.setState({
        visibleAddEmployeeDrawer: true,
        selectedUser: data
      })
    },
    hideAddEmployeeDrawer: () => {
      this.setState({
        visibleAddEmployeeDrawer: false,
        selectedUser: {}
      })
      this.tableRef.current.reload()
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewInfoDrawer: false,
      selectedRow: {},
      allAgents: [],
      allBranchManager: [],
      allCountry: [],
      selectedUser: {},
      visibleUpdatePassword: false,
      visibleUpdateUser: false,
      visibleUserExtraInfo: false,
      visibleUpdateSubAgent: false,
      visibleRatingLogsDrawer: false,
      visibleRatingDrawer: false,
      visibleUserRightDrawer: false,
      visibleAddEmployeeDrawer: false,
      userPointsList: {}
    }
    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.loadAllAgent()
    this.loadAllBranchManager()
    this.loadAllCountry()
    this.getUserPointsById()
  }

  async getUserPointsById() {
    let { dispatch } = this.props
    let data = await dispatch(getUserRating({}))
    console.log('6155b4fafb367853d413628b points', data)
    let obj = {}
    lodash.each(data, (item) => {
      if (item && item.userId) {
        obj[item.userId] = item.totalPoints ? item.totalPoints : ''
      }
    })
    this.setState({ userPointsList: obj })
  }

  apiRequest = (params) => {
    let { dispatch } = this.props
    return new Promise(async (resolve) => {
      params.sortField = 'name'
      params.sortOrder = 'ascend'

      // if (!params.userType || (params.userType && params.userType.length == 0)) {
      //   params.userType = ['branchManager', 'branchUser']
      // }

      let resp = await dispatch(listAllEmployees({
        ...params,
        regExFilters: ['name', 'address', 'mobile', 'email']
      }))
      console.log(resp)
      this.setState({ totalEmployees: resp.total })

      resolve(resp)
      // resolve(dispatch(listAllUsers({
      //   ...params,
      //   regExFilters: ['name', 'address', 'mobile', 'email']
      // })))
    })
  }

  async loadAllAgent() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUsers({ userType: 'agent' }))
    this.setState({ allAgents: data })
  }

  async loadAllBranchManager() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllUsers({ userType: 'branchManager' }))
    this.setState({ allBranchManager: data })
  }

  async loadAllCountry() {
    let { dispatch } = this.props
    let { data } = await dispatch(listAllCountries())
    this.setState({ allCountry: data })
  }

  viewInfo(record) {
    this.setState({
      selectedRow: record,
      viewInfoDrawer: true
    })
  }

  onClose = () => {
    this.setState({
      selectedRow: {},
      viewInfoDrawer: false
    })
  }

  closeUpdatePassword = () => {
    this.setState({
      selectedUser: {},
      visibleUpdatePassword: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  closeEditUser = () => {
    this.setState({
      selectedUser: {},
      visibleUpdateUser: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  updateAgentBlockStatus = async (id) => {
    let { dispatch } = this.props
    let resp = await dispatch(blockAgent({ agentId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  makeDefaultReferralFxn = async (id) => {
    let { dispatch } = this.props
    let resp = await dispatch(updateDefaultReferralApi({ userId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }
  allowShareReferralFxn = async (id) => {
    let { dispatch } = this.props
    let resp = await dispatch(updateShareReferralAllowApi({ userId: id }))
    if (resp && resp.success) {
      this.tableRef.current.reload()
    }
  }

  closeEditUserExtraInfo = () => {
    this.setState({
      selectedUser: {},
      visibleUserExtraInfo: false
    }, () => {
      this.tableRef.current.reload()
    })
  }
  closeRatingDrawer = () => {
    this.setState({
      selectedUser: {},
      visibleRatingDrawer: false
    }, () => {
      this.tableRef.current.reload()
      this.getUserPointsById()
    })
  }
  closeRatingLogsDrawer = () => {
    this.setState({
      selectedUser: {},
      visibleRatingLogsDrawer: false
    }, () => {
      this.tableRef.current.reload()
    })
  }

  render() {
    let {
      selectedRow,
      allAgents,
      allBranchManager,
      allCountry,
      visibleUpdatePassword,
      selectedUser,
      visibleUpdateUser,
      visibleUserExtraInfo,
      visibleRatingDrawer,
      visibleRatingLogsDrawer,
      visibleUpdateSubAgent,
      visibleUserRightDrawer,
      visibleAddEmployeeDrawer,
      totalBranchUser
    } = this.state
    let { dispatch } = this.props
    const columns = [
      {
        title: '#',
        key: '_id',
        dataIndex: '_id',
        width: 50,
        render: (ite, record, index) => {
          return (
            <React.Fragment>
              {index + 1}
            </React.Fragment>
          )
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name',
        width: 100,
        render: (item, record) => {
          let { referCode, branchManagerType } = record
          return (
            <ColumnWidth width={100}>
              {item}
              {record.postName ? <div>({record.postName})</div> : null}
            </ColumnWidth>
          )
        }
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        searchTextName: 'email',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Designation',
        dataIndex: 'designation',
        key: 'Designation',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Expense Department',
        dataIndex: 'departmentName',
        key: 'departmentName',
        width: 120
      },
      {
        title: 'UserType',
        dataIndex: 'userType',
        key: 'userType',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{item}</ColumnWidth>
          )
        }
      },
      {
        title: 'Date of Birth',
        key: 'dateOfBirth',
        width: 120,
        render: (item) => {
          return (
            <ColumnWidth width={120}>{displayDate(item)}</ColumnWidth>
          )
        }
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobile',
        key: 'mobile',
        searchTextName: 'mobile',
        width: 80,
        render: (item) => {
          return (
            <ColumnWidth width={80}>{item}</ColumnWidth>
          )
        }
      },
      /* {
         title: 'Address',
         dataIndex: 'address',
         key: 'address',
         searchTextName: 'address',
         width: 150,
         render: (item) => {
           return (
             <ColumnWidth width={150}>{item}</ColumnWidth>
           )
         }
       },*/

      {
        title: 'Country',
        dataIndex: 'countryId',
        key: 'countryId',
        filters: allCountry.map(x => ({ value: x._id, text: x.countryName })),
        width: 100,
        render: (item) => {
          return (
            <ColumnWidth width={100}>{item && item.countryName ? item.countryName : ''}</ColumnWidth>
          )
        }
      },


      {
        title: 'User Rating',
        dataIndex: 'userRating',
        key: 'userRating',
        width: 100,
        render: (item, { _id }) => {
          let { userPointsList } = this.state
          // console.log(record._id,userPointsList)
          return userPointsList[_id] ? `${userPointsList[_id]} points` : ''
          // return null
        }
      },
      /*
            {
              title: 'Logo',
              dataIndex: 'logo',
              key: 'logo',
              width: 100,
              render: (val) => {
                return (
                  <ColumnWidth width={100}>  {val && val.url ? <img src={val.url} className={'imgSm'}/> : null}</ColumnWidth>
                )
              }
            },*/
      {
        title: 'Action',
        key: '_id',
        dataIndex: '_id',
        width: 150,
        render: (val, record) => {
          return (
            <React.Fragment>
              <CheckMyRights rightList={[...adminRightUser, 'branchUser', 'hr']}>
                <Tooltip title='Edit Extra info'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleUserExtraInfo: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'edit'} />
                  </button>
                </Tooltip>

                <Tooltip title='Rating'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleRatingDrawer: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'trophy'} />
                  </button>
                </Tooltip>

                <Tooltip title='Rating logs'>
                  <button
                    className={'btn'}
                    style={{ marginRight: 6 }}
                    onClick={() => {
                      this.setState({
                        visibleRatingLogsDrawer: true,
                        selectedUser: record
                      })
                    }}>
                    <Icon type={'eye'} />
                  </button>
                </Tooltip>
              </CheckMyRights>
            </React.Fragment>
          )
        }
      }
    ]
    return (
      <div>

        <div className='row  mt-4'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='table-head d-flex align-items-center'>
                <h5>All Employees ({this.state.totalEmployees})</h5>
                <div className='search-box-table'>

                </div>

                <CheckMyRights rightList={[...adminRightUser, 'branchUser', 'hr']}>
                  <div className='sort-box-table mark-btn'>
                    <div>
                      <a className='btn' onClick={() => this.events.showAddEmployeeDrawer()}>
                        <img src={'../dist/icons/plus.png'} className={'plus'} /> Add Employee</a>
                    </div>
                  </div>
                </CheckMyRights>
              </div>
              <div className='card-body table-responsive'>
                <TableComp columns={columns}
                           ref={this.tableRef}
                           pagination={{
                             position: 'top',
                             pageSizeOptions: ['20', '50', '100', '200'],
                             defaultPageSize: 50,
                             current: 1
                           }}
                           apiRequest={this.apiRequest}
                           extraProps={{ scroll: { x: 1000 } }}
                />
              </div>
            </div>
          </div>
        </div>


        <Drawer visible={this.state.viewInfoDrawer}
                width={520}
                onClose={this.onClose}>
          <Card title={'Universities'}>
            {selectedRow && selectedRow.universities && selectedRow.universities.length ?
              <List
                size='small'
                bordered
                dataSource={selectedRow.universities}
                renderItem={item => <List.Item>{item && item.universityName ? item.universityName : null}</List.Item>}
              /> : null
            }

          </Card>
        </Drawer>
        {visibleUpdatePassword ? <UpdatePassword
          visible={visibleUpdatePassword}
          onClose={() => this.closeUpdatePassword()}
          userData={selectedUser} /> : ''}

        {visibleUpdateUser ? <EditUser
          visible={visibleUpdateUser}
          onClose={() => this.closeEditUser()}
          userData={selectedUser} /> : ''}

        {visibleUserExtraInfo ? <EditUserExtraInfo
          visible={visibleUserExtraInfo}
          onClose={() => this.closeEditUserExtraInfo()}
          userData={selectedUser} /> : ''}

        {visibleRatingDrawer ? <RatingDrawer
          visible={visibleRatingDrawer}
          onClose={() => this.closeRatingDrawer()}
          userData={selectedUser} /> : ''}

        {visibleRatingLogsDrawer ? <RatingLogsDrawer
          visible={visibleRatingLogsDrawer}
          onClose={() => this.closeRatingLogsDrawer()}
          userData={selectedUser} /> : ''}


        {visibleUserRightDrawer ? <UserRightList
          user={selectedUser}
          visible={visibleUserRightDrawer}
          onClose={() => this.events.hideUserRightDrawer()}
          closeAndUpdate={() => this.events.closeAndUpdateUserRightDrawer()} /> : ''}

        {visibleAddEmployeeDrawer ? <AddEmployee
          user={selectedUser}
          visible={visibleAddEmployeeDrawer}
          onClose={() => this.events.hideAddEmployeeDrawer()} /> : ''}

      </div>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllManagersAndUsers)
