import React, { useEffect, useState } from 'react'
import { groupedAmenitiesFxn } from '../../../components/_utils/unizhomeUtils'
import { Card, Icon, Popconfirm, Row, Col, Select, Spin, notification } from 'antd'
import {
  addAmenitiesFxn,
  commonRoomAmenitiesFxn,
  deleteAmenitiesFxn,
  getAmenitiesCategoryFxn,
} from '../action'
import { InputBox } from '../../../components/_utils/appUtils'
import { useDispatch } from 'react-redux'
import debounce from 'lodash/debounce'
import _ from 'lodash'
import moment from 'moment'
import { useRef } from 'react/index'

let { Option } = Select


class AmenitiesTypeheadComponent extends React.Component {
  state = {
    arrList: [],
    value: [],
    fetching: false,
    amenity: '',
    name: '',
    typedValue: '',
  }

  constructor(props) {
    super(props)
  }

  _getUserId = () => {
    let { name } = this.props
    if (name) {
      this.setState({
        name
      }, () => {
        this.fetchAmenities()
      })
    }
  }

  componentDidMount() {
    this._getUserId()
  }

  fetchAmenities = async (amenity) => {
    this.setState({ arrList: [], fetching: true });
    let obj = {
      results: 30,
      count: 30,
      name: amenity,
      select: ['name', 'type'],
      regExFilters: ['name', 'type'],
    };

    let { data } = await commonRoomAmenitiesFxn(obj);
    let dataNew = [];
    if (data && data.length) {
      _.each(data, (user) => {
        dataNew.push({
          text: user.name,
          value: user.name,
        });
      });
    }


    let newObj = {
      arrList: dataNew,
      fetching: false,
    };

    if (!dataNew.some((item) => item.text === amenity)) {
      newObj.typedValue = amenity;
    }

    this.setState(newObj);


  }



  handleChange = value => {
    let { onSelect } = this.props
    this.setState({
      amenity: value,
      name: value && value.key ? value.key : '',
      data: [],
      fetching: false,
      typedValue: '',
    })
    onSelect((value && value.key) || '')
  }

  render() {
    const { fetching, arrList, amenity, required = false,typedValue  } = this.state
    let { customStyle = '' } = this.props
    const typedValueExists = arrList.some((d) => d.value === typedValue);
    return (
      <Select
        labelInValue
        value={amenity || undefined}
        className={customStyle}
        placeholder='Search Amenities'
        allowClear={true}
        notFoundContent={fetching ? <Spin size='small' /> : null}
        filterOption={false}
        showSearch={true}
        onSearch={debounce(this.fetchAmenities, 500)}
        onChange={this.handleChange}>
        {arrList.map(d => (
          <Option key={d.value} value={d.value}>{d.text}</Option>
        ))}
        {!typedValueExists && typedValue && (
          <Option key={typedValue} value={typedValue}>
            {typedValue}
          </Option>
        )}
      </Select>
    )
  }
}

let initState = {
  featureType: '',
  featureName: '',
  name: ''
  // type: ''
}

const AmenitiesComponent = (props) => {
  let dispatch = useDispatch()
  let { amenities, onClick, propertyId , roomId, getAmenities,refreshData} = props
  const tableRef = useRef();
  let [list, setList] = useState([])
  let [state, setState] = useState(initState)
  let [refreshKey, setRefreshKey] = useState(moment())

  let [amenitiesCategory, setAmenitiesCategory] = useState([])
  useEffect(() => {
    amenitiesList()
  }, [amenities])
  const amenitiesList = () => {
    let resp = groupedAmenitiesFxn(amenities)
    setList(resp)
  }

  const events = {
    getAllAmenitiesCategory: async () => {
      let { data } = await dispatch(getAmenitiesCategoryFxn())
      setAmenitiesCategory(data)
    },
    handleCategoryChange: (category) => {
      setState((prevData) => ({
        ...prevData,
        featureName: category,
        featureType: 'unit_amenities'
      }));
    },
    _updateState: (data) => {
      setState((prevData) => {
        return {
          ...prevData,
          ...data
        }
      })
    },
    handleSubmit: async (e) => {
      e.preventDefault()
      const { name, featureName } = state
      if (!name || !featureName) {
        notification.warning({ message: 'Fill required fields' })
        return
      }
      let updatedState = {
        ...state,
        name: name,
        type: name,

        propertyId: propertyId
      }
      if (roomId) {
        updatedState.roomId = roomId
      }
      let resp = await dispatch(addAmenitiesFxn(updatedState))
      if (resp && resp.success) {
        setState((prevState) => {
          return {
            ...prevState,
            ...initState
          }
        })
        setRefreshKey(moment())
        refreshData()
      }
    }
  }
  const handleDeleteAmenities = async (data, propertyId) => {

    let updateState = {
      propertyId:propertyId,

      amenityId: data._id
    }
    if (roomId) {
      updateState.roomId = roomId
    }
    let resp = await dispatch(deleteAmenitiesFxn(updateState))
    if (resp) {

      refreshData()
    }

  }
  useEffect(() => {
    events.getAllAmenitiesCategory()
  }, [])

  return (
    <div>
      <Card className={'mb20 '}>
        <Row gutter={12}>

          <Col span={8}>
            <InputBox title={'Amenities Category'}>
              <Select
                name='Amenities Category'
                span={6}
                allowClear={true}
                value={state.featureName || 'Amenities Category'}
                placeholder={'Amenities Category'}
                onChange={events.handleCategoryChange}>
                {amenitiesCategory && amenitiesCategory.length
                  ? amenitiesCategory.map((item, key) => {
                    return (
                      <Option value={item.name} key={key}>
                        {item.name}
                      </Option>
                    )
                  })
                  : null}
              </Select>
            </InputBox>
          </Col>
          <Col span={8}>
            <InputBox title={'Amenities'}>
              <AmenitiesTypeheadComponent
                name={state.name}
                key={refreshKey}
                onSelect={(name) => {
                  events._updateState({ name })
                }} />
            </InputBox>
          </Col>
          <Col md={8} sm={8} xs={12} lg={4} className='mt-4'>
            <a className={'btn btn-default roundNew lg mt-1'} onClick={events.handleSubmit}>
              Submit
            </a>
          </Col>

        </Row>
      </Card>

      {list && list.length ?
        list.map((item) => {
          return (
            <Card title={item.name} size={'small'} className={'mb20'}>
              <div className={'rowWrap'}>
                {item.data.map((dd) => {
                  return (
                    <a className={'btn btn-default roundNew sm mr5 aic'}>
                      <span className={'mr10'}>{dd.name}</span>
                      <Popconfirm title={'Are you sure, you want to delete this amenity?'} onConfirm={() => {
                        handleDeleteAmenities(dd, propertyId)
                      }}>
                        <Icon type={'close'}
                              style={{ color: 'red' }} />
                      </Popconfirm>
                    </a>
                  )
                })}
              </div>
            </Card>
          )
        }) : null}

    </div>
  )
}

export default AmenitiesComponent
