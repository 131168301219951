import React, { useEffect, useRef, useState } from 'react'
import { Card, Row, Col, Form, notification, Tooltip, Icon, Button, Select, Modal } from 'antd'
import { connect, useDispatch } from 'react-redux'
import {
  getFinalChangesInInrFxn,
  reeudoRatesFxn,
  reeudoSourceFxn,
  createQuotationFxn,
  allQuotationsFxn,
  singleAgentMarginFxn,
  getReferenceUsersFxn,
  currentForexStatusFxn,
  quotationReadStatusFxn,
  getAgentsFxn,
  agentUnizMarginFxn
} from '../actions'
import { debounce } from 'lodash'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import './styles.less'
import {
  displayDate,
  displayTime,
  InputBox, ryanAgentId, rupeeSymbol, adminRightUser
} from '../../../components/_utils/appUtils'
import GetEachFormFields from '../../../components/_utils/appFormUtils'
import _ from 'lodash'
import { TableComp } from 'sz-react-utils-lite'
import AddTxnStudent from './addTxnStudent'
import './styles.css'
import TransactionDetailsModal from './transactionDetailsModal'
import ReeudoTimer from '../component/reeudoTimer'
import AgentTransactionModal from './transactionModal'
import UpdatedQuotationModal from '../component/updatedQuotationModal'
import ReeudoAgentAutoComplete from '../component/reeudoAgentAutoComplete'
import moment from 'moment'
import { stringToFixed } from '../reeudoUtils'
import AssignQuotationUser from './assignQuotationUser'
import AgentMarginModal from './agentMarginModal'
import AgentUnizMarginModal from './agentUnizMarginModal'
import { showPageLoad } from '../../../modules/actions'

const options = {
  autoplay: false,
  loop: false,
  nav: true,
  dots: false,
  // dotClass: 'owl-dot dotNone',
  margin: 15,
  autoplayTimeout: 2000,
  infinite: false,
  speed: 1500,
  lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  autoplaySpeed: 2000,
  responsive: {
    0: {
      items: 6,
      nav: true
    },
    600: {
      items: 6,
      nav: false
    },
    1000: {
      items: 6,
      nav: true,
      loop: false
    }
  }
  // autoWidth:true,

}

const RenderRow = (props) => {
  let { value, label, span } = props
  return (
    <Col span={span}>
      {label}
      <h5 className={'font-weight-600'}>{value}</h5>
    </Col>
  )
}

const initialState = {
  studentObj: '',
  viewCourseDrawer: false
}

const { Option } = Select
const ReedosComponent = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue, resetFields }, currentUserRights } = props
  let dispatch = useDispatch()
  let [reeudoData, setReeudoData] = useState({})
  let [currencyList, setCurrencyList] = useState([])
  let [sourceList, setSourceList] = useState([])
  let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({})
  let [finalChangesState, setFinalChangesState] = useState({})
  let [referenceUserList, setReferenceUserList] = useState({})
  const [isAgentModal, setIsAgentModal] = useState({
    visible: false,
    rappId: ''
  })
  let [total, setTotal] = useState(0)
  const [selectedSourceId, setSelectedSourceId] = useState(2)

  let [transactionModal, setTransactionModal] = useState({
    visible: false,
    quotation: {}
    // orgYourRate: ''
  })
  let [assignModal, setAssignModal] = useState({
    visible: false,
    quotationId: ''
    // orgYourRate: ''
  })

  let [updatedQuotationState, setUpdatedQuotationState] = useState({
    quotation: {},
    visible: false,
    exchangeMargin: 0
  })


  const [quotationId, setQuotationId] = useState('')
  const [agentName, setAgentName] = useState('')
  const [fromCurrency, setFromCurrency] = useState('')
  const [name, setName] = useState('')
  const [tableSearch, setTableSearch] = useState(false)
  const [agentMargin, setAgentMargin] = useState(undefined)
  const [exchangeMargin, setExchangeMargin] = useState(undefined)
  const [agentId, setAgentId] = useState(ryanAgentId)
  const [isModalVisible, setModalVisible] = useState(false)
  const [modalSource, setModalSource] = useState(null)
  const [isForexOff, setIsForexOff] = useState(false)
  const [refreshKey, setRefreshKey] = useState(moment())
  const [loading, setLoading] = useState(true)
  const [agentList, setAgentList] = useState([])
  const [filterAgentId, setFilterAgentId] = useState('')
  const [formDataState, setFormDataState] = useState({})
  const [agentMarginModal, setAgentMarginModal] = useState({
    visible: false,
    agentId: agentId,
    agentMargin: undefined
  })
  const [agentUnizMarginModal, setAgentUnizMarginModal] = useState({
    visible: false,
    agentId: agentId,
    unizMargin: undefined
  })
  const tableRef = useRef()
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null

  const currentForexStatus = async () => {
    try {
      setLoading(true)
      const resp = await dispatch(currentForexStatusFxn())
      if (resp.status === 'off') {
        setIsForexOff(true)
      } else {
        setIsForexOff(false)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    loadAgents()
  }, [])

  const loadAgents = async () => {
    let resp = await dispatch(getAgentsFxn())
    setAgentList(resp)
  }
  const showAgentModal = (rappId) => {
    setIsAgentModal({
      visible: true,
      rappId: rappId
    })
  }

  const closeAgentModal = () => {
    setIsAgentModal({
      visible: false,
      rappId: ''
    })
  }

  const showAgentMarginModal = () => {
    setAgentMarginModal({
      visible: true,
      agentId: agentId,
      agentMargin: agentMargin
    })
  }

  const closeAgentMarginModal = () => {
    setAgentMarginModal({
      visible: false,
      agentId: agentId,
      agentMargin: agentMargin
    })
    events.singleAgent(agentId)
  }

  const showAgentUnizMargin = (agentId) => {
    setAgentUnizMarginModal({
      visible: true,
      agentId: agentId,
      unizMargin: exchangeMargin
    })
  }

  const closeAgentUnizMargin = () => {
    setAgentUnizMarginModal({
      visible: false,
      agentId: agentId,
      unizMargin: undefined
    })
    events.singleAgent(agentId)
  }

  const showAssignModal = (quotationId) => {
    setAssignModal({
      visible: true,
      quotationId: quotationId
    })
  }

  const closeAssignModal = () => {
    setAssignModal({
      visible: false,
      quotationId: ''
    })
    tableRef.current.reload()
  }

  const updateReadStatus = async (id, isReaded) => {
    if (isReaded) {
      // console.log('Already read, no need to update.');
      return
    }
    const resp = await dispatch(quotationReadStatusFxn({ id: id }))
  }
  const events = {
    setDefaultAgent: () => {
      if (currentUserRights && currentUserRights.userType == 'agent') {
        setAgentId(currentUserRights._id)
      } else {
        setAgentId(ryanAgentId)
      }
      setRefreshKey(moment())
    },
    getFlag: (item) => {
      let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
      return url
    },
    loadRates: async () => {
      let { data, success } = await dispatch(reeudoRatesFxn())
      if (success && data) {
        let dataArr = []
        let { currencyData, currencyOrder } = data
        _.each(currencyOrder, (item) => {
          let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
          let resp = currencyData && currencyData[item] ? currencyData[item] : null
          const updatedRate = (parseFloat(resp.yourRate) + parseFloat(exchangeMargin) + parseFloat(agentMargin)).toFixed(2)

          let obj = {
            currency: item,
            ...resp,
            imgPath: url,
            yourRate: updatedRate
          }
          dataArr.push(obj)
        })
        // console.log(dataArr, "dataarr")
        setCurrencyList(dataArr)
        setReeudoData(data)
        if (dataArr.length) {
          const defaultCurrency = dataArr[0]
          setFieldsValue({
            amount: 100,
            currency: defaultCurrency.currency,
            customerRate: ((parseFloat(defaultCurrency.yourRate) || 0)).toFixed(2) // Set customerRate from yourRate
          })
          setSelectedCurrencyObj(defaultCurrency)
        }

        events.setDefaultParams()
      }
    },

    loadSource: async () => {
      let { data, success } = await dispatch(reeudoSourceFxn())
      if (success && data) {
        setSourceList(data)
      }
    },
    setDefaultParams: () => {
      let currency = getFieldValue('currency')
      if (!currency && currencyList.length) {
        const defaultCurrency = currencyList[0] // Use the first currency as default
        setTimeout(() => {
          setFieldsValue({
            amount: 100,
            currency: defaultCurrency.currency,
            customerRate: ((parseFloat(defaultCurrency.yourRate) || 0)).toFixed(2) // Ensure you are using yourRate from currencyList
          })
          setTimeout(() => {
            events.getFinalChangesFxn()
          }, 100)
        }, 100)
        setSelectedCurrencyObj(defaultCurrency)
      }
    },
    setDefaultSource: () => {
      setTimeout(() => {
        setFieldsValue({
          sourceId: sourceList && sourceList.length ? sourceList[1].id : ''
        })
      }, 100)
    },
    chooseCurrency: (currency) => {
      let findDoc = _.find(currencyList, (item) => item.currency === currency)
      if (findDoc) {
        setSelectedCurrencyObj(findDoc)
        setTimeout(() => {
          setFieldsValue({
            customerRate: findDoc ? ((parseFloat(findDoc.yourRate) || 0)).toFixed(2) : '' // Get yourRate from findDoc
          })
        }, 100)
      }
    },
    getFinalChangesFxn: async () => {
      let customerRate = getFieldValue('customerRate') || 0
      if (typeof customerRate == 'string') {
        customerRate = parseFloat(customerRate)
      }
      customerRate = customerRate.toFixed(2)
      let obj = {
        currency: getFieldValue('currency'),
        amount: getFieldValue('amount'),
        customerRate: parseFloat(customerRate),
        sourceId: getFieldValue('sourceId')
      }
      if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
        let { data, success } = await dispatch(getFinalChangesInInrFxn(obj))
        if (success && data && data.charges) {
          setFormDataState(obj)
          setFinalChangesState(data.charges)
        }
      }

    },
    showTransactionModal: (quotation) => {
      setTransactionModal({
        visible: true,
        quotation: quotation
      })
    },
    hideTransactionModal: () => {
      setTransactionModal({
        visible: false,
        quotation: {}
      })
    },
    singleAgent: async (agentId) => {
      let data = await dispatch(singleAgentMarginFxn({ userId: agentId }))
      if (data) {
        if (data.agentMargin !== undefined) {
          setAgentMargin(data.agentMargin)
        }
        if (data.exchangeMargin) {
          setExchangeMargin(data.exchangeMargin)
        } else {
          setExchangeMargin(currentUserRights.defaultExchangeMargin)
        }
      } else {
        setAgentMargin(0)
      }
    },
    showPdfModal : (quotationId, pdfPath) => {
      Modal.info({
        title: 'Quotation PDF has been generated',
        content: (
            <div>
              <p>Quotation ID: {quotationId}</p>
              <a href={pdfPath} target="_blank" rel="noopener noreferrer">Click here to view the PDF</a>
            </div>
        ),
        onOk() {},
      });
    },


    createQuotation: async (formData) => {
      dispatch(showPageLoad())
      let finalChanges = finalChangesState || {}
      let updateMargin = (formData.exchangeMargin - exchangeMargin) || 0
      let customerRate = parseFloat((finalChanges.fxRate + updateMargin).toFixed(2))
      let objN = {
        currency: finalChanges.fromCurrency,
        amount: finalChanges.fromCurrencyAmount,
        customerRate: customerRate,
        sourceId: formData.sourceId ? formData.sourceId : 1
      }
      let { data, success } = await dispatch(getFinalChangesInInrFxn(objN))
      if (success && data && data.charges) {
        finalChanges = { ...finalChanges, ...data.charges }
      }
      const obj = {
        userId: '',
        amount: parseFloat(finalChanges.fromCurrencyAmount).toFixed(2) || 0,
        bankFees: parseFloat(finalChanges.bank).toFixed(2) || 0,
        yourRate: parseFloat(finalChanges.yourRate).toFixed(2) || null,
        ibrRate: parseFloat(finalChanges.ibrRate).toFixed(2) || null,
        currentRate: customerRate || null,
        fxRate: customerRate || null,
        emailId: formData ? formData.emailAddress : '',
        pan: null,
        fromCurrency: finalChanges.fromCurrency || null,
        grossAmount: parseFloat(finalChanges.base).toFixed(2) || 0,
        gst: parseFloat(finalChanges.gst).toFixed(2) || 0,
        name: formData ? formData.studentName : '',
        nostroCharge: parseFloat(finalChanges.nostro).toFixed(2) || 0,
        tcsCharge: parseFloat(finalChanges.tcs).toFixed(2) || 0,
        phoneNo: formData ? formData.phoneNumber : '',
        toCurrency: finalChanges.toCurrency || null,
        totalAmount: parseFloat(finalChanges.total).toFixed(2) || 0,
        consultantName: null,
        consultantId: null,
        sourceId: formData.sourceId ? formData.sourceId : 1,
        agentId: formData.agentId ? formData.agentId : user._id
      }
      if (formData && formData.referencedBy && formData.referencedBy !== 'NA') {
        obj.referencedBy = formData.referencedBy
      }
      if (formData && formData.exchangeMargin && formData.exchangeMargin !== null) {
        obj.exchangeMargin = formData.exchangeMargin
      } else {
        obj.exchangeMargin = exchangeMargin
      }
      let resp = await dispatch(createQuotationFxn(obj))
      if (resp) {
        notification.success({ message: resp.message })
        closeModal()
        events.showTransactionModal(resp.quotationData)
        // if (resp.quotationData && resp.quotationData.quotationPdf && resp.quotationData.quotationPdf.path !== "") {
        //   events.showPdfModal(resp.quotationData.quotationId, resp.quotationData.quotationPdf.path);
        // }

        tableRef.current.reload()
      }
    },
    apiRequest: (params) => {
      return new Promise(async (resolve) => {
        if (quotationId) {
          params.customQuery = JSON.stringify({
            quotationId: { $regex: quotationId, $options: 'i' }
          })
        }
        if (agentName) {
          params.agentName = agentName
        }
        if (fromCurrency) {
          params.fromCurrency = fromCurrency
        }
        if (name) {
          params.name = name
        }
        if (filterAgentId) {
          params.agentId = filterAgentId
        }

        if (tableSearch) {
          params.page = 1
        }

        let resp = await dispatch(allQuotationsFxn({
          ...params,
          regExFilters: ['name', 'quotationId', 'agentName', 'fromCurrency']
        }))
        setTotal(resp.total)
        setTableSearch(false)
        resolve(resp)
      })
    },
    loadReferUsers: async () => {
      let { data } = await getReferenceUsersFxn({ agentId })
      let finArr = [{ _id: 'NA', name: 'NA' }]
      if (data && data.length) {
        _.each(data, (item) => {
          let postName = item.branchManagerType == 'agentWise' ? 'ARM' : 'Marketing User'
          item.name = `${item.name} (${postName})`
        })
        finArr = [...finArr, ...data]
      }
      setReferenceUserList(finArr)
    },

    showUpdateQuoModal: (data) => {
      setUpdatedQuotationState({
        quotation: data,
        visible: true,
        exchangeMargin: exchangeMargin
      })
    },
    hideUpdateQuoModal: () => {
      setUpdatedQuotationState({
        quotation: {},
        visible: false,
        exchangeMargin: exchangeMargin
      })
    },

    reloadTable: () => {
      if (tableRef && tableRef.current) {
        tableRef.current.reload()
      }
    },
    enterName: (name) => {
      setName(name)
    },
    searchData: async () => {
        events.reloadTable()
    },

    clearFxn: async () => {
      setQuotationId('')
      setAgentName('')
      setFromCurrency('')
      setName('')
      setTableSearch(true)
      setFilterAgentId('')
      setTimeout(() => {
        events.reloadTable()
      }, 100)
    }
    // transactionCount: async () => {
    //     let resp = await dispatch(getTransactionCountFxn())
    //     console.log(resp, "respppppp-count1")
    // },
    // quotationCount: async () => {
    //     let resp = await dispatch(getQuotationCountFxn())
    //     console.log(resp, "respppppp-count1")
    // }


  }

  const debouncedGetFinalChangesFxn = debounce(events.getFinalChangesFxn, 300)
  const showModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }


  useEffect(() => {
    currentForexStatus()
    events.setDefaultAgent()
  }, [])
  useEffect(() => {
    setAgentMargin(currentUserRights.agentMargin || 0)
    setExchangeMargin(currentUserRights.exchangeMargin || 0)
  }, [currentUserRights])


  useEffect(() => {
    // if (loading || isForexOff) return;
    window.scrollTo(0, 0)
    events.loadSource()
    // events.transactionCount();
    // events.quotationCount();
    // events.singleAgent(ryanAgentId)
  }, [])


  useEffect(() => {
    if (agentId) {
      events.loadReferUsers()
    }
  }, [agentId])

  useEffect(() => {
    // if (loading || isForexOff) return;
    if (agentMargin !== undefined && exchangeMargin !== undefined) {
      events.loadRates()
    }
  }, [agentMargin, exchangeMargin])

  useEffect(() => {
    // if (loading || isForexOff) return;
    events.setDefaultParams()
  }, [])

  useEffect(() => {
    // if (loading || isForexOff) return;

    events.setDefaultSource()
  }, [sourceList])

  useEffect(() => {
    // if (loading || isForexOff) return;
    debouncedGetFinalChangesFxn()
  }, [
    getFieldValue('currency'),
    getFieldValue('amount'),
    getFieldValue('customerRate'),
    getFieldValue('sourceId'),
    agentMargin
    // isForexOff,
    // loading
  ])

  let inputTypes = {
    fields: [
      {
        key: 'currency',
        label: 'Currency',
        type: 'select',
        required: true,
        options: currencyList,
        keyAccessor: x => x.currencyCode,
        valueAccessor: (x) => {
          return (
            <>
              <img src={x.imgPath} style={{ height: 25 }} />
              <span className={'ml20 font-weight-600'}>{x.currencyCode} - {x.currencyName}</span>
            </>
          )
        },
        onChange: x => {
          events.chooseCurrency(x)
          setFieldsValue({
            currency: x
          })
        }
      },
      { key: 'customerRate', label: 'Customer Rate', required: true, type: 'number' },
      { key: 'amount', label: 'Amount', required: true },
      {
        key: 'sourceId',
        label: 'Source',
        type: 'select',
        required: true,
        options: sourceList,
        keyAccessor: x => x.id,
        valueAccessor: (x) => x.sourceOfFund,
        onChange: x => {
          events.chooseCurrency(x)
          setFieldsValue({
            sourceId: x
          })
        }
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const ViewQuoFxn = (record) => {
    return (
      <div style={{ paddingLeft: 10 }}>
        <a className={'btn roundNew xs btn-default mt5'}
           onClick={() => {
             events.showTransactionModal(record)
             setModalSource('isReaded')
           }}>
          View
        </a>
      </div>
    )
  }

  const columns = [
    {
      title: 'Sr No.',
      dataIndex: 'serialNo',
      width: 70,
      render: (val, record, index) => <div>{index + 1}</div>
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 120,
      render: (text) => <span>{displayDate(text, 'DD/MM/YYYY')}</span>
    },
    {
      title: 'Quotation Id',
      dataIndex: 'quotationId',
      width: 120,
      render: (text, record) => (
        <div>


          <div>
            {user && user.userType == 'reeudoManager' ?
              <>
                <div style={{ fontWeight: record.isReaded ? 'normal' : 'bold' }} className={'jcic'}>
                  {record.isReaded ? null : <div className={'roundDot mr5'}></div>}
                  {text}
                </div>


                {!record.isReaded ? (
                  <a className={'btn roundNew xs btn-primary mt5 ml10'}
                     onClick={() => {
                       updateReadStatus(record._id, record.isReaded)
                       events.showTransactionModal(record)
                       setModalSource('isReaded')
                     }}>
                    Read
                  </a>
                ) : ViewQuoFxn(record)}
              </> :
              <>
                <div className={'jcic'}>
                  {text}
                </div>
                {ViewQuoFxn(record)}
              </>}

          </div>

        </div>
      )
    },
    {
      title: 'Student',
      dataIndex: 'name',
      width: 200,
      render: (text, record) => (
        <div>
          {<div>{text}</div>}
          {record && record.phoneNo ? <div><b>Mobile-</b>{record.phoneNo}</div> : ''}
          {record && record.emailId ? <div><b>Email-</b>{record.emailId}</div> : ''}
        </div>
      )
    },
    {
      title: 'Agent Name',
      dataIndex: 'agentId',
      width: 200,
      render: (item) => (
        <div>
          {item && item.name ? <div>{item.name}</div> : ''}
          {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ''}
          {item && item.email ? <div><b>Email-</b>{item.email}</div> : ''}
        </div>

      )
    },
    // {
    //     title: 'Consultant',
    //     dataIndex: 'consultantName',
    //     render: (text) => <span>{text}</span>,
    // },
    {
      title: 'Currency',
      dataIndex: 'fromCurrency',
      width: 100,
      render: (text) => <span>{text}</span>
    },

    {
      title: 'Your Rate',
      dataIndex: 'yourRate',
      width: 100,
      render: (text, record) => (
        <span> {rupeeSymbol}{(parseFloat(record.exchangeMargin) + text).toFixed(2)}</span>
      )
    },

    {
      title: 'Booking Rate',
      dataIndex: 'currentRate',
      width: 100,
      render: (text) => <span> {rupeeSymbol}{text}</span>
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      render: (text) => <span>{text}</span>
    },
    {
      title: 'Gross Amount',
      dataIndex: 'grossAmount',
      width: 100,
      render: (item) => {
        return `${rupeeSymbol}${stringToFixed(item)}`
      }
    },
    {
      title: 'Assigned User',
      key: 'assignedUserId',
      dataIndex: 'assignedUserId',
      width: 240,
      render: (item) => {
        return (
          <div>
            {item && item.name ? <div>{item.name}</div> : ''}
            {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ''}
            {item && item.email ? <div><b>Email-</b>{item.email}</div> : ''}
          </div>
        )
      }
    },
    {
      title: 'Referenced By',
      key: 'referencedBy',
      dataIndex: 'referencedBy',
      width: 240,
      render: (item) => {
        return (
          <div>
            {item && item.name ? <div>{item.name}</div> : ''}
            {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ''}
            {item && item.email ? <div><b>Email-</b>{item.email}</div> : ''}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'quotationPdf',
      fixed: 'right',
      render: (text, record) => (
        <>
          {record.quotationPdf && record.quotationPdf.path ? (
            <Tooltip title={'Download Quotation'}>
              <a download={record.quotationPdf.fileName}
                 className={'btn roundNew xs btn-default'}
                 href={record.quotationPdf.path}
                // onClick={() =>  updateReadStatus(record._id, record.isReaded)}
                 target='_blank'>
                <Icon type={'download'} /><br />
                Download
              </a>
            </Tooltip>
          ) : null}

          <Tooltip title={' Get Updated Details - Quotation & Book Transaction'}>
            <a className={'btn roundNew xs btn-default ml10'} onClick={() => {
              events.showUpdateQuoModal(record)
            }}>
              Get Updated Details<br />
              Quotation & Book Transaction
            </a>
          </Tooltip>
          <Tooltip title='Assign User'>
            <a className={'btn roundNew xs btn-default ml10'} onClick={() => {
              showAssignModal(record._id)
            }}>
              <Icon type={'user-add'} /><br />
              Assign User
            </a>
          </Tooltip>
        </>
      )
    }


  ]
  const fixed2Digit = (value) => {
    const num = parseFloat(value) || 0
    return num.toFixed(2)
  }


  return (
    <>
      <div className='row mt-4'>

        <div className='col-lg-12'>
          <div className='card'>

            <div className=''>

              <div className={'jcsb'} style={{ padding: '1px 32px' }}>
                <div className={'jcsb'}>
                  <div className={'mr50'}>
                    IBR Rate<br />
                    <strong>{reeudoData.date ? displayDate(reeudoData.date, 'DD/MM/YYYY') : null}</strong>
                  </div>
                  <div className={'mr50'}>
                    Last Updated<br />
                    <strong>{reeudoData.date ? displayTime(reeudoData.date, 'hh:mm:ss a') : null}</strong>
                  </div>
                  {user && [...adminRightUser, 'reeudoManager', 'assistantMarketingManager'].includes(user.userType) && (
                    <>
                      <div className={'mr50'}>
                        Agent Margin
                        <div className={'jcsb'}>
                          <strong>
                            {agentMargin ? `${agentMargin}` : '0'}
                          </strong>
                          <a className={'bs-link bs-xs font12'}
                             onClick={() => showAgentMarginModal(agentId)}>
                            Update&nbsp;
                            <Icon type={'edit'} />
                          </a>
                        </div>
                      </div>

                      <div className={'mr50'}>
                        Exchange Margin
                        <div className={'jcsb'}>
                          <strong>{exchangeMargin}</strong>
                          <a className={'bs-link bs-xs font12'}
                             onClick={() => showAgentUnizMargin(agentId)}>
                            Update&nbsp;
                            <Icon type={'edit'} />
                          </a>
                        </div>
                      </div>
                    </>
                  )}

                  <div className={'d-flex'}>
                    <ReeudoTimer callback={() => {
                      events.loadRates()
                    }} />
                    {isForexOff && (
                      <div className={'ml20'}>
                        <label className={'label label-danger forex-label d-flex appAction'}>
                          <Icon type='close-circle' />
                          <span className={'ml5'}>Forex is Closed</span>
                        </label>
                      </div>)}
                  </div>
                </div>


                {user && user.userType !== 'agent' ? (
                  <div key={refreshKey} className={'w-25'}>

                    <InputBox title={'* Choose Agent'}>
                      <ReeudoAgentAutoComplete
                        dispatch={dispatch}
                        customStyle={''}
                        agentId={agentId}
                        // defaultValue={ryanAgentId}
                        onSelect={item => {
                          setAgentId(item)
                          events.singleAgent(item)

                        }}
                      />
                    </InputBox>


                  </div>
                ) : null}


              </div>

            </div>
            <div className='card-body table-responsive'>
              <div className={'currency-list reeudo-slider'}>
                <OwlCarousel
                  className='owl-theme '
                  {...options}>
                  {currencyList && currencyList.length ? currencyList.map((item) => {
                    return (
                      <Card className={'currency-card'}>
                        <div className={'jcsb'}>
                          <div>
                            <div>
                              Live IBR / <span
                              className={'currency-name'}>{item.currency}</span>
                            </div>
                            <div className={'currency-value'}>
                              {item.rate}
                            </div>
                          </div>
                          <img src={events.getFlag(item.currency)}
                               style={{ height: 25, width: 'auto' }} />
                        </div>
                        <div className={'your-rate'}>
                          Your Rate<br />
                          <div className={'rate'}>
                            {item.yourRate}
                          </div>
                        </div>
                      </Card>
                    )
                  }) : null}
                </OwlCarousel>
              </div>
            </div>
            <div className='card-body table-responsive'>
              <Row gutter={24}>
                {inputTypes.fields.map((item, key) => {
                  return (
                    <Col span={6} key={key}>
                      <GetEachFormFields
                        item={item}
                        getFieldDecorator={getFieldDecorator}
                        formItemLayout={formItemLayout} />
                    </Col>
                  )
                })}
                {/*<Col span={4} className={'mt40'}>*/}
                {/*    <a className={'btn roundNew btn-default btn-block lg'} onClick={() => {*/}
                {/*        events.getFinalChangesFxn()*/}
                {/*    }}><Icon type={'search'}/> Search</a>*/}
                {/*</Col>*/}
              </Row>
              <Row className={'mt20'} gutter={15}>
                <Col span={18}>
                  <Card>
                    <Row>
                      <RenderRow
                        label={'Currency Type'}
                        value={finalChangesState && finalChangesState.fromCurrency ? finalChangesState.fromCurrency : ''}
                        span={7}
                      />
                      <RenderRow
                        label={'Foreign Currency Amount'}
                        value={finalChangesState && finalChangesState.fromCurrencyAmount ? finalChangesState.fromCurrencyAmount : ''}
                        span={6}
                      />
                      <RenderRow
                        label={'Rate'}
                        value={finalChangesState && finalChangesState.fxRate ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.fxRate)}` : `${rupeeSymbol} 0.00`}
                        span={5}
                      />
                      <RenderRow
                        label={'Gross Amount'}
                        value={finalChangesState && finalChangesState.base ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.base)}` : `${rupeeSymbol} 0.00`}
                        span={5}
                      />
                    </Row>
                    <Row className={'mt10'}>
                      <RenderRow
                        label={'Foreign Conversion GST'}
                        value={finalChangesState && finalChangesState.gst ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.gst)}` : `${rupeeSymbol} 0.00`}
                        span={7}
                      />
                      <RenderRow
                        label={'Bank Processing Fee'}
                        value={finalChangesState && finalChangesState.bank ? `${rupeeSymbol} ${finalChangesState.bank}` : `${rupeeSymbol} 0`}
                        span={6}
                      />
                      <RenderRow
                        label={'Nostro Charge'}
                        value={finalChangesState && finalChangesState.nostro ? `${rupeeSymbol} ${finalChangesState.nostro}` : `${rupeeSymbol} 0`}
                        span={5}
                      />
                      <RenderRow
                        label={'TCS Charge*'}
                        value={finalChangesState && finalChangesState.tcs ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.tcs)}` : `${rupeeSymbol} 0.00`}
                        span={5}
                      />
                    </Row>

                    <div className={'mt5 gray-color'}>
                      <small className={'mt1'}>*The Final TCS may change based on the historical
                        transactions of the
                        sender and the "Source of Fund"</small>
                    </div>
                  </Card>
                </Col>
                <Col span={6}>
                  <Card>
                    <div>
                      Grand Total<br />
                      <h4 className={'font-weight-600 colorPrimaryDark'}>
                        {rupeeSymbol} {finalChangesState && finalChangesState.total ? fixed2Digit(finalChangesState.total) : 0}
                      </h4>
                    </div>
                    <a
                      className={'btn btn-primary roundNew lg mt20 w-100'}
                      onClick={() => {
                        const customerRate = getFieldValue('customerRate')
                        const yourRate = selectedCurrencyObj.yourRate
                        const selectedSourceId = getFieldValue('sourceId') // Get the selected sourceId

                        if (parseFloat(customerRate) < parseFloat(yourRate)) {
                          notification.warning({
                            message: 'Warning',
                            description: `Customer rate cannot be less than your rate ${yourRate}`
                          })
                          return
                        }

                        showModal()
                        setModalSource('quote')

                        setSelectedSourceId(selectedSourceId)
                      }}
                    >
                      Get Quote & Book Transaction
                    </a>


                  </Card>
                  {/*<div>*/}
                  {/*    <a className={'btn btn-primary roundNew lg mt10 w-100'}*/}
                  {/*       onClick={() => {*/}
                  {/*           setTransactionDrawer({*/}
                  {/*               visible: true,*/}
                  {/*               id: ''*/}
                  {/*           })*/}
                  {/*       }}>*/}
                  {/*        Book Transaction*/}
                  {/*    </a>*/}
                  {/*</div>*/}

                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className='col-lg-12 mt-3'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Recent Quotations: (Total : {total})</h5>
            </div>
            <div className='card-body table-responsive'>
              <Row gutter={10} className={'filter_box'}>
                <Col span={4}>
                  <div className='search-box-table round'>
                    <input className='form-control form-control' type='search'
                           placeholder='Search by name...' value={name} onChange={(e) => {
                      events.enterName(e.target.value)
                    }} />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </Col>
                <Col span={4}>
                  <div className='search-box-table round'>
                    <input

                      className='form-control form-control' type='search'
                      placeholder='Search by Quotation ID...'
                      value={quotationId}
                      onChange={(e) => setQuotationId(e.target.value)}
                    />
                    <img src='/dist/img/search.png' alt='' />
                  </div>
                </Col>
                {[...adminRightUser, 'reeudoManager'].includes(user.userType) ?
                  <Col span={4}>
                    <ReeudoAgentAutoComplete
                      agentId={filterAgentId}
                      customStyle={''}
                      dispatch={dispatch}
                      onSelect={item => {
                        setFilterAgentId(item)
                      }}
                    />
                  </Col> : null}
                <Col span={4}>

                  <div className=''>
                    <Select
                      className='w-100'
                      placeholder='Search by Currency...'
                      value={fromCurrency || undefined}
                      onChange={(value) => setFromCurrency(value)}
                      allowClear
                      showSearch={true}
                    >
                      {currencyList.map((item) => (
                        <Option key={item.currency} value={item.currency}>
                          {item.currency}
                        </Option>
                      ))}
                    </Select>
                  </div>


                </Col>
                <Col span={4}>
                  <Button onClick={() => events.searchData()} className={'roundBtn'}>Search</Button>
                  <Button onClick={() => events.clearFxn()} className={'roundBtn'}>
                    Clear
                  </Button>
                </Col>
              </Row>
              <TableComp
                apiRequest={events.apiRequest}
                columns={columns}
                ref={tableRef}
                extraProps={{ scroll: { x: 600 } }}
                pagination={{
                  position: 'top',
                  pageSizeOptions: ['10', '20', '25', '50'],
                  defaultPageSize: 10
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {isModalVisible ?
        <AddTxnStudent
          {...props}
          visible={isModalVisible}
          agentId={agentId}
          isForexOff={isForexOff}
          exchangeMargin={exchangeMargin}
          orgExchangeMargin={exchangeMargin}
          onClose={closeModal}
          referenceUserList={referenceUserList}
          onSubmit={events.createQuotation}
          finalChangesState={finalChangesState}
          formDataState={formDataState}
          refreshRateFxn={() => {
            closeModal()
            events.loadRates()
          }}
          sourceId={selectedSourceId}
        /> : null}

      {
        transactionModal.visible ? (
          <TransactionDetailsModal
            visible={transactionModal.visible}
            quotation={transactionModal.quotation}
            // exchangeMargin={transactionModal.exchangeMargin}
            isForexOff={isForexOff}
            onClose={(rappId) => {
              events.hideTransactionModal()
              showAgentModal(rappId)
            }}
            onSkip={() => {
              events.hideTransactionModal()
              tableRef.current.reload()
            }}
            modalSource={modalSource}
          />
        ) : null
      }
      {
        isAgentModal.visible ? (
          <AgentTransactionModal
            {...isAgentModal}
            onClose={closeAgentModal}
          />
        ) : null
      }
      {updatedQuotationState.visible ? (
        <UpdatedQuotationModal
          {...updatedQuotationState}
          isForexOff={isForexOff}
          agentMargin={agentMargin}
          currentUser={props.currentUser}
          onClose={(rappId) => {
            events.hideUpdateQuoModal()
            showAgentModal(rappId)
          }}
          onSkip={() => {
            events.hideUpdateQuoModal()
            tableRef.current.reload()
          }}
        />
      ) : null
      }
      {
        assignModal.visible ? (
          <AssignQuotationUser
            {...assignModal}
            onClose={closeAssignModal}
          />
        ) : null
      }
      {
        agentMarginModal.visible ? (
          <AgentMarginModal
            {...agentMarginModal}
            onClose={closeAgentMarginModal}
          />
        ) : null
      }
      {
        agentUnizMarginModal.visible ? (
          <AgentUnizMarginModal
            {...agentUnizMarginModal}
            defaultExchangeMargin={currentUserRights.defaultExchangeMargin}
            onClose={closeAgentUnizMargin}
          />
        ) : null
      }
    </>
  )
}

const WrapReedosComponent = Form.create()(ReedosComponent)

const mapStateToProps = ({ global }) => ({
  currentUserRights: global.currentUserRights,
  currentUser: global.currentUser
})
export default connect(
  mapStateToProps,
  null
)(WrapReedosComponent)

